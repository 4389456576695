import { gql } from '@apollo/client';

export const UPDATE_DEFAULT_CHECKOUT_DOT_COM_CARD = gql`
  mutation UpdateDefaultCheckoutDotComCard($defaultMitCard: DefaultMitCardInput!) {
    updateDefaultMitCard(defaultMitCard: $defaultMitCard) {
      status
      errorMessage
    }
  }
`;
