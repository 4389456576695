import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { logger } from 'components/LogError';

interface UseMutateReturnHookResponse<T, P> {
  data: AxiosResponse<T, P> | undefined;
  mutateAsync: UseMutateAsyncFunction<AxiosResponse<T, P>, unknown, P | undefined, unknown>;
  error: unknown;
  isLoading: boolean;
}
interface IUseMutate<T, P> {
  mutationFn: (params?: P) => Promise<AxiosResponse<T, P>>;
}

const usePurchase = <T, P>({ mutationFn }: IUseMutate<T, P>): UseMutateReturnHookResponse<T, P> => {
  const { data, mutateAsync, error, isLoading } = useMutation({
    mutationFn: (params?: P) => mutationFn(params),
  });

  logger(data as unknown as Record<string, unknown>, { tags: 'campaignOfferPurchase' });

  return { data, mutateAsync, error, isLoading };
};

export default usePurchase;
