import { httpGet } from '../utils';

export const fetchLearningHubDocuments = () =>
  fetchDocuments<LearningHubDocument>({ docStatus: 'current', docType: 'cwi_portal_learning_hub' });

export const fetchOfferDocuments = ({ docStatus }: Pick<Params, 'docStatus'>) =>
  fetchDocuments<OfferDocument>({ docStatus, docType: 'cwi_portal_offer' });

const fetchDocuments = <TDocument extends Document>({ docType, docStatus }: Pick<Params, 'docType' | 'docStatus'>) =>
  httpGet<Result<TDocument>, Params>('third-party-integrations/PrismicDocuments', {
    docType,
    docStatus,
    page: 1,
    pageSize: 1000,
  });

interface Params {
  docStatus: 'current' | 'previous';
  docType: 'cwi_portal_learning_hub' | 'cwi_portal_offer';
  page: number;
  pageSize: number;
}

interface Result<TDocument extends Document> {
  page: number;
  resultPerPage: number;
  totalPages: number;
  totalResultsSize: number;
  resultSize: number;
  results: TDocument[];
}

type Document = LearningHubDocument | OfferDocument;

interface LearningHubDocument {
  id: string;
  contentShort: string;
  contentType: 'Article' | 'Video';
  mainImage: string;
  publishDate: string;
  title: string;
}

interface OfferDocument {
  id: string;
  expiryDate: string;
  mainImage: string;
  name: string;
  priceGbp: number;
  region: string;
  subtitle: string;
  type: string;
  unitSize: string;
}
