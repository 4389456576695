import { FC, ReactNode } from 'react';
import { getRegionColors, handleImageError } from '../../../helpers';
import { classNames } from '../../../utils';

const defaultBgColor = '#F4F4F4';
interface ProductImageProps {
  bgColor?: string;
  imageUrl?: string;
  region?: string;
  textColor?: string;
  children?: ReactNode;
  imageContainer?: string;
  imageByLwin18?: string;
}

const ProductImage: FC<ProductImageProps> = ({
  bgColor,
  imageUrl,
  region,
  textColor,
  children,
  imageContainer = '',
  imageByLwin18,
}) => {
  const txtColor = textColor || getRegionColors(region).textColor;

  const getImageByLwin18 = () => {
    const lwin18Image = `https://cwcdst2prdctimgimprtfnct.blob.core.windows.net/height/200/${imageByLwin18?.substring(
      0,
      7,
    )}.png`;
    return lwin18Image;
  };

  return (
    <div className="relative">
      <div
        className={` overflow-hidden flex flex-col  justify-center items-center`}
        style={{
          background: defaultBgColor,
          height: '264px',
        }}
      >
        {!children && imageUrl && imageUrl.length > 0 && (
          <div className="w-full relative left-0 flex flex-col h-100  items-center">
            <div className={classNames(' relative h-[264px] img-container', `${imageContainer}`.trim())}>
              <img src={imageUrl} alt="product" className="bg-transparent object-cover img" />
            </div>
          </div>
        )}

        {!children && imageByLwin18 && imageByLwin18.length > 0 && (
          <div className="w-full relative left-0 flex flex-col h-100  items-center">
            <div className={classNames(' relative max-h-[264px] img-container', `${imageContainer}`.trim())}>
              <img
                src={getImageByLwin18()}
                alt="product"
                className="bg-transparent object-cover img h-[248px]"
                onError={handleImageError}
              />
            </div>
          </div>
        )}
        {children && (
          <div className="w-full relative left-0 flex flex-col h-100  items-start">
            <div className={`relative min-w-[375px] h-[264px] flex-1 ${imageContainer}`.trim()}>{children}</div>
          </div>
        )}
        {!children && (!imageUrl || imageUrl.length === 0) && !imageByLwin18 && (
          <div className="w-full relative left-0 flex flex-col h-100  items-start">
            <div className=" relative min-w-[375px] h-[264px] flex-1" />
          </div>
        )}
        {region && (
          <div
            className={`px-3 h-5 border absolute top-3 right-3 flex rounded-full text-sm justify-center whitespace-nowrap items-center ${
              txtColor || ''
            }`.trim()}
            style={{ background: bgColor || 'transparent' }}
          >
            {region}
          </div>
        )}
      </div>
      <div className="w-full h-[15px]" style={{ background: bgColor || '' }} />
    </div>
  );
};

export default ProductImage;
