import { Dispatch } from 'react';
import { IsCheckedKeyEnum, MainReducerActions } from '../reducers/mainReducer';

export interface TransactionsFormatedType {
  acct_holder_id: string;
  client_id: string;
  client_name: string;
  wine_lwin_code: string;
  vintage: string;
  wine_name: string;
  region: string;
  unit_size: string;
  nb_of_units: string;
  Actual_Amt: string;
  transaction_date: string;
  buyOrSell: string;
}

export enum TRANSACTIONS_TABLE_REFS {
  ACCT_HOLDER_ID = 'acct_holder_id',
  CLIENT_ID = 'client_id',
  CLIENT_NAME = 'client_name',
  WINE_LWIN_CODE = 'wine_lwin_code',
  VINTAGE = 'vintage',
  WINE_NAME = 'wine_name',
  REGION = 'region',
  UNIT_SIZE = 'unit_size',
  NB_OF_UNITS = 'nb_of_units',
  ACTUAL_AMT = 'Actual_Amt',
  TRANSACTION_DATE = 'transaction_date',
  BUY_OR_SELL = 'buyOrSell',
}

export interface GetUniqueItemsType<T> {
  key: string;
  data: T[];
}

export enum SelectionTypeEnum {
  SINGLE_SELECT = 'singleSelect',
  MULTI_SELECT = 'multiSelect',
}

export interface GenerateDropDownItems<T> {
  listItems: T[];
  dispatch: Dispatch<MainReducerActions>;
  listItemsKey: string;
  isCheckedKey: IsCheckedKeyEnum;
  checkedArray: string[];
  selectionType?: SelectionTypeEnum;
  showCheckBox?: boolean;
  isPortfolio?: boolean;
}
