import { DropdownItem } from 'components/Dropdown';
import { AppContext } from 'context/ContextProvider';
import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface UseFeeModelInfoParamType {
  price: number;
  qty: number;
}

export interface UseFeeModelInfoType {
  showFeeModelInfo: boolean;
  canTopUp: boolean;
  canExecTransaction: boolean;
  ddlOptions: {
    id: string;
    value: string;
    content: JSX.Element;
    text: never;
  }[];
  selectedPortfolio: DropdownItem | undefined | null;
  getRemaingBalAndBtnText: (param?: boolean) => {
    remainingBal: number;
    btnText: string;
  };
  setSelectedPortfolio: Dispatch<SetStateAction<DropdownItem | undefined | null>>;
  resetSelectedPortfolio: () => void;
}

export const useFeeModelInfo = ({ price, qty }: UseFeeModelInfoParamType) => {
  const {
    state: {
      settings: { accountInfo },
    },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [selectedPortfolio, setSelectedPortfolio] = useState<DropdownItem | null>();

  const resetSelectedPortfolio = () => {
    setSelectedPortfolio(null);
  };
  const feeModelInfo = useMemo(() => {
    const pBalances = (accountInfo?.portfolios || []).filter((z) => `${z.portfolioId}`.length > 0);
    const feeModels = pBalances.filter((x) => `${x.portfolioId}`.length > 0);
    const otherFeeModelExist = !!feeModels.find((x) => x.currentFeeModel === false);

    const canExecTransaction = (accountInfo?.account.totalCashOnAccount ?? 0) >= price * qty;

    const validTopupPortfolios = feeModels.filter(
      (x) => `${x.portfolioId}`.length > 0 && !!x.currentFeeModel && x.currentFeeModel === true,
    );
    const canTopUp = validTopupPortfolios && validTopupPortfolios.length > 0;

    const ddlOptions = (
      canExecTransaction
        ? pBalances ?? []
        : pBalances.filter((x) => `${x.portfolioId}`.length > 0 && !!x.currentFeeModel && x.currentFeeModel === true) ??
          []
    )?.map((balance) => {
      const { portfolioId, portfolioName } = balance;
      return {
        id: portfolioId,
        value: portfolioId,
        content: (
          <div className="flex justify-between text-base">
            <span>{portfolioName}</span>
          </div>
        ),
        text: portfolioName,
      };
    });

    // setSelectedPortfolio({ content: null, id: '', value: 'ed', text: '' });

    if (ddlOptions?.length === 1) setSelectedPortfolio(ddlOptions?.[0]);

    const getRemaingBalAndBtnText = (isInPortfolioView = false) => {
      const purchasePrice = price * qty;
      const remaining = (accountInfo?.account.totalCashOnAccount ?? 0) - purchasePrice;
      let btnText = '';
      if (ddlOptions.length > 1 && !isInPortfolioView) {
        btnText = t('common:selectPortfolio');
      } else if (remaining >= 0) {
        btnText = t('common:confirm_purchase');
      } else {
        btnText = t('common:continue_to_top_up');
      }

      return { remainingBal: remaining, btnText };
    };

    return {
      showFeeModelInfo: otherFeeModelExist,
      canTopUp,
      canExecTransaction,
      ddlOptions,
      setSelectedPortfolio,
      getRemaingBalAndBtnText: (param?: boolean) => getRemaingBalAndBtnText(param),
    };
  }, [accountInfo, setSelectedPortfolio, price, qty, t]);

  return { ...feeModelInfo, selectedPortfolio, resetSelectedPortfolio };
};
