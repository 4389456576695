import { httpGet } from 'utils';

interface Params {
  emailAddress: string;
}

export interface ValidateEmailResponse {
  status: string;
}

export const validateEmailApi = (config?: Params) =>
  httpGet<ValidateEmailResponse, Params>('portal/validateEmail', config, false);
