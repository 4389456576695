import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles';
import { TableProps, TableRowProps } from '../../types';

const PdfTable = <T,>({ tableCol, tableRow }: TableProps<T>) => (
  <View style={styles.table}>
    {tableRow?.map((data) => {
      const row = data as TableRowProps;
      return (
        <View style={styles.tableRow} key={row?.id}>
          {tableCol.map((col, index) => {
            return (
              <View style={styles.cell} key={index}>
                <Text style={styles.cellText}>{row[col.value]}</Text>
              </View>
            );
          })}
        </View>
      );
    })}
  </View>
);

export default PdfTable;
