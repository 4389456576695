// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading/loading';
import Alert from 'components/Alert';
import { CardDetailResponse } from '__generated__/graphql';
import { OpenSlideoutFnType, SlideOutPanelViews } from '../types';
import AddCardButton from './AddCardButton';
import Card from './Checkout.Com/Card';
import EditCheckoutDotComCard from './slideoutTemplates/ManageCheckoutDotComCard';
import { useGetCheckoutDotComCards } from '../hooks/useGetCheckoutDotComCards';
import CardDetailsForm, { FlowType } from './Checkout.Com/CardDetailsForm';
import { cardHasExpired, selectCheckoutDotComCards } from '../utils';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning-black.svg';
import { ACCEPTED_PAYMENT_METHODS } from '../constants';

interface PaymentsProps {
  openSlideout: OpenSlideoutFnType;
  onClose: () => void;
}
export default function Payments({ openSlideout, onClose }: PaymentsProps) {
  const { t } = useTranslation();
  const { data, error, loading } = useGetCheckoutDotComCards();
  const _cards = selectCheckoutDotComCards(data);

  function handleEditCardClicked(card: CardDetailResponse): void {
    openSlideout(SlideOutPanelViews.CUSTOM, {
      title: t('account:payments.manageCard.title'),
      customTemplate: () => <EditCheckoutDotComCard card={card} onClose={onClose} />,
    });
  }

  return (
    <div className="flex flex-col p-5 w-full gap-4">
      {!loading && !error && _cards.filter((card) => !cardHasExpired(card)).length === 0 && (
        <Alert
          className="bg-amber w-full text-14 text-black"
          show={_cards.filter((card) => !cardHasExpired(card)).length === 0}
          icon={<WarningIcon className="min-w-[24px]" fill="black" />}
        >
          {t('account:payments.addCard.cardsExpired')}
        </Alert>
      )}
      <h2>{t('account:payments.savedCards')}</h2>
      <div className="flex flex-wrap gap-4">
        {loading ? (
          <Loading />
        ) : error ? (
          <p>{t('common:errorResult.title')}</p>
        ) : _cards === null || _cards === undefined || _cards.length === 0 ? null : (
          _cards
            .slice()
            .sort((a) => (a.defaultMitFlag ? -1 : 1))
            .map((card, index) =>
              ACCEPTED_PAYMENT_METHODS.find(
                (x) => x.toLocaleLowerCase() === (card?.scheme || '').toLocaleLowerCase(),
              ) ? (
                <Card
                  card={card}
                  onEditCardClicked={handleEditCardClicked}
                  key={`card-${index}`}
                  expired={cardHasExpired(card)}
                />
              ) : null,
            )
        )}
        {!loading && (
          <AddCardButton
            onClick={() =>
              openSlideout(SlideOutPanelViews.CUSTOM, {
                customTemplate: () => <CardDetailsForm amount={0} flow={FlowType.ADD_CARD} />,
                title: t('account:payments.addCard.title'),
              })
            }
          />
        )}
      </div>
    </div>
  );
}
