import { useQuery } from '@tanstack/react-query';
import { EstimatedManagementFeeResponse } from 'views/shared/types';
import { logError } from 'components/LogError';
import { fetchEstimatedFeeAmounts } from '../services/fetchEstimatedFeeAmounts';

interface UseEstimatedFeeAmountsReturnHookResponse {
  estimatedFeeAmounts: EstimatedManagementFeeResponse;
  loading: boolean;
  error: unknown;
}

export const useEstimatedFeeAmounts = (): UseEstimatedFeeAmountsReturnHookResponse => {
  const {
    data: estimatedFeeAmounts,
    isLoading: loading,
    error,
  } = useQuery({
    queryFn: fetchEstimatedFeeAmounts,
    queryKey: ['fetchEstimatedFeeAmounts'],
  });

  if (error) {
    logError(JSON.stringify(error));
  }
  return {
    estimatedFeeAmounts: estimatedFeeAmounts ?? {
      monthlyFeeAmount: 0,
      annualFeeAmount: 0,
      discountedAnnualFeeAmount: 0,
      discountPct: 0,
    },
    loading,
    error,
  };
};
