import axios from 'axios';
import { getAccessToken, getUserToken } from 'services/auth';

const baseHeaders = {
  'Content-type': 'application/json',
  'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_KEY,
};

export const httpPost = async <TResponse = unknown, TParameters extends {} = never>(
  apiSubPath: string,
  data?: TParameters,
  isSecured = true,
) => {
  const url = `${process.env.REACT_APP_APIM_URL}/${apiSubPath}`;
  return axios.post<TResponse>(url, data, {
    headers: {
      ...baseHeaders,
      ...(isSecured ? { authorization: `Bearer ${getAccessToken()}`, CdsUserJwt: getUserToken() } : {}),
    },
  });
};
