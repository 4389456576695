import { gql } from '@apollo/client';

export const SUBMIT_ONE_TIME_PASSCODE = gql`
  mutation SubmitOneTimePasscode($args: AuthMfaOobCodeVerifyRequestInput!) {
    portalAuthMfaVerify(mfaOobCodeVerifyRequestInput: $args) {
      accessToken
      idToken
      userToken
      refreshToken
      error
      errorDescription
    }
  }
`;
