import { CardDetailResponse, PaymentGetCardDetailsQuery } from '__generated__/graphql';
import moment from 'moment';
import { CardSchemeEnum } from './types';

export function selectCheckoutDotComCards(data: PaymentGetCardDetailsQuery | undefined): CardDetailResponse[] {
  if (!data) {
    return [];
  }

  if (!data.paymentGetCardDetails) {
    return [];
  }

  return data.paymentGetCardDetails;
}

export function cardHasExpired(card: CardDetailResponse): boolean {
  if (card.expiry) {
    const [mm, yy] = card.expiry.split('/');
    const expiryDate = moment(`${mm}20${yy}`, 'MMYYYY').add(1, 'M'); // add 1 for the 0 based indexing of month
    return expiryDate <= moment();
  }

  return true;
}

export function userHasValidCards(cards: CardDetailResponse[]): boolean {
  return cards.filter((card) => !cardHasExpired(card)).filter((x) => x.scheme !== CardSchemeEnum.AMEX).length > 0;
}
