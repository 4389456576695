/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query GetMiscellaneous {\n    miscellaneous @client {\n      languages {\n        id\n        value\n        symbol\n        text\n      }\n      currencies {\n        id\n        value\n        text\n        symbol\n      }\n      paymentFrequencies {\n        id\n        value\n        text\n      }\n    }\n  }\n": types.GetMiscellaneousDocument,
    "\n  mutation PortalDeliverWine($portalDeliverWineRequest: PortalDeliverWineRequestInput!) {\n    portalDeliverWine(portalDeliverWineRequest: $portalDeliverWineRequest) {\n      isSuccess\n      errorMessage\n    }\n  }\n": types.PortalDeliverWineDocument,
    "\n  mutation PortalBuyWine($portalBuyWineRequest: PortalBuyWineRequestInput!) {\n    portalBuyWine(portalBuyWineRequest: $portalBuyWineRequest) {\n      isSuccess\n      errorMessage\n    }\n  }\n": types.PortalBuyWineDocument,
    "\n  mutation PortalSellWine($portalSellWineRequest: PortalSellWineRequestInput!) {\n    portalSellWine(portalSellWineRequest: $portalSellWineRequest) {\n      isSuccess\n      errorMessage\n    }\n  }\n": types.PortalSellWineDocument,
    "\n  mutation AuthChangePassword($changePasswordInput: AuthChangePasswordInput!) {\n    authChangePassword(changePasswordInput: $changePasswordInput) {\n      wasPasswordChanged\n    }\n  }\n": types.AuthChangePasswordDocument,
    "\n  mutation PortalContactRelationshipManger($request: ContactRmInput!) {\n    portalContactRelationshipManger(request: $request) {\n      errorMessage\n      isSuccess\n    }\n  }\n": types.PortalContactRelationshipMangerDocument,
    "\n  mutation CreateStripeRecurringPayment($stripeRecurringPayment: StripeRecurringPaymentInput!) {\n    createStripeRecurringPayment(stripeRecurringPayment: $stripeRecurringPayment) {\n      id\n      subId\n      clientSecret\n    }\n  }\n": types.CreateStripeRecurringPaymentDocument,
    "\n  mutation DeletePaymentCard($instrumentId: String!) {\n    deleteCardDetail(instrumentId: $instrumentId) {\n      isSuccess\n      errorMessage\n    }\n  }\n": types.DeletePaymentCardDocument,
    "\n  query AuthUser {\n    authUser {\n      emailAddress\n    }\n  }\n": types.AuthUserDocument,
    "\n  query paymentReferenceNumber {\n    paymentReferenceNumber\n  }\n": types.PaymentReferenceNumberDocument,
    "\n  query PaymentGetCardDetails {\n    paymentGetCardDetails {\n      instrumentId\n      last4\n      clientName\n      expiry\n      scheme\n      mitConsentedFlag\n      defaultMitFlag\n    }\n  }\n": types.PaymentGetCardDetailsDocument,
    "\n  query ManagementFeesQuery {\n    portalManagementFees {\n      id\n      accountHolderName\n      vintradeAccountHolderId\n      clientName\n      vintradeClientId\n      valuationDate\n      feeType\n      portfolioValue\n      offsetValue\n      feeAmount\n      appliedPct\n      invoiceNumber\n      invoiceDate\n      status\n    }\n  }\n": types.ManagementFeesQueryDocument,
    "\n  query RecurringPaymentPrices($currency: String!) {\n    recurringPaymentPrices(currency: $currency) {\n      id\n      nickName\n      product\n      currency\n      unitAmount\n      recurringInterval\n      recurringIntervalCount\n    }\n  }\n": types.RecurringPaymentPricesDocument,
    "\n  query GetUserPreferences {\n    portalClientPreferences {\n      currency\n      language\n      firstLoginDate\n      managementFeePayment\n      annualManagementFeePayType\n      historicManagementFeePayType\n      bankTransferChaseEmailCount\n    }\n  }\n": types.GetUserPreferencesDocument,
    "\n  mutation CreateStripePayment($stripePayment: StripePaymentInput!) {\n    createStripePayment(stripePayment: $stripePayment) {\n      id\n      paymentIntentId\n      clientSecret\n    }\n  }\n": types.CreateStripePaymentDocument,
    "\n  mutation UpdateDefaultCheckoutDotComCard($defaultMitCard: DefaultMitCardInput!) {\n    updateDefaultMitCard(defaultMitCard: $defaultMitCard) {\n      status\n      errorMessage\n    }\n  }\n": types.UpdateDefaultCheckoutDotComCardDocument,
    "\n  mutation PortalUpdatePreferences($request: UpdatePreferencesInput!) {\n    portalUpdatePreferences(request: $request) {\n      currency\n      language\n      managementFeePayment\n      annualManagementFeePayType\n      historicManagementFeePayType\n    }\n  }\n": types.PortalUpdatePreferencesDocument,
    "\n  mutation CheckoutDotComCardPayment($args: CardPaymentInput!) {\n    createCardPayment(cardPayment: $args) {\n      id\n      status\n      errorMessage\n      redirectUrl\n    }\n  }\n": types.CheckoutDotComCardPaymentDocument,
    "\n  mutation Login($loginRequestInput: AuthLoginRequestInput!) {\n    portalAuthLogin(loginRequestInput: $loginRequestInput) {\n      accessToken\n      refreshToken\n      userToken\n      mfaToken\n    }\n  }\n": types.LoginDocument,
    "\n  mutation GetMFAAuthenticators($getMfaAuthenticatorsRequestInput: AuthGetMfaAuthenticatorsRequestInput!) {\n    portalAuthMfaAuthenticators(getMfaAuthenticatorsRequestInput: $getMfaAuthenticatorsRequestInput) {\n      mfaAuthenticators {\n        id\n        active\n        authenticatorType\n        name\n        oobChannel\n      }\n    }\n  }\n": types.GetMfaAuthenticatorsDocument,
    "\n  mutation IssueMFAChallenge($mfaChallengeRequestInput: AuthMfaChallengeRequestInput!) {\n    portalAuthMfaChallenge(mfaChallengeRequestInput: $mfaChallengeRequestInput) {\n      challengeType\n      oobCode\n    }\n  }\n": types.IssueMfaChallengeDocument,
    "\n  mutation SubmitOneTimePasscode($args: AuthMfaOobCodeVerifyRequestInput!) {\n    portalAuthMfaVerify(mfaOobCodeVerifyRequestInput: $args) {\n      accessToken\n      idToken\n      userToken\n      refreshToken\n      error\n      errorDescription\n    }\n  }\n": types.SubmitOneTimePasscodeDocument,
    "\n  mutation RefreshAccessToken($input: AuthRefreshAccessTokenRequestInput!) {\n    authRefreshAccessToken(refreshAccessTokenRequestInput: $input) {\n      accessToken\n      refreshToken\n      userToken\n    }\n  }\n": types.RefreshAccessTokenDocument,
    "\n  mutation PortalAuthRegister($registerUserInput: PortalAuthRegisterUserInput!) {\n    portalAuthRegister(registerUserInput: $registerUserInput) {\n      userId\n      emailAddress\n      displayName\n    }\n  }\n": types.PortalAuthRegisterDocument,
    "\n  mutation ResetPasswordMutation($resetPasswordInput: AuthResetPasswordInput!) {\n    portalAuthResetPassword(resetPasswordInput: $resetPasswordInput) {\n      emailAddress\n    }\n  }\n": types.ResetPasswordMutationDocument,
    "\n  query ValidatePortalEmail($emailAddress: String!) {\n    validatePortalEmail(emailAddress: $emailAddress) {\n      status\n    }\n  }\n": types.ValidatePortalEmailDocument,
    "\n  mutation PortalInvestNow($portalInvestNowRequest: PortalInvestNowRequestInput!) {\n    portalInvestNow(portalInvestNowRequest: $portalInvestNowRequest) {\n      isSuccess\n      errorMessage\n    }\n  }\n": types.PortalInvestNowDocument,
    "\n  query GetMyCellar {\n    portalMyCellar {\n      rotationNumber\n      stockId\n      holdingId\n      portfolioId\n      lwin18\n      wineName\n      vintage\n      region\n      imageFileName\n      cultWinesAllocationRegion\n      dealDate\n      dealRef\n      dealCCY\n      unit\n      unitCount\n      qty\n      status\n      location\n    }\n  }\n": types.GetMyCellarDocument,
    "\n  query GetmyCelllarWineDetails($stockId: String!) {\n    portalMyCellarWineDetails(stockId: $stockId) {\n      id\n      portfolioId\n      lwin18\n      wineName\n      vintage\n      region\n      cultWinesAllocationRegion\n      dealDate\n      dealRef\n      dealCCY\n      unit\n      imageFileName\n      unitCount\n      qty\n      qtyForSale\n      priceForSale\n      costPerUnit\n      totalCost\n      valuePerUnit\n      valuePerBottle\n      totalValue\n      changedPct\n      netPosition\n      netPositionPerUnit\n      profitAndLoss\n      profitAndLossPerUnit\n      mgmtFeePerUnit\n      totalMgmtFee\n      costWithMgmtFeePerUnit\n      totalCostWithMgmtFee\n      rotationNumber\n      status\n      location\n      historicMarketPrices {\n        date\n        marketPrice\n      }\n    }\n  }\n": types.GetmyCelllarWineDetailsDocument,
    "\n  query PortalNotifications($from: Int!, $pageSize: Int!) {\n    portalNotifications(from: $from, pageSize: $pageSize) {\n      total\n      pageSize\n      from\n      totalPages\n      unreadCount\n      results {\n        id\n        category\n        type\n        summary\n        description\n        isRead\n        createdDateTime\n        updatedDateTime\n      }\n    }\n  }\n": types.PortalNotificationsDocument,
    "\n  mutation MarkNotificationsRead($request: MarkNotificationsReadRequest!) {\n    markNotificationsRead(request: $request)\n  }\n": types.MarkNotificationsReadDocument,
    "\n  query PortalPortfolioAnnualisedReturn($portfolioId: Int) {\n    portalPortfolioAnnualisedReturn(portfolioId: $portfolioId) {\n      years {\n        date\n        value\n      }\n      monthly {\n        date\n        value\n      }\n    }\n  }\n": types.PortalPortfolioAnnualisedReturnDocument,
    "\n  query GetPortfolioExternalStock {\n    portalExternalHoldings {\n      lwin18\n      wineName\n      vintage\n      region\n      cultWinesAllocationRegion\n      unit\n      unitCount\n      qty\n      costPerUnit\n      totalCost\n      valuePerUnit\n      totalValue\n      changedPct\n      netPosition\n      location\n      cashOffer\n      createdDate\n    }\n  }\n": types.GetPortfolioExternalStockDocument,
    "\n  query GetProductDetails($id: String!) {\n    portalHoldingDetails(id: $id) {\n      id\n      portfolioId\n      lwin18\n      wineName\n      vintage\n      region\n      cultWinesAllocationRegion\n      dealDate\n      dealRef\n      dealCCY\n      unit\n      unitCount\n      qty\n      qtyForSale\n      priceForSale\n      costPerUnit\n      totalCost\n      valuePerUnit\n      valuePerBottle\n      totalValue\n      changedPct\n      netPosition\n      netPositionPerUnit\n      profitAndLoss\n      profitAndLossPerUnit\n      imageFileName\n      mgmtFeePerUnit\n      totalMgmtFee\n      costWithMgmtFeePerUnit\n      totalCostWithMgmtFee\n      holdingStocks {\n        rotationNumber\n        status\n        location\n      }\n      historicMarketPrices {\n        date\n        marketPrice\n      }\n    }\n  }\n": types.GetProductDetailsDocument,
    "\n  query GetSoldStocks {\n    portalSoldHoldings {\n      lwin18\n      wineName\n      vintage\n      region\n      cultWinesAllocationRegion\n      dealDate\n      dealRef\n      unit\n      unitCount\n      qtySold\n      status\n      soldDate\n      costPerUnit\n      totalCost\n      soldPricePerUnit\n      totalValue\n      changedPct\n      netPosition\n      netPositionPerUnit\n      profitAndLoss\n      profitAndLossPerUnit\n      mgmtFeePerUnit\n      totalMgmtFee\n      costWithMgmtFeePerUnit\n      totalCostWithMgmtFee\n    }\n  }\n": types.GetSoldStocksDocument,
    "\n  query GetPortfolioCurrentHoldings {\n    portalCurrentHoldings {\n      id\n      portfolioId\n      lwin18\n      wineName\n      vintage\n      region\n      cultWinesAllocationRegion\n      dealDate\n      dealRef\n      dealCCY\n      unit\n      unitCount\n      imageFileName\n      qty\n      qtyForSale\n      priceForSale\n      costPerUnit\n      totalCost\n      valuePerUnit\n      valuePerBottle\n      totalValue\n      changedPct\n      netPosition\n      netPositionPerUnit\n      profitAndLoss\n      profitAndLossPerUnit\n      mgmtFeePerUnit\n      totalMgmtFee\n      costWithMgmtFeePerUnit\n      totalCostWithMgmtFee\n      historicMarketPrices {\n        date\n        marketPrice\n      }\n    }\n  }\n": types.GetPortfolioCurrentHoldingsDocument,
    "\n  query GetPortfolioBalances {\n    portalPortfolioBalance {\n      balance\n      portfolioName\n      portfolioId\n      currentHoldings\n      capitalInvested\n      totalMgmtFee\n      netProceedsFromSales\n      netPosition\n      netPositionPct\n      profitAndLoss\n      profitAndLossPct\n      balancePending\n      totalRefunds\n      netContributions\n      currentFeeModel\n      feeModel\n      firstDealDate\n    }\n  }\n": types.GetPortfolioBalancesDocument,
    "\n  query PortalPortfolioPerformanceOverTime($portfolioId: Int) {\n    portalPortfolioPerformanceOverTime(portfolioId: $portfolioId) {\n      date\n      currentHoldings\n      netContributions\n    }\n  }\n": types.PortalPortfolioPerformanceOverTimeDocument,
    "\n  query GetPortalAllocations {\n    portalPortfolioCurrentAllocation {\n      portalRegionPerformances {\n        regionName\n        currentHoldings\n        totalPurchasePrice\n        netPosition\n        netPositionPct\n      }\n      portalPortfolioCurrentAllocation {\n        tacticalAllocation\n        regionName\n        currentAllocation\n        StrategicAllocation\n      }\n    }\n  }\n": types.GetPortalAllocationsDocument,
    "\n  query GetCashBalance {\n    portalCashBalance {\n      todayInvestment\n      balances {\n        portfolioName\n        portfolioId\n        balance\n      }\n    }\n  }\n": types.GetCashBalanceDocument,
    "\n  mutation BankTransferConfirm($request: BankTransferPreferenceInput!) {\n    bankTransferConfirm(request: $request) {\n      isSuccess\n      errorMessage\n    }\n  }\n": types.BankTransferConfirmDocument,
    "\n  query PortalOutstandingManagementFeeAmount {\n    portalOutstandingManagementFeeAmount {\n      totalOutstandingFeesAmount\n    }\n\n    portalEstimateManagementFee {\n      monthlyFeeAmount\n      annualFeeAmount\n      discountedAnnualFeeAmount\n      discountPct\n    }\n  }\n": types.PortalOutstandingManagementFeeAmountDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMiscellaneous {\n    miscellaneous @client {\n      languages {\n        id\n        value\n        symbol\n        text\n      }\n      currencies {\n        id\n        value\n        text\n        symbol\n      }\n      paymentFrequencies {\n        id\n        value\n        text\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetMiscellaneous {\n    miscellaneous @client {\n      languages {\n        id\n        value\n        symbol\n        text\n      }\n      currencies {\n        id\n        value\n        text\n        symbol\n      }\n      paymentFrequencies {\n        id\n        value\n        text\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PortalDeliverWine($portalDeliverWineRequest: PortalDeliverWineRequestInput!) {\n    portalDeliverWine(portalDeliverWineRequest: $portalDeliverWineRequest) {\n      isSuccess\n      errorMessage\n    }\n  }\n"): (typeof documents)["\n  mutation PortalDeliverWine($portalDeliverWineRequest: PortalDeliverWineRequestInput!) {\n    portalDeliverWine(portalDeliverWineRequest: $portalDeliverWineRequest) {\n      isSuccess\n      errorMessage\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PortalBuyWine($portalBuyWineRequest: PortalBuyWineRequestInput!) {\n    portalBuyWine(portalBuyWineRequest: $portalBuyWineRequest) {\n      isSuccess\n      errorMessage\n    }\n  }\n"): (typeof documents)["\n  mutation PortalBuyWine($portalBuyWineRequest: PortalBuyWineRequestInput!) {\n    portalBuyWine(portalBuyWineRequest: $portalBuyWineRequest) {\n      isSuccess\n      errorMessage\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PortalSellWine($portalSellWineRequest: PortalSellWineRequestInput!) {\n    portalSellWine(portalSellWineRequest: $portalSellWineRequest) {\n      isSuccess\n      errorMessage\n    }\n  }\n"): (typeof documents)["\n  mutation PortalSellWine($portalSellWineRequest: PortalSellWineRequestInput!) {\n    portalSellWine(portalSellWineRequest: $portalSellWineRequest) {\n      isSuccess\n      errorMessage\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AuthChangePassword($changePasswordInput: AuthChangePasswordInput!) {\n    authChangePassword(changePasswordInput: $changePasswordInput) {\n      wasPasswordChanged\n    }\n  }\n"): (typeof documents)["\n  mutation AuthChangePassword($changePasswordInput: AuthChangePasswordInput!) {\n    authChangePassword(changePasswordInput: $changePasswordInput) {\n      wasPasswordChanged\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PortalContactRelationshipManger($request: ContactRmInput!) {\n    portalContactRelationshipManger(request: $request) {\n      errorMessage\n      isSuccess\n    }\n  }\n"): (typeof documents)["\n  mutation PortalContactRelationshipManger($request: ContactRmInput!) {\n    portalContactRelationshipManger(request: $request) {\n      errorMessage\n      isSuccess\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateStripeRecurringPayment($stripeRecurringPayment: StripeRecurringPaymentInput!) {\n    createStripeRecurringPayment(stripeRecurringPayment: $stripeRecurringPayment) {\n      id\n      subId\n      clientSecret\n    }\n  }\n"): (typeof documents)["\n  mutation CreateStripeRecurringPayment($stripeRecurringPayment: StripeRecurringPaymentInput!) {\n    createStripeRecurringPayment(stripeRecurringPayment: $stripeRecurringPayment) {\n      id\n      subId\n      clientSecret\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeletePaymentCard($instrumentId: String!) {\n    deleteCardDetail(instrumentId: $instrumentId) {\n      isSuccess\n      errorMessage\n    }\n  }\n"): (typeof documents)["\n  mutation DeletePaymentCard($instrumentId: String!) {\n    deleteCardDetail(instrumentId: $instrumentId) {\n      isSuccess\n      errorMessage\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AuthUser {\n    authUser {\n      emailAddress\n    }\n  }\n"): (typeof documents)["\n  query AuthUser {\n    authUser {\n      emailAddress\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query paymentReferenceNumber {\n    paymentReferenceNumber\n  }\n"): (typeof documents)["\n  query paymentReferenceNumber {\n    paymentReferenceNumber\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PaymentGetCardDetails {\n    paymentGetCardDetails {\n      instrumentId\n      last4\n      clientName\n      expiry\n      scheme\n      mitConsentedFlag\n      defaultMitFlag\n    }\n  }\n"): (typeof documents)["\n  query PaymentGetCardDetails {\n    paymentGetCardDetails {\n      instrumentId\n      last4\n      clientName\n      expiry\n      scheme\n      mitConsentedFlag\n      defaultMitFlag\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ManagementFeesQuery {\n    portalManagementFees {\n      id\n      accountHolderName\n      vintradeAccountHolderId\n      clientName\n      vintradeClientId\n      valuationDate\n      feeType\n      portfolioValue\n      offsetValue\n      feeAmount\n      appliedPct\n      invoiceNumber\n      invoiceDate\n      status\n    }\n  }\n"): (typeof documents)["\n  query ManagementFeesQuery {\n    portalManagementFees {\n      id\n      accountHolderName\n      vintradeAccountHolderId\n      clientName\n      vintradeClientId\n      valuationDate\n      feeType\n      portfolioValue\n      offsetValue\n      feeAmount\n      appliedPct\n      invoiceNumber\n      invoiceDate\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query RecurringPaymentPrices($currency: String!) {\n    recurringPaymentPrices(currency: $currency) {\n      id\n      nickName\n      product\n      currency\n      unitAmount\n      recurringInterval\n      recurringIntervalCount\n    }\n  }\n"): (typeof documents)["\n  query RecurringPaymentPrices($currency: String!) {\n    recurringPaymentPrices(currency: $currency) {\n      id\n      nickName\n      product\n      currency\n      unitAmount\n      recurringInterval\n      recurringIntervalCount\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUserPreferences {\n    portalClientPreferences {\n      currency\n      language\n      firstLoginDate\n      managementFeePayment\n      annualManagementFeePayType\n      historicManagementFeePayType\n      bankTransferChaseEmailCount\n    }\n  }\n"): (typeof documents)["\n  query GetUserPreferences {\n    portalClientPreferences {\n      currency\n      language\n      firstLoginDate\n      managementFeePayment\n      annualManagementFeePayType\n      historicManagementFeePayType\n      bankTransferChaseEmailCount\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateStripePayment($stripePayment: StripePaymentInput!) {\n    createStripePayment(stripePayment: $stripePayment) {\n      id\n      paymentIntentId\n      clientSecret\n    }\n  }\n"): (typeof documents)["\n  mutation CreateStripePayment($stripePayment: StripePaymentInput!) {\n    createStripePayment(stripePayment: $stripePayment) {\n      id\n      paymentIntentId\n      clientSecret\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateDefaultCheckoutDotComCard($defaultMitCard: DefaultMitCardInput!) {\n    updateDefaultMitCard(defaultMitCard: $defaultMitCard) {\n      status\n      errorMessage\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateDefaultCheckoutDotComCard($defaultMitCard: DefaultMitCardInput!) {\n    updateDefaultMitCard(defaultMitCard: $defaultMitCard) {\n      status\n      errorMessage\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PortalUpdatePreferences($request: UpdatePreferencesInput!) {\n    portalUpdatePreferences(request: $request) {\n      currency\n      language\n      managementFeePayment\n      annualManagementFeePayType\n      historicManagementFeePayType\n    }\n  }\n"): (typeof documents)["\n  mutation PortalUpdatePreferences($request: UpdatePreferencesInput!) {\n    portalUpdatePreferences(request: $request) {\n      currency\n      language\n      managementFeePayment\n      annualManagementFeePayType\n      historicManagementFeePayType\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CheckoutDotComCardPayment($args: CardPaymentInput!) {\n    createCardPayment(cardPayment: $args) {\n      id\n      status\n      errorMessage\n      redirectUrl\n    }\n  }\n"): (typeof documents)["\n  mutation CheckoutDotComCardPayment($args: CardPaymentInput!) {\n    createCardPayment(cardPayment: $args) {\n      id\n      status\n      errorMessage\n      redirectUrl\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Login($loginRequestInput: AuthLoginRequestInput!) {\n    portalAuthLogin(loginRequestInput: $loginRequestInput) {\n      accessToken\n      refreshToken\n      userToken\n      mfaToken\n    }\n  }\n"): (typeof documents)["\n  mutation Login($loginRequestInput: AuthLoginRequestInput!) {\n    portalAuthLogin(loginRequestInput: $loginRequestInput) {\n      accessToken\n      refreshToken\n      userToken\n      mfaToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GetMFAAuthenticators($getMfaAuthenticatorsRequestInput: AuthGetMfaAuthenticatorsRequestInput!) {\n    portalAuthMfaAuthenticators(getMfaAuthenticatorsRequestInput: $getMfaAuthenticatorsRequestInput) {\n      mfaAuthenticators {\n        id\n        active\n        authenticatorType\n        name\n        oobChannel\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation GetMFAAuthenticators($getMfaAuthenticatorsRequestInput: AuthGetMfaAuthenticatorsRequestInput!) {\n    portalAuthMfaAuthenticators(getMfaAuthenticatorsRequestInput: $getMfaAuthenticatorsRequestInput) {\n      mfaAuthenticators {\n        id\n        active\n        authenticatorType\n        name\n        oobChannel\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation IssueMFAChallenge($mfaChallengeRequestInput: AuthMfaChallengeRequestInput!) {\n    portalAuthMfaChallenge(mfaChallengeRequestInput: $mfaChallengeRequestInput) {\n      challengeType\n      oobCode\n    }\n  }\n"): (typeof documents)["\n  mutation IssueMFAChallenge($mfaChallengeRequestInput: AuthMfaChallengeRequestInput!) {\n    portalAuthMfaChallenge(mfaChallengeRequestInput: $mfaChallengeRequestInput) {\n      challengeType\n      oobCode\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SubmitOneTimePasscode($args: AuthMfaOobCodeVerifyRequestInput!) {\n    portalAuthMfaVerify(mfaOobCodeVerifyRequestInput: $args) {\n      accessToken\n      idToken\n      userToken\n      refreshToken\n      error\n      errorDescription\n    }\n  }\n"): (typeof documents)["\n  mutation SubmitOneTimePasscode($args: AuthMfaOobCodeVerifyRequestInput!) {\n    portalAuthMfaVerify(mfaOobCodeVerifyRequestInput: $args) {\n      accessToken\n      idToken\n      userToken\n      refreshToken\n      error\n      errorDescription\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RefreshAccessToken($input: AuthRefreshAccessTokenRequestInput!) {\n    authRefreshAccessToken(refreshAccessTokenRequestInput: $input) {\n      accessToken\n      refreshToken\n      userToken\n    }\n  }\n"): (typeof documents)["\n  mutation RefreshAccessToken($input: AuthRefreshAccessTokenRequestInput!) {\n    authRefreshAccessToken(refreshAccessTokenRequestInput: $input) {\n      accessToken\n      refreshToken\n      userToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PortalAuthRegister($registerUserInput: PortalAuthRegisterUserInput!) {\n    portalAuthRegister(registerUserInput: $registerUserInput) {\n      userId\n      emailAddress\n      displayName\n    }\n  }\n"): (typeof documents)["\n  mutation PortalAuthRegister($registerUserInput: PortalAuthRegisterUserInput!) {\n    portalAuthRegister(registerUserInput: $registerUserInput) {\n      userId\n      emailAddress\n      displayName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ResetPasswordMutation($resetPasswordInput: AuthResetPasswordInput!) {\n    portalAuthResetPassword(resetPasswordInput: $resetPasswordInput) {\n      emailAddress\n    }\n  }\n"): (typeof documents)["\n  mutation ResetPasswordMutation($resetPasswordInput: AuthResetPasswordInput!) {\n    portalAuthResetPassword(resetPasswordInput: $resetPasswordInput) {\n      emailAddress\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ValidatePortalEmail($emailAddress: String!) {\n    validatePortalEmail(emailAddress: $emailAddress) {\n      status\n    }\n  }\n"): (typeof documents)["\n  query ValidatePortalEmail($emailAddress: String!) {\n    validatePortalEmail(emailAddress: $emailAddress) {\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PortalInvestNow($portalInvestNowRequest: PortalInvestNowRequestInput!) {\n    portalInvestNow(portalInvestNowRequest: $portalInvestNowRequest) {\n      isSuccess\n      errorMessage\n    }\n  }\n"): (typeof documents)["\n  mutation PortalInvestNow($portalInvestNowRequest: PortalInvestNowRequestInput!) {\n    portalInvestNow(portalInvestNowRequest: $portalInvestNowRequest) {\n      isSuccess\n      errorMessage\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMyCellar {\n    portalMyCellar {\n      rotationNumber\n      stockId\n      holdingId\n      portfolioId\n      lwin18\n      wineName\n      vintage\n      region\n      imageFileName\n      cultWinesAllocationRegion\n      dealDate\n      dealRef\n      dealCCY\n      unit\n      unitCount\n      qty\n      status\n      location\n    }\n  }\n"): (typeof documents)["\n  query GetMyCellar {\n    portalMyCellar {\n      rotationNumber\n      stockId\n      holdingId\n      portfolioId\n      lwin18\n      wineName\n      vintage\n      region\n      imageFileName\n      cultWinesAllocationRegion\n      dealDate\n      dealRef\n      dealCCY\n      unit\n      unitCount\n      qty\n      status\n      location\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetmyCelllarWineDetails($stockId: String!) {\n    portalMyCellarWineDetails(stockId: $stockId) {\n      id\n      portfolioId\n      lwin18\n      wineName\n      vintage\n      region\n      cultWinesAllocationRegion\n      dealDate\n      dealRef\n      dealCCY\n      unit\n      imageFileName\n      unitCount\n      qty\n      qtyForSale\n      priceForSale\n      costPerUnit\n      totalCost\n      valuePerUnit\n      valuePerBottle\n      totalValue\n      changedPct\n      netPosition\n      netPositionPerUnit\n      profitAndLoss\n      profitAndLossPerUnit\n      mgmtFeePerUnit\n      totalMgmtFee\n      costWithMgmtFeePerUnit\n      totalCostWithMgmtFee\n      rotationNumber\n      status\n      location\n      historicMarketPrices {\n        date\n        marketPrice\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetmyCelllarWineDetails($stockId: String!) {\n    portalMyCellarWineDetails(stockId: $stockId) {\n      id\n      portfolioId\n      lwin18\n      wineName\n      vintage\n      region\n      cultWinesAllocationRegion\n      dealDate\n      dealRef\n      dealCCY\n      unit\n      imageFileName\n      unitCount\n      qty\n      qtyForSale\n      priceForSale\n      costPerUnit\n      totalCost\n      valuePerUnit\n      valuePerBottle\n      totalValue\n      changedPct\n      netPosition\n      netPositionPerUnit\n      profitAndLoss\n      profitAndLossPerUnit\n      mgmtFeePerUnit\n      totalMgmtFee\n      costWithMgmtFeePerUnit\n      totalCostWithMgmtFee\n      rotationNumber\n      status\n      location\n      historicMarketPrices {\n        date\n        marketPrice\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PortalNotifications($from: Int!, $pageSize: Int!) {\n    portalNotifications(from: $from, pageSize: $pageSize) {\n      total\n      pageSize\n      from\n      totalPages\n      unreadCount\n      results {\n        id\n        category\n        type\n        summary\n        description\n        isRead\n        createdDateTime\n        updatedDateTime\n      }\n    }\n  }\n"): (typeof documents)["\n  query PortalNotifications($from: Int!, $pageSize: Int!) {\n    portalNotifications(from: $from, pageSize: $pageSize) {\n      total\n      pageSize\n      from\n      totalPages\n      unreadCount\n      results {\n        id\n        category\n        type\n        summary\n        description\n        isRead\n        createdDateTime\n        updatedDateTime\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation MarkNotificationsRead($request: MarkNotificationsReadRequest!) {\n    markNotificationsRead(request: $request)\n  }\n"): (typeof documents)["\n  mutation MarkNotificationsRead($request: MarkNotificationsReadRequest!) {\n    markNotificationsRead(request: $request)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PortalPortfolioAnnualisedReturn($portfolioId: Int) {\n    portalPortfolioAnnualisedReturn(portfolioId: $portfolioId) {\n      years {\n        date\n        value\n      }\n      monthly {\n        date\n        value\n      }\n    }\n  }\n"): (typeof documents)["\n  query PortalPortfolioAnnualisedReturn($portfolioId: Int) {\n    portalPortfolioAnnualisedReturn(portfolioId: $portfolioId) {\n      years {\n        date\n        value\n      }\n      monthly {\n        date\n        value\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetPortfolioExternalStock {\n    portalExternalHoldings {\n      lwin18\n      wineName\n      vintage\n      region\n      cultWinesAllocationRegion\n      unit\n      unitCount\n      qty\n      costPerUnit\n      totalCost\n      valuePerUnit\n      totalValue\n      changedPct\n      netPosition\n      location\n      cashOffer\n      createdDate\n    }\n  }\n"): (typeof documents)["\n  query GetPortfolioExternalStock {\n    portalExternalHoldings {\n      lwin18\n      wineName\n      vintage\n      region\n      cultWinesAllocationRegion\n      unit\n      unitCount\n      qty\n      costPerUnit\n      totalCost\n      valuePerUnit\n      totalValue\n      changedPct\n      netPosition\n      location\n      cashOffer\n      createdDate\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetProductDetails($id: String!) {\n    portalHoldingDetails(id: $id) {\n      id\n      portfolioId\n      lwin18\n      wineName\n      vintage\n      region\n      cultWinesAllocationRegion\n      dealDate\n      dealRef\n      dealCCY\n      unit\n      unitCount\n      qty\n      qtyForSale\n      priceForSale\n      costPerUnit\n      totalCost\n      valuePerUnit\n      valuePerBottle\n      totalValue\n      changedPct\n      netPosition\n      netPositionPerUnit\n      profitAndLoss\n      profitAndLossPerUnit\n      imageFileName\n      mgmtFeePerUnit\n      totalMgmtFee\n      costWithMgmtFeePerUnit\n      totalCostWithMgmtFee\n      holdingStocks {\n        rotationNumber\n        status\n        location\n      }\n      historicMarketPrices {\n        date\n        marketPrice\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetProductDetails($id: String!) {\n    portalHoldingDetails(id: $id) {\n      id\n      portfolioId\n      lwin18\n      wineName\n      vintage\n      region\n      cultWinesAllocationRegion\n      dealDate\n      dealRef\n      dealCCY\n      unit\n      unitCount\n      qty\n      qtyForSale\n      priceForSale\n      costPerUnit\n      totalCost\n      valuePerUnit\n      valuePerBottle\n      totalValue\n      changedPct\n      netPosition\n      netPositionPerUnit\n      profitAndLoss\n      profitAndLossPerUnit\n      imageFileName\n      mgmtFeePerUnit\n      totalMgmtFee\n      costWithMgmtFeePerUnit\n      totalCostWithMgmtFee\n      holdingStocks {\n        rotationNumber\n        status\n        location\n      }\n      historicMarketPrices {\n        date\n        marketPrice\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSoldStocks {\n    portalSoldHoldings {\n      lwin18\n      wineName\n      vintage\n      region\n      cultWinesAllocationRegion\n      dealDate\n      dealRef\n      unit\n      unitCount\n      qtySold\n      status\n      soldDate\n      costPerUnit\n      totalCost\n      soldPricePerUnit\n      totalValue\n      changedPct\n      netPosition\n      netPositionPerUnit\n      profitAndLoss\n      profitAndLossPerUnit\n      mgmtFeePerUnit\n      totalMgmtFee\n      costWithMgmtFeePerUnit\n      totalCostWithMgmtFee\n    }\n  }\n"): (typeof documents)["\n  query GetSoldStocks {\n    portalSoldHoldings {\n      lwin18\n      wineName\n      vintage\n      region\n      cultWinesAllocationRegion\n      dealDate\n      dealRef\n      unit\n      unitCount\n      qtySold\n      status\n      soldDate\n      costPerUnit\n      totalCost\n      soldPricePerUnit\n      totalValue\n      changedPct\n      netPosition\n      netPositionPerUnit\n      profitAndLoss\n      profitAndLossPerUnit\n      mgmtFeePerUnit\n      totalMgmtFee\n      costWithMgmtFeePerUnit\n      totalCostWithMgmtFee\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetPortfolioCurrentHoldings {\n    portalCurrentHoldings {\n      id\n      portfolioId\n      lwin18\n      wineName\n      vintage\n      region\n      cultWinesAllocationRegion\n      dealDate\n      dealRef\n      dealCCY\n      unit\n      unitCount\n      imageFileName\n      qty\n      qtyForSale\n      priceForSale\n      costPerUnit\n      totalCost\n      valuePerUnit\n      valuePerBottle\n      totalValue\n      changedPct\n      netPosition\n      netPositionPerUnit\n      profitAndLoss\n      profitAndLossPerUnit\n      mgmtFeePerUnit\n      totalMgmtFee\n      costWithMgmtFeePerUnit\n      totalCostWithMgmtFee\n      historicMarketPrices {\n        date\n        marketPrice\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPortfolioCurrentHoldings {\n    portalCurrentHoldings {\n      id\n      portfolioId\n      lwin18\n      wineName\n      vintage\n      region\n      cultWinesAllocationRegion\n      dealDate\n      dealRef\n      dealCCY\n      unit\n      unitCount\n      imageFileName\n      qty\n      qtyForSale\n      priceForSale\n      costPerUnit\n      totalCost\n      valuePerUnit\n      valuePerBottle\n      totalValue\n      changedPct\n      netPosition\n      netPositionPerUnit\n      profitAndLoss\n      profitAndLossPerUnit\n      mgmtFeePerUnit\n      totalMgmtFee\n      costWithMgmtFeePerUnit\n      totalCostWithMgmtFee\n      historicMarketPrices {\n        date\n        marketPrice\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetPortfolioBalances {\n    portalPortfolioBalance {\n      balance\n      portfolioName\n      portfolioId\n      currentHoldings\n      capitalInvested\n      totalMgmtFee\n      netProceedsFromSales\n      netPosition\n      netPositionPct\n      profitAndLoss\n      profitAndLossPct\n      balancePending\n      totalRefunds\n      netContributions\n      currentFeeModel\n      feeModel\n      firstDealDate\n    }\n  }\n"): (typeof documents)["\n  query GetPortfolioBalances {\n    portalPortfolioBalance {\n      balance\n      portfolioName\n      portfolioId\n      currentHoldings\n      capitalInvested\n      totalMgmtFee\n      netProceedsFromSales\n      netPosition\n      netPositionPct\n      profitAndLoss\n      profitAndLossPct\n      balancePending\n      totalRefunds\n      netContributions\n      currentFeeModel\n      feeModel\n      firstDealDate\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PortalPortfolioPerformanceOverTime($portfolioId: Int) {\n    portalPortfolioPerformanceOverTime(portfolioId: $portfolioId) {\n      date\n      currentHoldings\n      netContributions\n    }\n  }\n"): (typeof documents)["\n  query PortalPortfolioPerformanceOverTime($portfolioId: Int) {\n    portalPortfolioPerformanceOverTime(portfolioId: $portfolioId) {\n      date\n      currentHoldings\n      netContributions\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetPortalAllocations {\n    portalPortfolioCurrentAllocation {\n      portalRegionPerformances {\n        regionName\n        currentHoldings\n        totalPurchasePrice\n        netPosition\n        netPositionPct\n      }\n      portalPortfolioCurrentAllocation {\n        tacticalAllocation\n        regionName\n        currentAllocation\n        StrategicAllocation\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPortalAllocations {\n    portalPortfolioCurrentAllocation {\n      portalRegionPerformances {\n        regionName\n        currentHoldings\n        totalPurchasePrice\n        netPosition\n        netPositionPct\n      }\n      portalPortfolioCurrentAllocation {\n        tacticalAllocation\n        regionName\n        currentAllocation\n        StrategicAllocation\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCashBalance {\n    portalCashBalance {\n      todayInvestment\n      balances {\n        portfolioName\n        portfolioId\n        balance\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCashBalance {\n    portalCashBalance {\n      todayInvestment\n      balances {\n        portfolioName\n        portfolioId\n        balance\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation BankTransferConfirm($request: BankTransferPreferenceInput!) {\n    bankTransferConfirm(request: $request) {\n      isSuccess\n      errorMessage\n    }\n  }\n"): (typeof documents)["\n  mutation BankTransferConfirm($request: BankTransferPreferenceInput!) {\n    bankTransferConfirm(request: $request) {\n      isSuccess\n      errorMessage\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PortalOutstandingManagementFeeAmount {\n    portalOutstandingManagementFeeAmount {\n      totalOutstandingFeesAmount\n    }\n\n    portalEstimateManagementFee {\n      monthlyFeeAmount\n      annualFeeAmount\n      discountedAnnualFeeAmount\n      discountPct\n    }\n  }\n"): (typeof documents)["\n  query PortalOutstandingManagementFeeAmount {\n    portalOutstandingManagementFeeAmount {\n      totalOutstandingFeesAmount\n    }\n\n    portalEstimateManagementFee {\n      monthlyFeeAmount\n      annualFeeAmount\n      discountedAnnualFeeAmount\n      discountPct\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;