import moment from 'moment';
import { getRange, pickRandom, randomDate, randomNumberBetween } from '../../utils';

const getUserSettings = () => ({
  language: 'en-GB',
  currency: 'GBP',
  email: 'some-email@gmail.com',
  useLoginEmail: false,
});

const getMiscellaneous = () => ({
  languages: [
    {
      id: 'en-GB',
      text: 'United Kingdom',
      symbol: 'English',
      value: 'en-GB',
    },
    {
      id: 'zh-CN',
      symbol: '简体中文',
      text: '中国',
      value: 'zh-CN',
    },
    // {
    //   id: 'en-CA',
    //   symbol: 'English',
    //   text: 'Canada',
    //   value: 'en-CA',
    // },
    // {
    //   id: 'en-HK',
    //   symbol: 'English',
    //   text: 'Hong Kong',
    //   value: 'en-HK',
    // },
    // {
    //   id: 'en-US',
    //   symbol: 'English',
    //   text: 'United States',
    //   value: 'en-US',
    // },

    // {
    //   id: 'en-SG',
    //   symbol: 'English',
    //   text: 'Sinapore',
    //   value: 'en-SG',
    // },
    {
      id: 'ja-JP',
      symbol: '日本語',
      text: '日本',
      value: 'ja-JP',
    },
  ],

  currencies: [
    {
      id: 'CAD',
      symbol: '$',
      text: 'CAD',
      value: 'CAD',
    },
    {
      id: 'usd',
      symbol: '$',
      displayText: '$ USD',
      text: 'USD',
      value: 'USD',
    },
    {
      id: 'sgd',
      symbol: '$',
      text: 'SGD',
      value: 'SGD',
    },
    {
      id: 'hkd',
      symbol: '$',
      text: 'HKD',
      value: 'HKD',
    },
    {
      id: 'gbp',
      symbol: '£',
      text: 'GBP',
      value: 'GBP',
    },
    {
      id: 'eur',
      symbol: '€',
      text: 'EUR',
      value: 'EUR',
    },
    {
      id: 'cny',
      symbol: '¥',
      text: 'CNY',
      value: 'CNY',
    },

    {
      id: 'chf',
      symbol: '₣',
      text: 'CHF',
      value: 'CHF',
    },
  ],

  paymentFrequencies: [
    {
      id: 'weekly',
      text: 'Weekly',
      value: 'weekly',
    },
    {
      id: 'monthly',
      text: 'Monthly',
      value: 'monthly',
    },
    {
      id: 'yearly',
      text: 'Yearly',
      value: 'yearly',
    },
  ],
});

const getMockManagementFees = (numItems, startDate, endDate) => ({
  totalFees: 3000,
  totalOutstandingFees: 2000,
  managementFees: getRange(numItems).map((x, i) => ({
    id: `${i}`,
    name: `Name ${i}`,
    valuationDate: randomDate(startDate, endDate),
    feeType: pickRandom(['Annual Mgmt Fee', 'Late Paid Stock Fee', 'Cancelled Deal Rebate']),
    portfolioValue: randomNumberBetween(2000, 10000),
    offsetValue: '',
    feeAmount: randomNumberBetween(50, 500),
    applied: randomNumberBetween(10, 300),
    invoiceNumber: randomNumberBetween(100000, 10000000),
    invoiceDate: randomDate(startDate, endDate),
    status: pickRandom(['PAID', 'OUTSTANDING']),
  })),
});

const getMockNotifications = (numItems, startDate, endDate) => {
  const mockNotifications = [
    {
      title: 'Payment Received',
      subTitle:
        'Cult Wines Investment has successfully received a payment from you. Your available balance has now been updated to reflect this payment.',
    },
    {
      title: 'Fees Added',
      subTitle:
        'Cult Wines Investment has posted new fees to your account. Your available balance has now been updated to reflect these fees. If you wish to top your account up, please go to the accounts page to do so.',
    },
    {
      title: 'Wine Sold',
      subTitle:
        'Cult Wines Investment has successfully sold wine on your behalf. Your available balance has now been updated to reflect the incoming payment. Please note, it may take up to 14 working days before the funds have been cleared.',
    },
    {
      title: 'New Offer Available',
      subTitle: 'Cult Wines Investment has a new offer available for you. Please go your invest page to learn more.',
    },
    {
      title: 'Unsettled Deal',
      subTitle:
        'Cult Wines Investment is awaiting payment for deal: [add the deal reference from VinTrade]. Please navigate to the accounts page to add funds to your account to complete payment for the deal.',
    },
    {
      title: 'Sold Wine Payment Received',
      subTitle:
        'Cult Wines Investment has successfully completed the sale of wine for you. Your available balance has now been updated to reflect this payment.',
    },
  ];
  return getRange(numItems).map((x, i) => {
    const notification = pickRandom(mockNotifications);
    return {
      id: `${i}`,
      subject: notification.title,
      dateTime: randomDate(startDate, endDate),
      body: notification.subTitle,
      isRead: pickRandom([true, false]),
    };
  });
};

const onSetMgmtFeePreference = (_, args, context) => {
  console.log('setMgmtFeePreference 12213', args, _, context);
};

const mockResolver = {
  Query: {
    userSettings: getUserSettings,
    miscellaneous: getMiscellaneous,
    managementFees: () => getMockManagementFees(10, '2022-05-01', '2022-12-31'),
    notifications: () => getMockNotifications(10, '2022-12-01', moment().format('YYYY-MM-DD')),
  },
  Mutation: {
    setMgmtFeePreference: onSetMgmtFeePreference,
  },
};

export default mockResolver;
