import CheckBox from 'components/CheckBox';
import Dropdown, { DropdownItem } from 'components/Dropdown';
import { DateFilters, DateType } from 'components/Filters/dateFilter';
import { CellTypeEnum, TableCell, TableColumnType, TableRow } from 'components/Table';
import { TFunction } from 'i18next';
import moment from 'moment';
import { ExtendedDropDownType } from 'views/Accounts/types';
import { SearchIcon } from 'assets/icons';
import { capitalizeFirstLetter } from 'utils';
import { ALL_PORTFOLIOS, InitialStateType, MainReducerEnum, SELECT_ALL } from '../reducers/mainReducer';
import {
  GenerateDropDownItems,
  GetUniqueItemsType,
  SelectionTypeEnum,
  TRANSACTIONS_TABLE_REFS,
  TransactionsFormatedType,
} from '../types';

const cellClassName = 'text-sm text-black cursor-pointer whitespace-nowrap py-2';

const DATE_FORMAT = 'YYYY-MM-DD';

export const InitialiseTransactionTable = (translation: TFunction) => {
  const TRANSACTION_ITEMS_TEXTS = {
    [TRANSACTIONS_TABLE_REFS.ACCT_HOLDER_ID]: translation(
      `account:transactions.table.headers.${TRANSACTIONS_TABLE_REFS.ACCT_HOLDER_ID}`,
    ),
    [TRANSACTIONS_TABLE_REFS.CLIENT_ID]: translation(
      `account:transactions.table.headers.${TRANSACTIONS_TABLE_REFS.CLIENT_ID}`,
    ),
    [TRANSACTIONS_TABLE_REFS.CLIENT_NAME]: translation(
      `account:transactions.table.headers.${TRANSACTIONS_TABLE_REFS.CLIENT_NAME}`,
    ),
    [TRANSACTIONS_TABLE_REFS.WINE_LWIN_CODE]: translation(
      `account:transactions.table.headers.${TRANSACTIONS_TABLE_REFS.WINE_LWIN_CODE}`,
    ),
    [TRANSACTIONS_TABLE_REFS.VINTAGE]: translation(
      `account:transactions.table.headers.${TRANSACTIONS_TABLE_REFS.VINTAGE}`,
    ),
    [TRANSACTIONS_TABLE_REFS.WINE_NAME]: translation(
      `account:transactions.table.headers.${TRANSACTIONS_TABLE_REFS.WINE_NAME}`,
    ),
    [TRANSACTIONS_TABLE_REFS.REGION]: translation(
      `account:transactions.table.headers.${TRANSACTIONS_TABLE_REFS.REGION}`,
    ),
    [TRANSACTIONS_TABLE_REFS.UNIT_SIZE]: translation(
      `account:transactions.table.headers.${TRANSACTIONS_TABLE_REFS.UNIT_SIZE}`,
    ),
    [TRANSACTIONS_TABLE_REFS.NB_OF_UNITS]: translation(
      `account:transactions.table.headers.${TRANSACTIONS_TABLE_REFS.NB_OF_UNITS}`,
    ),
    [TRANSACTIONS_TABLE_REFS.ACTUAL_AMT]: translation(
      `account:transactions.table.headers.${TRANSACTIONS_TABLE_REFS.ACTUAL_AMT}`,
    ),
    [TRANSACTIONS_TABLE_REFS.TRANSACTION_DATE]: translation(
      `account:transactions.table.headers.${TRANSACTIONS_TABLE_REFS.TRANSACTION_DATE}`,
    ),
  };

  const transactionColumns: TableColumnType[] = [
    {
      dataRef: TRANSACTIONS_TABLE_REFS.CLIENT_NAME,
      className: 'pr-5 ',
      text: TRANSACTION_ITEMS_TEXTS[TRANSACTIONS_TABLE_REFS.CLIENT_NAME],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },
    {
      dataRef: TRANSACTIONS_TABLE_REFS.WINE_LWIN_CODE,
      className: 'pr-5 ',
      text: TRANSACTION_ITEMS_TEXTS[TRANSACTIONS_TABLE_REFS.WINE_LWIN_CODE],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },
    {
      dataRef: TRANSACTIONS_TABLE_REFS.VINTAGE,
      className: 'pr-5 ',
      text: TRANSACTION_ITEMS_TEXTS[TRANSACTIONS_TABLE_REFS.VINTAGE],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },

    {
      dataRef: TRANSACTIONS_TABLE_REFS.WINE_NAME,
      className: 'pr-5 ',
      text: TRANSACTION_ITEMS_TEXTS[TRANSACTIONS_TABLE_REFS.WINE_NAME],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },
    {
      dataRef: TRANSACTIONS_TABLE_REFS.REGION,
      className: 'pr-5 ',
      text: TRANSACTION_ITEMS_TEXTS[TRANSACTIONS_TABLE_REFS.REGION],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },
    {
      dataRef: TRANSACTIONS_TABLE_REFS.UNIT_SIZE,
      className: 'pr-5 ',
      text: TRANSACTION_ITEMS_TEXTS[TRANSACTIONS_TABLE_REFS.UNIT_SIZE],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },
    {
      dataRef: TRANSACTIONS_TABLE_REFS.NB_OF_UNITS,
      className: 'pr-5 ',
      text: TRANSACTION_ITEMS_TEXTS[TRANSACTIONS_TABLE_REFS.NB_OF_UNITS],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },
    {
      dataRef: TRANSACTIONS_TABLE_REFS.ACTUAL_AMT,
      className: 'pr-5 ',
      text: TRANSACTION_ITEMS_TEXTS[TRANSACTIONS_TABLE_REFS.ACTUAL_AMT],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },
    {
      dataRef: TRANSACTIONS_TABLE_REFS.TRANSACTION_DATE,
      className: 'pr-5 ',
      text: TRANSACTION_ITEMS_TEXTS[TRANSACTIONS_TABLE_REFS.TRANSACTION_DATE],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },
  ];

  return {
    transactionColumns,
  };
};

export const buildTableRow = (
  dataRow: Record<string, unknown>,
  columns: TableColumnType[],
  id?: string,
  className?: string,
): TableRow => {
  const cells = columns.map((col: TableColumnType) => {
    return {
      dataRef: col.dataRef,
      isVisible: col.isVisible,
      text: dataRow[col.dataRef] as string,
      className: col.cellClassName || '',
      type: col.cellType || CellTypeEnum.TD,
      cellContentTemplate: col.cellContentTemplate,
    };
  });

  return {
    id,
    className: className || '',
    cells,
    rowData: dataRow,
  };
};

export const SearchableDropdown = ({ config }: { config: ExtendedDropDownType }) => {
  return (
    <div className="flex flex-col  " key={config.name}>
      {config.name && <span className="text-base text-gray-800">{config.name}</span>}
      <Dropdown
        header={
          config.isSearchable && (
            <div className="flex flex-row items-center gap-2 px-4 pt-2">
              <SearchIcon />
              <input
                className="outline-none"
                type="text"
                name="Search..."
                placeholder="search"
                onChange={(e) => {
                  if (config?.setSearchText) config.setSearchText(e.target.value);
                }}
                value={config?.searchText}
              />
            </div>
          )
        }
        autoClose={config.autoClose ?? false}
        placeholder={config.placeholder ?? ''}
        value={config.value}
        containerClassName={config.containerClassName ?? 'w-full rounded '}
        itemsContainerClassName={config.itemsContainerClassName ?? 'max-h-[280px] overflow-y-auto'}
        className={
          config.className ?? ' text-14 text-black border  w-[280px]  p-4 rounded justify-between items-center '
        }
        itemsWrapperClassName={config.itemsWrapperClassName ?? 'w-full'}
        items={
          config.isSearchable && config.searchText
            ? (config.items || []).filter((x) =>
                x.value?.toLowerCase().includes(config?.searchText?.toLowerCase() || ''),
              )
            : config.items || []
        }
        itemClassName={config.itemClassName ?? 'text-base flex pl-0 pr-0 pt-0 pb-0'}
      />
    </div>
  );
};

const getUniqueItems = <T,>({ key, data }: GetUniqueItemsType<T>) => {
  const record = data as Record<string, unknown>[];
  const uniqueItems = [...new Set(record.map((x) => x[key]))];
  return uniqueItems;
};

export const generateDropDownItems = <T,>({
  dispatch,
  listItems,
  listItemsKey,
  isCheckedKey,
  checkedArray,
  selectionType,
  showCheckBox = true,
  isPortfolio,
}: GenerateDropDownItems<T>) => {
  let uniqueItems = getUniqueItems({ data: listItems, key: listItemsKey }) as string[];

  const selectAllText = isPortfolio ? ALL_PORTFOLIOS : SELECT_ALL;

  if (
    selectionType === SelectionTypeEnum.MULTI_SELECT ||
    (isPortfolio && selectionType === SelectionTypeEnum.SINGLE_SELECT)
  )
    uniqueItems = [selectAllText, ...uniqueItems];

  const items: DropdownItem[] = [];
  uniqueItems.forEach((value, index) => {
    const valueStr = String(value) || '';
    items.push({
      id: `${index}`,
      content: (
        <button
          className=" w-full py-3 px-4 text-base flex "
          onClick={() => {
            if (selectionType === SelectionTypeEnum.MULTI_SELECT) {
              dispatch({
                type: MainReducerEnum.MULTI_CHECK,
                payload: {
                  key: isCheckedKey,
                  value: valueStr,
                  all: uniqueItems,
                },
              });
            } else {
              dispatch({
                type: MainReducerEnum.SINGLE_CHECK,
                payload: {
                  key: isCheckedKey,
                  value: isPortfolio && valueStr === ALL_PORTFOLIOS ? [] : [valueStr],
                },
              });
            }
          }}
        >
          {showCheckBox ? (
            <CheckBox className="min-h-[20px] min-w-[20px]" isChecked={checkedArray.includes(valueStr)} id={`${index}`}>
              <span className="ml-3 text-left text-black text-14 ">{capitalizeFirstLetter(valueStr || '')}</span>
            </CheckBox>
          ) : (
            <span className="ml-3 text-left text-black text-14 min-h-[20px] min-w-[20px]">{valueStr}</span>
          )}
        </button>
      ),
      value: `${valueStr.toLocaleUpperCase()}`,
    });
  });

  return items;
};

export const filterDataSource = (state: InitialStateType, dataSource: TransactionsFormatedType[]) => {
  let source: TransactionsFormatedType[] = [...dataSource];
  if (state.isPortfolioCheck.length > 0)
    source = source.filter((x) => state.isPortfolioCheck.includes(x.client_name || ''));
  if (state.isRegionCheck.length > 0) source = source.filter((x) => state.isRegionCheck.includes(x.region || ''));
  if (state.isVintageCheck.length > 0) source = source.filter((x) => state.isVintageCheck.includes(x.vintage || ''));
  if (state.isWineNameCheck.length > 0)
    source = source.filter((x) => state.isWineNameCheck.includes(x.wine_name || ''));
  if (state.isBuyOrSellCheck.length > 0)
    source = source.filter((x) => state.isBuyOrSellCheck.includes(x.buyOrSell || ''));
  return source;
};

export const filterDataSourceByDate = (datePicker: DateFilters, dataSource: TransactionsFormatedType[]) => {
  let source: TransactionsFormatedType[] = [...dataSource];

  if (datePicker[DateType.START] || datePicker[DateType.END]) {
    const startDate = moment(datePicker[DateType.START]).format(DATE_FORMAT);
    const endDate = moment(datePicker[DateType.END]).format(DATE_FORMAT);
    source = source.filter((x) => {
      const rowDate = moment(x.transaction_date).format(DATE_FORMAT);
      return (
        moment(rowDate).isBetween(moment(startDate), moment(endDate)) ||
        moment(rowDate).isSame(moment(startDate)) ||
        moment(rowDate).isSame(moment(endDate))
      );
    });
  }
  return source;
};
