import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayTextKeys } from 'views/Trade/types';
import { AcceptBidEventType, Product } from 'types/productType';
import WineInfoTemplate from 'views/Trade/components/SlideOutView/WineInfoTemplate';
import { generateQuantityDropdown } from 'views/Trade/helpers';
import { AccountInfoType } from 'views/hooks/useFetchAccountData';
import useAcceptBid from 'hooks/useAcceptBid';
import { buildDisplayText } from '../../../utils';
import Button from '../../Button';
import Dropdown, { DropdownItem } from '../../Dropdown';
import { ProductEventType, ViewStateType } from '../types';
import { SortAndFilterLayoutContext } from '../../Layout/SortAndFilterLayout';
import { GbpToTargetCurrency } from '../../../types/commonTypes';

interface AcceptBidTemplateProps {
  product: Product;
  onCTA?: <T>(event: ProductEventType, data: T) => void;
  gbpToTargetCurrency: GbpToTargetCurrency;
  accountInfo: AccountInfoType | null;
}

const AcceptBidTemplate = ({ gbpToTargetCurrency, product, onCTA, accountInfo }: AcceptBidTemplateProps) => {
  const { t } = useTranslation();
  const slideoutContext = useContext(SortAndFilterLayoutContext);
  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'trade:common', t), [t]);
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const { lwin18, qty, vintage, wineName, unit, valuePerUnit, highestBid } = product;

  const qtyDropdown = (
    generateDropdown: (quantity?: number) => DropdownItem[],
    stockQuantity: number,
    bidQty: number,
  ) => {
    let qtyToGenerate = 0;
    if (bidQty > stockQuantity) {
      qtyToGenerate = stockQuantity;
    } else {
      qtyToGenerate = bidQty ?? 0;
    }
    return generateDropdown(qtyToGenerate);
  };

  const productLwin18 = useMemo(() => {
    return product?.lwin18;
  }, [product]);

  const { acceptBid } = useAcceptBid(productLwin18);

  const onClick = async (
    acceptBidFun: (selectedQty: number) => Promise<void>,
    selectedQty: number,
    account: AccountInfoType | null,
    setViewState?: <T>(event: ProductEventType, data: T) => void,
  ) => {
    if (!setViewState) return;
    slideoutContext?.updateSlideoutConfig({ showBackButton: false });
    let eventData: AcceptBidEventType = { viewStateType: ViewStateType.LOADING };

    try {
      setViewState(ProductEventType.SELL, eventData);
      await acceptBidFun(selectedQty);
      eventData = { viewStateType: ViewStateType.THANK_YOU, messageType: { translationKey: 'common:generalSuccess' } };
      setViewState(ProductEventType.SELL, eventData);
      setTimeout(() => {
        account?.account.refetchCashBalances?.();
      }, 3000);
    } catch (error) {
      eventData = { viewStateType: ViewStateType.ERROR_RESULT };
      setViewState(ProductEventType.SELL, eventData);
    }
  };

  return (
    <WineInfoTemplate
      config={{
        lwin18,
        quantity: qty,
        vintage: Number(vintage),
        wineName,
        unitSize: unit,
        marketValue: valuePerUnit,
        removeWineNameAndImage: true,
      }}
    >
      <div className="flex flex-col py-10 px-5 drop-shadow-sm ">
        <Dropdown
          placeholder={displayText[DisplayTextKeys.PLEASE_SELECT_QUANTITY]}
          value={selectedQuantity > 0 ? selectedQuantity?.toString() : ''}
          containerClassName="w-full rounded "
          itemsContainerClassName="max-h-[250px] min-h-10 overflow-y-auto"
          className="flex-1 text-14 text-black border  h-12 p-4 rounded justify-between items-center"
          itemsWrapperClassName="w-full"
          items={qtyDropdown(generateQuantityDropdown, qty, highestBid?.quantityOutstanding ?? 0)}
          onItemSelect={(item) => setSelectedQuantity(Number(item.value))}
        />
        <div className="flex justify-between divide-x divide-gray-300 mt-4">
          <div className="flex-1 flex flex-col">
            <span className="text-center text-sm">{`${displayText[DisplayTextKeys.ACCEPT_BID_MESSAGE]}`}</span>
            <span className="text-center text-sm">
              {`${selectedQuantity} x ${gbpToTargetCurrency.convertAndFormat(highestBid?.priceGbp ?? 0)} `}
            </span>
          </div>
        </div>

        <Button
          isDisable={!selectedQuantity}
          onClick={() => onClick(acceptBid, selectedQuantity, accountInfo, onCTA)}
          type="button"
          className="text-14 font-normal rounded-full mt-8  btn  bg-orange text-black disabled:bg-[#cccccc] disabled:text-[#b3b3b3]"
        >
          {displayText[DisplayTextKeys.ACCEPT_BID]}
        </Button>
      </div>
    </WineInfoTemplate>
  );
};

export default AcceptBidTemplate;
