import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';

interface AddCardButtonProps {
  onClick: () => void;
}

export default function AddCardButton({ onClick }: AddCardButtonProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <button
      onClick={onClick}
      type="button"
      className={`flex justify-center items-center gap-2 border-dashed border rounded-sm border-gray-300 bg-gray-100 underline p-4 w-[220px] h-[128px] text-sm`}
    >
      <PlusIcon /> {t('account:payments.addCard.title')}
    </button>
  );
}
