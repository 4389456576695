import { SettingsAction, SettingsEventTypes, UserSettings } from '../types/AppType';

export const settingsReducer = (state: UserSettings, action: SettingsAction) => {
  switch (action.type) {
    case SettingsEventTypes.UPDATE_SETTINGS:
      return {
        ...state,
        ...(action.payload || {}),
      };

    case SettingsEventTypes.UPDATE_MGMT_FEE_SETTINGS:
      return {
        ...state,
        mgmtFeesPolicy: {
          ...state.mgmtFeesPolicy,
          ...(action.payload || {}),
        },
      };

    case SettingsEventTypes.UPDATE_LEGACY_FEE_SETTINGS:
      return {
        ...state,
        legacyFeesPolicy: {
          ...state.legacyFeesPolicy,
          ...(action.payload || {}),
        },
      };

    default:
      return state;
  }
};
