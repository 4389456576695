import React from 'react';
import { Dialog } from '@headlessui/react';
import { CardNumber, Cvv, ExpiryDate, Frames } from 'frames-react';
import Alert from 'components/Alert';
import { useTranslation } from 'react-i18next';
import { CloseBlackIcon } from 'assets/icons';
import { Country, getISO2Country } from 'utils';
import { COUNTRIES } from '../../../../../constants';
import Visa from '../../../../../assets/images/visa.jpg';
import Mastercard from '../../../../../assets/images/mastercard.jpg';
import { Button } from 'components';

interface AddCardModalFormProps {
  nameOnCard: string;
  country: Country;
  postcode: string;
  makeCardDefault: boolean;
  disableCTA?: boolean;
  className?: string;
  error: Error | null;
  onClose: () => void;
  onNameOnCardChanged: (newValue: string) => void;
  onCountryChanged: (newValue: Country) => void;
  onPostcodeChanged: (newValue: string) => void;
  onMakeCardDefaultChanged: (newValue: boolean) => void;
  onSaveCardButtonClicked: () => void;
  isProcessing?: boolean;
}

export default function AddCardModalForm({
  nameOnCard,
  country,
  postcode,
  makeCardDefault,
  onNameOnCardChanged,
  onCountryChanged,
  onPostcodeChanged,
  onMakeCardDefaultChanged,
  onSaveCardButtonClicked,
  onClose,
  error,
  disableCTA,
  className = '',
  isProcessing,
}: AddCardModalFormProps) {
  const { t } = useTranslation();
  return (
    <Dialog.Panel
      className={`w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all ${className}`}
    >
      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
        <div className="flex justify-between">
          {t('account:payments.addCard.title')}
          <CloseBlackIcon className="right-5 cursor-pointer text-14 z-10  top-4" onClick={onClose} />
        </div>
      </Dialog.Title>
      <div className="flex flex-row mt-4">
        <p>{t('account:payments.addCard.acceptedCards')}</p>
        <div className="flex gap-2 ml-auto">
          <img src={Visa} alt="visa" />
          <img src={Mastercard} alt="mastercard" />
        </div>
      </div>
      <div className="mt-4">
        {error ? (
          <Alert className="bg-red w-full text-14 text-white m-0" show>
            {/* TODO: Surely we need something better than a generic error message for this, but we need actual translations provided for complex sentences such as this */}
            {/* We encountered and issue when trying to store your card details. Please ensure they are correct and try
            again. If the problem persists please contact support. */}
            {t('common:errorResult.title')}
          </Alert>
        ) : (
          <p className="text-sm text-gray-500">{t('account:payments.addCard.explainerOptional')}</p>
        )}
      </div>

      <div className="mt-4">
        <Frames
          config={{
            debug: process.env.NODE_ENV === 'development',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            publicKey: process.env.REACT_APP_CHECKOUT_COM_PUBLIC_KEY!,
            cardholder: {
              billingAddress: {
                country: getISO2Country(country),
                zip: postcode,
              },
              name: nameOnCard,
            },
            localization: {
              cardNumberPlaceholder: t('account:payments.addCard.formFields.placeholders.cardNumber'),
              expiryMonthPlaceholder: t('account:payments.addCard.formFields.placeholders.expiryMonth'),
              expiryYearPlaceholder: t('account:payments.addCard.formFields.placeholders.expiryYear'),
              cvvPlaceholder: t('account:payments.addCard.formFields.placeholders.cvv'),
            },
            style: {
              base: {
                // Get it as close to our text field styling as possible.
                color: 'black',
                fontSize: '14px',
                fontWeight: 300,
                letterSpacing: 'normal',
                border: `1px solid gray`,
                borderRadius: '4px',
                height: '47px',
                padding: '18px 12px',
                background: 'white',
                fontFamily: 'sans-serif',
              },
              placeholder: {
                base: {
                  color: 'black',
                },
              },
              invalid: {
                border: `1px solid red`,
              },
            },
          }}
          // TODO: in https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/9914
          cardValidationChanged={() => {}}
          cardTokenized={() => {}}
        >
          <div className="flex flex-col gap-4">
            <CardNumber className="h-[47px]" />
            <ExpiryDate className="h-[47px]" />
            <Cvv className="h-[47px]" />
            <input
              id="name"
              className="checkout-com-text-field"
              type="text"
              placeholder={t('account:payments.addCard.formFields.placeholders.name')}
              value={nameOnCard}
              onChange={(e) => onNameOnCardChanged(e.target.value)}
            />

            <input
              id="postcode"
              className="checkout-com-text-field"
              type="text"
              placeholder={t('account:payments.addCard.formFields.placeholders.postcode')}
              value={postcode}
              onChange={(e) => onPostcodeChanged(e.target.value)}
            />

            <label htmlFor="country" className="text-sm flex flex-col gap-1 items-start">
              {t('account:payments.addCard.formFields.placeholders.country')}
              <select
                className="mr-5 text-base outline-none w-full text-start relative"
                value={country}
                onChange={({ target: { value } }) => onCountryChanged(value)}
              >
                {COUNTRIES.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>

            <label htmlFor="makedefault" className="flex gap-1">
              <input
                id="makedefault"
                type="checkbox"
                checked={makeCardDefault}
                color="primary"
                onChange={() => onMakeCardDefaultChanged(!makeCardDefault)}
                disabled
              />
              {t('account:payments.addCard.formFields.makeDefault')}
            </label>
          </div>
        </Frames>
        <Button
          isDisable={disableCTA}
          isProcessing={isProcessing}
          type="button"
          className="btn-accent mt-4 disabled:bg-gray-300 "
          onClick={onSaveCardButtonClicked}
        >
          {t('account:payments.addCard.actions.save')}
        </Button>
      </div>
    </Dialog.Panel>
  );
}
