import { campaignsListType } from './services/fetchCampaignOffers';

export enum TabTypes {
  CURRENT = 'current_tab',
  PREVIOUS = 'previous_tab',
}

export interface InvestOffer extends CampaignsOffersType {
  id: string;
  type: string;
  name: string;
  subtitle: string;
  priceGbp: number;
  unitSize: string;
  expiryDate: string;
  region: string;
  status: 'current' | 'previous';
  offerSource?: 'prismic' | 'LD';
  mainImage: string;
  sections?: {
    title: string;
    content: string;
  }[];
  disclaimer?: string;
}

interface StatementsType {
  id?: string;
  date: string;
  status?: 'current' | 'previous';
  statements?: {
    main: {
      columns: string[];
      rows: string[][];
    };
  };
}

export interface CampaignsOffersType {
  id: string;
  buyOrSell: 'B' | 'S';
  price: number;
  initialQuantity: number;
  quantityOutstanding: number;
  stopLimitPrice: number | null;
  orderType: string;
  wineName: string;
  vintage: number;
  unitSize: string;
  assetId: number;
  lwin18: string;
  userId: string;
  assetAccountId: string;
  createdDateTime: string;
  updatedDateTime: string | null;
  marketValue: number;
  inPackage: boolean;
  highestBid: number | null;
  lowestOffer: number | null;
  campaignName: string;
  campaignId: number;
  packageName: string | null;
  packageId: string | null;
  executionPrice: number | null;
  wac: number;
  physicalQuantity: number;
  orderCreatedDateTime: string;
  region?: string;
  stringRef?: string;
  numberOfUnitsAllowed?: number;
  priceInTargetCurrency?: number;
  exchangeRate?: number;
}

export enum InventTopUpEnum {
  onInvestFailure = 'onInvestFailure',
  onInvestSuccess = 'onInvestSuccess',
}

export interface campaignPurchaseRefType {
  userId?: string;
  prevAcctBalance?: number;
  id?: string;
  portfolioId: number;
  lwin18: string;
  offerId: string;
  price: number;
  quantity: number;
  campaignId?: number | null;
  name?: string;
  priceGbp?: number;
  subtitle?: string;
  stringRef?: string;
}

export enum CampaignOffersQueryKeyEnum {
  'fetchInitalCampaignOffers' = 'fetchInitalCampaignOffers',
  'fetchAdditionalCampaignOffers' = 'fetchAdditionalCampaignOffers',
  'fetchAllAtOnceCampaignOffers' = 'fetchAllAtOnceCampaignOffers',
  'fetchCampaignOffers' = 'fetchCampaignOffers',
}

export enum loaclStorageKeysEnum {
  'campaignPurchases' = 'campaignPurchases',
}

export interface CampaignPurchasesType {
  campaignId: number;
  lwin18: string;
  quantity: number;
  numberOfUnitsAllowed?: number;
  id?: string;
}
export interface CampaignsConfigType {
  initalFetch: {
    queryKey: CampaignOffersQueryKeyEnum | null;
    enabled: boolean;
    campaigns: campaignsListType[];
  };
  additionalFetch: {
    queryKey: CampaignOffersQueryKeyEnum | null;
    enabled: boolean;
    campaigns: campaignsListType[];
  };

  allAtOnceFetch: {
    queryKey: CampaignOffersQueryKeyEnum | null;
    enabled: boolean;
    campaigns: campaignsListType[];
  };
  campaignsIsEnabled: boolean;
}
