import { useMemo } from 'react';
import { usePortfolioBalances } from 'views/Portfolio/components/Summary/hooks/usePortfolioBalances';
import { useFetchCashBalance } from 'views/shared/hooks/useFetchCashBalance';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { BalanceSummary, CashBalanceResponse } from 'views/shared/types';
import { PortfolioBalance } from 'types/DomainTypes';

export type PortfolioInfoType = BalanceSummary & PortfolioBalance;
type RefetchCashBalancesType =
  | (<TPageData>(
      options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<CashBalanceResponse, unknown>>)
  | undefined;

export interface AccountInfoType {
  account: {
    fxRate: number;
    totalCashOnAccount: number | undefined;
    refetchCashBalances: RefetchCashBalancesType;
  };
  portfolios: PortfolioInfoType[] | undefined;
}

export const useFetchAccountData = () => {
  const {
    data: cashBalanceResponse,
    isLoading: loadingCashBalances,
    refetch: refetchCashBalances,
    error: errorCashBalances,
  } = useFetchCashBalance();

  const portfolios = useMemo(() => {
    if (loadingCashBalances || errorCashBalances) return null;
    return cashBalanceResponse;
  }, [loadingCashBalances, cashBalanceResponse, errorCashBalances]);
  //  console.log('portfolios ', portfolios);
  const {
    portfolioBalances,
    loading: isLoadingPortfolioBalances,
    error: errorPortfolioBalances,
  } = usePortfolioBalances();

  const additionalPortfolioInfo = useMemo(() => {
    if (isLoadingPortfolioBalances || errorPortfolioBalances) return null;
    return portfolioBalances.filter((x) => x.portfolioId !== null);
  }, [isLoadingPortfolioBalances, portfolioBalances, errorPortfolioBalances]);

  const data = useMemo(() => {
    if (!portfolios || !additionalPortfolioInfo) return null;
    return {
      account: {
        fxRate: portfolios.balances?.[0].exchangeRate,
        totalCashOnAccount: portfolios?.balances?.[0]?.balance || 0,
        refetchCashBalances: refetchCashBalances,
      },
      portfolios: (portfolios?.balances || [])
        .filter((b) => b.portfolioId !== null)
        .map((x) => {
          const matchData = additionalPortfolioInfo?.find((a) => a.portfolioId === x.portfolioId);
          if (matchData) {
            return { ...matchData, ...x };
          }
          return x;
        }) as PortfolioInfoType[],
    };
  }, [additionalPortfolioInfo, portfolios, refetchCashBalances]);

  return {
    isLoading: loadingCashBalances || isLoadingPortfolioBalances,
    error: errorCashBalances || errorPortfolioBalances,
    data,
  };
};
