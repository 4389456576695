import { httpPost } from 'utils';

type BankTransferConfirmResponse = {
  errorMessage?: string;
  isSuccess: boolean;
};

export type BankTransferConfirmRequest = {
  accNumber: string;
  amount: number;
  bankAddress: string;
  bankInstitution: string;
  bankName: string;
  bic: string;
  company: string;
  currency: string;
  fxRate: number;
  gbpAmount: number;
  iban: string;
  reference: string;
  routing: string;
  sortCode: string;
};

export const confirmBankTransferApi = (config?: BankTransferConfirmRequest) =>
  httpPost<BankTransferConfirmResponse, BankTransferConfirmRequest>('portal/AuthRegister', config);
