import { useQuery } from '@tanstack/react-query';
import { fetchPortalDocument } from 'services/fetchPortalDocument';
import { PortalDocumentResult, PortalDocumentParams } from 'types/serviceTypes';

interface UseFetchPortalDocumentResponse {
  data: PortalDocumentResult | undefined;
  isLoading: boolean;
  error: unknown;
}

const useFetchPortalDocument = (params: PortalDocumentParams): UseFetchPortalDocumentResponse => {
  const { data, isLoading, error } = useQuery({
    queryFn: () => fetchPortalDocument(params),
    queryKey: [`$fetchPortalDocument${params.docType}${params.docRef}`],
  });

  return { data, isLoading, error };
};

export default useFetchPortalDocument;
