import { useMutation } from '@apollo/client';
import { ResetPasswordMutationMutation, ResetPasswordMutationMutationVariables } from '__generated__/graphql';
import { RESET_PASSWORD_MUTATION } from '../graphql/resetPassword';

const usePasswordMutation = () => {
  const [resetPassword] = useMutation<ResetPasswordMutationMutation, ResetPasswordMutationMutationVariables>(
    RESET_PASSWORD_MUTATION,
    {
      // Catches network errors and returns them in errors in response
      context: { serviceName: 'insecure' },
      onError: () => null,
    },
  );
  return {
    resetPassword,
  };
};

export default usePasswordMutation;
