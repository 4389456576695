export enum MainReducerEnum {
  CHECK = 'check',
  SELECT_PORTFOLIO = 'selectPortfolio',
  RESET_FILTERS = 'resetFilters',
}

export type MainReducerActions =
  | {
      type: MainReducerEnum.CHECK;
      payload: { key: IsCheckedKeyEnum; value: string; all: string[] };
    }
  | {
      type: MainReducerEnum.SELECT_PORTFOLIO;
      payload: { value: string[]; text: string };
    }
  | {
      type: MainReducerEnum.RESET_FILTERS;
      payload: { allPortfolio: string[]; allDetailBorad: string[] };
    };

export interface InitialStateType {
  isDetailBoradCheck: string[];
  selectedPortfolio: string[];
  selectedPortfolioText: string | undefined;
  isPortfolioCheckSelectedText: string | undefined;
  isDetailBoradCheckSelectedText: string | undefined;
}

export enum IsCheckedKeyEnum {
  isDetailBoradCheck = 'isDetailBoradCheck',
  isPortfolioCheck = 'selectedPortfolio',
}

export const SELECT_ALL = 'Select All';
export const ALL_PORTFOLIOS = 'All Portfolios';
export const mainReducer = (state: InitialStateType, action: MainReducerActions): InitialStateType => {
  switch (action.type) {
    case MainReducerEnum.CHECK: {
      const key = action.payload.key;
      const isChecked = state[action.payload.key];
      const value = action.payload.value;
      const selectAllData = action.payload.all;
      let returnState: InitialStateType;

      if (isChecked.includes(value)) {
        returnState = { ...state, [key]: isChecked.filter((x) => x !== value) };
        if (value === SELECT_ALL) {
          returnState = { ...state, [key]: [] };
        } else if (isChecked.includes(SELECT_ALL)) {
          returnState = {
            ...state,
            [key]: isChecked.filter((x) => x !== SELECT_ALL).filter((x) => x !== value),
          };
        }
      } else if (value === SELECT_ALL) {
        returnState = {
          ...state,
          [key]: selectAllData,
        };
      } else if (isChecked.length + 1 === selectAllData.length - 1) {
        returnState = {
          ...state,
          [key]: selectAllData,
        };
      } else {
        returnState = {
          ...state,
          [key]: [...isChecked, value],
        };
      }

      let textkey = '';
      let placeHolderValue = '';
      if (key === IsCheckedKeyEnum.isDetailBoradCheck) {
        textkey = 'isDetailBoradCheckSelectedText';
        placeHolderValue = 'All';
      } else {
        textkey = 'isPortfolioCheckSelectedText';
        placeHolderValue = 'All Portfolios';
      }

      if (returnState[key].length === 1) {
        returnState = { ...returnState, [textkey]: returnState[key][0] };
      } else if (returnState[key].length > 1 && !returnState[key].includes(SELECT_ALL)) {
        returnState = { ...returnState, [textkey]: 'Multiple selection' };
      } else {
        returnState = { ...returnState, [textkey]: placeHolderValue };
      }

      return {
        ...state,
        ...returnState,
      };
    }
    case MainReducerEnum.SELECT_PORTFOLIO: {
      return {
        ...state,
        selectedPortfolio: action.payload.value,
        selectedPortfolioText: action.payload.text,
      };
    }

    case MainReducerEnum.RESET_FILTERS: {
      return {
        ...state,
        isDetailBoradCheck: action.payload.allDetailBorad,
        selectedPortfolio: action.payload.allPortfolio,
      };
    }
    default:
      return state;
  }
};
