import { httpPost } from 'utils';

export interface BuyProductRequest {
  lwin18: string;
  qty: number;
  avgPurchasePrice: number;
  requestPrice: number;
}
interface BuyRequestResponse {
  isSuccess: boolean;
}

export const buyRequestApi = (config?: BuyProductRequest) =>
  httpPost<BuyRequestResponse, BuyProductRequest>('portal/BuyRequest', config);
