export interface IProformaReport {
  header: {
    currency: string;
    customerAddress: string;
    customerNo: string;
    documentDate: string;
    customerRef: string;
    contactPerson: string;
    salesContact: string;
  };

  tableRow: {
    itemNo: string;
    itemName: string;
    qty: string;
    price: string;
    totalGBP: string;
  }[];
  otherDetails: {
    accountName: string;
    bankName: string;
    bankAddress: string;
    accountNo: string;
    bankCode: string;
    branch: string;
    IBAN: string;
    totalBeforeTaxGBP: string;
    taxAmountGBP: string;
    documentTotalGBP: string;
    swiftBic: string;
    swiftBik: string;
    routing: string;
    payNowUED: string;
  };
}

export enum ProformaReportHashValueEnum {
  'reportData' = '#reportData=',
}
