import { httpPatch } from 'utils/httpPatch';

interface IEditOffersResponse {
  message: string | unknown;
  success: boolean | unknown;
}

interface IEditOffersBody {
  stopLimitPerUnit: number;
  quantity: number;
}

export interface IEditOffersParams {
  orderId: string | number;
  operationType: string;
  body: IEditOffersBody;
}

export const editOffer = (params?: IEditOffersParams) => {
  const path = `portal/Vintrade/Orders/${params?.orderId}/Sell`;
  return httpPatch<IEditOffersResponse, IEditOffersBody>(path, params?.body, true, {
    operationType: params?.operationType,
  });
};
