import { httpPost } from 'utils';

interface ContactUsParams {
  subject: string;
  message: string;
  contactMethod: string;
  contactMethodValue: string;
}

export interface ContactUsResult {
  isSuccess: boolean;
}

export const contactUsApi = (config?: ContactUsParams) =>
  httpPost<ContactUsResult, ContactUsParams>('portal/ContactUs', config);
