import { CircleSolidInfoIcon } from 'assets/icons';
import { Button, Dropdown } from 'components';
import { useTranslation } from 'react-i18next';
import { AccountViewType, SubjectOptionKeys } from 'views/Accounts/types';
import { NavigationPath } from 'types/DomainTypes';
import { useNavigate } from 'react-router-dom';
import { UseFeeModelInfoType } from '../hooks/useFeeModelInfo';

interface SelectPortfolioViewType {
  feeModelInfo: UseFeeModelInfoType;
  onClick: () => void;
}

const SelectPortfolioView = ({ feeModelInfo, onClick }: SelectPortfolioViewType) => {
  const { t } = useTranslation();
  const nav = useNavigate();

  return (
    <div className="flex flex-col bg-white h-full p-4">
      <div className="flex flex-1 flex-col">
        {feeModelInfo.showFeeModelInfo && (
          <div className="mb-[60px]">
            <div className="flex items-center w-full mt-8 mb-4">
              <div className="mr-5">{t('account:slideout.topup.recurring_payment_info_title')}</div>
              <CircleSolidInfoIcon />
            </div>
            <p className="text-sm w-full flex-1 h-full overflow-x-hidden">
              {t(`account:slideout.topup.current_fee_model_info`)}
            </p>
          </div>
        )}
        {feeModelInfo.ddlOptions.length > 1 && (feeModelInfo.canExecTransaction || feeModelInfo.canTopUp) && (
          <>
            <Dropdown
              placeholder={t('common:selectPortfolio')}
              value={feeModelInfo.selectedPortfolio?.text}
              containerClassName="w-full rounded mb-[20px] "
              itemsContainerClassName="max-h-[250px] min-h-10 overflow-y-auto"
              className="flex-1 text-14 text-black border-b  h-12 py-2 rounded justify-between items-center "
              itemsWrapperClassName="w-full"
              items={feeModelInfo.ddlOptions}
              onItemSelect={(item) => {
                feeModelInfo.setSelectedPortfolio(item);
              }}
            />
            <Button
              isDisable={!feeModelInfo.selectedPortfolio}
              className={` my-5 text-14 bg-orange font-normal rounded-full btn flex justify-center items-center py-3 text-black disabled:bg-gray-500`.trim()}
              onClick={onClick}
              props={{
                name: 'requestTab',
              }}
            >
              {feeModelInfo.getRemaingBalAndBtnText(true).btnText}
            </Button>
          </>
        )}

        {!feeModelInfo.canTopUp && !feeModelInfo.canExecTransaction && (
          <Button
            className={` my-5 text-14 bg-orange font-normal rounded-full btn flex justify-center items-center py-3 text-black disabled:bg-gray-500`.trim()}
            onClick={() => {
              nav(NavigationPath.ACCOUNTS, {
                state: {
                  accountViewType: AccountViewType.CONTACT_US,
                  subject: SubjectOptionKeys.FINANCIAL,
                },
              });
            }}
            props={{
              name: 'requestTab',
            }}
          >
            {t('common:contact')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SelectPortfolioView;
