import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
  },

  baseContainer: {
    display: 'flex',
    paddingHorizontal: 20,
    flexDirection: 'column',
  },

  headingContainer: {
    padding: 10,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 5,
  },
  negativeValue: {
    fontFamily: 'Times-Roman',
    fontSize: 10,
    color: 'red', // Set the color you want for negative values
  },
  headingImg: {
    //  transform:"scale(1.2)",
    width: 200,
    objectFit: 'contain',
  },
  subHeadingText: {
    marginLeft: 20,
    fontSize: 16,
    fontFamily: 'Times-Roman',
  },

  headingText: {
    display: 'flex',
    fontSize: 14,
    fontFamily: 'Times-Roman',
    fontWeight: 'bold',
    lineHeight: 1.5,
  },
  textMediumBold: {
    fontSize: 14,
    fontFamily: 'Times-Bold',
  },
  textRegularBold: {
    fontSize: 10,
    fontFamily: 'Times-Bold',
  },
  textRegular: {
    fontSize: 10,
    fontFamily: 'Times-Roman',
  },
  textRegularSmall: {
    fontSize: 10,
    fontFamily: 'Times-Roman',
  },
  statsContainer: {
    backgroundColor: '#E8E9EB',
    padding: 14,
    height: 150,
    marginBottom: 15,
  },

  statsInnerContainer: {
    flex: 1,
    padding: 18,
    justifyContent: 'space-around',
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
  },

  statsCardContainer: {
    display: 'flex',
    padding: 5,
    gap: 8,
    alignItems: 'center',
  },

  statsCardHeading: {
    fontSize: 10,
    fontFamily: 'Times-Roman',
  },
  statsCardText: {
    fontSize: 18,
    fontFamily: 'Times-Roman',
  },
  statsCardSmallText: {
    fontSize: 10,
    fontFamily: 'Times-Roman',
  },

  subHeadings: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableSection: {
    marginTop: 15,
    flexGrow: 1,
  },
  table: {
    backgroundColor: 'white',
    display: 'flex',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRight: 0,
    borderTop: 0,
  },
  tableHead: {
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    borderLeftWidth: 1,
  },
  tableRow: { display: 'flex', flexDirection: 'row' },
  cell: {
    padding: 5,
    flex: 1,
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderTopWidth: 1,
  },
  headingCell: { fontSize: 10, padding: 5, fontFamily: 'Times-Bold' },
  cellText: { fontFamily: 'Times-Roman', fontSize: 10 },

  footer: {
    display: 'flex',
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: 'column',
    fontSize: 12,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  footerText: {
    color: 'black',
    textAlign: 'center',
    fontFamily: 'Times-Roman',
  },
});
