import { NavigationPath } from 'types/DomainTypes';
import { useNavigate } from 'react-router-dom';
import { ViewStateType } from 'components/ProductTemplates/types';
import SlideOutPanel from 'components/SlideOutPanel';
import { useContext, useEffect, useState } from 'react';
import { onTradeTopUpEnum, SlideoutConfigType, TABLE_ACTIONS, TradePurchaseRefType } from 'views/Trade/types';
import { AppContext } from 'context/ContextProvider';
import { StatusTypes, useHandleBuy } from '../hooks/useHandleBuy';
import RequestWineOnFailedPurchase from './RequestWineOnFailedPurchase';
import { useFetchCashBalanceUtillChange } from '../hooks/useFetchCashBalanceUtillChange';
import { FeedbackTemplate } from '../FeedbackTemplate';

interface VerifyTopUpAndContinueToPurchaseType {
  config?: SlideoutConfigType['extraConfig'];
}

const TIME_OUT = 1500;

const VerifyTopUpAndContinueToPurchase = ({ config }: VerifyTopUpAndContinueToPurchaseType) => {
  const {
    state: {
      settings: { accountInfo },
    },
  } = useContext(AppContext);

  const nav = useNavigate();
  const [slideOutConfig, setSlideOutConfig] = useState<SlideoutConfigType>({
    showBackButton: false,
    isOpen: false,
    view: ViewStateType.DEFAULT,
    title: '',
    isDetailsFetched: false,
  });

  const [purchaseRef, setPurchaseRef] = useState<TradePurchaseRefType | null>(null);
  const { isBalanceChanged } = useFetchCashBalanceUtillChange(purchaseRef);
  const { handlePurchase: executeBuy, mutateStatus: PurchaseStatus, resetStatus } = useHandleBuy();

  const onStatusChange = (viewState: ViewStateType) => {
    setSlideOutConfig((prev) => ({ ...prev, view: viewState }));
  };

  useEffect(() => {
    const hashValue = window.location.hash;
    const isSuccess = Boolean(hashValue && hashValue.startsWith(`#${onTradeTopUpEnum.onTradeToupSuccess}=`));
    const isFail = Boolean(hashValue && hashValue.startsWith(`#${onTradeTopUpEnum.onTradeToupFailure}=`));
    const isTargetHash = isSuccess || isFail;
    if (!isTargetHash) return;

    const purchaseInfoRef = atob(
      decodeURIComponent(
        hashValue.replace(
          isSuccess ? `#${onTradeTopUpEnum.onTradeToupSuccess}=` : `#${onTradeTopUpEnum.onTradeToupFailure}=`,
          '',
        ),
      ),
    );

    if (purchaseInfoRef) {
      if (isSuccess) {
        const params = JSON.parse(purchaseInfoRef) as TradePurchaseRefType;
        setPurchaseRef(params);
        window.history.replaceState({}, document.title, window.location.pathname);
        setSlideOutConfig((prev) => ({
          ...prev,
          isOpen: true,
          slidoutView: TABLE_ACTIONS.CUSTOM,
          view: ViewStateType.TOP_UP_LOADING,
        }));
      }

      if (isFail) {
        setSlideOutConfig((prev) => ({
          ...prev,
          isOpen: true,
          slidoutView: TABLE_ACTIONS.CUSTOM,
          view: ViewStateType.LOADING,
        }));

        setTimeout(() => {
          setSlideOutConfig((prev) => ({
            ...prev,
            slidoutView: TABLE_ACTIONS.CUSTOM,
            view: ViewStateType.ERROR_RESULT,
            onCTA: () => {
              setSlideOutConfig({ isOpen: false });
            },
          }));
        }, TIME_OUT);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash, setPurchaseRef]);

  useEffect(() => {
    if (!purchaseRef) return;
    if (isBalanceChanged && PurchaseStatus !== StatusTypes.success) {
      setSlideOutConfig((prev) => ({
        ...prev,
        view: ViewStateType.TOP_UP_SUCCESS,
        onCTA: () => executeBuy({ body: purchaseRef?.body }),
      }));
      accountInfo?.account?.refetchCashBalances?.();
    }
    if (PurchaseStatus === StatusTypes.isLoading)
      setSlideOutConfig((prev) => ({
        ...prev,
        view: ViewStateType.LOADING,
      }));
    if (PurchaseStatus === StatusTypes.error)
      setSlideOutConfig((prev) => ({
        ...prev,
        view: ViewStateType.PURCASE_ERROR,
      }));
    if (PurchaseStatus === StatusTypes.success) {
      setSlideOutConfig((prev) => ({
        ...prev,
        view: ViewStateType.CAMPAIGN_PURCHASE,
        onCTA: () => {
          nav(`${NavigationPath.MY_PORTFOLIO}#find=${encodeURIComponent(btoa(JSON.stringify(purchaseRef)))}`);
          setSlideOutConfig({ isOpen: false });
          config?.success?.cta?.();
        },
      }));
      config?.success?.onSuccess?.();
      accountInfo?.account?.refetchCashBalances?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PurchaseStatus, isBalanceChanged, accountInfo?.account, purchaseRef]);

  return (
    <SlideOutPanel
      headClassName="bg-vine"
      onBack={slideOutConfig.onBack}
      showBackButton={slideOutConfig.showBackButton}
      title={slideOutConfig.title}
      isBackgroundDark={true}
      isOpen={slideOutConfig?.isOpen as boolean}
      onClose={() => {
        if (slideOutConfig.view === ViewStateType.LOADING || slideOutConfig.view === ViewStateType.TOP_UP_LOADING)
          return;
        setSlideOutConfig({ isOpen: false });
      }}
    >
      <div className="flex flex-col flex-1 bg-gradient-to-b from-vine to-gray-500 pb-5 px-3 w-screen relative overflow-x-hidden sm:w-[390px]">
        <div className="flex bg-white overflow-y-auto flex-col flex-1">
          {slideOutConfig.view === ViewStateType.PURCASE_ERROR && (
            <RequestWineOnFailedPurchase
              offer={{
                expiryDate: '',
                price: purchaseRef?.priceInTargetCurrency || 0,
                subtitle: purchaseRef?.subtitle || '',
                name: purchaseRef?.name || '',
              }}
              onStatusChange={onStatusChange}
              qtyBought={purchaseRef?.body?.buys.reduce((prevVal, currentVal) => prevVal + currentVal.quantity, 0) || 0}
              resetStatus={resetStatus}
              onClose={() => setSlideOutConfig({ isOpen: false })}
            />
          )}
          {slideOutConfig.view !== ViewStateType.PURCASE_ERROR && (
            <FeedbackTemplate
              viewState={slideOutConfig?.view || ViewStateType.DEFAULT}
              templateConfig={slideOutConfig.templateConfig}
              onCTA={slideOutConfig.onCTA}
              onClose={() => {
                setSlideOutConfig({ isOpen: false });
              }}
            />
          )}
        </div>
      </div>
    </SlideOutPanel>
  );
};

export default VerifyTopUpAndContinueToPurchase;
