import { httpGet } from 'utils';
import { PerformanceOverTime } from 'views/Portfolio/types';

export interface PortfolioHistoricPerformanceResponse {
  data: PerformanceOverTime[];
  size?: number;
}
export type PortfolioHistoricPerformanceParam = string | undefined;
export const fetchPortfolioHistoricPerformance = (portfolioId: PortfolioHistoricPerformanceParam) =>
  httpGet<PortfolioHistoricPerformanceResponse>(
    `portal/PortfolioHistoricPerformances${portfolioId && `?portfolioId=${portfolioId}`}`,
  );
