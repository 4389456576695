import { DownloadIcon } from 'assets/icons';
import ExportCSV from 'components/ExportToCsv';
import { DateFilters, DateType } from 'components/Filters/dateFilter';
import Loading from 'components/Loading/loading';
import Table from 'components/Table';
import { AppContext } from 'context/ContextProvider';
import { onExportToXlsx } from 'helpers';
import useFetchPortalDocument from 'hooks/useFetchPortalDocument';
import moment from 'moment';
import { FC, useContext, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PortalDocumentTypeEnum, TransactionsType } from 'types/serviceTypes';
import { buildDisplayText, capitalizeFirstLetter, formatterGbp } from 'utils';
import { ExtendedDropDownType } from 'views/Accounts/types';
import { formatToObject } from 'views/shared/helpers';
import { InitialStateType, IsCheckedKeyEnum, MainReducerEnum, mainReducer } from './reducers/mainReducer';
import { SelectionTypeEnum, TRANSACTIONS_TABLE_REFS, TransactionsFormatedType } from './types';
import {
  InitialiseTransactionTable,
  SearchableDropdown,
  buildTableRow,
  filterDataSource,
  filterDataSourceByDate,
  generateDropDownItems,
} from './helpers';
import { dateDropDownItem, datePickerText } from '../CashflowDetails/helpers';

enum DisplayTextKeys {
  TITLE = 'title',
  TOTAL = 'total',
  START_BALANCE = 'startBalance',
  END_BALANCE = 'endBalance',
  NO_STATEMENTS = 'noStatements',
  BUY_TRANSACTIONS = 'buyTransactions',
  SELL_TRANSACTIONS = 'sellTransactions',
  NO_TRANSACTIONS = 'noTransactions',
  ALLPORTFOLIOS = 'allPortfolios',
  REGIONS = 'regions',
  VINTAGE = 'vintage',
  BUYORSELL = 'buyOrSell',
  DATE = 'date',
  ALL = 'all',
  WINE_NAME = 'wineName',
  PORTFOLIOS = 'portfolio',
  SELECTDATE = 'selectDate',
  TABLE_TITLE = 'tableTitle',
  CLEAR_FILTERS = 'clearFilters',
}

const Transactions: FC = () => {
  const {
    state: {
      settings: { accountInfo },
    },
  } = useContext(AppContext);

  const { t } = useTranslation();
  const initialState: InitialStateType = {
    isRegionCheck: [],
    isPortfolioCheck: [],
    isVintageCheck: [],
    isWineNameCheck: [],
    isBuyOrSellCheck: [],
  };

  // test accountHolderId 2662 1566
  const displayText = useMemo(
    () => buildDisplayText(Object.values(DisplayTextKeys), 'account:overviewReport.transactions', t),
    [t],
  );
  const { data, isLoading } = useFetchPortalDocument({
    docType: PortalDocumentTypeEnum.TRANSACTIONS,
  });

  const formatedTransactions = useMemo((): TransactionsFormatedType[] => {
    if (!data?.data) return [];

    const transactions = data.data?.[0] as TransactionsType;

    const formatedResult = formatToObject(
      transactions?.statements.main.columns || [],
      transactions?.statements.main.rows || [],
    ) as unknown as TransactionsFormatedType[];

    const result = formatedResult.map((x) => {
      const Actual_Amt = formatterGbp.format(Number(x.Actual_Amt || 0));
      const vintage = Number(x.vintage || 0).toFixed(0);
      if (Number(x.Actual_Amt) > 0) {
        return { ...x, buyOrSell: 'sell', Actual_Amt, vintage };
      }
      return { ...x, buyOrSell: 'buy', Actual_Amt, vintage };
    });

    // console.log(result);
    return result.sort((a, b) => moment(b.transaction_date).diff(moment(a.transaction_date)));
  }, [data]);

  const [state, dispatch] = useReducer(mainReducer, initialState);

  const [datePicker, setDatePicker] = useState<DateFilters>({
    [DateType.START]: null,
    [DateType.END]: null,
  });
  const [searchText, setSearchText] = useState<string>('');

  const { transactionColumns } = useMemo(() => InitialiseTransactionTable(t), [t]);

  const regionsItems = useMemo(
    () =>
      generateDropDownItems({
        dispatch,
        isCheckedKey: IsCheckedKeyEnum.isRegionCheck,
        listItems: formatedTransactions,
        listItemsKey: TRANSACTIONS_TABLE_REFS.REGION,
        checkedArray: state.isRegionCheck,
      }),
    [formatedTransactions, state.isRegionCheck],
  );

  const buyOrSellItems = useMemo(
    () =>
      generateDropDownItems({
        dispatch,
        isCheckedKey: IsCheckedKeyEnum.isBuyOrSellCheck,
        listItems: formatedTransactions,
        listItemsKey: TRANSACTIONS_TABLE_REFS.BUY_OR_SELL,
        checkedArray: state.isBuyOrSellCheck,
      }),
    [formatedTransactions, state.isBuyOrSellCheck],
  );

  const vintageItems = useMemo(
    () =>
      generateDropDownItems({
        dispatch,
        isCheckedKey: IsCheckedKeyEnum.isVintageCheck,
        listItems: formatedTransactions,
        listItemsKey: TRANSACTIONS_TABLE_REFS.VINTAGE,
        checkedArray: state.isVintageCheck,
      }),
    [formatedTransactions, state.isVintageCheck],
  );

  const wineNameItems = useMemo(
    () =>
      generateDropDownItems({
        dispatch,
        isCheckedKey: IsCheckedKeyEnum.isWineNameCheck,
        listItems: formatedTransactions,
        listItemsKey: TRANSACTIONS_TABLE_REFS.WINE_NAME,
        checkedArray: state.isWineNameCheck,
      }),
    [formatedTransactions, state.isWineNameCheck],
  );

  const portfolioItems = useMemo(
    () =>
      generateDropDownItems({
        dispatch,
        isCheckedKey: IsCheckedKeyEnum.isPortfolioCheck,
        listItems: formatedTransactions,
        listItemsKey: TRANSACTIONS_TABLE_REFS.CLIENT_NAME,
        checkedArray: state.isPortfolioCheck,
        selectionType: SelectionTypeEnum.SINGLE_SELECT,
        showCheckBox: false,
        isPortfolio: true,
      }),
    [formatedTransactions, state.isPortfolioCheck],
  );

  const rows = useMemo(() => {
    const source = filterDataSource(state, formatedTransactions);

    //  const cashItemsRowsUnfilteredByDate = [...source] as TransactionsFormatedType[];
    const transactionRows = (filterDataSourceByDate(datePicker, source) || []).map((row) => {
      return {
        ...buildTableRow({ ...row }, transactionColumns),
      };
    });

    return {
      transactionRows,
    };
  }, [formatedTransactions, transactionColumns, state, datePicker]);

  const dateDropDown = useMemo(() => {
    const moments = formatedTransactions.map((x) => moment(x.transaction_date));
    const maxMoment = new Date();
    const minMoment = moment.min(moments);
    return dateDropDownItem(datePicker, setDatePicker, maxMoment, minMoment.toDate());
  }, [datePicker, setDatePicker, formatedTransactions]);

  const showClearButton = () => {
    if (
      state.isRegionCheck.length ||
      state.isPortfolioCheck.length ||
      state.isVintageCheck.length ||
      state.isWineNameCheck.length ||
      state.isBuyOrSellCheck.length ||
      datePicker[DateType.START]
    )
      return true;
    return false;
  };

  const dropdowns = useMemo(() => {
    const dropdownLists: ExtendedDropDownType[] = [];
    if ((accountInfo?.portfolios?.length || 0) > 1) {
      dropdownLists.push({
        name: displayText[DisplayTextKeys.PORTFOLIOS],
        placeholder: displayText[DisplayTextKeys.ALLPORTFOLIOS],
        value: state.isPortfolioCheck.toString(),
        items: portfolioItems,
      });
    }

    dropdownLists.push(
      {
        name: displayText[DisplayTextKeys.REGIONS],
        placeholder: displayText[DisplayTextKeys.ALL],
        value: state.isRegionCheck.toString(),
        items: regionsItems,
      },
      {
        name: displayText[DisplayTextKeys.VINTAGE],
        placeholder: displayText[DisplayTextKeys.ALL],
        containerClassName: ' rounded w-[150px]',
        itemsContainerClassName: 'max-h-[530px] overflow-y-auto',
        className: ' text-14 text-black border    p-4 rounded justify-between items-center',
        value: state.isVintageCheck.toString(),
        items: vintageItems,
      },
      {
        isSearchable: true,
        name: displayText[DisplayTextKeys.WINE_NAME],
        placeholder: displayText[DisplayTextKeys.ALL],
        value: state.isWineNameCheck.toString(),
        items: wineNameItems,
        searchText,
        setSearchText,
        containerClassName: ' rounded  sm:w-[550px]',
        itemsContainerClassName: 'max-h-[230px] overflow-y-auto',
        className: ' text-14 text-black border  p-4 rounded justify-between items-center',
      },
      {
        name: displayText[DisplayTextKeys.BUYORSELL],
        placeholder: displayText[DisplayTextKeys.ALL],
        containerClassName: ' rounded w-[150px]',
        itemsContainerClassName: 'max-h-[530px] overflow-y-auto',
        className: ' text-14 text-black border    p-4 rounded justify-between items-center',
        value: capitalizeFirstLetter(state.isBuyOrSellCheck.toString()),
        items: buyOrSellItems,
      },
    );

    return dropdownLists;
  }, [
    displayText,
    state,
    regionsItems,
    portfolioItems,
    wineNameItems,
    vintageItems,
    accountInfo?.portfolios,
    searchText,
    setSearchText,
    buyOrSellItems,
  ]);

  return (
    <div className="py-3 overflow-hidden flex flex-col ">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {formatedTransactions?.length === 0 && (
            <div className="items-center flex flex-1 h-full px-4">
              <span>{displayText[DisplayTextKeys.NO_TRANSACTIONS]}</span>
            </div>
          )}
          {formatedTransactions?.length > 0 && (
            <>
              <div className="flex flex-col gap-5 ">
                <div className="flex flex-row gap-5 flex-wrap">
                  {dropdowns.map((x, index) => (
                    <SearchableDropdown config={x} key={index} />
                  ))}
                </div>

                <div className="flex gap-5 items-end flex-row flex-wrap">
                  <SearchableDropdown
                    config={{
                      name: displayText[DisplayTextKeys.DATE],
                      autoClose: false,
                      placeholder: displayText[DisplayTextKeys.SELECTDATE],
                      value: datePickerText(datePicker),
                      containerClassName: 'sm:w-[250px] rounded ',
                      itemsContainerClassName: 'max-h-[530px] overflow-y-auto',
                      className: ' text-14 text-black border p-4 rounded justify-between items-center',
                      itemsWrapperClassName: 'w-[330px]',
                      items: dateDropDown,
                      itemClassName: 'text-base flex pl-0 pr-0 pt-0 pb-0',
                    }}
                  />

                  {showClearButton() && (
                    <button
                      className="text-light-blue-800 h-[33.33px]"
                      onClick={() => {
                        dispatch({ type: MainReducerEnum.RESET_FILTERS });
                        setDatePicker({ end: null, start: null });
                      }}
                    >
                      {displayText[DisplayTextKeys.CLEAR_FILTERS]}
                    </button>
                  )}
                </div>
              </div>

              <div className=" mt-8  min-h-[500px] ">
                <div className="flex flex-row justify-between gap-2 items-center ">
                  <h2 className="text-[20px]">{displayText[DisplayTextKeys.TABLE_TITLE]}</h2>
                  <ExportCSV
                    csvData={formatedTransactions}
                    fileName={`${'Statement_of_Account'}}-${moment().format('YYYY-MM-DD HH:mm')}`}
                    onPrepareData={(xlData: TransactionsFormatedType[]) => onExportToXlsx(xlData, transactionColumns)}
                  >
                    <div
                      className={`pl-8 flex items-center ${
                        formatedTransactions?.length > 0
                          ? 'cursor-pointer opacity-100'
                          : 'pointer-events-none opacity-50'
                      }`}
                    >
                      <DownloadIcon />
                    </div>
                  </ExportCSV>
                </div>
                <Table columns={transactionColumns || []} rows={rows.transactionRows} isFadeOnScrollDisable={true} />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Transactions;
