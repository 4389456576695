import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabType } from 'components/Tabs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tabs } from '../../../components';
import { buildDisplayText } from '../../../utils';
import { AccountViewType, SlideOutPanelViews } from '../types';
import ManagementFees from './ManagementFees';
import CashflowDetails from './CashflowDetails';
import Transactions from './Transactions';

enum TabTypes {
  MGMT_FEES = 'mgmt_fees',
  CASH_FLOW = 'cash_flow',
  TRANSACTIONS = 'transactions',
}

enum DisplayTextKeys {
  MGMT_FEES_TAB_TITLE = 'managementFees.tab_title',
  CASH_FLOW_TAB_TITLE = 'cashFlow.tab_title',
  TRANSACTIONS_TAB_TITLE = 'transactions.tab_title',
}

interface AccountReportProps {
  openSlideout: (view: SlideOutPanelViews) => void;
  onClose: (nextView?: AccountViewType) => void;
}
const AccountReport: FC<AccountReportProps> = ({ openSlideout, onClose }) => {
  const { showCashStatements, portalTransactions: showPortalTransactions } = useFlags();

  const { t } = useTranslation();
  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'account:overviewReport', t), [t]);
  const [selectedTab, setSelectedTab] = useState<string>(TabTypes.CASH_FLOW);

  const tabs = useMemo(() => {
    let options = [
      {
        id: TabTypes.CASH_FLOW,
        value: TabTypes.CASH_FLOW,
        title: <span className="text-14">{displayText[DisplayTextKeys.CASH_FLOW_TAB_TITLE]}</span>,
        content: () => <CashflowDetails />,
      },

      {
        id: TabTypes.MGMT_FEES,
        value: TabTypes.MGMT_FEES,
        title: <span className="text-14">{displayText[DisplayTextKeys.MGMT_FEES_TAB_TITLE]}</span>,
        content: () => <ManagementFees openSlideout={openSlideout} onClose={onClose} />,
      },

      {
        id: TabTypes.TRANSACTIONS,
        value: TabTypes.TRANSACTIONS,
        title: <span className="text-14">{displayText[DisplayTextKeys.TRANSACTIONS_TAB_TITLE]}</span>,
        content: () => <Transactions />,
      },
    ];
    options = options.filter((x) => {
      if (
        (!showCashStatements && x.id === TabTypes.CASH_FLOW) ||
        (!showPortalTransactions && x.id === TabTypes.TRANSACTIONS)
      ) {
        return false;
      }
      return true;
    });

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayText, showCashStatements, showPortalTransactions]);

  useEffect(() => {
    if (showCashStatements) {
      setSelectedTab(TabTypes.CASH_FLOW);
    } else {
      setSelectedTab(TabTypes.MGMT_FEES);
    }
  }, [showCashStatements]);

  const onTabSelect = (item: TabType) => {
    setSelectedTab(item?.value);
  };

  return (
    <div>
      <Tabs className="pb-5" items={tabs} onItemSelect={onTabSelect} value={selectedTab} />
    </div>
  );
};

export default AccountReport;
