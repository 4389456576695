import { useTranslation } from 'react-i18next';
import underMaintenance from '../../assets/images/underMaintenance.png';
import MessageTemplate from '../ProductTemplates/components/MessageTemplate';

enum DisplayTextKeys {
  TITLE = 'title',
  SUBTITLE = 'subTitle',
  BUTTON_TEXT = 'buttonText',
}

const UnderMaintenance = () => {
  const { t } = useTranslation();
  const title = t('common:maintenance.title');
  const MaintenanceMessage = () => {
    return (
      <div className="w-full h-screen justify-center items-center flex flex-col">
        <div className="w-[439px] flex flex-col items-center ">
          {/* <div className="flex w-full  justify-center  mb-[20px] items-center">
          <SmallScreenLogoBlack />
        </div> */}
          <MessageTemplate
            displayTextKeys={DisplayTextKeys}
            translationKey="common:maintenance"
            imageSrc={underMaintenance}
            showButton={false}
          />
        </div>
      </div>
    );
  };

  return <MaintenanceMessage />;
};

export default UnderMaintenance;
