import React from 'react';
import { Dialog } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

export default function ThreeDSecureFailureModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { t } = useTranslation();
  return (
    <Dialog as="div" className="relative z-10" open={open} onClose={() => null}>
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
              {t('account:payments.addCard.3DSecureFailure')}
            </Dialog.Title>
            <button onClick={onClose} type="button" className="btn-accent mt-4">
              {t('common:generalSuccess.buttonText')}
            </button>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
