import { httpGet } from 'utils';

export interface UserProfileResponse {
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  dateOfBirth: string | null;
  cwLeadStatus: string | null;
  city: string | null;
  country: string | null;
  state: string | null;
  postCode: string | null;
  addressline1: string | null;
  addressline2: string | null;
  addressline3: string | null;
}

interface Params {
  email: string | undefined;
}

export const fetchProfile = (params: Params) =>
  httpGet<UserProfileResponse, Params>('third-party-integrations/hubspot', params);
