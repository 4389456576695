import { logError } from 'components/LogError';
import useApiMutation from 'hooks/useApiMutation';
import { confirmBankTransferApi } from '../services/confirmBankTransferApi';

export const useBankTransfer = () => {
  const { executor: bankTransferConfirm, loading, error } = useApiMutation(confirmBankTransferApi);
  if (error) {
    logError(JSON.stringify(error), {
      tags: { userFlow: 'pay_fee_preferences', api: 'bankTransferConfirm' },
    });
  }
  return { bankTransferConfirm, loading, error };
};
