import { gql } from '@apollo/client';

export const GET_CHECKOUT_DOT_COM_CARDS = gql`
  query PaymentGetCardDetails {
    paymentGetCardDetails {
      instrumentId
      last4
      clientName
      expiry
      scheme
      mitConsentedFlag
      defaultMitFlag
    }
  }
`;
