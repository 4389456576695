import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProtectedPageContext } from 'components/ProtectedPageContainer';
import { NavigationPath } from '../../types/DomainTypes';
import ProtectedPageLayout from '../shared/ProtectedPageLayout';
import AccSideMenu from './components/AccSideMenu';
import ContactUs from './components/ContactUs';
import HelpCenter from './components/HelpCenter';
import Overview from './components/Overview';
import Profile from './components/Profile';
import Settings from './components/Settings';
import { AccountViewType, SubjectOptionKeys } from './types';
import Payments from './components/Payments';

const Accounts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const title = t('common:accounts');
  const { state: locationParam } = useLocation();
  const [selectedView, setSelectedView] = useState(locationParam?.accuntVievType || AccountViewType.OVERVIEW);
  const { closeSlidout, backSlideout, openSlideout } = useProtectedPageContext();

  const onChangeRequest = (subject: SubjectOptionKeys) => {
    navigate(NavigationPath.ACCOUNTS, {
      state: { accountViewType: AccountViewType.CONTACT_US, subject },
    });
  };

  const handleClose = (nextView?: AccountViewType) => {
    if (nextView) setSelectedView(nextView as AccountViewType);
    closeSlidout();
  };

  useEffect(() => {
    if (locationParam && locationParam.accountViewType && locationParam.accountViewType !== selectedView) {
      setSelectedView(locationParam.accountViewType);
    }
    // selectedView in dependencies causes bug (Account subpages do not open)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationParam]);

  return (
    <ProtectedPageLayout
      view={selectedView === AccountViewType.CONTACT_US ? NavigationPath.CONTACT_US : NavigationPath.ACCOUNTS}
      title={title}
      onBack={backSlideout}
    >
      <div className="flex  w-full h-full">
        <AccSideMenu setSelectedView={setSelectedView} selectedView={selectedView} />
        <div className="flex  w-full h-full overflow-y-auto">
          {selectedView === AccountViewType.OVERVIEW && <Overview openSlideout={openSlideout} onClose={handleClose} />}
          {selectedView === AccountViewType.PROFILE && (
            <Profile onChangeRequest={() => onChangeRequest(SubjectOptionKeys.UPDATE_DETAILS)} />
          )}
          {selectedView === AccountViewType.SETTINGS && <Settings openSlideout={openSlideout} onClose={handleClose} />}
          {selectedView === AccountViewType.HELP_CENTER && (
            <HelpCenter onChangeRequest={() => onChangeRequest(SubjectOptionKeys.GENERAL)} />
          )}
          {selectedView === AccountViewType.CONTACT_US && <ContactUs />}
          {selectedView === AccountViewType.PAYMENTS && <Payments openSlideout={openSlideout} onClose={handleClose} />}
        </div>
      </div>
    </ProtectedPageLayout>
  );
};

export default Accounts;
