import React, { useContext } from 'react';
import { Dialog } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { Frames } from 'frames-react';
import { useCreateCheckoutDotComPayment } from 'views/Accounts/hooks/useCreateCheckoutDotComPayment';
import { cache } from 'graphql/cache';
import { logError } from 'components/LogError';
import { Country } from 'utils';
import { AppContext } from 'context/ContextProvider';

import { ObjectType } from 'types/commonTypes';

import AddCardModalForm from './Form';

interface AddCardModalProps {
  open: boolean;

  onClose: () => void;
}

export default function AddCardModal({ open, onClose }: AddCardModalProps) {
  const { t } = useTranslation();
  const [makeCardDefault, setMakeCardDefault] = React.useState(true);
  const [nameOnCard, setNameOnCard] = React.useState<string>('');
  const [postcode, setPostcode] = React.useState<string>('');
  const [country, setCountry] = React.useState<Country>('United Kingdom');
  const [view, setView] = React.useState<'form' | 'success'>('form');
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);
  const { createCardPayment, loading: addingCard } = useCreateCheckoutDotComPayment();
  const {
    state: {
      app: { addCardRef },
    },
  } = useContext(AppContext);

  async function handleAddCard() {
    setIsProcessing(true);
    try {
      const tokenEvent = await Frames.submitCard();
      const addCardRefState = (addCardRef as ObjectType)?.pid
        ? encodeURIComponent(btoa(JSON.stringify(addCardRef)))
        : undefined;

      const baseReturnUri = window.location.origin + window.location.pathname;
      const successUrlString = `${baseReturnUri}#success${addCardRefState ? `=${addCardRefState}` : ''}`.trim();
      const failureUrl = `${baseReturnUri}#failure${addCardRefState ? `=${addCardRefState}` : ''}`.trim();

      const { errors, data } = await createCardPayment({
        variables: {
          args: {
            amount: 0,
            clientName: nameOnCard,
            storeCardFlag: true,
            mitConsentedFlag: true, // if user consents to store card, then they are also opting into MIT (confirmed by HHW).
            defaultMitFlag: makeCardDefault,
            cardToken: tokenEvent.token,
            failureUrl,
            successUrl: successUrlString,
          },
        },
      });

      if (errors) {
        console.error(errors);
        setIsProcessing(false);
        setError(errors[0] as Error);
        logError(JSON.stringify(errors[0]), { tags: { userFlow: 'payment' } });
        return;
      }

      if (data?.createCardPayment.status === 'Declined') {
        console.error('Card declined');
        logError(new Error(`Card declined: ${data?.createCardPayment.errorMessage}`), {
          tags: { userFlow: 'payment' },
        });
        setIsProcessing(false);
        setError(new Error('Card declined'));
        return;
      }

      if (data?.createCardPayment.status === 'Pending') {
        const { redirectUrl } = data.createCardPayment;
        if (!redirectUrl) {
          const err = new Error('Redirect error is null');
          logError(err, { tags: { userFlow: 'payment' } });
          throw err;
        }

        window.location.href = redirectUrl;
        cache.evict({ fieldName: 'paymentGetCardDetails' });
        setIsProcessing(false);
      }
    } catch (e) {
      logError(e as Error, { tags: { userFlow: 'payment' } });
      console.error(e);
      setIsProcessing(false);
      setError(e as Error);
    }
  }

  return (
    <Dialog as="div" className="relative z-10" open={open} onClose={onClose}>
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          {view === 'form' && (
            <AddCardModalForm
              nameOnCard={nameOnCard}
              postcode={postcode}
              country={country}
              onCountryChanged={setCountry}
              onPostcodeChanged={setPostcode}
              onNameOnCardChanged={setNameOnCard}
              makeCardDefault={makeCardDefault}
              onMakeCardDefaultChanged={setMakeCardDefault}
              disableCTA={
                addingCard || nameOnCard.length < 5 || isProcessing || postcode.length < 4 || country.length === 0
              }
              onSaveCardButtonClicked={handleAddCard}
              error={error}
              onClose={onClose}
              isProcessing={isProcessing}
            />
          )}
          {view === 'success' && (
            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all h-56">
              <p>{t('common:generalSuccess')}</p>
            </Dialog.Panel>
          )}
        </div>
      </div>
    </Dialog>
  );
}
