import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles/index';

const Footer = () => {
  return (
    <View style={styles.footer} fixed>
      <Text style={styles.footerText}>| www.wineinvestment.com | Company Reg No. 201812015K |</Text>
    </View>
  );
};

export default Footer;
