import { httpGet } from 'utils';
import { TradeHistoryType } from '../types';

interface IFetchTransactionReportResponce {
  data: TradeHistoryType[];
  count?: number;
}

interface IFetchTransactionReportParams {
  transactionType: 'buy' | 'sell';
  pageSize?: number;
  page?: number;
}

export const fetchTransactionReport = ({
  page = 0,
  pageSize = 100,
  transactionType,
}: IFetchTransactionReportParams) => {
  const path = `portal/TransactionReport?transactionType=${transactionType}&page=${page}&pageSize=${pageSize}`;
  return httpGet<IFetchTransactionReportResponce>(path);
};
