import { HowToPayEnum } from 'views/shared/HowToPay/type';
import { RadioBtnOption } from 'views/shared/MgmtFeePolicyEnforcer/mgmtPaymentTypeSelector';
import { campaignsListType } from '../services/fetchCampaignOffers';

import { CampaignOffersQueryKeyEnum, CampaignPurchasesType, CampaignsConfigType, loaclStorageKeysEnum } from '../types';
// import errorImage1 from '../../../assets/images/no_results_image.png';

export const getCampaignConfig = (
  portalCampaignOffers: campaignsListType[],
  fetchAllAtOnce?: boolean,
): CampaignsConfigType => {
  let config: CampaignsConfigType = {
    initalFetch: {
      queryKey: null,
      enabled: false,
      campaigns: [],
    },
    additionalFetch: {
      queryKey: null,
      enabled: false,
      campaigns: [],
    },
    allAtOnceFetch: {
      queryKey: null,
      enabled: false,
      campaigns: [],
    },
    campaignsIsEnabled: false,
  };

  if (fetchAllAtOnce)
    return {
      ...config,
      allAtOnceFetch: {
        ...config.allAtOnceFetch,
        enabled: true,
        campaigns: portalCampaignOffers,
        queryKey: CampaignOffersQueryKeyEnum.fetchAllAtOnceCampaignOffers,
      },
      campaignsIsEnabled: true,
    };

  if (portalCampaignOffers?.length === 1)
    config = {
      ...config,
      initalFetch: {
        ...config.initalFetch,
        enabled: true,
        campaigns: portalCampaignOffers,
        queryKey: CampaignOffersQueryKeyEnum.fetchInitalCampaignOffers,
      },
      campaignsIsEnabled: true,
    };
  if (portalCampaignOffers?.length && portalCampaignOffers?.length > 1)
    config = {
      ...config,
      initalFetch: {
        ...config.initalFetch,
        enabled: true,
        campaigns: portalCampaignOffers.slice(0, 1),
        queryKey: CampaignOffersQueryKeyEnum.fetchInitalCampaignOffers,
      },
      additionalFetch: {
        ...config.additionalFetch,
        enabled: true,
        campaigns: portalCampaignOffers.slice(1),
        queryKey: CampaignOffersQueryKeyEnum.fetchAdditionalCampaignOffers,
      },
    };

  return config;
};

export const storeCampaignPurchases = (data: CampaignPurchasesType) => {
  const campaignPurchase: CampaignPurchasesType = {
    campaignId: data.campaignId,
    lwin18: data.lwin18,
    quantity: data.quantity,
    id: data.id,
  };

  const prevPurchases = localStorage.getItem(loaclStorageKeysEnum.campaignPurchases);
  if (prevPurchases) {
    const arrayOfPurchases = JSON.parse(prevPurchases);
    arrayOfPurchases.push(campaignPurchase);
    localStorage.setItem(loaclStorageKeysEnum.campaignPurchases, JSON.stringify(arrayOfPurchases));
    return;
  }

  localStorage.setItem(loaclStorageKeysEnum.campaignPurchases, JSON.stringify([campaignPurchase]));
};

export const isAllowedCampaignPurchase = (campaignPurchase: CampaignPurchasesType) => {
  const allowedQty =
    campaignPurchase.numberOfUnitsAllowed && campaignPurchase.numberOfUnitsAllowed > 0
      ? campaignPurchase.numberOfUnitsAllowed
      : campaignPurchase.quantity || 0;

  // how many units i have already purchased
  const prevPurchases = localStorage.getItem(loaclStorageKeysEnum.campaignPurchases);
  if (!prevPurchases) return { qty: allowedQty, isAllowed: true };
  const arrayOfPurchases = JSON.parse(prevPurchases) as CampaignPurchasesType[];
  const similarItems = arrayOfPurchases
    .filter((a) => a.campaignId === campaignPurchase.campaignId)
    ?.filter((b) => b.lwin18 === campaignPurchase.lwin18)
    ?.filter((b) => b.id === campaignPurchase.id);

  if (!similarItems.length) return { qty: allowedQty, isAllowed: true };

  const allowedQtyAdjuqsted = allowedQty - similarItems.reduce((total, value) => total + value.quantity, 0);
  return { qty: allowedQtyAdjuqsted <= 0 ? 0 : allowedQtyAdjuqsted, isAllowed: !(allowedQtyAdjuqsted <= 0) };
};

export const getPaymentOptions = (hasCard: boolean): RadioBtnOption[] => {
  const baseOptions: RadioBtnOption[] = [
    {
      id: HowToPayEnum.CARD_ON_FILE,
      label: 'Card on file',
      value: HowToPayEnum.CARD_ON_FILE,
      description: (
        <span className=" text-xs flex space-x-2">
          <span className="">Select from an existing card saved on your account.</span>
        </span>
      ),
      data: '',
    },
    {
      id: HowToPayEnum.NEW_CARD,
      label: 'New Card',
      value: HowToPayEnum.NEW_CARD,
      description: (
        <span className="text-xs flex space-x-2">
          <span className="">Pay with a new card.</span>
        </span>
      ),
      data: '',
    },
  ];

  return hasCard ? baseOptions : [baseOptions[1]];
};

export const mockCampaignOffers: campaignsListType[] = [
  {
    name: 'Vins_RT',
    id: '65',
    numberOfUnitsAllowed: 2,
  },
  {
    name: 'Vins-mul',
    id: '59',
  },
  {
    name: 'Test Campaign',
    id: '64',
  },
];
