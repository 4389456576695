import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import BankTransferTemplate from 'views/Accounts/components/slideoutTemplates/BankTransferTemplate';
import { Button, Dropdown } from 'components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DropdownItem } from 'components/Dropdown';
import { currencyFormatter, sortItems } from 'utils';
import { useExecuteQuery } from 'views/hooks/useExecuteQuery';
import { GET_BANK_TRANSFER_REF } from 'views/Accounts/graphql/getBankTransferRef';
import { bankTranferDetails } from './helpers';
import { useBankTransfer } from '../MgmtFeePolicyEnforcer/hooks/useBankTransfer';
import { BankTransferConfirmRequest } from '../MgmtFeePolicyEnforcer/services/confirmBankTransferApi';

const defaultCurrency = 'GBP';
interface MgmtBankTransferInfoProps {
  show: boolean;
  amount?: number;
  onSubmit?: () => void;
  onBack?: () => void;
  sendConfirmEmail?: boolean;
}

const BankTransferInfo: FC<MgmtBankTransferInfoProps> = ({
  amount,
  show = false,
  onBack,
  onSubmit,
  sendConfirmEmail = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { feesCurrencyFxRate } = useFlags();
  const [currency, setCurrency] = useState<DropdownItem>();
  function onNavigateBack() {
    onBack?.();
    setIsOpen(false);
  }
  const formatter = useMemo(() => currencyFormatter('en-GB', currency?.id), [currency]);
  const { results: paymentRef } = useExecuteQuery('paymentReferenceNumber', GET_BANK_TRANSFER_REF);
  const { bankTransferConfirm } = useBankTransfer();

  const postBankInfoToServer = () => {
    const request = {
      currency: currency?.id,
      fxRate: currency?.value,
      gbpAmount: amount,
      amount: Number(amount || 0) * Number(currency?.value || 0),
      ...bankTransferInfo.reduce((acc, curr) => {
        return { ...acc, [curr.type]: curr.value };
      }, {}),
      reference: paymentRef,
    };
    bankTransferConfirm({ ...request } as unknown as BankTransferConfirmRequest);
  };

  const onConfirm = () => {
    if (sendConfirmEmail) {
      postBankInfoToServer();
    }

    onSubmit?.();
    setIsOpen(false);
  };

  useEffect(() => {
    if (show !== isOpen) setIsOpen(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const fxRates: DropdownItem[] = useMemo(() => {
    if (!feesCurrencyFxRate) return [];
    const ddlFxRates = Object.keys(feesCurrencyFxRate).map((x) => ({
      id: x,
      value: feesCurrencyFxRate[x],
      content: <div>{x}</div>,
      text: x,
    }));

    const rates = sortItems(ddlFxRates, true, 'id');
    setCurrency(rates.find((x) => x.id === defaultCurrency));
    return rates;
  }, [feesCurrencyFxRate]);

  const bankTransferInfo = useMemo(() => {
    return bankTranferDetails[`${currency?.id ?? defaultCurrency}_BankTransferInfo`];
  }, [currency]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900" />
                <BankTransferTemplate
                  bankTransferDetails={bankTransferInfo}
                  onBack={onNavigateBack}
                  disclaimerNode={
                    <p className="text-sm w-full">
                      Please ensure you always use the reference code when making bank transfers.
                    </p>
                  }
                  subTitleNode={
                    <div className="flex flex-col">
                      <p className="text-sm my-3">
                        Please find details for our GBP bank account below, if you wish to pay in another currency,
                        please use the dropdown to select your preferred currency.
                      </p>

                      <Dropdown
                        placeholder="Please select"
                        value={`${currency}`}
                        containerClassName="w-full border-b "
                        itemsContainerClassName="h-[250px] overflow-y-auto"
                        valueTemplate={
                          <div className="flex-1 text-start ">
                            {(fxRates.find((x) => x.id === currency?.id) || { text: '' }).text}
                          </div>
                        }
                        onItemSelect={(item) => setCurrency(item)}
                        items={fxRates}
                        className="flex-1 text-14 text-black "
                        itemsWrapperClassName="w-full"
                      />

                      {amount && (
                        <span className="text-base flex  items-center mt-3">
                          Amount payable:
                          <span className="text-base ml-3">
                            {formatter.format(Number(amount * Number(currency?.value ?? 1)))}
                          </span>
                        </span>
                      )}
                    </div>
                  }
                />
                <div className="w-full flex justify-center">
                  <Button className="btn-primary" onClick={onConfirm}>
                    Confirm
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default BankTransferInfo;
