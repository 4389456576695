import { GraphQLError } from 'graphql';
import { TFunction } from 'react-i18next';
import * as yup from 'yup';
import { IssueMfaChallengeMutation } from '__generated__/graphql';
import { logError } from '../../../components/LogError';
import { useAuth } from '../hooks/useAuthentication';
import useMfaChallengeMutation from '../hooks/useMfaChallengeMutation';
import usePasswordMutation from '../hooks/usePasswordMutation';
import { LoginModelProp, ModelValidationSchemaType } from '../types';

export const getLoginValidationSchema = (t: TFunction<'translation', undefined>): ModelValidationSchemaType => ({
  [LoginModelProp.PASSWORD]: yup
    .string()
    .test(LoginModelProp.PASSWORD, t('auth:password.invalid'), (password) => (password || '').length > 7)
    .required(t('auth:password.required')),
  [LoginModelProp.EMAIL]: yup.string().email(t('auth:emailAddress.required')).required(t('auth:emailAddress.required')),
});

function selectErrorMessage(errors: readonly GraphQLError[] | Error): string {
  return errors instanceof Error ? errors.message : errors[0].message;
}

export const executeLoginRequest = async (config: {
  model: { email: string; password: string };
  appIsWorkingVar: (arg: boolean) => boolean;
  login: ReturnType<typeof useAuth>['login'];
  errorHandler: (message: string) => void;
  t: TFunction<'translation', undefined>;
  getMFAAuthenticators: ReturnType<typeof useAuth>['getMFAAuthenticators'];
  issueMFAChallenge: ReturnType<typeof useAuth>['issueMFAChallenge'];
  onError: (e: Error) => void;
  onMFARequested?: (args: { mfaToken: string; mfaOOBCode: string; emailAddress: string; password: string }) => void;
}) => {
  const { email: emailAddress, password } = config.model;
  const { appIsWorkingVar, login, errorHandler, t, getMFAAuthenticators, issueMFAChallenge, onError, onMFARequested } =
    config;
  appIsWorkingVar(true);

  try {
    const loginResult = (await login({
      variables: {
        loginRequestInput: { emailAddress, password, clientId: process.env.REACT_APP_AUTH0_CLIENT_ID! },
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    })) as any;
    const { errors: loginErrors } = loginResult;
    // 403 is an error we tolerate, it means we need to getMFAAuthenticators with mfaToken from the response
    if (
      loginErrors &&
      loginErrors.length > 0 &&
      loginErrors[0].extensions &&
      loginErrors[0].extensions.response?.status !== 403
    ) {
      errorHandler(selectErrorMessage(loginErrors));
    }

    // Network error - different typing (an object instead of array)
    if (loginErrors !== undefined && !loginErrors.length) {
      errorHandler(loginErrors.toString());
    }

    const mfaToken = loginErrors && loginErrors[0]?.extensions?.response.body.mfaToken;
    if (!mfaToken) {
      errorHandler(t('auth:loginForm.failedToLogin'));
    }
    const { data: mfaAuthenticatorsResponse, errors: mfaAuthenticatorsErrors } = await getMFAAuthenticators({
      variables: {
        getMfaAuthenticatorsRequestInput: {
          mfaToken,
        },
      },
    });
    if (mfaAuthenticatorsErrors) {
      errorHandler(selectErrorMessage(mfaAuthenticatorsErrors));
    }

    // only email is supported at time of writing, so
    // request the code be sent to the registered email address.
    const email = mfaAuthenticatorsResponse!.portalAuthMfaAuthenticators.mfaAuthenticators.find(
      (authenticator: { oobChannel: string }) => authenticator.oobChannel === 'email',
    );
    if (!email) {
      errorHandler(t('auth:loginForm.failedToLogin'));
      return;
    }

    const { data: mfaData, errors: mfaErrors } = await issueMFAChallenge({
      variables: {
        mfaChallengeRequestInput: {
          clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
          authenticatorId: email.id,
          mfaToken,
        },
      },
    });
    if (mfaErrors) {
      errorHandler(selectErrorMessage(mfaErrors));
    }

    if (!mfaData) {
      errorHandler(t('auth:loginForm.failedToLogin'));
    }

    appIsWorkingVar(false);
    if (onMFARequested)
      onMFARequested({
        mfaOOBCode: (mfaData as IssueMfaChallengeMutation).portalAuthMfaChallenge.oobCode,
        mfaToken,
        emailAddress,
        password,
      });
  } catch (e) {
    appIsWorkingVar(false);
    onError(e as Error);
  }
};

export const executePasswordResetRequest = async (
  email: string,
  config: {
    appIsWorkingVar: (arg: boolean) => boolean;
    resetPassword: ReturnType<typeof usePasswordMutation>['resetPassword'];
    onError: (message: string) => void;
    onEmailSent: (sent: boolean) => void;
  },
) => {
  const { appIsWorkingVar, resetPassword, onError, onEmailSent } = config;
  appIsWorkingVar(true);

  const { errors } = await resetPassword({
    variables: { resetPasswordInput: { clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!, emailAddress: email } },
  });
  if (errors) {
    appIsWorkingVar(false);
    onError(selectErrorMessage(errors));
  } else {
    appIsWorkingVar(false);
    onEmailSent(true);
  }
};

export const submitMfaChallenge = async (
  arg: {
    mfaOOBCode: string;
    oneTimePasscode: string;
    mfaToken: string;
  },
  config: {
    appIsWorkingVar: (arg: boolean) => boolean;
    submitOTP: ReturnType<typeof useMfaChallengeMutation>['submitOTP'];
    t: TFunction<'translation', undefined>;
    onError: (message: string) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (data: any) => void;
    isLoggedInVar: (arg: boolean) => void;
  },
) => {
  const { mfaOOBCode, oneTimePasscode, mfaToken } = arg;
  const { appIsWorkingVar, submitOTP, onError, t, onSuccess, isLoggedInVar } = config;
  appIsWorkingVar(true);

  try {
    const { data, errors } = await submitOTP({
      variables: {
        args: {
          clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
          mfaOobCode: mfaOOBCode,
          mfaOtpCode: oneTimePasscode,
          mfaToken,
        },
      },
    });

    if (errors) {
      throw new Error(t('auth:loginForm.failedToLogin'));
    }

    // For some reason there is an error property returned in the main body of `data`.
    // Let's cover ourselves and ensure that any error info which might be stored in there
    // is captured and reported accordingly.
    if (data?.portalAuthMfaVerify.error) {
      throw new Error(data.portalAuthMfaVerify.error);
    }

    if (!data) {
      throw new Error(t('auth:loginForm.failedToLogin'));
    }

    onSuccess(data);
    isLoggedInVar(true);
    appIsWorkingVar(false);
  } catch (err: any) {
    appIsWorkingVar(false);
    logError(err);
    onError((err as Error).message);
  }
};
