import React from 'react';
import { useTranslation } from 'react-i18next';
import { FeedbackTemplate } from 'views/shared/FeedbackTemplate';
import { ViewStateType } from 'components/ProductTemplates/types';
import { useDeleteCheckoutDotComPaymentCard } from 'views/Accounts/hooks/useDeleteCheckoutDotComPaymentCard';
import { CardDetailResponse } from '__generated__/graphql';
import { useUpdateDefaultCheckoutDotComCard } from 'views/Accounts/hooks/useUpdateDefaultCheckoutDotComCard';
import { logError } from 'components/LogError';
import { cache } from 'graphql/cache';

interface EditCheckoutDotComCardProps {
  card: CardDetailResponse;
  onClose: () => void;
}

export default function EditCheckoutDotComCard({ card, onClose }: EditCheckoutDotComCardProps) {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(Boolean(card.defaultMitFlag));
  const [viewState, setViewState] = React.useState<ViewStateType>(ViewStateType.DEFAULT);
  const { updateDefaultCard } = useUpdateDefaultCheckoutDotComCard();
  const { deleteCard } = useDeleteCheckoutDotComPaymentCard();

  async function attemptToUpdateDefaultCard() {
    if (!card.instrumentId) {
      return;
    }
    try {
      const { data: updateDefaultCardResponse, errors: updateDefaultCardError } = await updateDefaultCard({
        variables: {
          defaultMitCard: {
            instrumentId: card.instrumentId,
          },
        },
      });
      if (updateDefaultCardResponse?.updateDefaultMitCard.errorMessage) {
        throw new Error(updateDefaultCardResponse?.updateDefaultMitCard.errorMessage);
      }
      if (updateDefaultCardError) {
        throw updateDefaultCardError[0];
      }

      setViewState(ViewStateType.SUCCESS);
      cache.evict({ fieldName: 'paymentGetCardDetails' });
    } catch (e) {
      logError(e as Error);
      setViewState(ViewStateType.ERROR_RESULT);
    }
  }

  async function handleConfirmDeleteCardClicked() {
    if (!card.instrumentId) {
      return;
    }
    setViewState(ViewStateType.LOADING);

    try {
      const { success } = await deleteCard(card.instrumentId);
      if (success) {
        setViewState(ViewStateType.SUCCESS);
        cache.evict({ fieldName: 'paymentGetCardDetails' });
      } else {
        setViewState(ViewStateType.ERROR_RESULT);
      }
    } catch (e) {
      setViewState(ViewStateType.ERROR_RESULT);
    }
  }

  return (
    <div className="flex flex-col flex-1 bg-gradient-to-b  from-gray-100 to-gray-500  pb-5 px-3 w-screen relative overflow-x-hidden sm:w-[300px]">
      <div className="w-full h-full rounded-md  overflow-y-auto flex flex-col">
        <FeedbackTemplate onClose={onClose} onCTA={() => setViewState(ViewStateType.DEFAULT)} viewState={viewState}>
          <div className="flex flex-col bg-white  divide-y divide-gray-200  h-full w-full p-4">
            <label htmlFor="makedefault" className="flex gap-2">
              <input
                id="makedefault"
                type="checkbox"
                checked={checked}
                color="primary"
                onChange={() => setChecked(!checked)}
                disabled={Boolean(card.defaultMitFlag)}
              />
              {t('common:default')}
            </label>

            <button
              type="button"
              className="btn-accent mt-auto"
              onClick={attemptToUpdateDefaultCard}
              disabled={Boolean(card.defaultMitFlag) || !checked}
            >
              {t('account:payments.addCard.actions.save')}
            </button>
            <span onClick={handleConfirmDeleteCardClicked} className="!border-0 mt-2 text-center text-sm text-vine">
              {t('account:payments.deleteCard')}
            </span>
          </div>
        </FeedbackTemplate>
      </div>
    </div>
  );
}
