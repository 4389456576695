import { Product } from 'types/productType';
import { httpGet } from 'utils';

interface Params {
  offset?: number;
  limit?: number;
}

interface SoldHoldingVT2Response {
  data: Product[];
  size?: number;
}

export const fetchSoldHoldings = (config?: Params) =>
  httpGet<SoldHoldingVT2Response, Params>('portal/SoldHoldings', config);
