import { httpGet } from '../utils';

export const fetchEventDocuments = () =>
  httpGet<Result, Params>('third-party-integrations/PrismicEventDocuments', {
    sortField: 'eventDate',
    sortDirection: 'desc',
    page: 1,
    pageSize: 1000,
  });

interface Params {
  sortField: 'eventDate';
  sortDirection: 'asc' | 'desc';
  page: number;
  pageSize: number;
}

interface Result {
  page: number;
  resultPerPage: number;
  totalPages: number;
  totalResultsSize: number;
  resultSize: number;
  results: EventDocument[];
}
interface EventDocument {
  id: string;
  type: string;
  title: string;
  price: number;
  country: string;
  priceCurrency: string;
  dateTime: string;
  locationShort: string;
  mainImage: string;
}
