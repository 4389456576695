import axios from 'axios';
import { getAccessToken, getUserToken } from 'services/auth';

const baseHeaders = {
  'Content-type': 'application/json',
  'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_KEY,
};

export const httpPatch = async <TResponse = unknown, TParameters extends {} = never>(
  apiSubPath: string,
  data?: TParameters,
  isSecured = true,
  extraHeaders?: Record<string, unknown>,
) => {
  const url = `${process.env.REACT_APP_APIM_URL}/${apiSubPath}`;
  return axios.patch<TResponse>(url, data, {
    headers: {
      ...extraHeaders,
      ...baseHeaders,
      ...(isSecured ? { authorization: `Bearer ${getAccessToken()}`, CdsUserJwt: getUserToken() } : {}),
    },
  });
};
