import { Product } from 'types/productType';
import { httpGet } from 'utils';

interface Params {
  offset?: number;
  limit?: number;
}

interface CurrentHoldingVT2Response {
  data: Product[];
  size?: number;
}

interface GetDetailsParams {
  lwin18: string;
}

export const fetchCurrentHoldingVT2 = (config?: Params) =>
  httpGet<CurrentHoldingVT2Response, Params>('portal/CurrentHoldings', config);
export const fetchCurrentHoldingDetailsVT2 = (params: GetDetailsParams) =>
  httpGet<Product, GetDetailsParams>('portal/CurrentHoldingsDetail', params);
