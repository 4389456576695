/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { DELETE_CHECKOUT_DOT_COM_PAYMENT_CARD } from '../graphql/deleteCheckoutDotComPaymentCard';
import { cache } from 'graphql/cache';
import { logError } from 'components/LogError';

function selectErrorMessage(errors: readonly GraphQLError[] | Error): string {
  return errors instanceof Error ? errors.message : errors[0].message;
}

export function useDeleteCheckoutDotComPaymentCard() {
  const [deletePaymentCard, { loading }] = useMutation(DELETE_CHECKOUT_DOT_COM_PAYMENT_CARD, { onError: () => null });

  async function deleteCard(instrumentId: string): Promise<{ success: boolean; error: Error | null }> {
    try {
      const response = await deletePaymentCard({ variables: { instrumentId } });
      if (response.data?.deleteCardDetail.isSuccess) {
        cache.evict({ fieldName: 'paymentGetCardDetails' });
        return { success: true, error: null };
      }

      if (response.errors) {
        throw new Error(selectErrorMessage(response.errors));
      }

      if (response.data?.deleteCardDetail.errorMessage) {
        throw new Error(response.data.deleteCardDetail.errorMessage);
      }
    } catch (e) {
      logError(new Error(`Failed to delete card instrument: ${instrumentId}`));
      return { success: false, error: e as Error };
    }

    return { success: false, error: null };
  }

  return { deleteCard, loading };
}
