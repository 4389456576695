import { useQuery } from '@tanstack/react-query';
import { validateEmailApi } from '../services/validateEmailApi';

export const useValidateEmailQuery = (emailAddress: string) => {
  const { data, isFetching, error, refetch } = useQuery({
    queryFn: () => validateEmailApi({ emailAddress }),
    queryKey: ['validateEmailApi', { emailAddress }],
    enabled: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  return {
    data,
    isFetching,
    error,
    refetch,
  };
};
