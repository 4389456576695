import { httpGet } from '../utils';

const fetchDocumentById = <TResult>(id: string) => httpGet<TResult>(`third-party-integrations/PrismicDocuments/${id}`);

export const fetchEventDocById = fetchDocumentById<EventAndExperienceDocument>;
export const fetchLearningDocById = fetchDocumentById<LearningHubDocument>;
export const fetchOfferDocById = fetchDocumentById<OfferDocument>;

interface EventAndExperienceDocument {
  id: string;
  content: string;
  country: string;
  dateTime: string;
  eventbriteId?: string;
  eventbriteShow: boolean;
  locationFullAddress: string;
  locationShort: string;
  mainImage: string;
  price: number;
  priceCurrency: string;
  title: string;
  type: string;
}

interface LearningHubDocument {
  id: string;
  contentLong: string;
  contentShort: string;
  contentType: 'Article' | 'Video';
  mainImage: string;
  publishDate: string;
  title: string;
  videoUrl?: string;
}

interface OfferDocument {
  id: string;
  type: string;
  name: string;
  subtitle: string;
  priceGbp: number;
  unitSize: string;
  expiryDate: string;
  region: string;
  mainImage: string;
  sections?: {
    title: string;
    content: string;
  }[];
  disclaimer?: string;
}
