import { useMutation } from '@tanstack/react-query';
import { authRegisterApi } from '../services/authRegisterApi';

const useRegistrationMutation = () => {
  const { data, mutateAsync, error, isLoading } = useMutation({
    mutationFn: authRegisterApi,
  });
  return { data, mutateAsync, error, isLoading };
};

export default useRegistrationMutation;
