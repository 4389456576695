import { gql } from '__generated__';

export const GET_NOTIFICATIONS = gql(`
  query PortalNotifications($from: Int!, $pageSize: Int!) {
    portalNotifications(from: $from, pageSize: $pageSize) {
      total
      pageSize
      from
      totalPages
      unreadCount
      results {
        id
        category
        type
        summary
        description
        isRead
        createdDateTime
        updatedDateTime
      }
    }
  }
`);
