/** @jsxImportSource @emotion/react */
import React from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import Routing from '../Routing';
import { AppProvider } from '../context/ContextProvider';

const App = () => {
  return (
    <AppProvider>
      <div className="w-screen h-screen" data-testid="app-container">
        <Routing />
      </div>
    </AppProvider>
  );
};

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID || '',
  options: {
    bootstrap: 'localStorage',
  },
})(App);
