import { FC, ReactNode, useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { ObjectType } from 'types/commonTypes';

export interface RadioBtnOption {
  id: string;
  value: string;
  label: string;
  description?: ReactNode;
  data?: unknown;
}

interface MgmtPaymentTypeSelectorProps {
  onSelected?: (selected: RadioBtnOption) => void;
  options?: RadioBtnOption[];
  selectedId?: string;
}

const MgmtPaymentTypeSelector: FC<MgmtPaymentTypeSelectorProps> = ({ onSelected, options, selectedId }) => {
  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    const selectedOption = options?.find((opt) => opt.value === selected);
    onSelected?.(selectedOption as RadioBtnOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, options]);

  useEffect(() => {
    if (selectedId) {
      const selectedOption = options?.find((opt) => opt.id === selectedId);
      if (selectedOption) setSelected(selectedOption.value);
    } else if (options?.length) setSelected(options[0].value);
  }, [selectedId, options]);

  return (
    <div className="w-full px-4 py-8">
      <div className="mx-auto w-full max-w-md">
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className="sr-only">Option selections</RadioGroup.Label>
          <div className="space-y-2">
            {options?.map((opt) => (
              <RadioGroup.Option
                key={opt.id}
                value={opt.value}
                className={({ active, checked }) =>
                  `${active ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300' : ''}
                  ${checked ? 'bg-vine bg-opacity-75 text-white' : 'bg-white'}
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className={`font-medium pb-2  ${checked ? 'text-white' : 'text-gray-900'}`}
                        >
                          {opt.label}
                        </RadioGroup.Label>

                        {opt.description && (
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${checked ? 'text-white' : 'text-gray-500'}`}
                          >
                            {opt.description}
                          </RadioGroup.Description>
                        )}
                      </div>
                    </div>
                    {checked && (
                      <div className="shrink-0 text-white ml-3">
                        <CheckIcon className="h-6 w-6" />
                      </div>
                    )}
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};

function CheckIcon(props: ObjectType) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path d="M7 13l3 3 7-7" stroke="#fff" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default MgmtPaymentTypeSelector;
