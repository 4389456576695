import { SortDirection } from '../../types/DomainTypes';

export enum ViewKeys {
  SUMMARY = 'summary',
  DETIALS = 'details',
}

export interface PerformanceOverTime {
  date: string;
  currentHoldings: number;
  netContributions: number;
}

export interface CurrentAllocation {
  regionName: string;
  currentAllocation: number;
  tacticalAllocation: number;
  StrategicAllocation: number;
}

export interface RegionPerformance {
  regionName: string;
  currentHoldings: number;
  totalPurchasePrice: number;
  profitAndLoss: number;
  profitAndLossPct: number;
}

export interface SortByOption {
  id: string;
  value: string;
  text: string;
  content: JSX.Element;
}

export interface SortByType {
  id: string;
  text: string;
  direction: SortDirection;
}

export interface StockTotal {
  title: string;
  value: number;
  color?: string;
  text: string;
  additionalText?: string;
}
