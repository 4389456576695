import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useExecuteMutation } from 'views/hooks/useExecuteMutation';
import { AppContext } from 'context/ContextProvider';
import { AppEventTypes, SettingsAction, SettingsEventTypes } from 'types/AppType';
import { UPDATE_USER_PREFERENCES } from 'views/Accounts/graphql/updateUserPreferences';
import { capitalizeFirstLetter } from 'utils';
import { ObjectType } from 'types/commonTypes';
import { logError } from 'components/LogError';
import BankTransferInfo from './BankTransferInfo';
import { HowToPayEnum } from './type';
import HowToPay from '.';
import { RadioBtnOption } from '../MgmtFeePolicyEnforcer/mgmtPaymentTypeSelector';
import { MgmtFeeTypeEnum, MgmtViewsEnum } from '../types';

enum ViewTypeEnum {
  DEFAULT = 'default',
  BANK_TRANSFER = 'bankTransfer',
}
interface HowToPayModalProps {
  isOpen: boolean;
  propKey: string;
  dbKey: string;
  amount?: number;
  forceAction?: boolean;
  eventType: SettingsEventTypes;
  onSubmit?: (value: HowToPayEnum, data?: ObjectType) => void;
  onClose?: () => void;
  options?: RadioBtnOption[];
}

const HowToPayModal: FC<HowToPayModalProps> = ({
  isOpen,
  propKey,
  dbKey = '',
  eventType,
  onSubmit,
  onClose,
  options,
  forceAction = false,
}) => {
  const { executor: updatePreferenceMutation, error: updatePreferenceError } =
    useExecuteMutation(UPDATE_USER_PREFERENCES);
  const { t } = useTranslation();
  const [viewState, setViewState] = useState<{
    forceAction: boolean;
    show: boolean;
    viewType: ViewTypeEnum;
  }>({
    forceAction: false,
    show: false,
    viewType: ViewTypeEnum.DEFAULT,
  });

  const [radioBtnOption, setSelectRadionBtnOption] = useState<RadioBtnOption>();
  const [paymentType, setPaymentType] = useState<HowToPayEnum>();
  const [selectedAmount, setSelectedAmount] = useState<number>();
  const {
    state: {
      app: { hasCard, addCardRef },
      settings: { email },
    },
    dispatch,
  } = useContext(AppContext);
  const config = useMemo(() => {
    if (eventType === SettingsEventTypes.UPDATE_MGMT_FEE_SETTINGS)
      return {
        title: t('account:mgmtFeePreference.howToPay.title'),
        subTitle: t('account:mgmtFeePreference.howToPay.subTitle'),
      };

    if (eventType === SettingsEventTypes.PAYMENT_REMINDER)
      return {
        title: t('account:paymentReminder.howToPay.title'),
        subTitle: t('account:paymentReminder.howToPay.subTitle'),
      };

    if (eventType === SettingsEventTypes.UPDATE_LEGACY_FEE_SETTINGS) {
      return {
        title: t('account:mgmtFeePreference.howToPay.title'),
        subTitle: t('account:mgmtFeePreference.howToPay.subTitle'),
      };
    }

    return {
      title: '',
      subTitle: '',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventType]);

  const submitHowToPay = (data?: HowToPayEnum, arg?: ObjectType) => {
    dispatch({
      type: eventType,
      payload: {
        [propKey]: data === HowToPayEnum.CHARGE_AND_SAVE_CARD ? HowToPayEnum.CARD : data,
      },
    } as SettingsAction);
    onSubmit?.(data ?? (paymentType as HowToPayEnum), arg);
  };

  const onMgmtFeeHowToPaySubmit = (data: unknown) => {
    if (viewState.show) setViewState({ ...viewState, show: false });
    const selectedOption = data as RadioBtnOption;
    const howToPay = selectedOption.value as HowToPayEnum;
    const cardRefObj = addCardRef as ObjectType;
    let mAddCardRef = {};
    setSelectRadionBtnOption(selectedOption);
    setPaymentType(howToPay);
    setSelectedAmount(selectedOption.data as number);
    if (dbKey) {
      updatePreferenceMutation({
        request: {
          [dbKey]: capitalizeFirstLetter(howToPay),
        },
      });
    }

    switch (howToPay) {
      case HowToPayEnum.BANK_TRANSFER:
        setViewState((prevState) => ({
          ...prevState,
          viewType: ViewTypeEnum.BANK_TRANSFER,
          show: true,
        }));
        break;
      case HowToPayEnum.CARD:
        if (cardRefObj.token === MgmtViewsEnum.MGMT_FEE_PAYMENT_TYPE && cardRefObj.type === MgmtFeeTypeEnum.MONTHLY) {
          if (!hasCard)
            mAddCardRef = {
              addCardRef: {
                ...cardRefObj,
                howToPay: HowToPayEnum.CARD,
                email,
                pid: `${cardRefObj.type}_${new Date().getTime().toString()}`,
              },
            };

          dispatch({
            type: AppEventTypes.UPDATE_STATE,
            payload: {
              showAddCardModal: !hasCard,
              ...mAddCardRef,
            },
          });
          if (hasCard) submitHowToPay(HowToPayEnum.CARD);
        } else submitHowToPay(HowToPayEnum.CHARGE_AND_SAVE_CARD, { amount: selectedOption.data });

        break;
      default:
        submitHowToPay(howToPay);
        break;
    }
  };

  const onConfirmBankTansferModal = () => {
    setViewState({
      ...viewState,
      viewType: ViewTypeEnum.DEFAULT,
      show: false,
    });
    submitHowToPay(paymentType);
  };

  const onCloseModal = () => {
    setViewState({
      ...viewState,
      viewType: ViewTypeEnum.DEFAULT,
      show: false,
    });
    onClose?.();
  };

  const onNavigateBack = () => {
    setViewState({
      ...viewState,
      viewType: ViewTypeEnum.DEFAULT,
      show: true,
    });
  };

  useEffect(() => {
    if (isOpen !== viewState.show) setViewState({ ...viewState, show: isOpen });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (updatePreferenceError) {
      logError(updatePreferenceError, {
        tags: {
          userFlow: 'pay_fee_preferences',
          details: JSON.stringify({
            email,
            paymentType,
            eventType,
          }),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePreferenceError]);

  return (
    <>
      {viewState.viewType === ViewTypeEnum.DEFAULT && (
        <HowToPay
          savedOption={radioBtnOption}
          config={config}
          options={options || []}
          forceAction={forceAction}
          show={viewState.show}
          onSubmit={onMgmtFeeHowToPaySubmit}
          onClose={onCloseModal}
        />
      )}

      {viewState.viewType === ViewTypeEnum.BANK_TRANSFER && (
        <BankTransferInfo
          sendConfirmEmail={eventType !== SettingsEventTypes.UPDATE_LEGACY_FEE_SETTINGS}
          amount={selectedAmount}
          show={viewState.show}
          onSubmit={onConfirmBankTansferModal}
          onBack={onNavigateBack}
        />
      )}
    </>
  );
};

export default HowToPayModal;
