import { useMutation } from '@apollo/client';
import { SubmitOneTimePasscodeMutation, SubmitOneTimePasscodeMutationVariables } from '__generated__/graphql';
import { SUBMIT_ONE_TIME_PASSCODE } from '../graphql/mfaChallenge';

const useMfaChallengeMutation = () => {
  const [submitOTP, { loading }] = useMutation<SubmitOneTimePasscodeMutation, SubmitOneTimePasscodeMutationVariables>(
    SUBMIT_ONE_TIME_PASSCODE,
    {
      context: { serviceName: 'insecure' },
      // Catches network errors and returns them in errors in response
      onError: () => null,
    },
  );
  return {
    submitOTP,
    loading,
  };
};

export default useMfaChallengeMutation;
