import CheckBox from 'components/CheckBox';
import { DropdownItem } from 'components/Dropdown';
import DateFilter, { DateFilters, DateType } from 'components/Filters/dateFilter';
import { CellTypeEnum, TableCell, TableColumnType, TableRow } from 'components/Table';
import { TFunction } from 'i18next';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { PortfolioInfoType } from 'views/hooks/useFetchAccountData';
import { capitalizeFirstLetter, formatterGbp } from 'utils';
import {
  ALL_PORTFOLIOS,
  InitialStateType,
  IsCheckedKeyEnum,
  MainReducerActions,
  MainReducerEnum,
  SELECT_ALL,
} from '../reducers/mainReducer';
import { CASHFLOW_DETAILS_REFS, CashFlowDetailsFormatedType } from '../types';

const cellClassName = 'text-sm text-black cursor-pointer whitespace-nowrap py-2';
const cellClassNameRight = `${cellClassName} text-right`;

const DATE_FORMAT = 'YYYY-MM-DD';
export const InitialiseCashItemsSummaryTable = (translation: TFunction) => {
  const CASH_FLOW_HISTORY_TEXTS = {
    [CASHFLOW_DETAILS_REFS.DETAILS_BROAD]: translation(
      `cashFlow:cashFlowDetails.table.headers.${CASHFLOW_DETAILS_REFS.DETAILS_BROAD}`,
    ),
    [CASHFLOW_DETAILS_REFS.CASH]: translation(`cashFlow:cashFlowDetails.table.headers.${CASHFLOW_DETAILS_REFS.CASH}`),
  };

  const cashItemsSummaryColumns: TableColumnType[] = [
    {
      dataRef: CASHFLOW_DETAILS_REFS.DETAILS_BROAD,
      className: 'pr-5 ',
      text: CASH_FLOW_HISTORY_TEXTS[CASHFLOW_DETAILS_REFS.DETAILS_BROAD],
      cellClassName: cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },
    {
      dataRef: CASHFLOW_DETAILS_REFS.CASH,
      className: 'pr-5  text-right',
      cellClassName: cellClassNameRight,
      text: CASH_FLOW_HISTORY_TEXTS[CASHFLOW_DETAILS_REFS.CASH],
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },
  ];

  return {
    cashItemsSummaryColumns,
  };
};

export const InitialiseCashItemsTable = (translation: TFunction) => {
  const CASH_FLOW_ITEMS_TEXTS = {
    [CASHFLOW_DETAILS_REFS.CLIENT_ID]: translation(
      `cashFlow:cashFlowDetails.table.headers.${CASHFLOW_DETAILS_REFS.CLIENT_ID}`,
    ),
    [CASHFLOW_DETAILS_REFS.DATE]: translation(`cashFlow:cashFlowDetails.table.headers.${CASHFLOW_DETAILS_REFS.DATE}`),
    [CASHFLOW_DETAILS_REFS.DETAILS_BROAD]: translation(
      `cashFlow:cashFlowDetails.table.headers.${CASHFLOW_DETAILS_REFS.DETAILS_BROAD}`,
    ),
    [CASHFLOW_DETAILS_REFS.DETAILS]: translation(
      `cashFlow:cashFlowDetails.table.headers.${CASHFLOW_DETAILS_REFS.DETAILS}`,
    ),
    [CASHFLOW_DETAILS_REFS.REFERENCE]: translation(
      `cashFlow:cashFlowDetails.table.headers.${CASHFLOW_DETAILS_REFS.REFERENCE}`,
    ),
    [CASHFLOW_DETAILS_REFS.DEBIT]: translation(`cashFlow:cashFlowDetails.table.headers.${CASHFLOW_DETAILS_REFS.DEBIT}`),
    [CASHFLOW_DETAILS_REFS.CREDIT]: translation(
      `cashFlow:cashFlowDetails.table.headers.${CASHFLOW_DETAILS_REFS.CREDIT}`,
    ),
    [CASHFLOW_DETAILS_REFS.BALANCE]: translation(
      `cashFlow:cashFlowDetails.table.headers.${CASHFLOW_DETAILS_REFS.BALANCE}`,
    ),
  };

  const cashItemsColumns: TableColumnType[] = [
    {
      dataRef: CASHFLOW_DETAILS_REFS.DATE,
      className: 'pr-5 ',
      text: CASH_FLOW_ITEMS_TEXTS[CASHFLOW_DETAILS_REFS.DATE],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },
    {
      dataRef: CASHFLOW_DETAILS_REFS.DETAILS_BROAD,
      className: 'pr-5 ',
      text: CASH_FLOW_ITEMS_TEXTS[CASHFLOW_DETAILS_REFS.DETAILS_BROAD],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },
    {
      dataRef: CASHFLOW_DETAILS_REFS.DETAILS,
      className: 'pr-5 ',
      text: CASH_FLOW_ITEMS_TEXTS[CASHFLOW_DETAILS_REFS.DETAILS],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },

    {
      dataRef: CASHFLOW_DETAILS_REFS.DEBIT,
      className: 'pr-5 ',
      text: CASH_FLOW_ITEMS_TEXTS[CASHFLOW_DETAILS_REFS.DEBIT],
      cellClassName: cellClassNameRight,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },
    {
      dataRef: CASHFLOW_DETAILS_REFS.CREDIT,
      className: 'pr-5 ',
      text: CASH_FLOW_ITEMS_TEXTS[CASHFLOW_DETAILS_REFS.CREDIT],
      cellClassName: cellClassNameRight,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },
    {
      dataRef: CASHFLOW_DETAILS_REFS.BALANCE,
      className: 'pr-5 ',
      text: CASH_FLOW_ITEMS_TEXTS[CASHFLOW_DETAILS_REFS.BALANCE],
      cellClassName: cellClassNameRight,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{`${cell.text}`}</span>,
    },
  ];

  return {
    cashItemsColumns,
  };
};

export const buildTableRow = (
  dataRow: Record<string, unknown>,
  columns: TableColumnType[],
  id?: string,
  className?: string,
): TableRow => {
  const cells = columns.map((col: TableColumnType) => {
    return {
      dataRef: col.dataRef,
      isVisible: col.isVisible,
      text: dataRow[col.dataRef] as string,
      className: col.cellClassName || '',
      type: col.cellType || CellTypeEnum.TD,
      cellContentTemplate: col.cellContentTemplate,
    };
  });

  return {
    id,
    className: className || '',
    cells,
    rowData: dataRow,
  };
};

export const processBalance = (transactions: CashFlowDetailsFormatedType[]) => {
  let cumulativeSums = 0;
  let credit: number | string = 0;
  let debit: number | string = 0;
  let total = 0;
  transactions.sort((a, b) => moment(a.date).diff(moment(b.date)));
  const result = transactions.map((payment, index) => {
    total = Number(payment.cash);

    if (Number(payment.cash) > 0) {
      credit = Number(payment.cash);
      debit = '';
    } else {
      debit = Number(payment.cash);
      credit = '';
    }

    const bal = total;
    cumulativeSums += bal;
    const prevBalance = cumulativeSums - bal;
    const newBalance = prevBalance + total;

    return {
      ...payment,
      total: bal,
      prevBalance,
      newBalance,
      id: index,
      credit: formatterGbp.format(Math.abs(Number(credit || 0))),
      debit: formatterGbp.format(Math.abs(Number(debit || 0))),
      balance: formatterGbp.format(Number(newBalance || 0)),
      date: payment.date || '',
      description: payment.details_broad || '',
      ref: payment.reference || '',
    };
  });

  return result.reverse();
};

export const detailsBoradDropDownItems = (
  formatedCashFlowDetails: CashFlowDetailsFormatedType[],
  isDetailBoradCheck: string[],
  dispatch: Dispatch<MainReducerActions>,
) => {
  const uniqueDetailsBroad = [...new Set([SELECT_ALL, ...formatedCashFlowDetails.map((x) => x.details_broad)])];
  const items: DropdownItem[] = [];
  uniqueDetailsBroad.forEach((value, index) => {
    const detailsBroad = value || '';
    items.push({
      id: `${index}`,
      content: (
        <button
          className=" w-full py-3 px-4 text-base flex "
          onClick={(e) => {
            e.stopPropagation();
            dispatch({
              type: MainReducerEnum.CHECK,
              payload: {
                all: uniqueDetailsBroad as string[],
                value: detailsBroad,
                key: IsCheckedKeyEnum.isDetailBoradCheck,
              },
            });
          }}
        >
          <CheckBox
            className="min-h-[20px] min-w-[20px]"
            isChecked={isDetailBoradCheck.includes(detailsBroad)}
            id={`${index}`}
          >
            <span className="ml-3 text-left text-black text-14 ">{capitalizeFirstLetter(detailsBroad || '')}</span>
          </CheckBox>
        </button>
      ),
      value: `${detailsBroad}`,
    });
  });

  return items;
};

export const portfolioDropDownItems = (
  portfolios: PortfolioInfoType[],
  seletedPortfolio: string[],
  dispatch: Dispatch<MainReducerActions>,
) => {
  const items: DropdownItem[] = [];

  const portfolioIdsStr = [ALL_PORTFOLIOS, ...portfolios.map((x) => String(x.portfolioId))];

  portfolioIdsStr.forEach((value, index) => {
    const portfolioId = String(value) || '';
    const portfolioName =
      portfolios.find((x) => String(x.portfolioId) === portfolioId)?.portfolioName || ALL_PORTFOLIOS;
    const selectedAll = portfolioName === ALL_PORTFOLIOS;
    items.push({
      id: `${index}`,
      content: (
        <button
          className=" w-full py-3 px-4 text-base flex "
          onClick={(e) => {
            dispatch({
              type: MainReducerEnum.SELECT_PORTFOLIO,
              payload: {
                text: portfolioName,
                value: selectedAll ? portfolioIdsStr : [portfolioId],
              },
            });
          }}
        >
          <span className="ml-3 text-left text-black text-14 min-h-[20px] min-w-[20px]">{portfolioName}</span>
        </button>
      ),
      value: `${portfolioName}`,
    });
  });

  return items;
};

export const filterDataSourceByDetailBroad = (state: InitialStateType, dataSource: CashFlowDetailsFormatedType[]) => {
  let source: CashFlowDetailsFormatedType[] = [...dataSource];
  if (state.isDetailBoradCheck.length > 0)
    source = source.filter((x) => state.isDetailBoradCheck.includes(x.details_broad || ''));
  if (state.selectedPortfolio.length > 0)
    source = source.filter((x) => state.selectedPortfolio.includes(Number(x.client_id).toFixed(0) || ''));

  return source;
};

export const filterDataSourceByDate = (datePicker: DateFilters, dataSource: CashFlowDetailsFormatedType[]) => {
  let source: CashFlowDetailsFormatedType[] = [...dataSource];

  if (datePicker[DateType.START] || datePicker[DateType.END]) {
    const startDate = moment(datePicker[DateType.START]).format(DATE_FORMAT);
    const endDate = moment(datePicker[DateType.END]).format(DATE_FORMAT);
    source = source.filter((x) => {
      const rowDate = moment(x.date).format(DATE_FORMAT);
      return (
        moment(rowDate).isBetween(moment(startDate), moment(endDate)) ||
        moment(rowDate).isSame(moment(startDate)) ||
        moment(rowDate).isSame(moment(endDate))
      );
    });
  }
  return source;
};

export const datePickerText = (datePicker: DateFilters) => {
  const start = datePicker[DateType.START];
  const end = datePicker[DateType.END];
  const startDate = moment(start).format(DATE_FORMAT);
  const endDate = moment(end).format(DATE_FORMAT);
  if (start && end) return `${startDate} --- ${endDate}`;
  if (start) return startDate;
  return undefined;
};

export const dateDropDownItem = (
  datePicker: DateFilters,
  setDatePicker: Dispatch<SetStateAction<DateFilters>>,
  maxDate?: Date,
  minDate?: Date,
) => {
  const items: DropdownItem[] = [];
  items.push({
    id: `${1}`,
    content: (
      <div className="hover:bg-white w-[330px]" onClick={(e) => e.stopPropagation()}>
        {datePicker[DateType.START] && (
          <button onClick={() => setDatePicker({ start: null, end: null })} className="p-2 text-sm underline">
            Clear
          </button>
        )}
        <DateFilter
          maxDate={maxDate}
          minDate={minDate}
          model={datePicker}
          options={{ datePlaceHolderText: 'Select date', endTitle: 'To', startTitle: 'From' }}
          onModelUpdate={(e) => setDatePicker(e)}
        />
      </div>
    ),
    value: `${1}`,
  });

  return items;
};
