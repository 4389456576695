import { useMemo } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from '@tanstack/react-query';
import { logError } from '../../../../../components/LogError';

import { PerformanceOverTime } from '../../../types';
import {
  PortfolioHistoricPerformanceParam,
  PortfolioHistoricPerformanceResponse,
  fetchPortfolioHistoricPerformance,
} from '../services/fetchPortfolioHistoricPerformance';

export interface UsePortfolioPerformanceOverTimeResponse {
  portfolioPerformanceOverTime: PerformanceOverTime[];
  loading: boolean;
  error: unknown;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<PortfolioHistoricPerformanceResponse, unknown>>;
}

export const usePortfolioPerformanceOverTime = (
  portfolioId: PortfolioHistoricPerformanceParam,
): UsePortfolioPerformanceOverTimeResponse => {
  const {
    data,
    isLoading: loading,
    error,
    refetch,
  } = useQuery({
    queryFn: () => fetchPortfolioHistoricPerformance(portfolioId),
    queryKey: [`fetchPortfolioHistoricPerformance${portfolioId}`],
  });

  if (error) {
    logError(JSON.stringify(error));
  }
  const portfolioPerformanceOverTime = useMemo(() => data?.data || [], [data]);
  return { portfolioPerformanceOverTime, loading, error, refetch };
};
