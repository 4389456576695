import { useEffect, useState } from 'react';
import { fetchBookGroups } from '../services/fetchBookGroups';
import { fetchBookGroupsOrders } from '../services/fetchBookGroupsOrders';
import usePurchase from './usePurchase';
import { IPurchaseParams, executeBuy } from '../services/executeBuy';

export enum StatusTypes {
  success = 'success',
  error = 'error',
  isLoading = 'isLoading',
}

export const useHandleBuy = () => {
  const { mutateAsync: mutatePurchase } = usePurchase({ mutationFn: executeBuy });
  const [mutateStatus, setMutateStatus] = useState<StatusTypes | null>();
  const resetStatus = () => setMutateStatus(null);

  useEffect(() => {
    // alert(mutateStatus);
    // if (!onStatusChange) return;
    // if (mutateStatus === StatusTypes.success) onStatusChange(ViewStateType.SUCCESS);
    // if (mutateStatus === StatusTypes.isLoading) onStatusChange(ViewStateType.LOADING);
    // if (mutateStatus === StatusTypes.error) onStatusChange(ViewStateType.ERROR_RESULT);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateStatus]);

  const handlePending = (orderGroupId: string | number) => {
    const recusrsiveFunc = async () => {
      try {
        const { status } = await fetchBookGroups(orderGroupId);
        if (status === 'Pending') {
          setTimeout(async () => {
            await recusrsiveFunc();
          }, 1000);
        } else {
          const bookGroupId = orderGroupId;
          const BookGroupsOrdersRes = await fetchBookGroupsOrders(bookGroupId);
          if (BookGroupsOrdersRes.data.length > 0) {
            setMutateStatus(StatusTypes.success);
          } else {
            setTimeout(async () => {
              await recusrsiveFunc();
            }, 1000);
          }
        }
      } catch (error) {
        setMutateStatus(StatusTypes.error);
      }
    };
    recusrsiveFunc();
  };

  const handlePurchase = async (params: IPurchaseParams) => {
    setMutateStatus(StatusTypes.isLoading);
    try {
      //     throw new Error('wdwd');
      const buyResponse = await mutatePurchase(params);
      if (buyResponse.data.status === 'Succeed') {
        const { orderGroupId } = buyResponse.data.result;
        await handlePending(orderGroupId);
      }
    } catch (error) {
      setMutateStatus(StatusTypes.error);
    }
  };

  return { handlePurchase, mutateStatus, resetStatus };
};
