import { TFunction } from 'i18next';
import { TableCell, TableColumnType, TableRow } from 'components/Table';
import { DropdownItem } from 'components/Dropdown';
import { ViewStateType } from 'components/ProductTemplates/types';
import { GbpToTargetCurrency } from 'types/commonTypes';
import { getRegions } from 'helpers';
import { toInternalId } from 'utils';
import moment from 'moment';
import {
  IGenerateRowsFormat,
  IGenerateTableActions,
  IRowData,
  MARKET_REFS,
  MY_OFFERS_REFS,
  OffersType,
  PortfolioOffersType,
  SUB_TABS_ENUMS,
  SetSlideoutConfigType,
  SlideoutConfigType,
  TABLE_ACTIONS,
  TRADE_HISTORY_REFS,
  TabsPops,
  TradeHistoryType,
} from '../types';

const cellClassName = 'text-center text-sm text-black cursor-pointer whitespace-nowrap';
const cellClassName2 = 'text-center text-sm text-black cursor-pointer whitespace-nowrap py-5';

const getActionButtonText = (actionText: TABLE_ACTIONS, translation: TFunction) => {
  return translation(`trade:common.actions.${actionText}`);
  // switch (actionText) {
  //   case TABLE_ACTIONS.ACCEPT_HIGHEST_BID:
  //     return 'Accept Bid';
  //   case TABLE_ACTIONS.MY_OFFERS_ACCEPT_HIGHEST_BID:
  //     return 'Accept Bid';
  //   case TABLE_ACTIONS.CREATE_OFFER:
  //     return 'Create Offer';
  //   case TABLE_ACTIONS.EDIT_OFFER:
  //     return 'Edit Offer';
  //   case TABLE_ACTIONS.PURCHASE:
  //     return 'Purchase';
  //   default:
  //     return '';
  // }
};

const onClickButton = (
  translation: TFunction,
  row: IRowData,
  action: TABLE_ACTIONS,
  slideOutConfig: SlideoutConfigType,
  setSlideOutConfig: SetSlideoutConfigType,
) => {
  const {
    lwin18,
    wineName,
    quantityOutstanding: quantity,
    vintage,
    marketValueInTargetCurrency: marketValue,
    unitSize,
  } = row;
  setSlideOutConfig({
    innerViewState: ViewStateType.DEFAULT,
    view: ViewStateType.DEFAULT,
    isOpen: true,
    slidoutView: action,
    offer: row,
    title: getActionButtonText(action, translation),
    wineInfoTemplateConfig: {
      lwin18,
      marketValue,
      quantity,
      unitSize,
      vintage,
      wineName,
      cultWinesAllocationRegion: row?.cultWinesAllocationRegion ?? '',
      region: row?.region,
    },
  });
};

export const InitialiseMarketTable = (
  translation: TFunction,
  slideOutConfig: SlideoutConfigType,
  setSlideOutConfig: SetSlideoutConfigType,
  gbpToTargetCurrency: GbpToTargetCurrency,
) => {
  const MARKET_TABLE_TEXTS = {
    [MARKET_REFS.ID]: 'id',
    [MARKET_REFS.REGION]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.REGION}`),
    [MARKET_REFS.WINENAME]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.WINENAME}`),
    [MARKET_REFS.VINTAGE]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.VINTAGE}`),
    [MARKET_REFS.UNITSIZE]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.UNITSIZE}`),
    [MARKET_REFS.LWIN18]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.LWIN18}`),
    [MARKET_REFS.QUANTITY]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.QUANTITY}`),
    [MARKET_REFS.MARKET_VALUE]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.MARKET_VALUE}`),
    [MARKET_REFS.TOTAL_MARKET_VALUE]: translation(
      `trade:offersAndBids.table.headers.${MARKET_REFS.TOTAL_MARKET_VALUE}`,
    ),
    [MARKET_REFS.HIGHEST_BID]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.HIGHEST_BID}`),
    [MARKET_REFS.LOWEST_OFFER]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.LOWEST_OFFER}`),
    [MARKET_REFS.OFFER]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.OFFER}`),
    [MARKET_REFS.LAST_TRADE_PRICE]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.LAST_TRADE_PRICE}`),
  };

  const marketColumns: TableColumnType[] = [
    {
      dataRef: MARKET_REFS.REGION,
      className: 'text-center px-5',
      text: MARKET_TABLE_TEXTS[MARKET_REFS.REGION],
      cellClassName,
      // cellContentTemplate: (cell: TableCell, _, rowData) => {
      //   const row = rowData as unknown as IRowData;
      //   return (
      //     <div className="flex justify-center gap-2 px-2">
      //       {row.actions.map((action: TABLE_ACTIONS) => {
      //         let style;
      //         switch (action) {
      //           case TABLE_ACTIONS.ACCEPT_HIGHEST_BID:
      //             style = 'bg-vine text-white';
      //             break;
      //           case TABLE_ACTIONS.PURCHASE:
      //             style = 'bg-orange ';
      //             break;

      //           default:
      //             style = '';
      //             break;
      //         }
      //         return (
      //           <button
      //             key={action}
      //             className={`border p-1 rounded-lg ${style}`}
      //             onClick={() => onClickButton(translation, row, action, slideOutConfig, setSlideOutConfig)}
      //           >
      //             {getActionButtonText(action, translation)}
      //           </button>
      //         );
      //       })}
      //     </div>
      //   );
      // },

      cellContentTemplate: (cell: TableCell, t: TFunction, rowData: TableRow['rowData']) => {
        const row = rowData as unknown as OffersType;
        const regionColors = getRegions();
        return (
          <div className="flex items-center ">
            <div
              style={{
                backgroundColor: regionColors.find((x) => x.id === toInternalId(`${row?.cultWinesAllocationRegion}`))
                  ?.color,
              }}
              className="w-[8px] h-[60px] mr-3"
            />
            <span className="mr-3">{`${row.region}`}</span>
          </div>
        );
      },
    },
    {
      dataRef: MARKET_REFS.WINENAME,
      className: 'pr-5  ',
      text: MARKET_TABLE_TEXTS[MARKET_REFS.WINENAME],
      cellClassName: 'text-sm text-black cursor-pointer whitespace-nowrap px-2 ',
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as OffersType;
        return <span>{`${row.vintage} ${row.wineName} `}</span>;
      },
    },

    {
      dataRef: MARKET_REFS.UNITSIZE,
      className: 'text-center pl-5',
      text: MARKET_TABLE_TEXTS[MARKET_REFS.UNITSIZE],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span>{cell.text}</span>,
    },

    {
      dataRef: MARKET_REFS.QUANTITY,
      className: 'text-center pl-5',
      text: MARKET_TABLE_TEXTS[MARKET_REFS.QUANTITY],
      cellClassName,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as OffersType;
        return <span>{row.quantityOutstanding}</span>;
      },
    },
    {
      dataRef: MARKET_REFS.HIGHEST_BID,
      className: 'text-center pl-5',
      text: MARKET_TABLE_TEXTS[MARKET_REFS.HIGHEST_BID],
      cellClassName,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as IRowData;
        return (
          <div className="flex flex-col px-2">
            <span className="">
              {row.highestBid?.price ? gbpToTargetCurrency.convertAndFormat(Number(row.highestBid?.price || 0)) : ''}
            </span>
            {row.actions.includes(TABLE_ACTIONS.ACCEPT_HIGHEST_BID) && (
              <button
                className={` text-orange font-bold`}
                onClick={() =>
                  onClickButton(translation, row, TABLE_ACTIONS.ACCEPT_HIGHEST_BID, slideOutConfig, setSlideOutConfig)
                }
              >
                {getActionButtonText(TABLE_ACTIONS.ACCEPT_HIGHEST_BID, translation)}
              </button>
            )}
          </div>
        );
      },
    },
    {
      dataRef: MARKET_REFS.LOWEST_OFFER,
      className: 'text-center pl-5',
      text: MARKET_TABLE_TEXTS[MARKET_REFS.OFFER],
      cellClassName,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as IRowData;
        return (
          <div className="flex flex-col px-2">
            <span className="">
              {row.lowestOffer?.price ? gbpToTargetCurrency.convertAndFormat(Number(row.lowestOffer?.price || 0)) : ''}
            </span>
            {row.actions.includes(TABLE_ACTIONS.PURCHASE) && (
              <button
                className={` text-orange font-bold`}
                onClick={() =>
                  onClickButton(translation, row, TABLE_ACTIONS.PURCHASE, slideOutConfig, setSlideOutConfig)
                }
              >
                {getActionButtonText(TABLE_ACTIONS.PURCHASE, translation)}
              </button>
            )}
          </div>
        );
      },
    },
    {
      dataRef: MARKET_REFS.LAST_TRADE_PRICE,
      className: 'text-center pl-5',
      text: MARKET_TABLE_TEXTS[MARKET_REFS.LAST_TRADE_PRICE],
      cellClassName,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as OffersType;
        return (
          <div className="flex flex-col">
            <span className="px-2">
              {row?.lastTradePrice ? gbpToTargetCurrency.convertAndFormat(Number(row.lastTradePrice || 0)) : ''}
            </span>
            <span className="text-orange text-xs">
              {row?.tradeDateTime ? moment(row?.tradeDateTime).format('YYYY/MM/DD') : ''}
            </span>
          </div>
        );
      },
    },
    {
      dataRef: MARKET_REFS.ID,
      text: MARKET_REFS.ID,
      isVisible: false,
    },
  ];

  //  highestBid: data.highestBid?.price,
  //               quantity: data.initialQuantity,
  //               totalMarketValue: data.quantityOutstanding * data.marketValue,
  // const sortByOptions = columns
  //   .filter((x) => x.isVisible === true || x.isVisible === undefined)
  //   .map((col) => ({
  //     id: col.dataRef,
  //     value: col.dataRef,
  //     text: col.text,
  //     content: <span>{col.text}</span>,
  //   }));

  // const defaultSortBy = () => {
  //   const { id, text } = sortByOptions.find((x) => x.id === MARKET_REFS.ID)!;
  //   return {
  //     id,
  //     text,
  //     direction: SortDirection.DESCENDING,
  //   };
  // };

  return {
    marketColumns,
  };
};

export const InitialiseMyOffersTable = (
  translation: TFunction,
  slideOutConfig: SlideoutConfigType,
  setSlideOutConfig: SetSlideoutConfigType,
  gbpToTargetCurrency: GbpToTargetCurrency,
) => {
  const MY_OFFERS_TABLE_TEXTS = {
    [MY_OFFERS_REFS.ID]: 'id',
    [MY_OFFERS_REFS.ACTIONS]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.ACTIONS}`),
    [MY_OFFERS_REFS.WINENAME]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.WINENAME}`),
    [MY_OFFERS_REFS.LWIN18]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.LWIN18}`),
    [MY_OFFERS_REFS.VINTAGE]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.VINTAGE}`),
    [MY_OFFERS_REFS.UNITSIZE]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.UNITSIZE}`),

    [MY_OFFERS_REFS.OFFER_PRICE]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.OFFER_PRICE}`),
    [MY_OFFERS_REFS.OFFER_QUANTITY]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.OFFER_QUANTITY}`),
    [MY_OFFERS_REFS.QUANTITY_TO_SELL]: translation(
      `trade:offersAndBids.table.headers.${MY_OFFERS_REFS.QUANTITY_TO_SELL}`,
    ),
    [MY_OFFERS_REFS.HIGHEST_BID]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.HIGHEST_BID}`),
    [MY_OFFERS_REFS.STOP_LIMIT]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.STOP_LIMIT}`),
    [MY_OFFERS_REFS.WAC]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.WAC}`),
    [MY_OFFERS_REFS.PHYSICAL_QUANTITY]: translation(
      `trade:offersAndBids.table.headers.${MY_OFFERS_REFS.PHYSICAL_QUANTITY}`,
    ),
    [MY_OFFERS_REFS.CREATED_DATE_TIME]: translation(
      `trade:offersAndBids.table.headers.${MY_OFFERS_REFS.CREATED_DATE_TIME}`,
    ),
  };

  const myOffersColumns: TableColumnType[] = [
    {
      dataRef: MY_OFFERS_REFS.ACTIONS,
      className: 'text-center px-5',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.ACTIONS],
      cellClassName,
      cellContentTemplate: (cell: TableCell, _, rowData) => {
        const row = rowData as unknown as IRowData;
        return (
          <div className="flex justify-center gap-2 px-2">
            {row.actions.map((action: TABLE_ACTIONS) => {
              let style;
              switch (action) {
                case TABLE_ACTIONS.MY_OFFERS_ACCEPT_HIGHEST_BID:
                  style = 'bg-vine text-white';
                  break;
                case TABLE_ACTIONS.CANCEL_OFFER:
                  style = 'text-white  bg-trenddown';
                  break;

                default:
                  style = '';
                  break;
              }
              return (
                <button
                  key={action}
                  className={`border p-1 rounded-lg ${style}`}
                  onClick={() => onClickButton(translation, row, action, slideOutConfig, setSlideOutConfig)}
                >
                  {getActionButtonText(action, translation)}
                </button>
              );
            })}
          </div>
        );
      },
    },
    {
      dataRef: MY_OFFERS_REFS.WINENAME,
      className: 'pr-5 ',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.WINENAME],
      cellClassName: 'text-sm text-black cursor-pointer whitespace-nowrap px-2 py-5',
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as PortfolioOffersType;
        return <span>{`${row.vintage} ${row.wineName} `}</span>;
      },
    },

    {
      dataRef: MY_OFFERS_REFS.UNITSIZE,
      className: 'text-center pl-5',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.UNITSIZE],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{cell.text}</span>,
    },
    {
      dataRef: MY_OFFERS_REFS.OFFER_PRICE,
      className: 'text-center pl-5',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.OFFER_PRICE],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => {
        return <span>{gbpToTargetCurrency.convertAndFormat(Number(cell.text))}</span>;
      },
    },
    {
      dataRef: MY_OFFERS_REFS.OFFER_QUANTITY,
      className: 'text-center pl-5',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.OFFER_QUANTITY],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span>{cell.text}</span>,
    },
    {
      dataRef: MY_OFFERS_REFS.HIGHEST_BID,
      className: 'text-center pl-5',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.HIGHEST_BID],
      cellClassName,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as OffersType;
        return (
          <span>
            {row.highestBid?.price ? gbpToTargetCurrency.convertAndFormat(Number(row.highestBid?.price || 0)) : ''}
          </span>
        );
      },
    },
    {
      dataRef: MY_OFFERS_REFS.STOP_LIMIT,
      className: 'text-center px-5',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.STOP_LIMIT],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span>{gbpToTargetCurrency.convertAndFormat(Number(cell.text))}</span>,
    },
    {
      dataRef: MY_OFFERS_REFS.CREATED_DATE_TIME,
      className: 'text-center px-5',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.CREATED_DATE_TIME],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{cell.text?.split('T')[0]}</span>,
    },

    {
      dataRef: MY_OFFERS_REFS.ID,
      text: MY_OFFERS_REFS.ID,
      isVisible: false,
    },
  ];

  return {
    myOffersColumns,
  };
};

const dateTimeSplitFunc = (dateTime: string) => {
  const date = new Date(dateTime).toLocaleDateString('en', {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
  });

  try {
    return { date: date.split(',')[0], time: date.split(',')[1] };
  } catch (error) {
    return { date: '', time: '' };
  }
};
export const InitialiseTradeHistoryTable = (translation: TFunction, gbpToTargetCurrency: GbpToTargetCurrency) => {
  const TRADE_HISTORY_TEXTS = {
    [TRADE_HISTORY_REFS.DATE]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.DATE}`),
    [TRADE_HISTORY_REFS.TIME]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.TIME}`),
    [TRADE_HISTORY_REFS.WINENAME]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.WINENAME}`),
    [TRADE_HISTORY_REFS.REGION]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.REGION}`),
    [TRADE_HISTORY_REFS.PRODUCER]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.PRODUCER}`),
    [TRADE_HISTORY_REFS.VINTAGE]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.VINTAGE}`),
    [TRADE_HISTORY_REFS.UNITSIZE]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.UNITSIZE}`),
    [TRADE_HISTORY_REFS.LWIN18]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.LWIN18}`),
    [TRADE_HISTORY_REFS.QUANTITY]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.QUANTITY}`),
    [TRADE_HISTORY_REFS.TRANSACTIONTYPE]: translation(
      `trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.TRANSACTIONTYPE}`,
    ),
    [TRADE_HISTORY_REFS.PRICE]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.PRICE}`),
    [TRADE_HISTORY_REFS.TOTAL]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.TOTAL}`),
    [TRADE_HISTORY_REFS.BUYER]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.BUYER}`),
    [TRADE_HISTORY_REFS.SELLER]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.SELLER}`),
  };

  const tradeHistoryColumns: TableColumnType[] = [
    {
      dataRef: TRADE_HISTORY_REFS.DATE,
      className: 'pr-5 ',
      text: TRADE_HISTORY_TEXTS[TRADE_HISTORY_REFS.DATE],
      cellClassName: cellClassName2,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as TradeHistoryType;
        return <span className="px-2">{`${moment(row?.dateTime).format('YYYY/MM/DD')}`}</span>;
      },
    },
    {
      dataRef: TRADE_HISTORY_REFS.TIME,
      className: 'pr-5 ',
      text: TRADE_HISTORY_TEXTS[TRADE_HISTORY_REFS.TIME],
      cellClassName: cellClassName2,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as TradeHistoryType;
        return <span className="px-2">{`${dateTimeSplitFunc(row.dateTime).time}`}</span>;
      },
    },
    {
      dataRef: TRADE_HISTORY_REFS.WINENAME,
      className: 'pr-5 ',
      text: TRADE_HISTORY_TEXTS[TRADE_HISTORY_REFS.WINENAME],
      cellClassName: 'text-sm text-black cursor-pointer whitespace-nowrap px-2 ',
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as TradeHistoryType;
        return <span>{`${row.vintage} ${row.wineName} `}</span>;
      },
    },

    {
      dataRef: TRADE_HISTORY_REFS.UNITSIZE,
      className: 'text-center pl-5',
      text: TRADE_HISTORY_TEXTS[TRADE_HISTORY_REFS.UNITSIZE],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span>{cell.text}</span>,
    },

    {
      dataRef: TRADE_HISTORY_REFS.QUANTITY,
      className: 'text-center pl-5',
      text: TRADE_HISTORY_TEXTS[TRADE_HISTORY_REFS.QUANTITY],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{cell.text}</span>,
    },

    {
      dataRef: TRADE_HISTORY_REFS.PRICE,
      className: 'text-center pl-5',
      text: TRADE_HISTORY_TEXTS[TRADE_HISTORY_REFS.PRICE],
      cellClassName,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as TradeHistoryType;
        return (
          <span>
            {row.transactionType === 'Sell'
              ? gbpToTargetCurrency.convertAndFormat(Number(row.executionPrice))
              : gbpToTargetCurrency.convertAndFormat(Number(row.price))}
          </span>
        );
      },
    },
    {
      dataRef: TRADE_HISTORY_REFS.TOTAL,
      className: 'text-center pl-5',
      text: TRADE_HISTORY_TEXTS[TRADE_HISTORY_REFS.TOTAL],
      cellClassName,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as TradeHistoryType;
        return (
          <span>
            {row.transactionType === 'Sell'
              ? gbpToTargetCurrency.convertAndFormat(Number(row.executionPrice * row.quantity))
              : gbpToTargetCurrency.convertAndFormat(Number(row.total))}
          </span>
        );
      },
    },
  ];

  //  highestBid: data.highestBid?.price,
  //               quantity: data.initialQuantity,
  //               totalMarketValue: data.quantityOutstanding * data.marketValue,
  // const sortByOptions = columns
  //   .filter((x) => x.isVisible === true || x.isVisible === undefined)
  //   .map((col) => ({
  //     id: col.dataRef,
  //     value: col.dataRef,
  //     text: col.text,
  //     content: <span>{col.text}</span>,
  //   }));

  // const defaultSortBy = () => {
  //   const { id, text } = sortByOptions.find((x) => x.id === MARKET_REFS.ID)!;
  //   return {
  //     id,
  //     text,
  //     direction: SortDirection.DESCENDING,
  //   };
  // };

  return {
    tradeHistoryColumns,
  };
};

const generateTableActions = ({ row, portfolioStocks, tableType }: IGenerateTableActions): TABLE_ACTIONS[] => {
  const actions: TABLE_ACTIONS[] = [];
  switch (tableType) {
    case SUB_TABS_ENUMS.MARKET: {
      actions.push(TABLE_ACTIONS.PURCHASE);
      if (row.highestBid?.price && portfolioStocks?.find((x) => x.lwin18 === row.lwin18))
        actions.push(TABLE_ACTIONS.ACCEPT_HIGHEST_BID);
      return actions;
    }
    case SUB_TABS_ENUMS.MY_OFFERS: {
      if (row.highestBid?.price) actions.push(TABLE_ACTIONS.MY_OFFERS_ACCEPT_HIGHEST_BID);
      actions.push(TABLE_ACTIONS.EDIT_OFFER);
      return actions;
    }

    default: {
      return [];
    }
  }
};

export const generateRowsFormat = ({
  currentSubTab,
  offers,
  portfolioStocks,
  portfolioOffers,
  fxRate,
}: IGenerateRowsFormat) => {
  switch (currentSubTab) {
    case SUB_TABS_ENUMS.MARKET: {
      const source: IRowData[] = [];
      offers
        .filter((i) => !i.campaignId)
        .filter((x) => !x.isLiquidating)
        .forEach((row) => {
          const index = source.findIndex((x) => x.lwin18 === row.lwin18);
          if (index > -1) {
            // add to exsisting
            const tableActions = [
              ...new Set([
                ...generateTableActions({
                  row: row,
                  portfolioStocks,
                  tableType: SUB_TABS_ENUMS.MARKET,
                }),
                ...source[index].actions,
              ]),
            ];

            source[index] = {
              ...source[index],
              actions: tableActions,
              quantityOutstanding: source[index].quantityOutstanding + row.quantityOutstanding,
            };
          } else {
            // add to as new
            source.push({
              ...row,
              priceGbp: row.price,
              priceInTargetCurrency: row.price * fxRate,

              marketValueGbp: row.marketValue,
              marketValueInTargetCurrency: row.marketValue * fxRate,

              stopLimitPriceGbp: row.stopLimitPrice,
              stopLimitPriceInTargetCurrency: row.stopLimitPrice * fxRate,
              actions: generateTableActions({
                row: row,
                portfolioStocks,
                tableType: SUB_TABS_ENUMS.MARKET,
              }),
            });
          }
        });
      // source.sort((a, b) => (b.actions.length || 0) - (a.actions.length || 0));
      return source.sort((a, b) => (b.highestBid?.price || 0) - (a.highestBid?.price || 0));
    }
    case SUB_TABS_ENUMS.MY_OFFERS: {
      return portfolioOffers.map((row) => {
        return {
          ...row,
          priceGbp: row.price,
          priceInTargetCurrency: Number((row.price * fxRate).toFixed(2)),

          marketValueGbp: row.marketValue,
          marketValueInTargetCurrency: Number(((row?.marketValue || 0) * fxRate).toFixed(2)),

          stopLimitPriceGbp: row.stopLimitPrice,
          stopLimitPriceInTargetCurrency: Number((row.stopLimitPrice * fxRate).toFixed(2)),

          actions: generateTableActions({ row: row, tableType: SUB_TABS_ENUMS.MY_OFFERS }),
        };
      });
    }

    default:
      return [];
  }
};

export const subTabsBidsAndOffers: TabsPops[] = [
  { id: 1, tabName: SUB_TABS_ENUMS.MY_OFFERS },
  { id: 2, tabName: SUB_TABS_ENUMS.MARKET },
];

export const stopLimitsItems: DropdownItem[] = [
  { id: '1', content: <div>5% below market value</div>, text: '5% below market value', value: '0.95' },
  { id: '1', content: <div>10% below market value</div>, text: '10% below market value', value: '0.90' },
  { id: '2', content: <div>15% below market value</div>, text: '15% below market value', value: '0.85' },
  { id: '3', content: <div>20% below market value</div>, text: '20% below market value', value: '0.80' },
  { id: '4', content: <div>40% below market value</div>, text: '40% below market value', value: '0.60' },
];

export const generateQuantityDropdown = (quantity = 0) => {
  const arr = Array(quantity).fill(1, 0);
  const items: DropdownItem[] = [];
  arr.forEach((_, i) => {
    items.push({
      id: `${i + 1}`,
      content: (
        <div key={i}>
          <span>{`${i + 1}`}</span>
        </div>
      ),
      value: `${i + 1}`,
    });
  });
  return items;
};
