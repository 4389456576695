import { useOutletContext } from 'react-router-dom';
import { OpenSlideoutFnType } from 'views/Accounts/types';

export interface ProtectedRouteContextType {
  openSlideout: OpenSlideoutFnType;
  backSlideout: () => void;
  closeSlidout: () => void;
}

export const useProtectedPageContext = () => {
  return useOutletContext<ProtectedRouteContextType>();
};
