import { InvestOffer } from 'views/Invest/types';

interface AccountSummaryType {
  id: string;
  accountHolderId: number;
  date: string;
  statements: {
    main: {
      columns: string[];
      rows: string[][];
    };
  };
}

export interface TransactionsType {
  id: string;
  accountHolderId: string;
  last_updated: string;
  total: string;
  statements: {
    main: {
      columns: string[];
      rows: string[][];
    };
  };
}

export enum PortalDocumentTypeEnum {
  INVESTOFFERS = 'investOffers',
  ACCOUNTSUMMARY = 'accountSummary',
  CASH_FLOW_DETAILS = 'cashFlowDetails',
  TRANSACTIONS = 'Transactions',
}

export interface PortalDocumentResult {
  count: number;
  data: InvestOffer[] | AccountSummaryType[] | TransactionsType[];
}

export interface PortalDocumentParams {
  docType: PortalDocumentTypeEnum;
  docRef?: string | number | null;
  isDetail?: boolean;
  sort?: string;
  page?: number;
  pageSize?: number;
  sortOrder?: 'ASC' | 'DESC';
}
