import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from '@tanstack/react-query';
import { logError } from '../../../components/LogError';

interface IUseGetReturnHookResponse<T> {
  responce: T | undefined | null;
  isLoading: boolean;
  error: unknown;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<T, unknown>>;
  isFetching?: boolean;
}
interface IUseGetParams<T, P> {
  queryFn: (params?: P | unknown) => Promise<T>;
  queryKey: string[];
  refetchOnWindowFocus?: boolean;
  isEnabled?: boolean;
  shouldCache?: boolean;
}
export const useFetch = <T, P>({
  queryFn,
  queryKey,
  refetchOnWindowFocus = true,
  isEnabled = true,
  shouldCache = true,
}: IUseGetParams<T, P>): IUseGetReturnHookResponse<T> => {
  const cache = !shouldCache ? { cacheTime: 0 } : {};
  const { data, isLoading, error, refetch, isFetching } = useQuery({
    queryFn: (params?: P | unknown) => queryFn(params),
    queryKey: queryKey,
    refetchOnWindowFocus: refetchOnWindowFocus,
    enabled: isEnabled,
    ...cache,
  });

  if (error) {
    logError(JSON.stringify(error));
  }

  return {
    isFetching,
    responce: data,
    isLoading,
    error,
    refetch,
  };
};
