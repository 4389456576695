import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { logError } from '../../../../../components/LogError';
import { CurrentAllocation, RegionPerformance } from '../../../types';
import { fetchCurrentAllocations } from '../services/fetchCurrentAllocations';

interface AllocationHookResponse {
  portalCashBalance: number;
  regionPerformances: RegionPerformance[];
  allocations: CurrentAllocation[];
  loading: boolean;
}

export const usePortfolioCurrentAllocation = (): AllocationHookResponse => {
  const {
    data,
    isLoading: loading,
    error,
  } = useQuery({
    queryFn: fetchCurrentAllocations,
    queryKey: ['fetchCurrentAllocations'],
  });
  const {
    portalCashBalance,
    portalPortfolioCurrentAllocation: {
      portalRegionPerformances: regionPerformances,
      portalPortfolioCurrentAllocation: allocations,
    },
  } = useMemo(() => {
    if (!data)
      return {
        portalCashBalance: 0,
        portalPortfolioCurrentAllocation: {
          portalRegionPerformances: [] as RegionPerformance[],
          portalPortfolioCurrentAllocation: [] as CurrentAllocation[],
        },
      };

    return {
      portalCashBalance: 0,
      portalPortfolioCurrentAllocation: {
        portalRegionPerformances: data.regionPerformances as RegionPerformance[],
        portalPortfolioCurrentAllocation: data.portfolioCurrentAllocation as CurrentAllocation[],
      },
    };
  }, [data]);

  if (error) {
    logError(JSON.stringify(error));
  }
  return {
    portalCashBalance,
    regionPerformances,
    allocations,
    loading,
  };
};
