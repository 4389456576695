import moment from 'moment';
import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircleSolidInfoIcon, PortfolioBalanceIcon } from '../../assets/icons';
import { Button } from '../../components';
import { ViewStateType } from '../../components/ProductTemplates/types';
import RadioButton from '../../components/RadioButton';
import { AppContext } from '../../context/ContextProvider';
import { AppEventTypes } from '../../types/AppType';
import { NavigationPath, PortfolioBalance } from '../../types/DomainTypes';
import { buildDisplayText, classNames, formatterGbp } from '../../utils';
import { usePortfolioBalances } from '../Portfolio/components/Summary/hooks/usePortfolioBalances';
import { FeedbackTemplate, MessageType } from './FeedbackTemplate';
import {
  AccountViewType,
  ModelKeys,
  ModelType,
  PaymentType,
  SubjectOptionKeys,
  TopupSlideoutViewState,
  TopupSlideoutViewType,
} from '../Accounts/types';
import BankTransferTemplate from '../Accounts/components/slideoutTemplates/BankTransferTemplate';
import OTPTopup from '../Accounts/components/slideoutTemplates/OTPTopup';
import RecurringTopup from '../Accounts/components/slideoutTemplates/RecurringTopup';
import StripeOTP from '../Accounts/components/Stripe/StripeOTP';
import StripeRecurringPayment from '../Accounts/components/Stripe/StripeRecurringPayment';
import { StripeRedirectKeys } from '../Accounts/components/Stripe/types';
import { CheckoutOtp } from '../Accounts/components/Checkout.Com/CheckoutOtp';
import MgmtPaymentTypeSelector, { RadioBtnOption } from './MgmtFeePolicyEnforcer/mgmtPaymentTypeSelector';
import { HowToPayEnum } from './HowToPay/type';

export interface TopupSlideoutRefType {
  onBack: () => void;
}

enum DisplayTextKeys {
  TOPUP_AMOUNT_TEXT = 'top_up_amount',
  PORTFOLIO_PLACEHOLDER_TEXT = 'portfolio_placeholder_text',
  PAYMENT_PLACEHOLDER_TEXT = 'payment_placeholder_text',
  SELECT_PORTFOLIO_TITLE = 'select_portfolio_title',
  PAYMENT_FREQUENCY_TITLE = 'payment_frequency_title',
  PAYMENT_METHOD_TITLE = 'payment_method_title',
  TOPUP_TEXT = 'account:text.topup',
  NEXT = 'next',
  RECURRING_PAYMENT_TEXT = 'recurring_payment_text',
  FREQUENCY_TEXT = 'frequency_text',
  START_DATE_TEXT = 'start_date_text',
  CONTACT_US = 'common:contact',
  RECURRING_PAYMENTS = 'recurring-payments',
  OTP_CREDIT_CARD = 'credit-card',
  BANK_TRANSFER = 'bank-transfer',
}

const dateFormat = 'DD MMM YYYY';

interface TopupSlideoutProps {
  onClose?: (nextView?: AccountViewType) => void;
  onStateChange?: (state: TopupSlideoutViewState) => void;
  initialModel?: ModelType;
  initialView?: TopupSlideoutViewType;
}
const defaultValue = {
  [ModelKeys.AMOUNT]: '',
  [ModelKeys.PORTFOLIO_ID]: '',
  [ModelKeys.PAYMENT_TYPE]: PaymentType.CREDIT_CARD,
  [ModelKeys.IS_RECURRING]: false,
  [ModelKeys.START_DATE]: moment().format(dateFormat),
  [ModelKeys.FREQUENCY_TYPE]: '',
};

export const TopupSlideout = forwardRef(
  (
    {
      onClose,
      onStateChange,
      initialModel = defaultValue,
      initialView = TopupSlideoutViewType.NONE,
    }: TopupSlideoutProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();

    const displayText = useMemo(
      () => buildDisplayText(Object.values(DisplayTextKeys), 'account:slideout.topup', t),
      [t],
    );
    const [feedbackState, setFeedbackState] = useState(ViewStateType.DEFAULT);

    const {
      state: {
        settings: { currency },
        app: { paymentType, isConfirmPayment, hasCard },
      },
      dispatch,
    } = useContext(AppContext);

    const [selectedOption, setSelectedOption] = useState<RadioBtnOption | null>();
    const [selectedTopupTypeId, setSelectedTopTypeId] = useState('');
    const [view, setView] = useState(initialView);

    const [model, setModel] = useState<ModelType>({ ...defaultValue, ...initialModel });
    const [feedbackTemplateConfig, setFeedbackTemplateConfig] = useState<MessageType>({
      onClick: () => null,
      content: () => null,
      footer: null,
    });

    // function closeModal() {
    //   onClose?.();
    //   setIsOpen(false);
    // }
    const onOptionSelected = (selected: RadioBtnOption) => {
      setSelectedOption(selected);
    };

    const options = useMemo((): RadioBtnOption[] => {
      const baseOptions: RadioBtnOption[] = [
        {
          id: HowToPayEnum.CARD_ON_FILE,
          label: 'Card on file',
          value: HowToPayEnum.CARD_ON_FILE,
          description: (
            <span className=" text-xs flex space-x-2">
              <span className="">Select from an existing card saved on your account.</span>
            </span>
          ),
          data: '',
        },
        {
          id: HowToPayEnum.NEW_CARD,
          label: 'New Card',
          value: HowToPayEnum.NEW_CARD,
          description: (
            <span className=" text-xs flex space-x-2">
              <span className="">Pay with a new card.</span>
            </span>
          ),
          data: '',
        },
      ];

      return hasCard ? baseOptions : [baseOptions[1]];
    }, [hasCard]);

    const onTopupTypeSelect = () => {
      let nView = TopupSlideoutViewType.NONE;
      let showBackButton = false;
      switch (selectedTopupTypeId) {
        case TopupSlideoutViewType.BANK_TRANSFER:
          nView = TopupSlideoutViewType.BANK_TRANSFER;
          showBackButton = true;
          break;
        case TopupSlideoutViewType.RECURRING_PAYMENTS:
        case TopupSlideoutViewType.OTP_CREDIT_CARD:
          nView = selectedTopupTypeId as TopupSlideoutViewType;
          showBackButton = true;
          break;
        default:
          break;
      }
      setView(nView);
      if (onStateChange)
        onStateChange({
          showBackButton,
          view: nView,
        });
    };
    const onViewStateChange = (change: TopupSlideoutViewState) => {
      setModel({ ...model, ...(change.model || {}) });
      setView(change.view);
      if (onStateChange) onStateChange(change);
    };

    const onCTA = (requestView: ViewStateType) => {
      switch (requestView) {
        case ViewStateType.ERROR_RESULT:
          setView(TopupSlideoutViewType.ERROR);
          setFeedbackTemplateConfig({
            footer: (
              <div className="flex flex-col w-full">
                <Button
                  className="btn-outline mt-5"
                  onClick={() => {
                    onCTA(ViewStateType.CONTACT_US);
                  }}
                  props={{
                    name: displayText[DisplayTextKeys.CONTACT_US],
                  }}
                >
                  {displayText[DisplayTextKeys.CONTACT_US]}
                </Button>
              </div>
            ),
          });
          setFeedbackState(ViewStateType.ERROR_RESULT);
          if (onStateChange)
            onStateChange({
              showBackButton: false,
              view: TopupSlideoutViewType.ERROR,
            });
          break;

        case ViewStateType.PAYMENT_SUCCESS:
          setView(TopupSlideoutViewType.NONE);
          setFeedbackTemplateConfig({
            onClick: () => {
              if (onClose) onClose();
            },

            subTitle: (
              <div className="text-14 mt-2">
                {t`paymentSuccess.subTitle${
                  paymentType === TopupSlideoutViewType.STRIPE_RECURRING_PAYMENT ? '_recurring' : ''
                }`.trim()}
              </div>
            ),
          });
          setFeedbackState(requestView);
          dispatch({ type: AppEventTypes.UPDATE_STATE, payload: { isConfirmPayment: false } });
          break;

        case ViewStateType.CONTACT_US:
          if (onClose) onClose(AccountViewType.CONTACT_US);
          navigate(NavigationPath.ACCOUNTS, {
            state: { accountViewType: AccountViewType.CONTACT_US, subject: SubjectOptionKeys.FINANCIAL },
          });
          break;

        default:
          setView(TopupSlideoutViewType.NONE);
          setFeedbackState(requestView);
          break;
      }
    };

    const onBack = () => {
      let showBackButton = true;
      const viewUpdate = TopupSlideoutViewType.NONE;
      switch (view) {
        case TopupSlideoutViewType.BANK_TRANSFER:
          showBackButton = false;
          break;
        case TopupSlideoutViewType.OTP_CREDIT_CARD:
          showBackButton = false;
          break;

        default:
          showBackButton = false;
          break;
      }

      setView(viewUpdate);
      if (onStateChange)
        onStateChange({
          showBackButton,
          view: viewUpdate,
        });
    };

    const { portfolioBalances } = usePortfolioBalances();

    const feeModelInfo = useMemo(() => {
      const feeModels = portfolioBalances.filter((x: PortfolioBalance) => `${x.portfolioId}`.length > 0);
      const otherFeeModelExist = !!feeModels.find((x) => x.currentFeeModel === false);

      const canTopUp = feeModels.filter(
        (x: PortfolioBalance) => `${x.portfolioId}`.length > 0 && !!x.currentFeeModel && x.currentFeeModel === true,
      );
      return {
        showFeeModelInfo: otherFeeModelExist,
        canTopUp: canTopUp && canTopUp.length > 0,
      };
    }, [portfolioBalances]);

    useImperativeHandle(ref, () => ({
      onBack,
    }));

    const onConfirmPayment = (queryParams: URLSearchParams) => {
      let paymentInfo: Record<string, string> = {};
      for (const [key, value] of queryParams) {
        paymentInfo = { ...paymentInfo, [key]: value };
      }

      if (
        paymentInfo[StripeRedirectKeys.REDIRECT_STATUS].length > 0 &&
        paymentInfo[StripeRedirectKeys.CLIENT_SECRET].length > 0 &&
        paymentInfo[StripeRedirectKeys.REDIRECT_STATUS] === 'succeeded'
      ) {
        onCTA(ViewStateType.PAYMENT_SUCCESS);
        dispatch({
          type: AppEventTypes.UPDATE_STATE,
          payload: {
            isConfirmPayment: false,
          },
        });
      }
      const rootUrl = window.location.pathname.replace(NavigationPath.PAYMENT_CONFIRMATION, '');
      window.history.replaceState(null, '', rootUrl);
    };

    useEffect(() => {
      if (isConfirmPayment && pathname.endsWith(NavigationPath.PAYMENT_CONFIRMATION)) {
        onConfirmPayment(new URLSearchParams(search));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConfirmPayment]);

    return (
      <div className="flex flex-col flex-1 bg-gradient-to-b from-gray-100 to-gray-500 pb-5 px-3 w-screen relative overflow-x-hidden sm:w-[390px]">
        <div className="w-full h-full rounded-md overflow-y-auto flex flex-col">
          <FeedbackTemplate
            onClose={onClose}
            templateConfig={feedbackTemplateConfig}
            viewState={feedbackState}
            onCTA={onCTA}
          >
            <div className="flex flex-col bg-white divide-y divide-gray-200 h-full w-full">
              {view === TopupSlideoutViewType.NONE && (
                <div className=" w-full h-full p-5 flex flex-col">
                  <div className="flex flex-col mt-5 gap-5 flex-1">
                    {[
                      TopupSlideoutViewType.OTP_CREDIT_CARD,
                      TopupSlideoutViewType.RECURRING_PAYMENTS,
                      TopupSlideoutViewType.BANK_TRANSFER,
                    ].map((topType) => {
                      const isSelected = selectedTopupTypeId === topType;
                      return (
                        <div
                          onClick={() => setSelectedTopTypeId(topType)}
                          key={`topupType-${topType}`}
                          className="bg-gray-100 cursor-pointer flex items-center self-stretch p-5 gap-5 w-full h-[112px] rounded-md"
                        >
                          <div className="flex flex-1 ">{displayText[topType]}</div>
                          <RadioButton
                            id={`${topType}`}
                            name="topup-types"
                            isChecked={isSelected}
                            isDisabled={!feeModelInfo.canTopUp}
                          />
                        </div>
                      );
                    })}
                    {/* <button onClick={() => setView(TopupSlideoutViewType.CHECKOUT_COM)}>checkout.com</button> */}
                  </div>
                  {feeModelInfo.showFeeModelInfo && (
                    <>
                      <div className="flex items-center w-full mt-8 mb-4">
                        <div className="mr-5">{t`account:slideout.topup.recurring_payment_info_title`}</div>
                        <CircleSolidInfoIcon />
                      </div>
                      <p className="text-sm w-full flex-1 h-full overflow-x-hidden">
                        {t(`account:slideout.topup.current_fee_model_info`)}
                      </p>
                    </>
                  )}

                  {feeModelInfo.canTopUp && (
                    <Button
                      isDisable={selectedTopupTypeId === ''}
                      className={classNames('mt-5', selectedTopupTypeId === '' ? 'btn-disabled' : 'btn-accent')}
                      onClick={onTopupTypeSelect}
                      props={{
                        name: DisplayTextKeys.NEXT,
                      }}
                    >
                      {displayText[DisplayTextKeys.NEXT]}
                    </Button>
                  )}

                  {!feeModelInfo.canTopUp && (
                    <Button
                      className="mt-5 btn-accent"
                      onClick={() => onCTA(ViewStateType.CONTACT_US)}
                      props={{
                        name: DisplayTextKeys.CONTACT_US,
                      }}
                    >
                      {displayText[DisplayTextKeys.CONTACT_US]}
                    </Button>
                  )}
                </div>
              )}

              {view === TopupSlideoutViewType.RECURRING_PAYMENTS && (
                <RecurringTopup initialModel={model} onStateChange={onViewStateChange} />
              )}
              {view === TopupSlideoutViewType.OTP_CREDIT_CARD && (
                <OTPTopup initialModel={model} onStateChange={onViewStateChange} />
              )}
              {view === TopupSlideoutViewType.BANK_TRANSFER && <BankTransferTemplate />}
              {view === TopupSlideoutViewType.STRIPE_RECURRING_PAYMENT && (
                <StripeRecurringPayment
                  model={model}
                  onCTA={onCTA}
                  request={{
                    currency,
                    priceId: '',
                    portfolioId: Number(model[ModelKeys.PORTFOLIO_ID]),
                    defaultPaymentMethod: null,
                    amount: Number(model[ModelKeys.AMOUNT]),
                    frequency: model[ModelKeys.FREQUENCY_TYPE],
                  }}
                />
              )}
              {view === TopupSlideoutViewType.STRIPE_OTP_PAYMENT && (
                <StripeOTP
                  model={model}
                  onCTA={onCTA}
                  request={{
                    amount: Number(model[ModelKeys.AMOUNT]),
                    portfolioId: Number(model[ModelKeys.PORTFOLIO_ID]),
                  }}
                />
              )}
              {view === TopupSlideoutViewType.CHECKOUT_OTP_PAYMENT && (
                <>
                  <div className="flex items-center flex-col p-5 gap-2">
                    <div className="w-[32px] h-[26.18px]">
                      <PortfolioBalanceIcon className="mr-2 bg-gray-200 " />
                    </div>
                    <span className="text-14">{t('account:slideout.topup.top_up_amount')}</span>
                    <span className="text-center text-lg border-none">{`${formatterGbp.format(
                      Number(model[ModelKeys.AMOUNT]),
                    )}`}</span>
                  </div>
                  <MgmtPaymentTypeSelector
                    onSelected={onOptionSelected}
                    options={options}
                    selectedId={selectedOption?.id}
                  />

                  <CheckoutOtp
                    showTopUpIcon={false}
                    isUseExistingCard={selectedOption?.id === (HowToPayEnum.CARD_ON_FILE as string)}
                    amount={Number(model[ModelKeys.AMOUNT])}
                    portfolioId={Number(model[ModelKeys.PORTFOLIO_ID])}
                  />
                </>
              )}
            </div>
          </FeedbackTemplate>
        </div>
      </div>
    );
  },
);
