import { useMemo } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from '@tanstack/react-query';
import { logError } from '../../../../../components/LogError';
import { PortfolioBalance } from '../../../../../types/DomainTypes';
import { PortfolioBalancesResponse, fetchPortfolioBalances } from '../services/fetchPortfolioBalances';

interface UsePortfolioBalancesReturnHookResponse {
  portfolioBalances: PortfolioBalance[];
  loading: boolean;
  error: unknown;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<PortfolioBalancesResponse, unknown>>;
}

export const usePortfolioBalances = (): UsePortfolioBalancesReturnHookResponse => {
  const {
    data,
    isLoading: loading,
    error,
    refetch,
  } = useQuery({
    queryFn: fetchPortfolioBalances,
    queryKey: ['fetchPortfolioBalances'],
  });

  const portfolioBalances = useMemo(
    () =>
      (data?.data || []).map((x: PortfolioBalance) => ({
        ...x,
        portfolioId: x.portfolioId || '',
      })),
    [data],
  );

  if (error) {
    logError(JSON.stringify(error));
  }
  return {
    refetch,
    portfolioBalances,
    loading,
    error,
  };
};
