import { ProtectedPageContainer } from 'components/ProtectedPageContainer';
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import RenderApp from 'views/OrderReport/RenderApp';
import { ViewKeys } from 'views/Portfolio/types';
import Trade from 'views/Trade';
import { isLoggedInVar } from '../graphql/cache';
import { NavigationPath } from '../types/DomainTypes';
import Accounts from '../views/Accounts';
import { Login } from '../views/Authentication';
import ResetPassword from '../views/Authentication/ResetPassword';
import RegistrationForm from '../views/Authentication/components/RegistrationForm';
import { Error as CheckoutError, Success as CheckoutSuccess } from '../views/Checkout';
import Discover from '../views/Discover';
import Invest from '../views/Invest';
import MyCellar from '../views/MyCellar';
import { Notifications } from '../views/Notifications';
import Portfolio from '../views/Portfolio';
import VintradeMapper from '../views/VintradeMapper';
import NoRoute from './NoRoute';
import ProtectedRoute from './ProtectedRoute';

const VINTRADE_MAP_PATH = '/maptovintrade';

const publicPaths = [
  NavigationPath.LOGIN,
  NavigationPath.REGISTRATION,
  NavigationPath.FORGET_PASSWORD,
  VINTRADE_MAP_PATH,
  NavigationPath.CHECKOUT_SUCCESS,
  NavigationPath.CHECKOUT_FAIL,
  NavigationPath.PROFORMAREPORT,
];

const Routing = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isLogin = isLoggedInVar();

  useEffect(() => {
    if (!isLogin && !publicPaths.includes(pathname)) {
      navigate(NavigationPath.LOGIN);
    }

    // if (isLogin && publicPaths.includes(pathname)) {
    //   signOut();
    //   isLoggedInVar(false);
    //   navigate(NavigationPath.LOGIN);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isLogin]);

  return (
    <Routes>
      <Route path={NavigationPath.LOGIN} element={<Login />} />
      <Route path={NavigationPath.FORGET_PASSWORD} element={<ResetPassword />} />
      <Route path={NavigationPath.REGISTRATION} element={<RegistrationForm />} />
      <Route path={VINTRADE_MAP_PATH} element={<VintradeMapper />} />
      <Route path={NavigationPath.PROFORMAREPORT} element={<RenderApp />} />
      <Route path={NavigationPath.CHECKOUT_SUCCESS} element={<CheckoutSuccess />} />
      <Route path={NavigationPath.CHECKOUT_FAIL} element={<CheckoutError />} />

      <Route
        element={
          <ProtectedRoute>
            <ProtectedPageContainer />
          </ProtectedRoute>
        }
      >
        <Route path={NavigationPath.PORTFOLIO} element={<Portfolio />} />
        <Route path={NavigationPath.MY_PORTFOLIO} element={<Portfolio viewKeyIn={ViewKeys.DETIALS} />} />
        <Route path={`${NavigationPath.PORTFOLIO}${NavigationPath.PAYMENT_CONFIRMATION}/*`} element={<Portfolio />} />

        <Route path={NavigationPath.INVEST} element={<Invest />} />
        <Route path={`${NavigationPath.INVEST}${NavigationPath.PAYMENT_CONFIRMATION}/*`} element={<Invest />} />

        <Route path={NavigationPath.MY_CELLAR} element={<MyCellar />} />
        <Route path={`${NavigationPath.MY_CELLAR}${NavigationPath.PAYMENT_CONFIRMATION}/*`} element={<MyCellar />} />

        <Route path={NavigationPath.ACCOUNTS} element={<Accounts />} />
        <Route path={`${NavigationPath.ACCOUNTS}${NavigationPath.PAYMENT_CONFIRMATION}/*`} element={<Accounts />} />

        <Route path={NavigationPath.DISCOVER} element={<Discover />} />
        <Route path={`${NavigationPath.DISCOVER}${NavigationPath.PAYMENT_CONFIRMATION}/*`} element={<Discover />} />
        <Route path={NavigationPath.TRADE} element={<Trade />} />
        <Route path={NavigationPath.NOTIFICATIONS} element={<Notifications />} />
        <Route
          path={`${NavigationPath.NOTIFICATIONS}${NavigationPath.PAYMENT_CONFIRMATION}/*`}
          element={<Notifications />}
        />
      </Route>
      <Route path="*" element={<NoRoute />} />
    </Routes>
  );
};

export default Routing;
