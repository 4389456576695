import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { campaignPurchaseRefType } from 'views/Invest/types';
import { TradePurchaseRefType } from 'views/Trade/types';
import { fetchCashBalance } from '../services/fetchCashBalance';

interface UseFetchCashBalanceResponce {
  isBalanceChanged: boolean;
}

export const useFetchCashBalanceUtillChange = (
  purchaseRef?: campaignPurchaseRefType | TradePurchaseRefType | null,
): UseFetchCashBalanceResponce => {
  const { data } = useQuery({
    queryFn: fetchCashBalance,
    queryKey: [`fetchCashBal${JSON.stringify(purchaseRef)}`],
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchInterval(query) {
      if (!purchaseRef) return false;
      if (query?.balances[0].balance !== undefined && query?.balances[0].balance !== purchaseRef?.prevAcctBalance) {
        return false;
      }
      return 3000;
    },
  });

  const isBalanceChanged = useMemo(() => {
    if (!purchaseRef) return false;
    const prevBalance = purchaseRef?.prevAcctBalance;
    const currentBalance = data?.balances[0].balance;
    if (currentBalance !== undefined && currentBalance !== prevBalance) return true;
    return false;
  }, [data?.balances, purchaseRef]);

  return {
    isBalanceChanged,
  };
};
