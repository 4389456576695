import React, { FC, Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { capitalizeFirstLetter, classNames } from 'utils';
import { AppContext } from 'context/ContextProvider';
import { useTranslation } from 'react-i18next';
import MgmtPaymentTypeSelector, { RadioBtnOption } from './mgmtPaymentTypeSelector';
import { EstimatedManagementFeeResponse, MgmtFeeTypeEnum } from '../types';

interface MgmFeePolicyEnforcerProps {
  show: boolean;
  onClose?: () => void;
  onSubmit?: (data: unknown) => void;
  forceAction?: boolean;
  estimatedFeeAmount?: EstimatedManagementFeeResponse;
}

const MgmFeePolicyEnforcer: FC<MgmFeePolicyEnforcerProps> = ({
  show = false,
  onClose,
  onSubmit,
  forceAction = false,
  estimatedFeeAmount,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<RadioBtnOption | null>();
  const { formatter: currencyFormatter } = useContext(AppContext);
  function closeModal() {
    if (!forceAction) {
      onClose?.();
      setIsOpen(false);
    }
  }

  const onOptionSelected = (selected: RadioBtnOption) => {
    setSelectedOption(selected);
  };

  const onSubmitPreference = () => {
    setIsOpen(false);
    onSubmit?.(selectedOption?.value);
  };

  const mgmtFeePaymentPlanOptions = useMemo(() => {
    const { monthlyFeeAmount, annualFeeAmount } = estimatedFeeAmount as EstimatedManagementFeeResponse;

    // const { monthlyFeeAmount, annualFeeAmount } = estimatedFeeAmount as EstimatedManagementFeeResponse;
    const feePaymentPlanOptions = [
      {
        id: MgmtFeeTypeEnum.MONTHLY,
        label: capitalizeFirstLetter(MgmtFeeTypeEnum.MONTHLY),
        value: MgmtFeeTypeEnum.MONTHLY,
        data: 0,
        description: (
          <span className=" text-sm space-y-2  flex flex-col">
            <span> {t('account:mgmtFeePreference.optionModal.payMonthtly')}</span>
            <span className="flex space-x-2">{`${currencyFormatter.format(monthlyFeeAmount)} per month`}</span>
            <span className="flex space-x-2 italic text-xs">
              {t('account:mgmtFeePreference.optionModal.payMonthtlyExplainer')}
            </span>
          </span>
        ),
      },
      {
        id: MgmtFeeTypeEnum.YEARLY,
        label: 'Annually',
        value: MgmtFeeTypeEnum.YEARLY,
        data: annualFeeAmount,
        description: (
          <span className="flex flex-col  space-y-2">
            <span className="mr-1">{t('account:mgmtFeePreference.optionModal.payAnnually')}</span>
            <span className="font-medium ">{currencyFormatter.format(annualFeeAmount, true)}</span>
            <span className="flex space-x-2 italic text-xs">
              {t('account:mgmtFeePreference.optionModal.payAnnuallyExplainer')}
            </span>
          </span>
        ),
      },
    ] as RadioBtnOption[];

    return feePaymentPlanOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatedFeeAmount]);

  useEffect(() => {
    if (show !== isOpen) setIsOpen(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-20 font-medium leading-6 text-gray-900">
                  {t('account:mgmtFeePreference.optionModal.title')}
                </Dialog.Title>

                <div className="mt-2">
                  <p className="text-base text-gray-800 mb-2">
                    {t('account:mgmtFeePreference.optionModal.subheading')}
                  </p>
                  <p className="text-sm text-gray-500">{t('account:mgmtFeePreference.optionModal.subTitle')}</p>
                  <p className="text-sm text-gray-500 italic mt-3">
                    {t('account:mgmtFeePreference.optionModal.notes')}
                  </p>
                </div>

                <MgmtPaymentTypeSelector onSelected={onOptionSelected} options={mgmtFeePaymentPlanOptions} />
                <span className="text-sm text-gray-800">
                  {`${t('account:feePolicyEnforcerTemplate.common.totalDueToday')} ${currencyFormatter.format(
                    Number(selectedOption?.data) || 0,
                    true,
                  )}`}
                </span>
                {selectedOption?.value === MgmtFeeTypeEnum.MONTHLY && (
                  <p className="text-sm text-gray-500 mt-2 ">
                    {t('account:feePolicyEnforcerTemplate.mgmtFee&AccruedFee.note2')}
                  </p>
                )}
                <div className="mt-2  flex w-full justify-end">
                  <button
                    type="button"
                    className={classNames(
                      'inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium',
                      selectedOption ? 'bg-orange' : 'btn-disabled',
                    )}
                    onClick={() => onSubmitPreference()}
                  >
                    {t('account:mgmtFeePreference.optionModal.btnText')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MgmFeePolicyEnforcer;
