import { useEffect, FC, useMemo, useContext } from 'react';
import { StripePaymentForm } from './StripePaymentForm';
import Loading from '../../../../components/Loading/loading';
import { ViewStateType } from '../../../../components/ProductTemplates/types';
import { logError } from '../../../../components/LogError';
import StripeContainer from '.';
import { ModelType } from '../../types';
import {
  CreateStripeRecurringPaymentMutationResponse,
  useCreateStripeRecurringPaymentMutation,
} from '../../hooks/useCreateStripeRecurringPaymentMutation';
import { stripeThemeAppearance } from './helpers';
import { useGetRecurringPrices } from '../../hooks/useGetRecurringPrices';
import { AppContext } from '../../../../context/ContextProvider';

interface StripeRecurringPaymentProps {
  request: Parameters<CreateStripeRecurringPaymentMutationResponse['execute']>[0];
  onCTA?: (viewState: ViewStateType) => void;
  model: ModelType;
}

const StripeRecurringPayment: FC<StripeRecurringPaymentProps> = ({ request, onCTA, model }) => {
  const {
    state: {
      miscellaneous: { frequencyIntervalMap },
    },
  } = useContext(AppContext);
  const { execute, error, loading, data } = useCreateStripeRecurringPaymentMutation();
  const { recurringPaymentPrices, loading: loadingPriceProducts } = useGetRecurringPrices('GBP');

  const stripeResponse = useMemo(() => {
    if (!data) return { paymentIntentId: '', options: null };
    const { clientSecret, id } = data.createStripeRecurringPayment;
    return {
      paymentIntentId: id,
      options: {
        clientSecret,
        appearance: { ...stripeThemeAppearance },
      } as const,
    };
  }, [data]);

  useEffect(() => {
    if (!loadingPriceProducts) {
      const { amount, paymentFrequency } = model;
      const priceId = (
        recurringPaymentPrices.find(
          (x) => `${x.unitAmount}` === `${amount}` && `${paymentFrequency}` === `${x.recurringInterval}`,
        ) || { id: '' }
      ).id;

      execute({
        ...request,
        priceId,
        frequency: (frequencyIntervalMap as Map<string, string>).get(paymentFrequency) || paymentFrequency,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurringPaymentPrices]);

  const onError = (err: unknown) => {
    logError(err as Error);
    if (onCTA) {
      onCTA(ViewStateType.ERROR_RESULT);
    }
  };

  useEffect(() => {
    if (error) {
      onError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <div>
      {loading && <Loading containerClassName="mt-10" />}
      {!loading && stripeResponse.options && stripeResponse.options.clientSecret && (
        <StripeContainer options={stripeResponse.options}>
          <StripePaymentForm onError={onError} model={model} />
        </StripeContainer>
      )}
    </div>
  );
};

export default StripeRecurringPayment;
