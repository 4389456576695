import { httpGet } from 'utils';
import { PortalDocumentParams, PortalDocumentResult, PortalDocumentTypeEnum } from 'types/serviceTypes';
import { promises } from 'dns';

export const fetchPortalDocument = (params?: PortalDocumentParams): Promise<PortalDocumentResult> => {
  // if (params?.docType === PortalDocumentTypeEnum.TRANSACTIONS) {
  //   return Promise.resolve({
  //     count: 2,
  //     data: [
  //       {
  //         acct_holder_id: 'AH123456',
  //         client_id: 'C987654',
  //         client_name: 'John Doe',
  //         wine_lwin_code: 'WLC098765',
  //         vintage: '2015',
  //         wine_name: 'Chateau Margaux',
  //         region: 'Bordeaux',
  //         unit_size: '750ml',
  //         nb_of_units: '12',
  //         actual_Amt: '1200.00',
  //         transaction_date: '2023-05-15',
  //       },
  //       {
  //         acct_holder_id: 'AH234567',
  //         client_id: 'C876543',
  //         client_name: 'Jane Smith',
  //         wine_lwin_code: 'WLC123456',
  //         vintage: '2018',
  //         wine_name: 'Penfolds Grange',
  //         region: 'Barossa Valley',
  //         unit_size: '750ml',
  //         nb_of_units: '6',
  //         actual_Amt: '1800.00',
  //         transaction_date: '2023-06-20',
  //       },
  //       {
  //         acct_holder_id: 'AH345678',
  //         client_id: 'C765432',
  //         client_name: 'Alice Johnson',
  //         wine_lwin_code: 'WLC234567',
  //         vintage: '2016',
  //         wine_name: 'Screaming Eagle',
  //         region: 'Napa Valley',
  //         unit_size: '750ml',
  //         nb_of_units: '3',
  //         actual_Amt: '7500.00',
  //         transaction_date: '2023-07-10',
  //       },
  //       {
  //         acct_holder_id: 'AH123456',
  //         client_id: 'C987654',
  //         client_name: 'John Doe',
  //         wine_lwin_code: 'WLC098765',
  //         vintage: '2015',
  //         wine_name: 'Chateau Margaux',
  //         region: 'Bordeaux',
  //         unit_size: '750ml',
  //         nb_of_units: '12',
  //         actual_Amt: '1200.00',
  //         transaction_date: '2023-05-15',
  //       },
  //       {
  //         acct_holder_id: 'AH234567',
  //         client_id: 'C876543',
  //         client_name: 'Jane Smith',
  //         wine_lwin_code: 'WLC123456',
  //         vintage: '2018',
  //         wine_name: 'Penfolds Grange',
  //         region: 'Barossa Valley',
  //         unit_size: '750ml',
  //         nb_of_units: '6',
  //         actual_Amt: '1800.00',
  //         transaction_date: '2023-06-20',
  //       },
  //       {
  //         acct_holder_id: 'AH345678',
  //         client_id: 'C765432',
  //         client_name: 'Alice Johnson',
  //         wine_lwin_code: 'WLC234567',
  //         vintage: '2016',
  //         wine_name: 'Screaming Eagle',
  //         region: 'Napa Valley123',
  //         unit_size: '750ml',
  //         nb_of_units: '3',
  //         actual_Amt: '7500.00',
  //         transaction_date: '2023-07-10',
  //       },
  //     ],
  //   });
  // }
  const buildQueryParams = () => {
    let queryParams = '';
    if (params?.docRef) {
      queryParams += `&docRef=${params?.docRef}`;
    }
    if (params?.isDetail) {
      queryParams += `&isDetail=${params?.isDetail}`;
    }
    return queryParams;
  };

  return httpGet<PortalDocumentResult>(`portal/PortalDocument?docType=${params?.docType}${buildQueryParams()}`);
};
