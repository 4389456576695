import { RequiredStringSchema } from 'yup/lib/string';

export type ModelValidationSchemaType = Record<
  string,
  RequiredStringSchema<string | undefined, Record<string, unknown>>
>;

export enum LoginModelProp {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export interface MfaRequestProps {
  mfaToken: string;
  mfaOOBCode: string;
  emailAddress: string;
  password: string;
}
