import React, { FC, Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CloseBlackIcon } from 'assets/icons';

interface MgmtPreferenceConfirmationProps {
  show: boolean;
  onClose?: () => void;
  body: ReactNode;
  footer?: ReactNode;
}

const PreferenceConfirmation: FC<MgmtPreferenceConfirmationProps> = ({ show = false, onClose, body, footer }) => {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    onClose?.();
    setIsOpen(false);
  }

  const BodyText = useMemo(() => {
    return () => body ?? null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Footer = useMemo(() => {
    return () => footer ?? null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (show !== isOpen) setIsOpen(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  {onClose && (
                    <div className="flex justify-end">
                      <CloseBlackIcon className="right-5 cursor-pointer text-14 z-10  top-4" onClick={closeModal} />
                    </div>
                  )}
                </Dialog.Title>
                <div className="text-14">{BodyText()}</div>
                {footer && <div className="mt-4">{Footer()}</div>}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PreferenceConfirmation;
