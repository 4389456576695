import { Button } from 'components';
import Loading from 'components/Loading/loading';
import Table, { TableColumnType } from 'components/Table';
import { AppContext } from 'context/ContextProvider';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { buildTableRow } from 'views/Portfolio/helpers';
import {
  InitialiseMarketTable,
  InitialiseMyOffersTable,
  generateRowsFormat,
  subTabsBidsAndOffers,
} from 'views/Trade/helpers';
import { useSilentFetch } from 'views/Trade/hooks/useSilentFetch';
import { fetchOffers } from 'views/Trade/services/fetchOffers';
import { fetchPortfolioOffers } from 'views/Trade/services/fetchPortfolioOffers';
import { fetchPortfolioStocks } from 'views/Trade/services/fetchPortfolioStocks';
import {
  DisplayTextKeys,
  NoResultsTextKeys,
  OffersType,
  PortfolioOffersType,
  QUERY_KEYS,
  SUB_TABS_ENUMS,
  SetSlideoutConfigType,
  SlideoutConfigType,
  TABLE_ACTIONS,
} from 'views/Trade/types';
import MessageTemplate from 'components/ProductTemplates/components/MessageTemplate';
import { buildDisplayText } from 'utils';
import ExpandingSearch from 'components/ExpandingSearch';
import { useFetch } from 'views/Trade/hooks/useFetch';
import noResultImage from '../../../../assets/images/no_results_image.png';

interface BidsAndOffersParams {
  slideOutConfig: SlideoutConfigType;
  setSlideOutConfig: SetSlideoutConfigType;
}

const BidsAndOffers = ({ slideOutConfig, setSlideOutConfig }: BidsAndOffersParams) => {
  const {
    state: {
      settings: { accountInfo },
    },
    gbpToTargetCurrency,
  } = useContext(AppContext);

  const { t } = useTranslation();

  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'trade:common', t), [t]);

  const [searchText, setSearchText] = useState<string>('');
  const [currentSubTab, setCurrentSubTab] = useState<string>(SUB_TABS_ENUMS.MARKET);
  const [resultCount, setResultCount] = useState<number>(0);
  const PAGE_SIZE = 500;

  const fetchPortfolioStocksSetup = useMemo(() => {
    return {
      pageSize: PAGE_SIZE,
      queryFn: fetchPortfolioStocks,
      params: {},
      queryKey: QUERY_KEYS.FETCH_PORTFOLIO_STOCK,
      timeOutBetweenFetch: 4000,
    };
  }, []);

  const fetchPortfolioOffersSetup = useMemo(() => {
    return {
      pageSize: PAGE_SIZE,
      queryFn: fetchPortfolioOffers,
      params: {},
      queryKey: QUERY_KEYS.FETCH_PORTFOLIO_OFFERS,
      timeOutBetweenFetch: 3000,
    };
  }, []);

  const fetchOffersSetup = useMemo(() => {
    return {
      pageSize: PAGE_SIZE,
      queryFn: fetchOffers,
      params: {},
      queryKey: QUERY_KEYS.FETCH_OFFERS,
      timeOutBetweenFetch: 2000,
    };
  }, []);

  const {
    refetch: refreshPortfolioStocks,
    isBackgroundFetchActive: isAllPortfolioStocks,
    response: portfolioStocks,
    initIsLoading: isLoadingPortfolioStocks,
  } = useSilentFetch(fetchPortfolioStocksSetup);
  const {
    refetch: refreshPortfolioOffers,
    response: portfolioOffers,
    initIsLoading: isLoadingPortfolioOffers,
  } = useSilentFetch(fetchPortfolioOffersSetup);
  const {
    refetch: refreshMarketOffers,
    isBackgroundFetchActive: isAllMarketOffers,
    response: marketOffers,
    initIsLoading: isLoadingOffers,
  } = useSilentFetch(fetchOffersSetup);

  const {
    isLoading: loadingMarketSearch,
    responce: marketSearchData,
    // refetch: refetchMarketSearchData,
  } = useFetch({
    refetchOnWindowFocus: false,
    shouldCache: false,
    isEnabled: searchText.length > 0 && currentSubTab === SUB_TABS_ENUMS.MARKET,
    queryFn: () => fetchOffers({ searchText: searchText, page: 0, pageSize: PAGE_SIZE }),
    queryKey: [QUERY_KEYS.FETCH_OFFERS_SEARCH, searchText],
  });

  const {
    isLoading: loadingPortfolioOfferSearch,
    responce: portfolioOfferSearchData,
    // refetch: refetchPortfolioOfferSearch,
  } = useFetch({
    refetchOnWindowFocus: false,
    shouldCache: false,
    isEnabled: searchText.length > 0 && currentSubTab === SUB_TABS_ENUMS.MY_OFFERS,
    queryFn: () => fetchPortfolioOffers({ search: searchText, page: 0, pageSize: PAGE_SIZE }),
    queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_OFFERS_SEARCH, searchText],
  });

  const searchMarketOffers = useMemo(() => {
    if (!marketSearchData?.data || loadingMarketSearch) return [];
    return marketSearchData?.data || [];
  }, [marketSearchData?.data, loadingMarketSearch]);

  const searchPortfolioOffer = useMemo(() => {
    if (!portfolioOfferSearchData?.data || loadingPortfolioOfferSearch) return [];
    return portfolioOfferSearchData?.data || [];
  }, [portfolioOfferSearchData?.data, loadingPortfolioOfferSearch]);

  const refetchAll = useCallback(() => {
    refreshPortfolioOffers();
    refreshPortfolioStocks();
    refreshMarketOffers();
  }, [refreshPortfolioOffers, refreshPortfolioStocks, refreshMarketOffers]);

  // useEffect(() => {
  //   if (isAllMarketOffers) {
  //     refetchMarketSearchData();
  //     refetchPortfolioOfferSearch();
  //   }
  // }, [refetchMarketSearchData, refetchPortfolioOfferSearch, isAllMarketOffers]);

  const { marketColumns } = useMemo(
    () => InitialiseMarketTable(t, slideOutConfig, setSlideOutConfig, gbpToTargetCurrency),

    [slideOutConfig, setSlideOutConfig, t, gbpToTargetCurrency],
  );
  const { myOffersColumns } = useMemo(
    () => InitialiseMyOffersTable(t, slideOutConfig, setSlideOutConfig, gbpToTargetCurrency),

    [slideOutConfig, setSlideOutConfig, t, gbpToTargetCurrency],
  );

  const tableBuilder = useMemo(() => {
    const fxRate = accountInfo?.account.fxRate || 1;
    const isSearch = searchText.length > 0;
    let storeOffers: OffersType[] = [];
    let storePortfolioOffers: PortfolioOffersType[] = [];

    if (isSearch && currentSubTab === SUB_TABS_ENUMS.MARKET) {
      storeOffers = searchMarketOffers;
    } else {
      storeOffers = marketOffers;
    }

    if (isSearch && currentSubTab === SUB_TABS_ENUMS.MY_OFFERS) {
      storePortfolioOffers = searchPortfolioOffer;
    } else {
      storePortfolioOffers = portfolioOffers;
    }

    const source = generateRowsFormat({
      fxRate,
      currentSubTab,
      offers: storeOffers,
      portfolioOffers: storePortfolioOffers,
      portfolioStocks: portfolioStocks,
    });

    let columns: TableColumnType[] = [];
    let isLoading = true;

    if (currentSubTab === SUB_TABS_ENUMS.MARKET) {
      columns = marketColumns;
      isLoading =
        (!isSearch && isLoadingOffers) || (!isSearch && isLoadingPortfolioStocks) || (loadingMarketSearch && isSearch);
    } else {
      columns = myOffersColumns;
      isLoading = (!isSearch && isLoadingPortfolioOffers) || (loadingPortfolioOfferSearch && isSearch);
    }

    setResultCount(source?.length || 0);
    const rows = (source || []).map((data) => {
      return {
        ...buildTableRow(
          {
            ...data,
          },
          columns,
        ),
      };
    });
    return { rows, columns, isLoading };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    portfolioStocks,
    isLoadingPortfolioStocks,
    portfolioOffers,
    isLoadingPortfolioOffers,
    marketOffers,
    isLoadingOffers,
    currentSubTab,
    accountInfo?.account,
    searchText,
    marketSearchData,
    loadingMarketSearch,
    searchPortfolioOffer,
    loadingPortfolioOfferSearch,
  ]);

  const onSearch = (search: string) => {
    setSearchText(search);
  };

  return (
    <div className="flex flex-1 sm:p-5 bg-white w-full flex-col">
      <div className="flex items-center gap-1 mb-4 justify-between ">
        <div className="flex items-center gap-1">
          <div className="flex gap-2 items-center">
            <div className="flex  items-center text-sm border rounded-full overflow-hidden ">
              {subTabsBidsAndOffers.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setCurrentSubTab(tab.tabName)}
                  className={`py-1 px-4 border-r ${currentSubTab === tab.tabName ? 'bg-vine text-white' : null}`}
                >
                  {t(`trade:common.${tab.tabName}`)}
                </button>
              ))}
            </div>
          </div>
          <div className="text-sm  flex items-center gap-1">
            {currentSubTab === SUB_TABS_ENUMS.MARKET ? (
              <>
                {(!searchText && isAllMarketOffers) ||
                (!searchText && isAllPortfolioStocks) ||
                (loadingMarketSearch && searchText) ? (
                  <div className="flex items-center gap-2">
                    <p>{displayText[DisplayTextKeys.LOADING]}</p>
                    <Loading />
                  </div>
                ) : (
                  <>
                    <p>{displayText[DisplayTextKeys.LIVE]}</p> <div className="w-2 h-2 bg-red rounded-full" />{' '}
                    <p>{`${displayText[DisplayTextKeys.RESULT]} ${resultCount}`}</p>
                  </>
                )}
              </>
            ) : (
              <div className="flex flex-row items-center">
                <p>{`${displayText[DisplayTextKeys.RESULT]} ${resultCount}`}</p>
              </div>
            )}
          </div>
        </div>

        <div className="flex gap-2 items-center">
          <ExpandingSearch
            uniqueIdRef=""
            placeholder={displayText[DisplayTextKeys.SEARCH]}
            data={[]}
            onSearch={(_, search) => onSearch(search || '')}
            className="focus:bg-white !max-h-8"
            props={[]}
          />

          <Button
            isDisable={isLoadingPortfolioStocks}
            onClick={() =>
              setSlideOutConfig({
                refetch: refetchAll,
                isOpen: true,
                slidoutView: TABLE_ACTIONS.CREATE_OFFER,
                title: 'Create Offers',
                wineInfoTemplateConfig: { showTemplate: false },
              })
            }
            className="text-sm bg-vine max-h-8  text-white rounded-md disabled:bg-gray-700"
          >
            {displayText[DisplayTextKeys.CREATE_OFFER]}
          </Button>
        </div>
      </div>

      <div className="justify-center items-center bg-white rounded-b-md flex-1 h-full ">
        {tableBuilder.isLoading && <Loading />}

        {!tableBuilder.isLoading && tableBuilder.rows.length > 0 && (
          <Table
            columns={tableBuilder.columns}
            rows={tableBuilder.rows}
            applyfadeOnScrollClassName={false}
            isFadeOnScrollDisable={true}
          />
        )}

        {!tableBuilder.isLoading && !tableBuilder.rows.length && (
          <div className="animate-[fade-in_5s_ease-out]">
            <MessageTemplate
              showButton={false}
              displayTextKeys={NoResultsTextKeys}
              translationKey="common:noResult"
              imageSrc={noResultImage}
              title={
                <div className="flex items-center gap-3">
                  <span className="text-20 font-medium ">{t('common:noResult.title')}</span>
                </div>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BidsAndOffers;
