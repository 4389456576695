import { FC, Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { BackArrow } from 'assets/icons';
import { CheckoutOtp } from 'views/Accounts/components/Checkout.Com/CheckoutOtp';
import { FlowType } from 'views/Accounts/components/Checkout.Com/CardDetailsForm';
import { formatterGbp } from 'utils';
import { AppContext } from 'context/ContextProvider';
import MgmtPaymentTypeSelector, { RadioBtnOption } from '../MgmtFeePolicyEnforcer/mgmtPaymentTypeSelector';
import { HowToPayEnum } from './type';
import { MgmtViewsEnum } from '../types';

export enum ChargeAndSaveCardEnum {
  onChargeCardFailure = 'onChargeCardFailure',
  onChargeCardSuccess = 'onChargeCardSuccess',
}

interface ChargeAndSaveCardProps {
  onClose?: () => void;
  show?: boolean;
  amount: number;
  title?: string;
  transactionType?: string;
}

const ChargeAndSaveCard: FC<ChargeAndSaveCardProps> = ({
  title,
  show = false,
  onClose,
  amount,
  transactionType = '',
}) => {
  const [isOpen, setIsOpen] = useState(show);
  const [selectedOption, setSelectedOption] = useState<RadioBtnOption | null>();
  function closeModal() {
    onClose?.();
    setIsOpen(false);
  }
  const onOptionSelected = (selected: RadioBtnOption) => {
    setSelectedOption(selected);
  };

  const {
    state: {
      app: { addCardRef, hasCard },
    },
  } = useContext(AppContext);

  const options = useMemo((): RadioBtnOption[] => {
    const baseOptions: RadioBtnOption[] = [
      {
        id: HowToPayEnum.CARD_ON_FILE,
        label: 'Card on file',
        value: HowToPayEnum.CARD_ON_FILE,
        description: (
          <span className=" text-xs flex space-x-2">
            <span className="">Select from an existing card saved on your account.</span>
          </span>
        ),
        data: '',
      },
      {
        id: HowToPayEnum.NEW_CARD,
        label: 'New Card',
        value: HowToPayEnum.NEW_CARD,
        description: (
          <span className=" text-xs flex space-x-2">
            <span className="">Pay with a new card.</span>
          </span>
        ),
        data: '',
      },
    ];

    return hasCard ? baseOptions : [baseOptions[1]];
  }, [hasCard]);

  useEffect(() => {
    setSelectedOption(options[0]);
  }, [options]);

  const addCardRefState = useMemo(() => {
    return encodeURIComponent(btoa(JSON.stringify(addCardRef)));
  }, [addCardRef]);

  const transaction = useMemo(() => {
    switch (transactionType) {
      case MgmtViewsEnum.MGMT_FEE_PAYMENT_TYPE:
        return 'management fees';
      case MgmtViewsEnum.COMBINE_FEE_PAYMENT_TYPE:
        return 'management&accruedFees';
      default:
        return 'accrued fees';
    }
  }, [transactionType]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg  flex-col font-medium leading-6 text-gray-900">
                  {!!title && (
                    <div className="text-20 font-medium leading-6 text-gray-900  space-x-3 flex items-center ">
                      {onClose && <BackArrow className="cursor-pointer text-14 z-10  top-4" onClick={closeModal} />}
                      <div>{title}</div>
                    </div>
                  )}
                  <div className="w-full flex mt-5 flex-col items-center">
                    <span className="text-sm text-gray-700">Amount</span>
                    <span className="text-center text-lg border-none">{`${formatterGbp.format(amount)}`}</span>
                  </div>
                </Dialog.Title>
                <MgmtPaymentTypeSelector
                  onSelected={onOptionSelected}
                  options={options}
                  selectedId={selectedOption?.id}
                />

                <CheckoutOtp
                  isUseExistingCard={selectedOption?.id === (HowToPayEnum.CARD_ON_FILE as string)}
                  amount={amount}
                  transactionType={transaction}
                  portfolioId={-1}
                  btnText="Process"
                  flowType={FlowType.CHARGE_ADD_CARD}
                  onSuccessUrl={`#${ChargeAndSaveCardEnum.onChargeCardSuccess}=${addCardRefState}`}
                  onFailureUrl={`#${ChargeAndSaveCardEnum.onChargeCardFailure}=${addCardRefState}`}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ChargeAndSaveCard;
