import axios from 'axios';
import { getAccessToken, getUserToken } from 'services/auth';

const baseHeaders = {
  'Content-type': 'application/json',
  'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_KEY,
};

export const httpGet = async <TResponse = unknown, TParameters extends {} = never>(
  apiSubPath: string,
  parameters?: TParameters,
  isSecured = true,
  signal?: AbortSignal | undefined,
) => {
  const url = `${process.env.REACT_APP_APIM_URL}/${apiSubPath}${getQueryParams(parameters)}`;
  const res = await axios.get<TResponse>(url, {
    headers: {
      ...baseHeaders,
      ...(isSecured ? { authorization: `Bearer ${getAccessToken()}`, CdsUserJwt: getUserToken() } : {}),
    },
    signal,
  });
  return res.data;
};

const getQueryParams = (parameters: {} | undefined) => {
  const paramString = encodeQueryData(parameters);
  return paramString ? `?${paramString}` : '';
};

const encodeQueryData = (data: Record<string, string | number> | undefined) =>
  data &&
  Object.keys(data)
    .sort()
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
