export enum MainReducerEnum {
  MULTI_CHECK = 'multiCheck',
  SINGLE_CHECK = 'singleCheck',
  RESET_FILTERS = 'resetFilters',
}

export enum IsCheckedKeyEnum {
  isRegionCheck = 'isRegionCheck',
  isVintageCheck = 'isVintageCheck',
  isWineNameCheck = 'isWineNameCheck',
  isPortfolioCheck = 'isPortfolioCheck',
  isBuyOrSellCheck = 'isBuyOrSellCheck',
}
export type MainReducerActions =
  | {
      type: MainReducerEnum.MULTI_CHECK;
      payload: { key: IsCheckedKeyEnum; value: string; all: string[] };
    }
  | {
      type: MainReducerEnum.SINGLE_CHECK;
      payload: { key: IsCheckedKeyEnum; value: string[] };
    }
  | {
      type: MainReducerEnum.RESET_FILTERS;
    };

export interface InitialStateType {
  isRegionCheck: string[];
  isVintageCheck: string[];
  isWineNameCheck: string[];
  isPortfolioCheck: string[];
  isBuyOrSellCheck: string[];
}

export const SELECT_ALL = 'Select All';
export const ALL_PORTFOLIOS = 'All Portfolios';
export const mainReducer = (state: InitialStateType, action: MainReducerActions): InitialStateType => {
  switch (action.type) {
    case MainReducerEnum.MULTI_CHECK: {
      const key = action.payload.key;
      const isChecked = state[action.payload.key];
      const value = action.payload.value;
      const selectAllData = action.payload.all;
      let returnState: InitialStateType;

      if (isChecked.includes(value)) {
        returnState = { ...state, [key]: isChecked.filter((x) => x !== value) };
        if (value === SELECT_ALL || value === ALL_PORTFOLIOS) {
          returnState = { ...state, [key]: [] };
        } else if (isChecked.includes(SELECT_ALL) || isChecked.includes(ALL_PORTFOLIOS)) {
          returnState = {
            ...state,
            [key]: isChecked.filter((x) => x !== SELECT_ALL && x !== ALL_PORTFOLIOS).filter((x) => x !== value),
          };
        }
      } else if (value === SELECT_ALL || value === ALL_PORTFOLIOS) {
        returnState = {
          ...state,
          [key]: selectAllData,
        };
      } else if (isChecked.length + 1 === selectAllData.length - 1) {
        returnState = {
          ...state,
          [key]: selectAllData,
        };
      } else {
        returnState = {
          ...state,
          [key]: [...isChecked, value],
        };
      }

      // let textkey = '';
      // let placeHolderValue = '';
      // if (key === IsCheckedKeyEnum.isDetailBoradCheck) {
      //   textkey = 'isDetailBoradCheckSelectedText';
      //   placeHolderValue = 'All';
      // } else {
      //   textkey = 'isPortfolioCheckSelectedText';
      //   placeHolderValue = 'All Portfolios';
      // }

      // if (returnState[key].length === 1) {
      //   returnState = { ...returnState, [textkey]: returnState[key][0] };
      // } else if (returnState[key].length > 1 && !returnState[key].includes(SELECT_ALL)) {
      //   returnState = { ...returnState, [textkey]: 'Multiple selection' };
      // } else {
      //   returnState = { ...returnState, [textkey]: placeHolderValue };
      // }

      return {
        ...state,
        ...returnState,
      };
    }
    case MainReducerEnum.SINGLE_CHECK: {
      if (state[action.payload.key].includes(action.payload.value[0])) {
        return {
          ...state,
          [action.payload.key]: [],
        };
      }

      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }

    case MainReducerEnum.RESET_FILTERS: {
      return {
        ...state,
        isRegionCheck: [],
        isPortfolioCheck: [],
        isVintageCheck: [],
        isWineNameCheck: [],
        isBuyOrSellCheck: [],
      };
    }
    default:
      return state;
  }
};
