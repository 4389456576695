import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from '@tanstack/react-query';
import { fetchCampaignOffers, fetchCampaignOffersParams } from '../services/fetchCampaignOffers';
import { CampaignOffersQueryKeyEnum, CampaignsOffersType } from '../types';

interface useFetchInvestOffersResponseType {
  data: CampaignsOffersType[] | undefined;
  isLoading: boolean;
  error: unknown;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<CampaignsOffersType[], unknown>>;
  isRefetching: boolean;
}

const useFetchInvestOffers = (params: fetchCampaignOffersParams): useFetchInvestOffersResponseType => {
  // console.log('is called  ', params);
  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryFn: ({ signal }) => fetchCampaignOffers(params, signal),
    queryKey: [String(CampaignOffersQueryKeyEnum.fetchCampaignOffers)],
    enabled: params?.listOfCampaigns?.length ? true : false,
    refetchInterval: 300000,
  });

  return { data, isLoading, error, refetch, isRefetching };
};

export default useFetchInvestOffers;
