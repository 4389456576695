import { PortfolioBalanceIcon } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { CheckoutOtp } from 'views/Accounts/components/Checkout.Com/CheckoutOtp';
import { useContext, useMemo, useState } from 'react';
import { AppContext } from 'context/ContextProvider';
import { getPaymentOptions } from 'views/Invest/helpers';
import { HowToPayEnum } from '../HowToPay/type';
import MgmtPaymentTypeSelector, { RadioBtnOption } from '../MgmtFeePolicyEnforcer/mgmtPaymentTypeSelector';

interface ToUpViewParamType {
  portfolioId: number;
  onSuccessUrl?: string;
  onFailureUrl?: string;
  topupAmount: number;
  fxRate: number;
}

const TopUpView = ({ fxRate, portfolioId, topupAmount, onFailureUrl, onSuccessUrl }: ToUpViewParamType) => {
  const {
    formatter,
    state: {
      app: { hasCard },
    },
  } = useContext(AppContext);
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState<RadioBtnOption | null>();

  const options = useMemo((): RadioBtnOption[] => {
    return getPaymentOptions(hasCard as boolean);
  }, [hasCard]);
  return (
    <div className="bg-white h-full">
      <div className="flex items-center flex-col p-5 gap-2">
        <div className="w-[32px] h-[26.18px]">
          <PortfolioBalanceIcon className="mr-2 bg-gray-200 " />
        </div>
        <span className="text-14">{t('account:slideout.topup.top_up_amount')}</span>
        <span className="text-center text-lg border-none">{`${formatter.format(
          topupAmount * (fxRate ?? 1),
          true,
        )}`}</span>
        {(fxRate ?? 1) !== 1 && (
          <>
            <span className="text-center text-[18px] border-none">{`${formatter.format(topupAmount)}`}</span>
            <div className="mt-3">
              <span className="text-[14px]">
                <strong className="text-[16px] mr-2"> {t('common:pleaseNote')}:</strong>
                {t('common:currency_fxRate_note')}
              </span>
            </div>
          </>
        )}
      </div>
      <MgmtPaymentTypeSelector
        onSelected={(selected) => setSelectedOption(selected)}
        options={options}
        selectedId={selectedOption?.id}
      />

      <CheckoutOtp
        showTopUpIcon={false}
        isUseExistingCard={selectedOption?.id === (HowToPayEnum.CARD_ON_FILE as string)}
        amount={topupAmount}
        portfolioId={portfolioId}
        onSuccessUrl={onSuccessUrl}
        onFailureUrl={onFailureUrl}
      />
    </div>
  );
};

export default TopUpView;
