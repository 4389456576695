export interface CashFlowDetailsType {
  id: string;
  accountHolderId: number;
  date: string;
  statements: {
    main: {
      columns: string[];
      rows: string[][];
    };
  };
}

export interface CashFlowDetailsFormatedType {
  id?: number;
  rowIndex?: number;
  acct_holder_id?: string;
  client_id?: string;
  date?: string;
  details_broad?: string;
  details?: string;
  reference?: string;
  cash?: number | string;
  balance?: number | string;
}

export enum CASHFLOW_DETAILS_REFS {
  ACCT_HOLDER_ID = 'acct_holder_id',
  CLIENT_ID = 'client_id',
  DATE = 'date',
  DETAILS_BROAD = 'details_broad',
  DETAILS = 'details',
  REFERENCE = 'reference',
  CASH = 'cash',
  BALANCE = 'balance',
  DEBIT = 'debit',
  CREDIT = 'credit',
}
