import moment from 'moment';
import { TFunction } from 'react-i18next';
import { COUNTRIES, ISO2COUNTRIES } from '../constants';

export { httpGet } from './httpGet';
export { httpPost } from './httpPost';

export const roundNumber = (num: number, scale = 2) => {
  if (!`${num}`.includes('e')) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return +`${Math.round(`${num}e+${scale}` as any)}e-${scale}`;
  }
  const arr = `${num}`.split('e');
  let sig = '';
  if (+arr[1] + scale > 0) {
    sig = '+';
  }
  const i = `${+arr[0]}e${sig}${+arr[1] + scale}`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const j = Math.round(i as any);
  const k = +`${j}e-${scale}`;
  return k;
};

// These options are needed to round to whole numbers if that's what you want.
// minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
// maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
export const currencyFormatter = (locale = 'en-GB', currencyCode = 'GBP') => {
  const mFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  });
  return {
    format: (value: number) => mFormatter.format(roundNumber(value)),
  };
};

export const formatterGbp = currencyFormatter();

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * @deprecated use t('file:key')
 */
export const buildDisplayText = <T extends string>(
  keys: T[],
  translationPrefix: string,
  t: TFunction<'translation', undefined>,
  isCapitialisedFirstLeter = true,
): Record<string, string> =>
  keys.reduce((TResult, key) => {
    // the value of key is relative to the namespace specified by translationPrefix, but there are instance when we want to use a different namespace hence the following conditional check
    const translation = /(:)/.test(key) ? t(`${key}`) : t(`${translationPrefix}.${key}`);
    const result = translation && isCapitialisedFirstLeter ? capitalizeFirstLetter(translation) : translation;
    return { ...TResult, [key]: result !== undefined ? result : key };
  }, {});

/**
 * @deprecated Modifies original array. Use sortByProp.
 */
export const sortItems = <T>(items: T[], isAscending: boolean, prop: keyof T): T[] => {
  return isAscending
    ? items.sort((a, b) => (a[prop] > b[prop] ? 1 : b[prop] > a[prop] ? -1 : 0))
    : items.sort((a, b) => (a[prop] < b[prop] ? 1 : b[prop] < a[prop] ? -1 : 0));
};

export const sortByProp = <T>(
  items: T[],
  { direction = 'asc', prop }: { direction?: 'asc' | 'desc'; prop: keyof T },
): T[] => {
  return direction === 'asc'
    ? [...items].sort((a, b) => (a[prop] > b[prop] ? 1 : b[prop] > a[prop] ? -1 : 0))
    : [...items].sort((a, b) => (a[prop] < b[prop] ? 1 : b[prop] < a[prop] ? -1 : 0));
};

export const toInternalId = (str: string) => str.replace(/[ ,]/g, '').toLowerCase().trim();

export const uniqueItems = <T>(array: T[], key?: keyof T) => {
  if (key) return [...new Map(array.map((item) => [item[key], item])).values()];
  return [...new Map(array.map((item) => [item, item])).values()];
};

export const randomNumberBetween = (min: number, max: number) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const calculateSum = (array: number[]) => array.reduce((partialSum, num) => partialSum + num, 0);
export const sumBy = <T>(data: T[], prop: keyof T): number => {
  return calculateSum(data.map((x) => x[prop] as number));
};

export const getRange = (length: number): number[] => {
  return Array.from({ length }, (x, i) => i);
};

export const toNumeric = (value: string, isPhone = false) => {
  let result = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
  if (!isPhone) result = result.replace(/^0[^.]/, '0');

  return result;
};

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function randomDate(start: string, end: string, format = 'YYYY-MM-DD') {
  const startDate = moment(start, format).toDate();
  const endDate = moment(end, format).toDate();
  return moment(new Date(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime()))).format(
    format,
  );
}

export const toChartXYPoint = <DataType>(
  source: DataType[],
  xProp?: keyof DataType,
  yProp?: keyof DataType,
  includeData = true,
) =>
  source.map((datePoint) => {
    const result: { x: string; y: unknown; data?: DataType } = { x: `${datePoint}`, y: datePoint };
    if (xProp) {
      result.x = `${datePoint[xProp]}`;
    }

    if (yProp) {
      result.y = datePoint[yProp];
    }
    if (includeData) {
      result.data = datePoint;
    }

    return { ...result };
  });

export const pickRandom = <T>(arr: T[]): T => {
  return arr[randomNumberBetween(0, arr.length - 1)];
};

export const validateEmail = (email: string) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(email);
};

export type Country = typeof COUNTRIES[number];
export const getISO2Country = (country: Country): string => {
  if (!ISO2COUNTRIES[country]) {
    throw new Error('No match found.');
  }

  return ISO2COUNTRIES[country];
};

export function dateIsInPast(ticks?: number): boolean {
  if (!ticks) {
    return true;
  }
  return new Date().getTime() > ticks;
}
