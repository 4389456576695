const GBP_BankTransferInfo = [
  { type: 'company', title: 'Company', value: 'Cult Wines Ltd' },
  { type: 'sortCode', title: 'Sort Code', value: '40-03-21' },
  { type: 'accNumber', title: 'Account No.', value: '02276453' },
  { type: 'routing', title: 'Routing/Transit', value: 'N/A' },
  { type: 'bankInstitution', title: 'Bank Institution', value: 'N/A' },
  { type: 'iban', title: 'IBAN', value: 'GB49HBUK40032102276453' },
  { type: 'bic', title: 'BIC/Swift', value: 'HBUKGB4B' },
  { type: 'bankName', title: 'Bank', value: 'HSBC' },
  { type: 'bankAddress', title: 'Branch address', value: '21 Kings Mall, King Street, London, W6 0QF' },
  { type: 'reference', title: 'Reference', value: '' },
];
const CAD_BankTransferInfo = [
  { type: 'company', title: 'Company', value: 'Cult Wines Canada Limited' },
  { type: 'sortCode', title: 'Sort Code', value: 'N/A' },
  { type: 'accNumber', title: 'Account No.', value: '5609473 or 0690-5609473 ' },
  { type: 'routing', title: 'Routing/Transit', value: '1020 or 10202' },
  { type: 'bankInstitution', title: 'Bank Institution', value: '004 or 0004' },
  { type: 'iban', title: 'IBAN', value: 'N/A' },
  { type: 'bic', title: 'BIC/Swift', value: 'TDOMCATTTOR' },
  { type: 'bankName', title: 'Bank', value: 'Toronto Dominion' },
  { type: 'bankAddress', title: 'Branch address', value: 'TD Centre Branch, 55 King St W, Toronto, ON, M5K 1A2' },
  { type: 'reference', title: 'Reference', value: '' },
];
const HKD_BankTransferInfo = [
  { type: 'company', title: 'Company', value: 'Cult Wines Hong Kong Ltd' },
  { type: 'sortCode', title: 'Sort Code', value: 'N/A' },
  { type: 'accNumber', title: 'Account No.', value: '582-311775-838' },
  { type: 'routing', title: 'Routing/Transit', value: 'N/A' },
  { type: 'bankInstitution', title: 'Bank Institution', value: 'N/A' },
  { type: 'iban', title: 'IBAN', value: 'N/A' },
  { type: 'bic', title: 'BIC/Swift', value: 'HSBCHKHH' },
  { type: 'bankName', title: 'Bank', value: 'HSBC' },
  { type: 'bankAddress', title: 'Branch address', value: '1 Queens Road, Central, Hong Kong' },
  { type: 'reference', title: 'Reference', value: '' },
];
const SGD_BankTransferInfo = [
  { type: 'company', title: 'Company', value: 'Cult Wines (SG) Pte. Ltd' },
  { type: 'sortCode', title: 'Sort Code', value: 'N/A' },
  { type: 'accNumber', title: 'Account No.', value: '052-528791-001' },
  { type: 'routing', title: 'Routing/Transit', value: 'N/A' },
  { type: 'bankInstitution', title: 'Bank Institution', value: 'N/A' },
  { type: 'iban', title: 'IBAN', value: 'N/A' },
  { type: 'bic', title: 'BIC/Swift', value: 'HSBCSGSG' },
  { type: 'bankName', title: 'Bank', value: 'HSBC' },
  { type: 'bankAddress', title: 'Branch address', value: '21 Collyer Quay, HSBC Building Level 1, Singapore, 049320' },
  { type: 'reference', title: 'Reference', value: '' },
];
const EUR_BankTransferInfo = [
  { type: 'company', title: 'Company', value: 'Cult Wines Ltd.' },
  { type: 'sortCode', title: 'Sort Code', value: '40-12-76' },
  { type: 'accNumber', title: 'Account No.', value: '83904844' },
  { type: 'routing', title: 'Routing/Transit', value: 'N/A' },
  { type: 'bankInstitution', title: 'Bank Institution', value: 'N/A' },
  { type: 'iban', title: 'IBAN', value: 'GB96HBUK40127683904844' },
  { type: 'bic', title: 'BIC/Swift', value: 'HBUKGB4B' },
  { type: 'bankName', title: 'Bank', value: 'HSBC' },
  { type: 'bankAddress', title: 'Branch address', value: '593 - 599 Fulham Road, Fulham, London, SW6 5UA' },
  { type: 'reference', title: 'Reference', value: '' },
];
const USD_BankTransferInfo = [
  { type: 'company', title: 'Company', value: 'Cult Wines Inc' },
  { type: 'sortCode', title: 'Sort Code', value: 'N/A' },
  { type: 'accNumber', title: 'Account No.', value: '31227662' },
  { type: 'routing', title: 'Routing/Transit', value: '021000089' },
  { type: 'bankInstitution', title: 'Bank Institution', value: 'N/A' },
  { type: 'iban', title: 'IBAN', value: 'N/A' },
  { type: 'bic', title: 'BIC/Swift', value: 'CITIUS33' },
  { type: 'bankName', title: 'Bank', value: 'CitiBank US' },
  { type: 'bankAddress', title: 'Branch address', value: '111 Wall Street, New York, NY 10043' },
  { type: 'reference', title: 'Reference', value: '' },
];

export const bankTranferDetails = {
  CAD_BankTransferInfo,
  SGD_BankTransferInfo,
  HKD_BankTransferInfo,
  GBP_BankTransferInfo,
  EUR_BankTransferInfo,
  USD_BankTransferInfo,
} as Record<string, Record<string, string>[]>;
