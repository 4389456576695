type ObjectType = Record<string, unknown | string>;

export const formatToObject = (columns: string[], rows: string[][]) => {
  const object: ObjectType[] = [];
  rows.forEach((row, rowIndex) => {
    let detailsObject: ObjectType = {};
    columns.forEach((column, columnIndex) => {
      detailsObject = { ...detailsObject, [column]: row[columnIndex], id: rowIndex + 1, rowIndex: rowIndex + 1 };
    });
    object.push(detailsObject);
  });

  return object;
};
