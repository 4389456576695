import { TableColProps } from '../types';

export const headerInfo: string[] = ['www.wineinvestment.sg', 'accountsreceivable@cultwinesltd.com'];

export const colums: TableColProps[] = [
  {
    id: 2,
    title: 'Date',
    value: 'date',
  },
  {
    id: 3,
    title: 'Description',
    value: 'description',
  },
  {
    id: 4,
    title: 'Debit',
    value: 'debit',
  },
  {
    id: 5,
    title: 'Credit',
    value: 'credit',
  },
  {
    id: 6,
    title: 'Balance',
    value: 'balance',
  },
];
