/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { LoginForm } from '../LoginForm/LoginForm';
import { MFAChallengeForm } from '../MFAChallengeForm/MFAChallengeForm';
import { MfaRequestProps } from '../types';

export const Login = () => {
  const forwardedEmail = useLocation().state?.email || '';

  const [showMfaView, setShowMfaView] = useState(false);
  const [mfaOOBCode, setMFAOOBCode] = useState<string | null>(null);
  const [mfaToken, setMFAToken] = useState<string | null>(null);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [timesStamp, setTimeStamp] = useState(new Date().getTime());

  const [transaction, setTransaction] = useState<Sentry.Transaction | null>(null);
  const [span, setSpan] = useState<Sentry.Span | null>(null);

  useEffect(() => {
    setTransaction(Sentry.startTransaction({ name: 'login' }));
  }, []);
  useEffect(() => {
    if (showMfaView && transaction) setSpan(transaction.startChild({ op: 'mfa' }));
  }, [showMfaView, transaction]);

  const handleMFARequested = ({
    mfaOOBCode: _mfaOOBCode,
    mfaToken: _mfaToken,
    emailAddress: _emailAddress,
    password: _password,
  }: MfaRequestProps): void => {
    setMFAOOBCode(_mfaOOBCode);
    setMFAToken(_mfaToken);
    setEmailAddress(_emailAddress);
    setPassword(_password);
    setTimeStamp(new Date().getTime());
    setShowMfaView(true);
  };

  const onBackToLogin = () => {
    setTimeStamp(new Date().getTime());
    setShowMfaView(false);
  };

  return showMfaView ? (
    <MFAChallengeForm
      mfaToken={mfaToken}
      mfaOOBCode={mfaOOBCode}
      emailAddress={emailAddress}
      password={password}
      onBack={onBackToLogin}
      timesStamp={timesStamp}
      onSucess={() => {
        span?.finish();
        transaction?.finish();
      }}
    />
  ) : (
    <LoginForm onMFARequested={handleMFARequested} emailAddress={forwardedEmail} />
  );
};
