import { ViewStateType } from 'components/ProductTemplates/types';
import { InvestOffer } from 'views/Invest/types';
import { Button } from 'components';
import { investNowApi } from 'views/Invest/services/investNowApi';
import useApiMutation from 'hooks/useApiMutation';
import { useTranslation } from 'react-i18next';
import errorImage from '../../../assets/images/error.png';
import { FeedbackTemplate, MessageType } from '../FeedbackTemplate';

interface RequestWineOnFailedPurchaseType {
  onStatusChange: (viewState: ViewStateType, message?: MessageType | undefined) => void;
  qtyBought: number;
  resetStatus: () => void;
  onClose?: () => void;
  offer:
    | InvestOffer
    | {
        expiryDate: string;
        price: number;
        subtitle: string;
        name: string;
      };
}

const RequestWineOnFailedPurchase = ({
  onStatusChange,
  qtyBought,
  resetStatus,
  onClose,
  offer,
}: RequestWineOnFailedPurchaseType) => {
  const { name: title, subtitle: subTitle, price, expiryDate } = offer;

  const investRequest = {
    offerTitle: title,
    offerSubTitle: subTitle,
    offerPrice: price,
    numberOfUnits: qtyBought,
    totalPrice: Number(price) * qtyBought,
    offerExpiryDate: expiryDate,
  };
  const { t } = useTranslation();
  const { executor } = useApiMutation(() => investNowApi(investRequest as unknown as InvestOffer));

  const processInvestMore = async () => {
    onStatusChange(ViewStateType.LOADING);
    const result = await executor({});

    if (result?.data?.isSuccess) {
      onStatusChange(ViewStateType.THANK_YOU);
    } else {
      onStatusChange(ViewStateType.ERROR_RESULT);
    }
  };

  return (
    <FeedbackTemplate
      templateConfig={{
        title: (
          <div className="flex flex-col gap-2 ">
            <img className="w-[120px] p-5 h-[120px]" src={errorImage} alt="errorImage" />
            <p> {t('common:error')}</p>
          </div>
        ),
        subTitle: (
          <div className="flex flex-col gap-2 text-gray-600 text-sm">
            <p>{t('common:purchase_error_message')}</p>
          </div>
        ),
        content: () => (
          <div className="flex flex-col gap-2">
            <Button
              className="btn text-14 font-normal min-w-[100px]  bg-orange rounded-full mt-4  text-black w-full"
              onClick={() => {
                processInvestMore();
                resetStatus();
              }}
            >
              {t('common:request_purchase')}
            </Button>
            <button className="text-sm" onClick={onClose}>
              {t('common:close')}
            </button>
          </div>
        ),
      }}
      viewState={ViewStateType.CUSTOM}
      onCTA={onClose}
    />
  );
};

export default RequestWineOnFailedPurchase;
