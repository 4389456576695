import { Transition } from '@headlessui/react';
import { DocumentProps, usePDF } from '@react-pdf/renderer';
import { Close } from 'assets/icons';
import Loading from 'components/Loading/loading';
import {
  Dispatch,
  JSXElementConstructor,
  ReactElement,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

interface PDFViewerProps {
  PDF: ReactElement<DocumentProps, string | JSXElementConstructor<any>>;
}

export interface PDFRef {
  showPDF: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

const PDFViewer = forwardRef(({ PDF }: PDFViewerProps, ref) => {
  const [isVisiable, setIsVisiable] = useState(false);
  const [instance, update] = usePDF({ document: isVisiable ? PDF : undefined });

  useEffect(() => {
    if (isVisiable) update(PDF);
  }, [update, isVisiable, PDF]);
  useImperativeHandle(ref, () => {
    return {
      showPDF: setIsVisiable,
      isLoading: instance.loading,
    };
  });

  return (
    <Transition
      show={isVisiable}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      onClick={() => (!instance.loading ? setIsVisiable(false) : null)}
      className="bg-black w-full h-screen  items-center flex flex-col absolute top-0 left-0 bg-opacity-40 "
    >
      <div className="w-full flex flex-row  z-40 gap-2 justify-end items-center px-2 py-2">
        <Close width={25} />
      </div>
      {instance.loading ? (
        <Loading />
      ) : (
        <div onClick={(e) => setIsVisiable(false)} className="overflow-y-auto w-full items-center flex flex-col">
          <iframe
            onClick={(e) => e.stopPropagation()}
            className="w-[70%] h-screen"
            src={`${instance.url}#zoom=90` || ''}
            title="pdf"
          />
        </div>
      )}
    </Transition>
  );
});

export default PDFViewer;
