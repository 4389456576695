// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-console */
import React, { useMemo, useState } from 'react';
import { CardNumber, Cvv, ExpiryDate, Frames, FrameValidationChangedEvent } from 'frames-react';
import { useTranslation } from 'react-i18next';
import { logError } from 'components/LogError';
import { Country, getISO2Country } from 'utils';
import { useCreateCheckoutDotComPayment } from 'views/Accounts/hooks/useCreateCheckoutDotComPayment';
import { ViewStateType } from 'components/ProductTemplates/types';
import { cache } from 'graphql/cache';
import { useGetCheckoutDotComCards } from 'views/Accounts/hooks/useGetCheckoutDotComCards';
import { FeedbackTemplate } from 'views/shared/FeedbackTemplate';
import { selectCheckoutDotComCards, userHasValidCards } from 'views/Accounts/utils';
import { Button } from 'components';
import { DropdownItem } from 'components/Dropdown';
import { CardDetailResponse, CardPaymentInput } from '__generated__/graphql';
import { COUNTRIES } from '../../../../constants';
import Visa from '../../../../assets/images/visa.jpg';
import Mastercard from '../../../../assets/images/mastercard.jpg';
import { ACCEPTED_PAYMENT_METHODS } from '../../constants';

export enum FlowType {
  ADD_CARD = 'ADD_CARD',
  TOP_UP = 'TOP_UP',
  CHARGE_ADD_CARD = 'CHARGE_ADD_CARD',
}

interface CardDetailsFormProps {
  amount: number;
  ContainerClassName?: string;
  flow: FlowType;
  portfolioId?: number;
  btnText?: string;
  onSuccessUrl?: string;
  onFailureUrl?: string;
  isUseExistingCard?: boolean;
  transactionType?: string;
}

type FrameInput = {
  'name-on-card': boolean | undefined;
  cvv: boolean | undefined;
  'card-number': boolean | undefined;
  'expiry-date': boolean | undefined;
  'poste-code': boolean | undefined;
};

export default function CardDetailsForm({
  amount,
  ContainerClassName,
  flow,
  portfolioId,
  btnText,
  onSuccessUrl,
  onFailureUrl,
  isUseExistingCard = false,
  transactionType = '',
}: CardDetailsFormProps) {
  const { t } = useTranslation();
  const [makeCardDefault, setMakeCardDefault] = React.useState(true);
  const [selectedSavedCard, setSelectedSavedCard] = useState<DropdownItem>();
  const [saveCard, setSaveCard] = React.useState(true);
  const [nameOnCard, setNameOnCard] = React.useState<string>('');
  const [postcode, setPostcode] = React.useState<string>('');
  const [country, setCountry] = React.useState<Country>('United Kingdom');
  const [viewState, setViewState] = React.useState<ViewStateType>(ViewStateType.DEFAULT);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isPaymentMethodValid, setIsPaymentMethodValid] = useState<null | false | true>(null);
  const { data: cardsData, error: failedToGetCards, loading: loadingCards } = useGetCheckoutDotComCards();

  const _cards = useMemo(() => {
    if (!cardsData) return [];
    return selectCheckoutDotComCards(cardsData);
  }, [cardsData]);

  const { createCardPayment, loading: addingCard } = useCreateCheckoutDotComPayment();
  const [cardDetailsValidity, setCardDetailsValidity] = React.useState<FrameInput>({
    'card-number': undefined,
    'expiry-date': undefined,
    'name-on-card': undefined,
    'poste-code': undefined,
    cvv: undefined,
  });

  const onHandleSumbit = () => {
    setViewState(ViewStateType.LOADING);
    handleSubmit();
  };

  const cardsOnFile = useMemo(() => {
    if (!_cards) return [];
    const cards = _cards.filter((card) =>
      ACCEPTED_PAYMENT_METHODS.find((x) => x.toLocaleLowerCase() === card.scheme?.toLocaleLowerCase() || ''),
    );
    const saveCards = cards.map((x) => ({
      id: x.instrumentId as string,
      value: x.instrumentId as string,
      content: x.defaultMitFlag,
      text: `${x.clientName}......${x.last4}.....${x.scheme}`,
    }));

    setSelectedSavedCard(saveCards.find((x) => x.content) ?? saveCards[0]);
    return saveCards;
  }, [_cards]);

  const handleSubmit = async () => {
    setIsProcessing(true);
    try {
      const baseReturnUri = window.location.origin + window.location.pathname;
      const successUrl = `${baseReturnUri}${
        onSuccessUrl || (flow === FlowType.TOP_UP ? '#topUp_success' : '#addCard_success')
      }`;

      const failureUrl = `${baseReturnUri}${
        onFailureUrl || (flow === FlowType.TOP_UP ? '#topUp_failure' : '#addCard_failure')
      }`;

      let request: CardPaymentInput = {
        amount,
        failureUrl,
        successUrl,
        transactionType,
      };

      if (cardsOnFile && isUseExistingCard) {
        const { instrumentId } = _cards.find((x) => x.instrumentId === selectedSavedCard?.value) as CardDetailResponse;
        request = {
          ...request,
          instrumentId,
          portfolioId: flow === FlowType.TOP_UP ? portfolioId : undefined,
        };
      } else {
        const tokenEvent = await Frames.submitCard();
        request = {
          ...request,
          cardToken: tokenEvent.token,
          clientName: nameOnCard,
          storeCardFlag: saveCard || flow === FlowType.ADD_CARD,
          mitConsentedFlag: saveCard || flow === FlowType.ADD_CARD, // if user consents to store card, then they are also opting into MIT (confirmed by HHW).
          defaultMitFlag: flow === FlowType.ADD_CARD ? makeCardDefault : false, // Only set card to Default when adding a new card. (not topping up)
          portfolioId: flow === FlowType.TOP_UP ? portfolioId : undefined,
        };
      }

      const { errors, data } = await createCardPayment({
        variables: {
          args: {
            ...request,
          },
        },
      });

      if (errors) {
        console.error(errors);
        setViewState(ViewStateType.ERROR_RESULT);
        logError(JSON.stringify(errors[0]), { tags: { userFlow: 'payment' } });
        return;
      }

      if (data?.createCardPayment.status === 'Declined') {
        console.error('Card declined');
        logError(new Error(`Card declined: ${data?.createCardPayment.errorMessage}`), {
          tags: { userFlow: 'payment' },
        });
        setViewState(ViewStateType.ERROR_RESULT);
        return;
      }

      if (data?.createCardPayment.status === 'Failed') {
        console.error('Process card failed');
        logError(new Error(`Process card failed': ${data?.createCardPayment.errorMessage}`), {
          tags: { userFlow: 'payment' },
        });
        setViewState(ViewStateType.ERROR_RESULT);
        return;
      }

      if (data?.createCardPayment.status === 'Pending') {
        const { redirectUrl } = data.createCardPayment;
        if (!redirectUrl) {
          const err = new Error('Redirect error is null');
          logError(err, { tags: { userFlow: 'payment' } });
          throw err;
        }

        cache.evict({ fieldName: 'paymentGetCardDetails' });
        window.location.href = redirectUrl;
      }
    } catch (e) {
      logError(e as Error, { tags: { userFlow: 'payment' } });
      console.error(e);
      setViewState(ViewStateType.ERROR_RESULT);
    } finally {
      setIsProcessing(false);
    }
  };

  React.useEffect(() => {
    if (failedToGetCards && flow === FlowType.ADD_CARD) {
      setViewState(ViewStateType.ERROR_RESULT);
    }
  }, [failedToGetCards, flow]);

  const validCardAlreadyOnFile = userHasValidCards(_cards);

  function _frameValidationChangedHandler(e: FrameValidationChangedEvent) {
    setCardDetailsValidity((prev) => {
      return { ...prev, [e.element as keyof typeof cardDetailsValidity]: e.isValid };
    });
  }

  function _onChangeTextHandler(text: string) {
    setNameOnCard(text);
    const nameValid = text.length >= 2 && text.length <= 24;
    setCardDetailsValidity((prev) => {
      return { ...prev, 'name-on-card': nameValid };
    });
  }

  function _onChangePostTextHandler(text: string) {
    setPostcode(text);
    const postCodeValid = text.length >= 2 && text.length <= 24;
    setCardDetailsValidity((prev) => {
      return { ...prev, 'poste-code': postCodeValid };
    });
  }

  return (
    <div
      className={
        ContainerClassName ||
        'flex flex-col flex-1 bg-gradient-to-b  from-gray-100 to-gray-500  pb-5 px-3 w-screen relative overflow-x-hidden sm:w-[300px]'
      }
    >
      <div className="w-full h-full rounded-md  overflow-y-auto flex flex-col">
        <form className="h-full">
          <FeedbackTemplate
            onClose={() => setViewState(ViewStateType.DEFAULT)}
            onCTA={() => setViewState(ViewStateType.DEFAULT)}
            viewState={viewState}
          >
            <div className="flex flex-col bg-white  divide-y divide-gray-200 h-full w-full p-4">
              {(!cardsOnFile || !isUseExistingCard) && (
                <>
                  <div className="flex flex-row mt-4 mb-4">
                    <h2>{t('account:payments.addCard.acceptedCards')}</h2>
                    <div className="flex gap-2 ml-auto">
                      <img src={Visa} alt="visa" />
                      <img src={Mastercard} alt="mastercard" />
                    </div>
                  </div>
                  <Frames
                    paymentMethodChanged={(e) => {
                      if (ACCEPTED_PAYMENT_METHODS.includes(e.paymentMethod)) {
                        setIsPaymentMethodValid(true);
                      } else {
                        setIsPaymentMethodValid(false);
                      }
                    }}
                    config={{
                      acceptedPaymentMethods: ACCEPTED_PAYMENT_METHODS,
                      debug: process.env.NODE_ENV === 'development',
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      publicKey: process.env.REACT_APP_CHECKOUT_COM_PUBLIC_KEY!,
                      cardholder: {
                        billingAddress: { country: getISO2Country(country), zip: postcode },
                        name: nameOnCard,
                      },
                      localization: {
                        cardNumberPlaceholder: t('account:payments.addCard.formFields.placeholders.cardNumber'),
                        expiryMonthPlaceholder: t('account:payments.addCard.formFields.placeholders.expiryMonth'),
                        expiryYearPlaceholder: t('account:payments.addCard.formFields.placeholders.expiryYear'),
                        cvvPlaceholder: t('account:payments.addCard.formFields.placeholders.cvv'),
                      },
                      style: {
                        base: {
                          // Get it as close to our text field styling as possible.
                          color: 'black',
                          fontSize: '14px',
                          fontWeight: 300,
                          letterSpacing: 'normal',
                          border: `1px solid gray`,
                          borderRadius: '4px',
                          height: '47px',
                          padding: '18px 12px',
                          background: 'white',
                          fontFamily: 'sans-serif',
                        },
                        placeholder: {
                          base: {
                            color: 'black',
                          },
                        },
                        invalid: {
                          border: `1px solid red`,
                        },
                      },
                    }}
                    frameValidationChanged={_frameValidationChangedHandler}
                    // TODO: in https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/9914
                    cardValidationChanged={() => {}}
                    cardTokenized={() => {}}
                  >
                    <div className="flex flex-col gap-4">
                      <input
                        id="name"
                        className="checkout-com-text-field"
                        type="text"
                        placeholder={t('account:payments.addCard.formFields.placeholders.name')}
                        value={nameOnCard}
                        onChange={(e) => _onChangeTextHandler(e.target.value)}
                      />
                      {typeof cardDetailsValidity['name-on-card'] === 'boolean' &&
                        cardDetailsValidity['name-on-card'] === false && (
                          <span className="text-red text-sm -mt-4">{t('account:payments.validation.nameOnCard')}</span>
                        )}

                      <CardNumber className="h-[47px]" />
                      {typeof cardDetailsValidity['card-number'] === 'boolean' &&
                        cardDetailsValidity['card-number'] === false && (
                          <span className="text-red text-sm -mt-4">{t('account:payments.validation.cardNumber')}</span>
                        )}

                      {typeof cardDetailsValidity['card-number'] === 'boolean' &&
                        cardDetailsValidity['card-number'] === true &&
                        isPaymentMethodValid === false && (
                          <span className="text-red text-sm -mt-4">
                            {t('account:payments.validation.acceptedCards')}
                          </span>
                        )}

                      <ExpiryDate className="h-[47px]" />
                      {typeof cardDetailsValidity['expiry-date'] === 'boolean' &&
                        cardDetailsValidity['expiry-date'] === false && (
                          <span className="text-red text-sm -mt-4">{t('account:payments.validation.expDate')}</span>
                        )}

                      <Cvv className="h-[47px]" />
                      {typeof cardDetailsValidity.cvv === 'boolean' && cardDetailsValidity.cvv === false && (
                        <span className="text-red text-sm -mt-4">{t('account:payments.validation.cvv')}</span>
                      )}

                      <input
                        id="postcode"
                        className="checkout-com-text-field"
                        type="text"
                        placeholder={t('account:payments.addCard.formFields.placeholders.postcode')}
                        value={postcode}
                        onChange={(e) => _onChangePostTextHandler(e.target.value)}
                      />
                      {typeof cardDetailsValidity['poste-code'] === 'boolean' &&
                        cardDetailsValidity['poste-code'] === false && (
                          <span className="text-red text-sm -mt-4">{t('account:payments.validation.postCode')}</span>
                        )}

                      <label htmlFor="country" className="text-sm flex flex-col gap-1 items-start ">
                        {t('account:payments.addCard.formFields.placeholders.country')}
                        <select
                          className="mr-5 text-base outline-none w-full text-start relative  border-b pb-2 border-b-gray-300"
                          value={country}
                          onChange={({ target: { value } }) => setCountry(value)}
                        >
                          {COUNTRIES.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </label>

                      {(flow === FlowType.CHARGE_ADD_CARD || flow === FlowType.TOP_UP) && (
                        <label htmlFor="saveCardInput" className="flex gap-1 text-sm">
                          <input
                            id="saveCardInput"
                            type="checkbox"
                            checked={saveCard}
                            color="accent_orange"
                            onChange={() => setSaveCard(!saveCard)}
                          />
                          {t('account:payments.addCard.actions.saveCardText')}
                        </label>
                      )}

                      {flow === FlowType.ADD_CARD && (
                        <>
                          <label htmlFor="makedefault" className="flex gap-1 text-sm">
                            <input
                              id="makedefault"
                              type="checkbox"
                              checked={makeCardDefault}
                              disabled={!validCardAlreadyOnFile}
                              color="primary"
                              onChange={() => setMakeCardDefault(!makeCardDefault)}
                            />
                            {t('account:payments.addCard.formFields.makeDefault')}
                          </label>
                        </>
                      )}

                      {/* TODO: Find out what sort of agreement/information is needed around MIT from product */}
                      {/* {flags.storeAndReuseCardDetailsDuringTopup && flags.merchantInitiatedTransaction12341 && showEnableMIT && (
                <div className={classes.checkboxWrapper}>
                  <Checkbox
                    checked={agreeToMITChecked}
                    disabled={!storeCardDetailsChecked}
                    inputProps={{ 'aria-label': 'agree-to-mit-checkbox' }}
                    color="primary"
                    onChange={handleAgreeMITCheckboxToggled}
                  />
                  <div className={clsx(classes.column, classes.rowGap1, classes.ml1)}>
                    <Typography className={classes.storeCardDescriptionText} variant="caption">
                      {t('wallet:payByCard.addCardModal.mitConsentCheckboxLabel')}
                      <button
                        onClick={handleLearnMoreClicked}
                        type="button"
                        className={clsx(classes.learnMore, classes.bold)}
                      >
                        {t('wallet:payByCard.addCardModal.learnMore')}
                      </button>
                    </Typography>
                    <Typography className={clsx(classes.pill, classes.uppercase, classes.bold)} variant="caption">
                      {t('common:recommended')}
                    </Typography>
                  </div>
                </div>
              )} */}
                    </div>
                  </Frames>

                  <Button
                    isDisable={
                      isProcessing ||
                      loadingCards ||
                      addingCard ||
                      nameOnCard.length < 5 ||
                      postcode.length < 4 ||
                      country.length === 0 ||
                      isPaymentMethodValid === false
                    }
                    onClick={handleSubmit}
                    isProcessing={isProcessing}
                    type="button"
                    className="btn-accent disabled:bg-gray-300 mt-[15px]"
                  >
                    {!!btnText && btnText}
                    {!btnText &&
                      (flow === FlowType.TOP_UP
                        ? t('account:slideout.topup.title')
                        : t('account:payments.addCard.actions.save'))}
                  </Button>
                </>
              )}
              {cardsOnFile && isUseExistingCard && (
                <>
                  {/* <div className="flex flex-col">
                    <span className="text-sm text-gray-700">Card On File</span>
                    <Dropdown
                      placeholder="Please select"
                      value={`${selectedSavedCard?.value}`}
                      containerClassName="w-full border-b asolute top-0 left-0"
                      itemsContainerClassName="h-[250px] overflow-y-auto"
                      valueTemplate={<div className="flex-1 text-start ">{selectedSavedCard?.text}</div>}
                      onItemSelect={(item) => setSelectedSavedCard(item)}
                      items={cardsOnFile}
                      className="flex-1 text-14 text-black "
                      itemsWrapperClassName="w-full"
                    />
                  </div> */}

                  <label htmlFor="country" className="text-sm flex flex-col gap-1 items-start ">
                    Card On File
                    <select
                      className="mr-5 text-base outline-none w-full text-start relative  border-b pb-2 border-b-gray-300"
                      value={selectedSavedCard?.value}
                      onChange={({ target: { value } }) =>
                        setSelectedSavedCard(cardsOnFile.find((x) => value === x.value))
                      }
                    >
                      {cardsOnFile.map((option) => (
                        <option key={option.id} value={option.value as string}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </label>

                  <Button
                    isDisable={loadingCards}
                    onClick={onHandleSumbit}
                    type="button"
                    className="btn-accent disabled:bg-gray-300 "
                  >
                    {!!btnText && btnText}
                    {!btnText &&
                      (flow === FlowType.TOP_UP
                        ? t('account:slideout.topup.title')
                        : t('account:payments.addCard.actions.save'))}
                  </Button>
                </>
              )}
            </div>
          </FeedbackTemplate>
        </form>
      </div>
    </div>
  );
}
