import { gql } from '@apollo/client';

export const DELETE_CHECKOUT_DOT_COM_PAYMENT_CARD = gql`
  mutation DeletePaymentCard($instrumentId: String!) {
    deleteCardDetail(instrumentId: $instrumentId) {
      isSuccess
      errorMessage
    }
  }
`;
