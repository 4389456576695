import { useQuery } from '@apollo/client';
import { GET_CHECKOUT_DOT_COM_CARDS } from '../graphql/getCheckoutDotComCards';
import { PaymentGetCardDetailsQuery } from '__generated__/graphql';

export function useGetCheckoutDotComCards(props?: { skip?: boolean }) {
  const { data, error, loading } = useQuery<PaymentGetCardDetailsQuery>(GET_CHECKOUT_DOT_COM_CARDS, {
    skip: props?.skip,
  });

  return { data, error, loading };
}
