import { httpPost } from 'utils';

export interface BuysType {
  offerId: string;
  lwin18: string;
  price: number;
  quantity: number;
}

interface IPurchaseBody {
  portfolioId: number;
  description: string;
  buys: BuysType[];
}

interface IPurchaseheaders {
  email: string;
  operationType?: 'CreateBuyOrder';
}

export interface IPurchaseParams {
  headers?: IPurchaseheaders;
  body: IPurchaseBody;
}
interface Order {
  offerId: null | string;
  clientOrderId: string;
  lwin18: string;
  price: number;
  quantity: number;
}

interface Result {
  orderGroupId: string;
  buyOrSell: 'S' | 'B';
  accountId: string;
  orders: Order[];
}
interface IPurchaseResponse {
  result: Result;
  status: 'Succeed' | 'Failed';
  errorMessage: null | string;
}

export const executeBuy = (params?: IPurchaseParams) => {
  const path = `portal/PurchaseOrders`;
  return httpPost<IPurchaseResponse, IPurchaseBody>(path, params?.body);
};

// export const fetchOffer = (): Promise<BidsResponse> =>
//   new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(mockBids);
//     }, 2000);
//   });
