import React from 'react';
import { useTranslation } from 'react-i18next';
import MasterCard from '../../../../assets/images/mastercard.png';
import Visa from '../../../../assets/images/visa.png';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-icon.svg';
import { CardDetailResponse } from '__generated__/graphql';

function selectImage(scheme?: string | null): JSX.Element | null {
  if (!scheme) {
    return null;
  }

  switch (scheme.toLowerCase()) {
    case 'mastercard': {
      return <img src={MasterCard} width="28" alt="mastercard" />;
    }
    case 'visa': {
      return <img src={Visa} width="28" alt="visa" />;
    }
    default:
      return null;
  }
}

interface CardProps {
  card: CardDetailResponse;
  expired?: boolean;
  onEditCardClicked: (card: CardDetailResponse) => void;
}

export default function Card({ card, expired, onEditCardClicked }: CardProps) {
  const { t } = useTranslation();
  return (
    <div
      className={`flex flex-col p-3 rounded-md border w-[220px] h-[128px]  border-gray-200 bg-gray-200 gap-2 ${
        expired ? 'text-gray-500' : ''
      }`}
    >
      <div className="flex">
        {selectImage(card.scheme)}
        <button onClick={() => onEditCardClicked(card)} className="ml-auto">
          <EditIcon aria-label={t('account:payments.manageCard.title')} />
        </button>
      </div>
      <p className="my-[-4px]">{card.clientName}</p>
      <div className="w-full text-sm">{`**** **** **** ${card.last4}`}</div>
      <div className="flex items-center">
        <div className="text-xs">{expired ? t('account:payments.addCard.expired') : card.expiry}</div>
        {card.defaultMitFlag && (
          <div
            className={`ml-auto text-sm  ${expired ? 'bg-vineHover' : 'bg-vine'}  px-2 rounded-lg ${
              expired ? 'text-gray-300' : 'text-white'
            }`}
          >
            {t('common:default')}
          </div>
        )}
      </div>
    </div>
  );
}
