import { View, Text, Image } from '@react-pdf/renderer';
import { styles } from '../../styles/index';
import { logo } from '../logo/index';
import SubHeading from './subHeading';
import { headerInfo } from '../../constants';
import { HeadingProps } from '../../types';

const Header = ({ accountHolderId, accountName }: HeadingProps) => {
  return (
    <View style={styles.headingContainer} fixed>
      <Image src={logo} style={styles.headingImg} />
      <View>
        <Text style={styles.headingText}>{accountName}</Text>
        <Text style={styles.headingText}>CustomerRef#:{accountHolderId}</Text>
        {headerInfo.map((info) => (
          <Text key={`${info}-index`} style={styles.headingText}>
            {info}
          </Text>
        ))}
        <Text>STATEMENT</Text>
      </View>
      <SubHeading />
    </View>
  );
};

export default Header;
