import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles';

const SubHeading = () => {
  return (
    <View>
      <View>
        <Text style={styles.subHeadingText}>Clearedge Financial PTC Ltd Upfront</Text>
        <Text style={styles.subHeadingText}>C-CLEAREDGGBP</Text>
      </View>
    </View>
  );
};

export default SubHeading;
