import * as Sentry from '@sentry/react';

type UserFlow = 'auth' | 'payment' | 'account' | 'pay_fee_preferences';
type Tags = {
  userFlow: UserFlow;
  [key: string]: string | number;
};

interface Context {
  user?: {
    email: string;
  };
  tags?: Tags;
}

export const logError = (error: string | Error | undefined, context: Context = {}) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log('%clogError', 'background: red; color: white', error);
  }

  const allContext = {
    ...context,
    tags: {
      ...context.tags,
      // additional data to be grouped as "tags"
      subscription: 'LogByConsole',
    },
  };

  if (typeof error === 'string') {
    Sentry.captureMessage(error, allContext);
  } else {
    Sentry.captureException(error, allContext);
  }
};

const errors = new Set<string>();
export const logErrorOnce = (error: string) => {
  if (!errors.has(error)) {
    logError(error);
    errors.add(error);
  }
};

export const logger = (data: Record<string, unknown>, tags: Record<string, string>) => {
  logError(JSON.stringify(data), tags);
};
