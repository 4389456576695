export enum MGMT_DATA_REFS {
  ID = 'id',
  NAME = 'portfolioName',
  VALUATION_DATE = 'valuationDate',
  FEE_TYPE = 'feeType',
  PORTFOLIO_VALUE = 'portfolioValue',
  OFFSET_VALUE = 'offsetValue',
  FFE_AMOUNT = 'feeAmount',
  APPLIED = 'appliedPct',
  INVOICE_NUMBER = 'invoiceNumber',
  INVOICE_DATE = 'invoiceDate',
  STATUS = 'status',
}

export enum MgmtFeesExplainedType {
  ANNUAL_FEES = 'annualMgmtFees',
  LATE_PAID_STOCK_FEES = 'latePaidStockFees',
  CANCELLED_DEAL_REBATE = 'cancelledDealRebate',

  // Commenting these out for the minute, they are types which technically exist but which
  // do not make it through to the client side. I want to wait until 14758 is released before deleting these.
  // PCI_STORAGE_FEE = 'pciStorageFee',
  // PCI_MANAGEMENT_FEE = 'pciMgmtFee',
  // PCI_STORAGE_FEE_VAT = 'pciStorageFeeVAT',
  // PCI_MANAGEMENT_FEE_VAT = 'pciMgmtFeeVAT',

  // TODO: https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/14758
  // check these once they start coming through.
  MONTHLY_MANAGEMENT_FEE = 'monthlyMgmtFee', // to replace ANNUAL_FEES
  MONTHLY_MANAGEMENT_FEE_UPFRONT_ACCOUNT = 'monthlyMgmtFeeUpfrontAccount',
  LEGACY_MANAGEMENT_FEE = 'legacyMgmtFee',
}

export interface ManagementFeeType {
  id: string;
  vintradeAccountHolderId: number;
  valuationDate: string;
  feeType: string;
  portfolioValue: number;
  offsetValue: number;
  feeAmount: number;
  appliedPct: number;
  invoiceNumber: string;
  invoiceDate: string;
  status: string;
  portfolioName: string;
  portfolioId: string;
}
