import { httpPost } from 'utils';
import { DESCRIPTIONS } from '../types';

export interface IAcceptHighestBidBody {
  accountId: string;
  description?: string;
  items: [
    {
      lwin18: string;
      stopLimitPerUnit: number;
      stopLimitType: string | undefined | number;
      priceType?: 'AcceptHighestBid';
      quantity: number;
    },
  ];
}
interface IAcceptHighestBidResponse {
  result: 'Result';
  status: 'Succeed' | 'Failed';
  errorMessage: null | string;
}

export const acceptHighestBid = (body?: IAcceptHighestBidBody) => {
  const path = `portal/Vintrade/Orders/Sell`;
  return httpPost<IAcceptHighestBidResponse, IAcceptHighestBidBody>(path, {
    ...(body as IAcceptHighestBidBody),
    description: DESCRIPTIONS.PORTAL_SELL,
  });
};
