import { ProductImage } from 'components/ProductTemplates';
import { ReactNode, useContext, useMemo } from 'react';
import { AppContext } from 'context/ContextProvider';
import { buildDisplayText } from 'utils';
import { DisplayTextKeys } from 'views/Trade/types';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { useNavigate } from 'react-router-dom';
import { NavigationPath } from 'types/DomainTypes';
import { AccountViewType, SubjectOptionKeys } from 'views/Accounts/types';
import { getRegionColors } from 'helpers';

export interface IWineInfoTemplate {
  config?: {
    wineName?: string;
    lwin18?: string;
    vintage?: number;
    quantity?: number;
    marketValue?: number;
    unitSize?: string;
    cultWinesAllocationRegion?: string;
    showTemplate?: boolean;
    removeWineNameAndImage?: boolean;
    region?: string;
    displayText?: {
      leftDisplayText?: DisplayTextKeys;
      rightDisplayText?: DisplayTextKeys;
    };
  };
  children?: ReactNode;
}

const WineInfoTemplate = ({ config, children }: IWineInfoTemplate) => {
  const { formatter } = useContext(AppContext);
  const wineName = config?.wineName;
  const lwin18 = config?.lwin18;
  const quantity = config?.quantity;
  const vintage = config?.vintage;
  const marketValue = config?.marketValue;
  const unitSize = config?.unitSize;
  const region = config?.region;
  const showTemplate = config?.showTemplate !== undefined ? config?.showTemplate : true;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'trade:common', t), [t]);
  const text = config?.displayText;
  const removeWineNameAndImage = config?.removeWineNameAndImage;
  const cultWinesAllocationRegion = config?.cultWinesAllocationRegion;
  const regionColor = useMemo(() => {
    return getRegionColors(cultWinesAllocationRegion ?? '').color;
  }, [cultWinesAllocationRegion]);

  return (
    <div className="flex flex-1 flex-col  bg-white">
      {showTemplate && (
        <>
          {!removeWineNameAndImage && (
            <>
              <ProductImage imageByLwin18={lwin18} region={region} bgColor={regionColor} />
              {/* <div className="bg-orange min-h-[15px] w-full" /> */}
              <div className="flex justify-between p-3 pb-5 border">
                <span className="text-20 mr-5">{`${vintage} ${wineName}`}</span>
              </div>
            </>
          )}
          <div className="flex flex-col border-b">
            {/* <div className="flex justify-center flex-col items-center p-5 pb-0">
              <span className="text-sm">{displayText[DisplayTextKeys.TOTAL_VALUE]}</span>
              <div className="flex">
                <div className="text-20 mr-2">
                  {quantity && marketValue
                    ? formatter.format(quantity * marketValue, true)
                    : formatter.format(marketValue || 0, true)}
                </div>
              </div>
            </div> */}

            <div className="my-5 flex justify-center">
              <div className="flex flex-col items-center flex-1 ">
                <div className="flex flex-col items-center border-r pb-3 border-r-gray-200 w-full">
                  <span className="text-xs">
                    {text?.leftDisplayText
                      ? displayText[text?.leftDisplayText]
                      : displayText[DisplayTextKeys.UINTS_OWNED]}
                  </span>
                  <span className="text-14">
                    {quantity}x({unitSize})
                  </span>
                </div>
              </div>

              <div className="flex flex-col items-center flex-1 w-full">
                <div className="flex flex-col items-center border-l pb-3 border-l-gray-200 w-full">
                  <span className="text-xs">
                    {text?.rightDisplayText
                      ? displayText[text?.rightDisplayText]
                      : displayText[DisplayTextKeys.VALUE_PER_UNIT]}
                  </span>
                  <span className="text-14">{formatter.format(marketValue || 0, true)}</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className={`flex-col flex flex-1 bg-white ${!showTemplate ? '' : ''}`}>{children}</div>

      {showTemplate && (
        <Button
          className="btn-link w-full flex justify-center text-center text-sm mb-3 self-end"
          type="button"
          isLink={true}
          onClick={() => {
            navigate(`${NavigationPath.ACCOUNTS}`, {
              state: { accountViewType: AccountViewType.CONTACT_US, subject: SubjectOptionKeys.GENERAL },
            });
          }}
        >
          {displayText[DisplayTextKeys.CONTACT_US_INFO]}
        </Button>
      )}
    </div>
  );
};

export default WineInfoTemplate;
