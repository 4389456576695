import Loading from 'components/Loading/loading';
import MessageTemplate from 'components/ProductTemplates/components/MessageTemplate';
import Table from 'components/Table';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { buildDisplayText, capitalizeFirstLetter } from 'utils';
import { buildTableRow } from 'views/Portfolio/helpers';
import { InitialiseTradeHistoryTable } from 'views/Trade/helpers';
import { useFetch } from 'views/Trade/hooks/useFetch';
import { fetchTransactionReport } from 'views/Trade/services/fetchTransactionReport';
import { DisplayTextKeys, NoResultsTextKeys, QUERY_KEYS, TRANSACTION_TYPE_ENUM } from 'views/Trade/types';
import { AppContext } from 'context/ContextProvider';
import moment from 'moment';
import noResultImage from '../../../../assets/images/no_results_image.png';

const PAGE_SIZE = 120;
const TradeHistory = () => {
  const { gbpToTargetCurrency } = useContext(AppContext);
  const { t } = useTranslation();
  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'trade:common', t), [t]);

  const [transactionType, setTransactionType] = useState<TRANSACTION_TYPE_ENUM>(TRANSACTION_TYPE_ENUM.BUY);

  const { isLoading: isLoadingTransactionReport, responce: transactionReportResponce } = useFetch({
    queryFn: () =>
      fetchTransactionReport({
        pageSize: PAGE_SIZE,
        transactionType: transactionType,
      }),
    queryKey: [`${QUERY_KEYS.FETCH_TRANSACTION_REPORT}${transactionType}`],
  });

  const [resultCount, setResultCount] = useState<number>(0);

  const { tradeHistoryColumns } = useMemo(
    () => InitialiseTradeHistoryTable(t, gbpToTargetCurrency),
    [t, gbpToTargetCurrency],
  );

  const tableBuilder = useMemo(() => {
    const source = transactionReportResponce?.data || [];

    source.sort((a, b) => moment(b.dateTime).diff(moment(a.dateTime)));
    setResultCount(source?.length || 0);
    const rows = (source || []).map((data) => {
      return {
        ...buildTableRow(
          {
            ...data,
          },
          tradeHistoryColumns,
        ),
      };
    });
    return { rows };
  }, [tradeHistoryColumns, transactionReportResponce]);

  return (
    <div className="flex flex-1 sm:p-5 bg-white w-full flex-col">
      <div className="flex items-center gap-1 mb-4 justify-between">
        <div className="flex items-center gap-1">
          <div className="flex gap-2 items-center">
            <div className="flex  items-center text-sm border rounded-full overflow-hidden ">
              {[TRANSACTION_TYPE_ENUM.BUY, TRANSACTION_TYPE_ENUM.SELL].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setTransactionType(tab)}
                  className={`py-1 px-4 border-r ${transactionType === tab ? 'bg-vine text-white' : null}`}
                >
                  {capitalizeFirstLetter(t(`trade:common.${tab}`))}
                </button>
              ))}
            </div>
          </div>
          <div className="text-sm  flex items-center gap-1">
            {isLoadingTransactionReport ? (
              <div className="flex items-center gap-2">
                <p>{displayText[DisplayTextKeys.LOADING]}</p>
                <Loading />
              </div>
            ) : (
              <p>{`${displayText[DisplayTextKeys.RESULT]} ${resultCount}`}</p>
            )}
          </div>
        </div>
      </div>

      <div className="justify-center items-center bg-white rounded-b-md flex-1 h-full ">
        {isLoadingTransactionReport && <Loading />}

        {!isLoadingTransactionReport && tableBuilder.rows.length > 0 && (
          <Table
            columns={tradeHistoryColumns}
            rows={tableBuilder.rows}
            applyfadeOnScrollClassName={false}
            isFadeOnScrollDisable={true}
          />
        )}

        {!isLoadingTransactionReport && !tableBuilder.rows.length && (
          <div className="animate-[fade-in_5s_ease-out]">
            <MessageTemplate
              showButton={false}
              displayTextKeys={NoResultsTextKeys}
              translationKey="common:noResult"
              imageSrc={noResultImage}
              title={
                <div className="flex items-center gap-3">
                  <span className="text-20 font-medium ">{t('common:noResult.title')}</span>
                </div>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TradeHistory;
