import { httpGet } from 'utils';
import { OfferTypeEnum, OffersType } from '../types';

interface IFetchBidResponce {
  data: OffersType[];
  count?: number;
}

interface IFetchBidsParams {
  page?: number;
  pageSize?: number;
  lwin18?: string;
}

export const fetchBid = (params?: IFetchBidsParams, signal?: AbortSignal | undefined) => {
  const path = `portal/Orders?offerType=${OfferTypeEnum.CUSTOM}&orderType=CwBid&inPackage=false&buyOrSell=B&pageSize=${
    params?.pageSize ?? 1
  }&page=${params?.page ?? 0}
  ${params?.lwin18 ? `&queryStr=${params.lwin18}` : ''}`;

  return httpGet<IFetchBidResponce>(path, undefined, true, signal);
};
