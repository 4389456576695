import { DownloadIcon } from 'assets/icons';
import { Dropdown } from 'components';
import ExportCSV from 'components/ExportToCsv';
import { DateFilters, DateType } from 'components/Filters/dateFilter';
import Loading from 'components/Loading/loading';
import PDFViewer, { PDFRef } from 'components/PDFViewer';
import Table from 'components/Table';
import { AppContext } from 'context/ContextProvider';
import { onExportToXlsx } from 'helpers';
import useFetchPortalDocument from 'hooks/useFetchPortalDocument';
import moment from 'moment';
import { FC, useContext, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PortalDocumentTypeEnum } from 'types/serviceTypes';
import { buildDisplayText, capitalizeFirstLetter, currencyFormatter, formatterGbp } from 'utils';
import CashflowReport from 'views/Reports/CashflowReport';
import { formatToObject } from 'views/shared/helpers';
import { ExtendedDropDownType } from 'views/Accounts/types';
import { ManagementFeeType } from '../ManagementFees/types';
import {
  InitialiseCashItemsSummaryTable,
  InitialiseCashItemsTable,
  buildTableRow,
  dateDropDownItem,
  datePickerText,
  detailsBoradDropDownItems,
  filterDataSourceByDate,
  filterDataSourceByDetailBroad,
  portfolioDropDownItems,
  processBalance,
} from './helpers';
import { InitialStateType, SELECT_ALL, mainReducer } from './reducers/mainReducer';
import { CashFlowDetailsFormatedType, CashFlowDetailsType } from './types';

enum DisplayTextKeys {
  TITLE = 'title',
  TOTAL = 'total',
  START_BALANCE = 'startBalance',
  END_BALANCE = 'endBalance',
  NO_STATEMENTS = 'noStatements',
  ALLPORTFOLIOS = 'allPortfolios',
  DATE = 'date',
  ALL = 'all',
  PORTFOLIOS = 'portfolios',
  SELECTDATE = 'selectDate',
  DETAIL_BROAD = 'detailBroad',
  CLEAR_FILTERS = 'clearFilters',
}

const CashflowDetails: FC = () => {
  const {
    state: {
      settings: { accountInfo, fullname },
    },
  } = useContext(AppContext);

  const { t } = useTranslation();
  const initialState: InitialStateType = {
    isDetailBoradCheck: [],
    selectedPortfolio: [],
    selectedPortfolioText: '',
    isPortfolioCheckSelectedText: undefined,
    isDetailBoradCheckSelectedText: undefined,
  };

  // test accountHolderId 2662 1566
  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'cashFlow:headings', t), [t]);
  const { data, isLoading } = useFetchPortalDocument({
    docType: PortalDocumentTypeEnum.CASH_FLOW_DETAILS,
  });

  const pdfRef = useRef<PDFRef>();
  const [state, dispatch] = useReducer(mainReducer, initialState);

  const [datePicker, setDatePicker] = useState<DateFilters>({
    [DateType.START]: null,
    [DateType.END]: null,
  });

  const formatedCashFlowDetails = useMemo((): CashFlowDetailsFormatedType[] => {
    if (!data?.data) return [];

    const cashFlowDetailsData = data?.data as CashFlowDetailsType[];
    let cashFlowData: CashFlowDetailsType | undefined;
    if (data.data.length > 1) {
      const dateStrings = data?.data as CashFlowDetailsType[];
      const dates = dateStrings.map((x) => new Date(x.date));

      const latestDate = dates.reduce((latest, currentDate) => {
        return currentDate > latest ? currentDate : latest;
      }, dates[0]);

      cashFlowData = cashFlowDetailsData.find((x) => String(new Date(x.date)) === String(latestDate));
    } else {
      cashFlowData = cashFlowDetailsData[0];
    }

    return formatToObject(cashFlowData?.statements.main.columns || [], cashFlowData?.statements.main.rows || []);
  }, [data]);

  const { cashItemsSummaryColumns } = useMemo(() => InitialiseCashItemsSummaryTable(t), [t]);
  const { cashItemsColumns } = useMemo(() => InitialiseCashItemsTable(t), [t]);

  const forReport = useMemo(
    () => processBalance(formatedCashFlowDetails).sort((a, b) => moment(b.date).diff(moment(a.date))),
    [formatedCashFlowDetails],
  );

  const detailsBoradDropDown = useMemo(
    () => detailsBoradDropDownItems(formatedCashFlowDetails, state.isDetailBoradCheck, dispatch),
    [formatedCashFlowDetails, state.isDetailBoradCheck, dispatch],
  );

  const portfolioDropDown = useMemo(
    () => portfolioDropDownItems(accountInfo?.portfolios || [], state.selectedPortfolio, dispatch),
    [accountInfo?.portfolios, state.selectedPortfolio, dispatch],
  );

  const dateDropDown = useMemo(() => {
    const moments = formatedCashFlowDetails.map((x) => moment(x.date));
    const maxMoment = new Date();
    const minMoment = moment.min(moments);
    return dateDropDownItem(datePicker, setDatePicker, maxMoment, minMoment.toDate());
  }, [datePicker, setDatePicker, formatedCashFlowDetails]);

  const rows = useMemo(() => {
    const source = filterDataSourceByDetailBroad(state, formatedCashFlowDetails);

    const getCashItemsSummary = () => {
      let total = 0;
      const dataRows: CashFlowDetailsFormatedType[] = [];
      const detailsBroad = [
        ...source.map((x) => ({
          client_id: x.client_id,
          date: x.date,
          details_broad: capitalizeFirstLetter(x?.details_broad || ''),
          cash: source.reduce((accumulator, currentValue) => {
            if (currentValue.details_broad === x.details_broad) return accumulator + Number(currentValue.cash);
            return accumulator;
          }, 0),
        })),
      ];

      const uniqueDetailsBroad = [...new Set(detailsBroad.map((x) => x.details_broad))];

      uniqueDetailsBroad.forEach((detailBroad) => {
        const detail = detailsBroad.find((row) => row.details_broad === detailBroad);
        if (!detail) return;
        total += Number(detail.cash);
        dataRows.push({
          client_id: detail.client_id,
          date: detail.date,
          details_broad: detail.details_broad,
          cash: currencyFormatter('en').format(Number(detail.cash)),
        });
      });

      dataRows.push({
        details_broad: 'Total',
        cash: currencyFormatter().format(total),
      });

      return dataRows;
    };

    const cashItemsSummaryRows = getCashItemsSummary().map((row) => {
      return {
        ...buildTableRow({ ...row }, cashItemsSummaryColumns),
      };
    });

    const newSource = processBalance(source);
    const cashItemsRowsUnfilteredByDate = [...newSource] as CashFlowDetailsFormatedType[];
    const cashItemsRows = (filterDataSourceByDate(datePicker, newSource) || []).map((row) => {
      return {
        ...buildTableRow({ ...row }, cashItemsColumns),
      };
    });

    return { cashItemsRows, cashItemsSummaryRows, cashItemsRowsUnfilteredByDate };
  }, [formatedCashFlowDetails, cashItemsSummaryColumns, cashItemsColumns, state, datePicker]);

  // const showClearButton = () => {
  //   if (state.isDetailBoradCheck.length || state.selectedPortfolio.length || datePicker[DateType.START]) return true;
  //   return false;
  // };

  const dropdowns = useMemo(() => {
    const dropdownLists: ExtendedDropDownType[] = [];

    if ((accountInfo?.portfolios?.length || 0) > 1) {
      dropdownLists.push({
        name: displayText[DisplayTextKeys.PORTFOLIOS],
        placeholder: displayText[DisplayTextKeys.ALLPORTFOLIOS],
        value: state.selectedPortfolioText,
        items: portfolioDropDown,
      });
    }

    dropdownLists.push(
      {
        name: displayText[DisplayTextKeys.DETAIL_BROAD],
        placeholder: displayText[DisplayTextKeys.DETAIL_BROAD],
        value: state.isDetailBoradCheckSelectedText,
        items: detailsBoradDropDown,
      },

      {
        name: displayText[DisplayTextKeys.DATE],
        autoClose: false,
        placeholder: displayText[DisplayTextKeys.SELECTDATE],
        value: datePickerText(datePicker),
        containerClassName: 'w-full rounded ',
        itemsContainerClassName: 'max-h-[530px] overflow-y-auto',
        className: ' text-14 text-black border    p-4 rounded justify-between items-center',
        itemsWrapperClassName: 'w-[330px]',
        items: dateDropDown,
        itemClassName: 'text-base flex pl-0 pr-0 pt-0 pb-0',
      },
    );

    return dropdownLists;
  }, [displayText, state, detailsBoradDropDown, portfolioDropDown, dateDropDown, accountInfo?.portfolios, datePicker]);

  const startAndEndBalance = useMemo(() => {
    let startBal = formatterGbp.format(0);
    if (rows.cashItemsRows.length > 0) {
      const isDateSelected = datePicker[DateType.START] || datePicker[DateType.START];
      const isAllDetailBroad = state.isDetailBoradCheck.includes(SELECT_ALL);

      const rowData = [...rows.cashItemsRows]?.map((x) => x.rowData);

      if (!isAllDetailBroad && isDateSelected) {
        rows.cashItemsRowsUnfilteredByDate?.forEach((x, index) => {
          if (x.rowIndex === rowData?.slice(-1)?.[0]?.rowIndex) {
            startBal = (rows.cashItemsRowsUnfilteredByDate[index + 1]?.balance as string) || startBal;
          }
        });
      }

      return {
        startBal,
        endBal: rowData[0]?.balance as string,
      };
    }

    return {
      startBal,
      endBal: formatterGbp.format(0),
    };
  }, [rows.cashItemsRows, state.isDetailBoradCheck, rows.cashItemsRowsUnfilteredByDate, datePicker]);

  return (
    <div className="py-3 overflow-hidden flex flex-col ">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {formatedCashFlowDetails.length === 0 && (
            <div className="items-center flex flex-1 h-full px-4">
              <span>{displayText[DisplayTextKeys.NO_STATEMENTS]}</span>
            </div>
          )}

          {formatedCashFlowDetails.length > 0 && (
            <div className="py-3 overflow-hidden flex flex-col ">
              <div className="flex flex-row justify-between items-center   px-3">
                <div className="flex-row flex gap-2 items-end  flex-wrap">
                  {dropdowns.map((x) => {
                    return (
                      <div className="flex flex-col  " key={x.name}>
                        {x.name && <span className="text-base text-gray-800">{x.name}</span>}
                        <Dropdown
                          autoClose={x.autoClose ?? false}
                          placeholder={x.placeholder ?? ''}
                          value={x.value}
                          containerClassName={x.containerClassName ?? 'w-full rounded '}
                          itemsContainerClassName={x.itemsContainerClassName ?? 'max-h-[280px] overflow-y-auto'}
                          className={
                            x.className ??
                            ' text-14 text-black border  w-[280px]  p-4 rounded justify-between items-center'
                          }
                          itemsWrapperClassName={x.itemsWrapperClassName ?? 'w-full'}
                          items={x.items || []}
                          itemClassName={x.itemClassName ?? 'text-base flex pl-0 pr-0 pt-0 pb-0'}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="flex flex-row gap-7 max-h-[280px] flex-wrap items-end">
                <div className="max-h-[280px]  max-w-[400px] overflow-y-auto px-3 mt-4  ">
                  <Table
                    applyfadeOnScrollClassName={false}
                    isFadeOnScrollDisable={true}
                    columns={cashItemsSummaryColumns || []}
                    rows={(rows.cashItemsSummaryRows || []).slice(0, rows.cashItemsSummaryRows.length - 1)}
                    className="table-fixed"
                  />
                </div>

                <div className="flex  gap-11">
                  <div className="flex flex-col  ">
                    <span className="text-center text-14 ">{displayText[DisplayTextKeys.START_BALANCE]} </span>
                    <span className="text-center text-md ">{startAndEndBalance.startBal} </span>
                  </div>

                  <div className="flex flex-col  ">
                    <span className="text-center text-14 ">{displayText[DisplayTextKeys.END_BALANCE]} </span>
                    <span className="text-center text-md ">{startAndEndBalance.endBal} </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-end gap-2 items-center mt-10 ">
                <ExportCSV
                  csvData={forReport}
                  fileName={`${'Statement_of_Account'}}-${moment().format('YYYY-MM-DD HH:mm')}`}
                  onPrepareData={(xlData: ManagementFeeType[]) => onExportToXlsx(xlData, cashItemsColumns)}
                >
                  <div
                    className={`pl-8 flex items-center ${
                      forReport.length > 0 ? 'cursor-pointer opacity-100' : 'pointer-events-none opacity-50'
                    }`}
                  >
                    <DownloadIcon />
                  </div>
                </ExportCSV>
              </div>
              <div className="w-full overflow-y-auto px-3 h-full     min-h-[300px]  ">
                <Table columns={cashItemsColumns || []} rows={rows.cashItemsRows || []} isFadeOnScrollDisable={true} />
              </div>
            </div>
          )}
        </>
      )}

      <PDFViewer
        ref={pdfRef}
        PDF={<CashflowReport ReportData={forReport} accountHolderId={0} accountName={fullname} />}
      />
    </div>
  );
};

export default CashflowDetails;
