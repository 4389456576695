import { FC, useMemo } from 'react';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { fetchOfferDocuments } from 'services';
import InvestPromotion, { InvestOfferProps } from './InvestPromotion';
import { sortByProp } from '../../../utils';
import { InvestOffer } from '../types';

interface PreviousInvestProps {
  onItemSelect: InvestOfferProps['onItemSelect'];
}

export const PreviousInvest: FC<PreviousInvestProps> = ({ onItemSelect }) => {
  const { data, isLoading } = useQuery({
    queryFn: () => fetchOfferDocuments({ docStatus: 'previous' }),
    queryKey: ['fetchOfferDocuments', 'previous'],
  });

  const offers = data?.results;

  const previousOffers = useMemo(() => {
    if (!offers) return [];

    return sortByProp(
      offers.map((x) => ({
        ...x,
        date: moment(x.expiryDate).toDate().getTime(),
        price: x.priceGbp,
        offerSource: 'prismic',
      })),
      { direction: 'desc', prop: 'date' },
    );
  }, [offers]);

  return (
    <InvestPromotion
      offers={previousOffers as unknown as InvestOffer[]}
      onItemSelect={onItemSelect}
      loading={isLoading}
    />
  );
};
