import Alert from 'components/Alert';
import { AppContext } from 'context/ContextProvider';
import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface LegacyFeeBannerProps {
  onOpen: () => void;
}

const LegacyFeeBanner: FC<LegacyFeeBannerProps> = ({ onOpen }) => {
  const { t } = useTranslation();
  const {
    state: {
      settings: { legacyFeesPolicy },
    },
  } = useContext(AppContext);
  useEffect(() => {
    if (!legacyFeesPolicy?.isDismissed) {
      onOpen();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Alert className="bg-orange w-full text-14 text-white m-0 animate-[fade-in_3s_ease-out] " show>
      <>
        <div className="flex items-center">
          <svg
            className="flex-shrink-0 w-4 h-4 mr-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <span className="sr-only">Select accrued payment preference</span>
          <h3 className="text-lg font-medium">{t('account:accruedFeePreference.banner.title')}</h3>
        </div>
        <div className="mt-2 mb-4 text-sm">{t('account:accruedFeePreference.banner.subTitle')}</div>
        <div className="flex">
          <button
            onClick={onOpen}
            type="button"
            className="text-white bg-vine font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center"
          >
            <svg
              className="-ml-0.5 mr-2 h-3 w-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 14"
            >
              <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
            </svg>

            {t('account:accruedFeePreference.banner.btnText')}
          </button>
        </div>
      </>
    </Alert>
  );
};

export default LegacyFeeBanner;
