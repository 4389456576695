import { useQueryClient } from '@tanstack/react-query';
import { CircleSolidInfoIcon, PortfolioBalanceIcon } from 'assets/icons';
import Dropdown, { DropdownItem } from 'components/Dropdown';
import useApiMutation from 'hooks/useApiMutation';
import moment from 'moment';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppEventTypes } from 'types/AppType';
import { CheckoutOtp } from 'views/Accounts/components/Checkout.Com/CheckoutOtp';
import { usePortfolioBalances } from 'views/Portfolio/components/Summary/hooks/usePortfolioBalances';
import { HowToPayEnum } from 'views/shared/HowToPay/type';
import MgmtPaymentTypeSelector, { RadioBtnOption } from 'views/shared/MgmtFeePolicyEnforcer/mgmtPaymentTypeSelector';
import { useNavigate } from 'react-router-dom';
import { NavigationPath, PortfolioBalance } from 'types/DomainTypes';
import { AccountViewType, SubjectOptionKeys } from 'views/Accounts/types';
import { IPurchaseParams } from 'views/shared/services/executeBuy';
import { StatusTypes, useHandleBuy } from 'views/shared/hooks/useHandleBuy';
import RequestWineOnFailedPurchase from 'views/shared/SlideOutViews/RequestWineOnFailedPurchase';
import { Button } from '../../../components';
import Accordion from '../../../components/Accordion';
import Loading from '../../../components/Loading/loading';
import { ProductImage } from '../../../components/ProductTemplates';
import InvestMoreTemplate from '../../../components/ProductTemplates/components/InvestMoreTemplate';
import { MessageProductTemplateProp } from '../../../components/ProductTemplates/components/MessageTemplate';
import {
  BuySellHoldingModel,
  InvestMoreTemplateViewEnum,
  PricingType,
  ProductEventType,
  ViewStateType,
} from '../../../components/ProductTemplates/types';
import Spacer from '../../../components/Spacer';
import { AppContext } from '../../../context/ContextProvider';
import { getRegions, handleImageError } from '../../../helpers';
import { buildDisplayText, formatterGbp, toInternalId } from '../../../utils';
import useExecutor from '../../hooks/useExecutor';
import { FeedbackTemplate } from '../../shared/FeedbackTemplate';
import { getPaymentOptions, isAllowedCampaignPurchase, storeCampaignPurchases } from '../helpers';
import { useInvestDetails } from '../hooks/useInvestDetails';
import { investNowApi } from '../services/investNowApi';

import { InventTopUpEnum, InvestOffer, TabTypes, campaignPurchaseRefType } from '../types';

enum DisplayTextKeys {
  INVEST_NOW_TEXT = 'invest_now_text',
  NOTIFY_WHEN_AVAILABLE = 'notify_when_available_text',
  TITLE = 'title',
  PRICE = 'price',
}

interface InvestNowRequest {
  dealRef?: string;
  qty: number;
  purchasePrice?: number;
  requestPrice?: number;
}

type MessageType = Pick<MessageProductTemplateProp, 'title' | 'subTitle' | 'onClick' | 'buttonText'>;
interface InvestSlideoutProps {
  offer: InvestOffer;
  timestamp?: number;
  onClose?: () => void;
  requestTab: TabTypes;
  viewIn?: ViewStateType;
}

const InvestSlideout: FC<InvestSlideoutProps> = ({ onClose, requestTab, offer, viewIn = ViewStateType.DEFAULT }) => {
  const {
    state: {
      settings: { accountInfo },
      app: { hasCard },
    },
    dispatch,
    formatter,
  } = useContext(AppContext);

  const nav = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const regions = useMemo(() => getRegions(t), [t]);

  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'invest:slideout', t), [t]);
  const [viewState, setViewState] = useState(ViewStateType.DEFAULT);
  const [feedbackState, setFeedbackState] = useState(ViewStateType.DEFAULT);
  const [purchaseInfo, setPurchaseInfo] = useState<campaignPurchaseRefType>();
  const [selectedOption, setSelectedOption] = useState<RadioBtnOption | null>();
  const [selectedPortfolio, setSelectedPortfolio] = useState<DropdownItem>();
  const isPrismicOffer = offer.offerSource === 'prismic';
  const [buySellModel, setBuySellModel] = useState<BuySellHoldingModel>({
    price: 0,
    units: 0,
    reason: '',
    pricingType: PricingType.MARKET,
    investMoreTemplateView: !isPrismicOffer ? InvestMoreTemplateViewEnum.CAMPAIGNS : null,
  });
  const [topupAmount, setTopAmount] = useState(0);

  const { portfolioBalances } = usePortfolioBalances();

  const feeModelInfo = useMemo(() => {
    const pBalances = portfolioBalances.filter((z) => `${z.portfolioId}`.length > 0);

    const feeModels = pBalances.filter((x: PortfolioBalance) => `${x.portfolioId}`.length > 0);
    const otherFeeModelExist = !!feeModels.find((x) => x.currentFeeModel === false);

    const canExecTransaction = (accountInfo?.account.totalCashOnAccount ?? 0) >= offer.price * buySellModel.units;

    const validTopupPortfolios = feeModels.filter(
      (x: PortfolioBalance) => `${x.portfolioId}`.length > 0 && !!x.currentFeeModel && x.currentFeeModel === true,
    );
    const canTopUp = validTopupPortfolios && validTopupPortfolios.length > 0;
    const ddlOptions = (
      canExecTransaction
        ? pBalances ?? []
        : pBalances.filter(
            (x: PortfolioBalance) => `${x.portfolioId}`.length > 0 && !!x.currentFeeModel && x.currentFeeModel === true,
          ) ?? []
    ).map((balance: PortfolioBalance) => {
      const { portfolioId, portfolioName } = balance;
      return {
        id: portfolioId,
        value: portfolioId,
        content: (
          <div className="flex justify-between text-base">
            <span>{portfolioName}</span>
          </div>
        ),
        text: portfolioName,
      };
    });

    if (ddlOptions.length === 1) setSelectedPortfolio(ddlOptions[0]);

    return {
      showFeeModelInfo: otherFeeModelExist,
      canTopUp,
      canExecTransaction,
      ddlOptions,
    };
  }, [portfolioBalances, offer, buySellModel, accountInfo]);

  const { sections, loading } = useInvestDetails({
    id: offer.id,
    enabled: isPrismicOffer,
  });
  const [feedbackTemplateConfig, setFeedbackTemplateConfig] = useState<MessageType>({
    onClick: () => null,
  });

  const purchaseInfoRef = useMemo(() => {
    return encodeURIComponent(btoa(JSON.stringify(purchaseInfo)));
  }, [purchaseInfo]);

  const { handlePurchase: executeBuy, mutateStatus: status, resetStatus } = useHandleBuy();

  const isAllowedPurchase = useMemo(() => {
    const numberOfUnitsAllowed = isAllowedCampaignPurchase({
      campaignId: offer?.campaignId,
      lwin18: offer.lwin18,
      quantity: offer.quantityOutstanding,
      numberOfUnitsAllowed: offer.numberOfUnitsAllowed,
      id: accountInfo?.portfolios?.map((x) => x.portfolioName).toString(),
    });
    return numberOfUnitsAllowed;
  }, [offer, accountInfo?.portfolios]);

  const options = useMemo((): RadioBtnOption[] => {
    return getPaymentOptions(hasCard as boolean);
  }, [hasCard]);

  const onExecutorStatusChange = (vState: ViewStateType, feedbackConfig?: MessageType) => {
    if (feedbackConfig) setFeedbackTemplateConfig(feedbackConfig);
    setFeedbackState(vState);
    setViewState(vState);
  };

  const executor = useExecutor({
    mutation: useApiMutation(investNowApi),
    viewRef: ViewStateType.INVEST_MORE,
    onStatusChange: onExecutorStatusChange,
    onClose: onClose || (() => null),
  });

  const accordionItems = useMemo(() => {
    return sections?.map((section, i) => ({
      id: `${i}`,
      title: `${section.title}`,
      titleContainerClassName: 'text-base p-5',
      content: () => <div className="text-14 p-3" dangerouslySetInnerHTML={{ __html: section.content }} />,
    }));
  }, [sections]);

  const onRequest = () => {
    if (requestTab === TabTypes.CURRENT) {
      setViewState(ViewStateType.INVEST_MORE);
    } else
      processInvestMore(ProductEventType.EXECUTE_BUY_PRODUCT_REQUEST, {
        qty: 0,
      });
  };

  const processBuyRequest = async (offerIn: InvestOffer) => {
    if (!selectedPortfolio) {
      setViewState(ViewStateType.SELECT_PORTFOLIO);
      return;
    }
    const {
      id: offerId,
      campaignId,
      lwin18,
      price: offerPrice,
      name: offerLabel,
      stringRef,
      priceGbp,
      exchangeRate: fxRate,
    } = offerIn;

    const purchaseRefData: campaignPurchaseRefType = {
      prevAcctBalance: accountInfo?.account.totalCashOnAccount,
      id: offerId,
      campaignId,
      portfolioId: Number(selectedPortfolio.value),
      lwin18,
      offerId: offerId,
      price: offerPrice,
      quantity: buySellModel.units,
      name: offerLabel,
      priceGbp,
      subtitle: '',
      stringRef: stringRef,
      userId: accountInfo?.portfolios?.map((x) => x.portfolioName).toString(),
    };

    setPurchaseInfo(purchaseRefData);

    const purchasePrice = price * buySellModel.units;
    const _topupAmount = purchasePrice - (accountInfo?.account.totalCashOnAccount ?? 0);

    if (_topupAmount > 0) {
      if (!feeModelInfo.canTopUp) {
        setViewState(ViewStateType.SELECT_PORTFOLIO);
        return;
      }
      setTopAmount(_topupAmount / (fxRate ?? 1));
      setViewState(ViewStateType.TOP_UP);
      return;
    }
    const params: IPurchaseParams = {
      body: {
        portfolioId: Number(selectedPortfolio.value),
        description: offer.stringRef ?? '',
        buys: [
          {
            lwin18: lwin18,
            offerId: offerId,
            price: priceGbp,
            quantity: buySellModel.units,
          },
        ],
      },
    };
    await executeBuy(params);
  };

  const processInvestMore = <T,>(eventType: ProductEventType, eventData: T) => {
    const { qty } = eventData as InvestNowRequest;
    const { name: _title, subtitle: _subTitle, priceGbp, expiryDate: _expiryDate } = offer;

    const investRequest = {
      offerTitle: _title,
      offerSubTitle: _subTitle,
      offerPrice: priceGbp,
      numberOfUnits: qty,
      totalPrice: Number(priceGbp) * qty,
      offerExpiryDate: _expiryDate,
    };

    executor(investRequest);
  };

  useEffect(() => {
    if (viewIn !== viewState) {
      setViewState(viewIn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewIn]);

  useEffect(() => {
    if (status === StatusTypes.isLoading) {
      setViewState(ViewStateType.LOADING);
      setFeedbackState(ViewStateType.LOADING);
    }

    if (status === StatusTypes.error) setFeedbackState(ViewStateType.ERROR_RESULT);
    if (status === StatusTypes.success && feedbackState !== ViewStateType.CAMPAIGN_PURCHASE) {
      dispatch({
        type: AppEventTypes.UPDATE_STATE,
        payload: {
          campaignPurchaseRef: {
            id: offer.id,
            quantity: buySellModel.units,
          },
        },
      });
      storeCampaignPurchases({
        campaignId: offer.campaignId,
        lwin18: offer.lwin18,
        quantity: buySellModel.units,
        id: accountInfo?.portfolios?.map((x) => x.portfolioName).toString(),
      });
      setFeedbackState(ViewStateType.CAMPAIGN_PURCHASE);
      setTimeout(() => {
        queryClient.refetchQueries({ queryKey: ['fetchCampaignOffers'] });
        accountInfo?.account?.refetchCashBalances?.();
      }, 2000);
    }
  }, [
    status,
    buySellModel,
    offer,
    dispatch,
    accountInfo?.account,
    accountInfo?.portfolios,
    queryClient,
    feedbackState,
  ]);

  const {
    name: title,
    subtitle: subTitle,
    price,
    expiryDate,
    region,
    unitSize,
    mainImage,
    initialQuantity,
    quantityOutstanding,
    offerSource,
    exchangeRate: fxRate,
  } = offer;
  const selectedRegion = regions.find((x) => x.id === toInternalId(region.toLowerCase()));
  const regionColor = selectedRegion?.color || '#FFFFFF';
  const textColor = `text-${selectedRegion?.textColor || 'black'}`;
  const buttonText =
    displayText[
      requestTab === TabTypes.CURRENT ? DisplayTextKeys.INVEST_NOW_TEXT : DisplayTextKeys.NOTIFY_WHEN_AVAILABLE
    ];

  const buttonStyle = requestTab === TabTypes.CURRENT ? 'bg-orange' : 'border border-gray-900';

  const calulateRemaingBal = (PortfolioView = false) => {
    const purchasePrice = price * buySellModel.units;
    const remaining = (accountInfo?.account.totalCashOnAccount ?? 0) - purchasePrice;
    let btnText = '';
    if (feeModelInfo.ddlOptions.length > 1 && !PortfolioView) {
      btnText = 'Select portfolio';
    } else if (remaining >= 0) {
      btnText = 'Confirm purchase';
    } else {
      btnText = 'Continue to top-up';
    }

    return { remainingBal: remaining, btnText };
  };

  return (
    <div className="flex flex-col flex-1 bg-gradient-to-b from-vine to-gray-500 pb-5 px-3 w-screen relative overflow-x-hidden sm:w-[390px]">
      <div className="w-full h-full rounded-md  overflow-y-auto flex flex-col">
        {status === StatusTypes.error && (
          <div className="flex flex-col bg-white flex-1 h-full">
            <RequestWineOnFailedPurchase
              offer={offer}
              onStatusChange={onExecutorStatusChange}
              resetStatus={resetStatus}
              onClose={onClose}
              qtyBought={buySellModel.units}
            />
          </div>
        )}

        {viewState === ViewStateType.SELECT_PORTFOLIO && status !== StatusTypes.error && (
          <div className="flex flex-col bg-white h-full p-4">
            <div className="flex flex-1 flex-col">
              {feeModelInfo.showFeeModelInfo && (
                <div className="mb-[60px]">
                  <div className="flex items-center w-full mt-8 mb-4">
                    <div className="mr-5">{t`account:slideout.topup.recurring_payment_info_title`}</div>
                    <CircleSolidInfoIcon />
                  </div>
                  <p className="text-sm w-full flex-1 h-full overflow-x-hidden">
                    {t(`account:slideout.topup.current_fee_model_info`)}
                  </p>
                </div>
              )}
              {feeModelInfo.ddlOptions.length > 1 && (feeModelInfo.canExecTransaction || feeModelInfo.canTopUp) && (
                <>
                  <Dropdown
                    placeholder="Select portfolio"
                    value={selectedPortfolio?.text}
                    containerClassName="w-full rounded mb-[20px] "
                    itemsContainerClassName="max-h-[250px] min-h-10 overflow-y-auto"
                    className="flex-1 text-14 text-black border-b  h-12 py-2 rounded justify-between items-center "
                    itemsWrapperClassName="w-full"
                    items={feeModelInfo.ddlOptions}
                    onItemSelect={(item) => {
                      setSelectedPortfolio(item);
                    }}
                  />
                  <Button
                    isDisable={!selectedPortfolio || !feeModelInfo.canTopUp}
                    className={` my-5 text-14 font-normal rounded-full btn flex justify-center items-center py-3 text-black disabled:bg-gray-500 ${buttonStyle}`.trim()}
                    onClick={() => processBuyRequest(offer)}
                    props={{
                      name: 'requestTab',
                    }}
                  >
                    {calulateRemaingBal(true).btnText}
                  </Button>
                </>
              )}

              {!feeModelInfo.canTopUp && !feeModelInfo.canExecTransaction && (
                <Button
                  className={` my-5 text-14 font-normal rounded-full btn flex justify-center items-center py-3 text-black disabled:bg-gray-500 ${buttonStyle}`.trim()}
                  onClick={() => {
                    nav(NavigationPath.ACCOUNTS, {
                      state: {
                        accountViewType: AccountViewType.CONTACT_US,
                        subject: SubjectOptionKeys.FINANCIAL,
                      },
                    });
                  }}
                  props={{
                    name: 'requestTab',
                  }}
                >
                  Contact us
                </Button>
              )}
            </div>
          </div>
        )}

        {viewState === ViewStateType.TOP_UP && (
          <div className="bg-white h-full">
            <div className="flex items-center flex-col p-5 gap-2">
              <div className="w-[32px] h-[26.18px]">
                <PortfolioBalanceIcon className="mr-2 bg-gray-200 " />
              </div>
              <span className="text-14">{t('account:slideout.topup.top_up_amount')}</span>
              <span className="text-center text-lg border-none">{`${formatter.format(
                topupAmount * (fxRate ?? 1),
                true,
              )}`}</span>
              {(fxRate ?? 1) !== 1 && (
                <>
                  <span className="text-center text-[18px] border-none">{`${formatterGbp.format(topupAmount)}`}</span>
                  <div className="mt-3">
                    <span className="text-[14px]">
                      <strong className="text-[16px] mr-2">Please note:</strong> The amount displayed in your default
                      currency is for reference only. All transactions will be processed and charged in GBP.
                    </span>
                  </div>
                </>
              )}
            </div>
            <MgmtPaymentTypeSelector
              onSelected={(selected) => setSelectedOption(selected)}
              options={options}
              selectedId={selectedOption?.id}
            />

            <CheckoutOtp
              showTopUpIcon={false}
              isUseExistingCard={selectedOption?.id === (HowToPayEnum.CARD_ON_FILE as string)}
              amount={topupAmount}
              portfolioId={Number(selectedPortfolio?.value) || -1}
              onSuccessUrl={`#${InventTopUpEnum.onInvestSuccess}=${purchaseInfoRef}`}
              onFailureUrl={`#${InventTopUpEnum.onInvestFailure}=${purchaseInfoRef}`}
            />
          </div>
        )}

        {viewState !== ViewStateType.TOP_UP &&
          viewState !== ViewStateType.SELECT_PORTFOLIO &&
          status !== StatusTypes.error && (
            <FeedbackTemplate
              onClose={() => {
                if (status === StatusTypes.isLoading) return;
                if (onClose) onClose();
              }}
              templateConfig={feedbackTemplateConfig}
              viewState={feedbackState}
              onCTA={(vState) => {
                if (feedbackState === ViewStateType.CAMPAIGN_PURCHASE) {
                  nav(`${NavigationPath.MY_PORTFOLIO}#find=${purchaseInfoRef}`);
                  return;
                }
                setFeedbackState(vState);
              }}
            >
              <>
                <ProductImage
                  imageUrl={mainImage}
                  bgColor={regionColor}
                  region={region}
                  textColor={textColor}
                  imageContainer="!min-w-[100%] "
                >
                  <div className="flex justify-center w-full">
                    <img
                      className=" w-[152.94px] h-[230px]  object-contain"
                      alt={title}
                      src={mainImage}
                      onError={handleImageError}
                    />
                  </div>
                </ProductImage>
                <div className="flex flex-col bg-white flex-1 h-100">
                  <div className="flex-col p-3 pb-5">
                    <span className="text-20 mr-5">{`${title}`}</span>
                    {subTitle && <p className="text-14">{subTitle}</p>}
                  </div>
                  <div className="flex justify-between items-center px-3 pb-1">
                    <div className="text-right text-20">{formatter.format(Number(price), true)}</div>
                  </div>
                  <div className="flex justify-between items-center text-14 px-3 ">
                    <span>{`${moment(expiryDate).format('YYYY-MM-DD')}`}</span>
                    <span>{`${unitSize}`}</span>
                  </div>
                  <div className="flex justify-between items-center text-14 px-3 ">
                    {!isPrismicOffer && (
                      <span className="text-sm">
                        Quantity: {quantityOutstanding} / {initialQuantity}
                      </span>
                    )}
                  </div>

                  {viewState === ViewStateType.INVEST_MORE && (
                    <InvestMoreTemplate
                      formatter={{
                        format: (val: number) => formatter.format(val, true),
                      }}
                      canCustomPrice={false}
                      onCTA={processInvestMore}
                      marketPrice={Number(price)}
                      model={buySellModel}
                      qtyOwned={0}
                      setModel={(modelIn) => setBuySellModel(modelIn)}
                      overideOnCTA={!isPrismicOffer ? [() => processBuyRequest(offer)] : null}
                      quantity={!isPrismicOffer ? isAllowedPurchase?.qty || 0 : null}
                      isPrismicOffer={isPrismicOffer}
                      extraData={{
                        buttonText: calulateRemaingBal().btnText,
                        accountBalance: accountInfo?.account.totalCashOnAccount ?? 0,
                        accountBalanceRemaining: calulateRemaingBal().remainingBal,
                        isLD: offerSource === 'LD',
                      }}
                    />
                  )}

                  {viewState === ViewStateType.DEFAULT && (
                    <>
                      <Button
                        isDisable={!isAllowedPurchase?.isAllowed}
                        className={`mx-3 my-5 text-14 font-normal rounded-full btn flex justify-center items-center py-3 text-black disabled:bg-gray-500 ${buttonStyle}`.trim()}
                        onClick={onRequest}
                        props={{
                          name: requestTab,
                        }}
                      >
                        {buttonText}
                      </Button>

                      {!isAllowedPurchase?.isAllowed ? (
                        <span className="text-sm mx-3 text-red text-center">{`Only a max of ${offer.numberOfUnitsAllowed} units are allowed per customer`}</span>
                      ) : null}

                      {loading && isPrismicOffer ? (
                        <div className="h-full w-full flex-1 justify-center p-5">
                          <Loading />
                        </div>
                      ) : (
                        <>
                          <Accordion className="divide-y divide-gray-300" items={accordionItems || []} />
                          <Spacer h="100px" />
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            </FeedbackTemplate>
          )}
      </div>
    </div>
  );
};

export default InvestSlideout;
