import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useFetch } from './useFetch';
import { QUERY_KEYS } from '../types';

interface DATA<T> {
  data?: T[];
  count?: number;
}

interface UseFetchSilentlyParams<T, P> {
  pageSize: number;
  queryFn: (params?: P, signal?: AbortSignal | undefined) => Promise<DATA<T>>;
  params: P;
  queryKey: QUERY_KEYS;
  refetchOnWindowFocus?: boolean;
  timeOutBetweenFetch?: number;
}

export const useSilentFetch = <T, P>({
  pageSize,
  timeOutBetweenFetch = 2000,
  queryFn,
  queryKey,
  params,
  refetchOnWindowFocus = true,
}: UseFetchSilentlyParams<T, P>) => {
  // console.log('================== startSilentFetch');
  const {
    responce: initResponse,
    isLoading: initIsLoading,
    refetch,
    isFetching,
    error: initError,
  } = useFetch({
    queryFn: () => queryFn({ ...params, pageSize, page: 0 }),
    queryKey: [queryKey],
    shouldCache: false,
    refetchOnWindowFocus,
  });

  const initResult = useMemo(() => {
    if (!initResponse?.data || initError) return null;
    return { count: initResponse?.count || 0, data: initResponse.data };
  }, [initResponse?.data, initResponse?.count, initError]);

  // useEffect(() => {
  //   if (!refreash || !merge || !refetchOnWindowFocus) return;
  //   const handleFocus = () => {
  //     refreash();
  //   };

  //   window.addEventListener('focus', handleFocus);

  //   return () => {
  //     window.removeEventListener('focus', handleFocus);
  //   };
  // }, [merge, refreash, refetchOnWindowFocus]);

  const startSilentFetch = useCallback(
    async (signal?: AbortSignal | undefined) => {
      if (!initResult?.data) return [];
      const numberOfPages = Math.floor((initResult.count > pageSize ? initResult.count - pageSize : 0) / pageSize);
      const remainingPage = initResult.count % pageSize;
      const fetchResult = [];

      // console.log(`startSilentFetch-- init count  ${queryKey}`, initResult.count);
      // console.log(`startSilentFetch-- numberOfPages  ${queryKey}`, numberOfPages);
      // console.log(`startSilentFetch-- remainingPage  ${queryKey}`, remainingPage);
      // console.log(`=======================================  ${queryKey}`);
      if (!numberOfPages) {
        return [];
      }

      let trackPage = 0;
      /* eslint-disable no-await-in-loop */
      for (let page = 1; page < numberOfPages; page++) {
        // console.log(`startSilentFetch-- page  in loop  ${queryKey}`, page);
        trackPage = page;
        await new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              const fetchResponse = await queryFn({ ...params, pageSize, page }, signal);
              const resp = fetchResponse?.data ?? [];
              if (resp.length > 0) fetchResult.push(...resp);
              resolve(null);
            } catch (error) {
              reject(new Error('bg fetch error'));
            }
          }, timeOutBetweenFetch);
        });
      }

      if (remainingPage) {
        // console.log('startSilentFetch-- has remainingPage ', remainingPage);
        try {
          const fetchResponse = await queryFn({ ...params, pageSize: remainingPage, page: trackPage + 1 }, signal);
          const resp = fetchResponse?.data ?? [];
          if (resp.length > 0) fetchResult.push(...resp);
        } catch (error) {
          console.log('fetch error');
        }
      }

      if (fetchResult.length) {
        return fetchResult;
      }
    },
    [initResult?.data, initResult?.count, timeOutBetweenFetch, pageSize, params, queryFn],
  );

  const {
    data: backgroundDataResponse,
    isFetching: backgroundDataIsFetching,
    refetch: backgroundDataRefetch,
  } = useQuery([`${queryKey}InBackground`], ({ signal }) => startSilentFetch(signal), {
    cacheTime: 0,
    enabled: false,
  });

  useEffect(() => {
    if (!initResult?.data || isFetching) return;
    backgroundDataRefetch();
  }, [backgroundDataRefetch, initResult?.data, isFetching]);

  const response = useMemo(() => {
    //   console.log('startSilentFetch initResult?.data', initResult?.data);

    if (backgroundDataResponse?.length && !backgroundDataIsFetching) {
      const res = [...(initResult?.data || []), ...backgroundDataResponse];
      return {
        count: res.length,
        data: res,
      };
    }
    return {
      count: initResult?.count || 0,
      data: initResult?.data || [],
    };
  }, [initResult?.data, initResult?.count, backgroundDataResponse, backgroundDataIsFetching]);

  // console.log('backgroundError ', backgroundError);
  // console.log('backgroundDataResponse ', backgroundDataResponse);
  // console.log('dataResponse ', dataResponse);
  // console.log('dataResponse ', dataIsLoading);
  // console.log('startSilentFetch-- page  in loop error ', error);

  return { isBackgroundFetchActive: backgroundDataIsFetching, response: response.data, initIsLoading, refetch };
};
