import { logErrorOnce } from 'components/LogError';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en-GB',
    fallbackLng: 'en-GB',
    ns: [
      'common',
      'account',
      'auth',
      'product',
      'portfolio',
      'invest',
      'notifications',
      'discover',
      'cashFlow',
      'trade',
    ],
    defaultNS: 'common',
    keySeparator: '.',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    missingKeyHandler(lng, ns, key) {
      logErrorOnce(`Missing translation: ${key} (${lng}/${ns}.json)`);
    },
    // TODO
    // Currently turned off for prod to avoid flooding Sentry with errors.
    // Fix all errors and enable for all environments https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/14556
    saveMissing: process.env.NODE_ENV === 'development',
  });

export default i18n;
