import { AccountInfoType } from 'views/hooks/useFetchAccountData';
import { TopupSlideoutViewType } from '../views/Accounts/types';
import { ActionMap } from './commonTypes';

export interface AppState {
  campaignPurchaseRef?: {
    quantity: number;
    id: string;
  } | null;
  isConfirmPayment: boolean;
  paymentType: TopupSlideoutViewType;
  [key: string]: unknown;
}

export interface ManagementFeePolicy {
  type: string;
  startDate: string;
  endDate: string;
  loadOnInit?: boolean;
  timestamp: string;
  howToPay?: string;
}

export interface LegacyFeePolicy {
  hasCard: boolean;
  startDate: string;
  endDate: string;
  howToPay?: string;
  timestamp: string;
  isDismissed?: boolean;
}

export interface UserSettings {
  // accountInfo: CwiAccountType | null;
  // accountName: string | null;
  accountHolderId: number | null;
  //  userId: string;
  // assetAccountId: string;
  // relationshipManagerId: string;
  accountInfo: AccountInfoType | null;

  language: string;
  currency: string;
  email: string;
  fullname: string;
  firstLoginDate: string;
  mgmtFeesPolicy: ManagementFeePolicy;
  legacyFeesPolicy: LegacyFeePolicy;
  isExemptFromFees: boolean;
  bankTransferChaseEmailCount?: number;
  [key: string]: unknown;
}

export enum SettingsEventTypes {
  UPDATE_SETTINGS = 'update_settings',
  UPDATE_MGMT_FEE_SETTINGS = 'update_mgmt_fee_settings',
  UPDATE_LEGACY_FEE_SETTINGS = 'update_legacy_fee_settings',
  PAYMENT_REMINDER = 'payment_reminder',
}

type SettingsPayload = {
  [SettingsEventTypes.UPDATE_SETTINGS]: Partial<UserSettings>;
  [SettingsEventTypes.UPDATE_MGMT_FEE_SETTINGS]: Partial<ManagementFeePolicy>;
  [SettingsEventTypes.UPDATE_LEGACY_FEE_SETTINGS]: Partial<LegacyFeePolicy>;
};

export type SettingsAction = ActionMap<SettingsPayload>[keyof ActionMap<SettingsPayload>];

export type MiscellaneousType = Record<string, unknown>;
export enum MiscellaneousEventTypes {
  UPDATE_MISC = 'update_misc',
}

type MiscellaneousPayload = {
  [MiscellaneousEventTypes.UPDATE_MISC]: Partial<MiscellaneousType>;
};

export type MiscellaneousAction = ActionMap<MiscellaneousPayload>[keyof ActionMap<MiscellaneousPayload>];

export enum AppEventTypes {
  UPDATE_STATE = 'update_appState',
}
type AppPayload = {
  [AppEventTypes.UPDATE_STATE]: Partial<AppState>;
};

export type AppAction = ActionMap<AppPayload>[keyof ActionMap<AppPayload>];
