import { httpGet } from 'utils';
import { OffersType, OfferTypeEnum, PortfolioOffersType } from '../types';

interface IFetchPortfolioStocksResponce {
  data: PortfolioOffersType[];
  count?: number;
}

interface IFetchPortfolioOffersParams {
  page?: number;
  pageSize?: number;
  search?: string;
}

export const fetchPortfolioOffers = (params?: IFetchPortfolioOffersParams, signal?: AbortSignal | undefined) => {
  const path = `portal/PortfolioOffers?pageSize=${params?.pageSize}&page=${params?.page}
  ${params?.search ? `&queryStr=${params.search}` : ''}`;
  return httpGet<IFetchPortfolioStocksResponce>(path, undefined, true, signal);
};

// export const fetchPortfolioOffersDetails = (params?: IFetchPortfolioOffersParams, signal?: AbortSignal | undefined) => {
//   const path = `portal/Orders?offerType=${
//     OfferTypeEnum.PORTFOLIO_OFFERS
//   }&orderType=Cwi&inPackage=false&buyOrSell=S&pageSize=${params?.pageSize}&page=${params?.page}
//   ${params?.search ? `&queryStr=${params.search}` : ''}`;

//   return httpGet<IFetchPortfolioStocksDetailsResponce>(path, undefined, true, signal);
// };
