import { useMutation } from '@apollo/client';
import { UPDATE_DEFAULT_CHECKOUT_DOT_COM_CARD } from '../graphql/updateDefaultCheckoutDotComCard';
import {
  UpdateDefaultCheckoutDotComCardMutation,
  UpdateDefaultCheckoutDotComCardMutationVariables,
} from '__generated__/graphql';

export function useUpdateDefaultCheckoutDotComCard() {
  const [updateDefaultCard, { loading: processingDefaultCardRequest }] = useMutation<
    UpdateDefaultCheckoutDotComCardMutation,
    UpdateDefaultCheckoutDotComCardMutationVariables
  >(UPDATE_DEFAULT_CHECKOUT_DOT_COM_CARD, {
    onError: () => null,
  });

  return { updateDefaultCard, processingDefaultCardRequest };
}
