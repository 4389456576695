import { useQueryClient } from '@tanstack/react-query';
import { AppContext } from 'context/ContextProvider';
import { useContext, useMemo, useState } from 'react';
import { STOP_LIMIT_TYPE } from 'views/Trade/constants';
import { useFetch } from 'views/Trade/hooks/useFetch';
import useMutate from 'views/Trade/hooks/useMutate';
import { acceptHighestBid, IAcceptHighestBidBody } from 'views/Trade/services/acceptHighestBid';
import { deleteOffer, IDeleteOffersParams } from 'views/Trade/services/deleteOffer';
import { fetchPortfolioOffers } from 'views/Trade/services/fetchPortfolioOffers';
import { fetchPortfolioStocks } from 'views/Trade/services/fetchPortfolioStocks';
import { PortfolioOffersType, QUERY_KEYS } from 'views/Trade/types';

const PAGE = 0;
const PAGESIZE = 10;

enum ACCEPT_BID_STATUS {
  success = 'success',
  error = 'error',
  isLoading = 'isLoading',
}

const useAcceptBid = (lwin18: string) => {
  const queryClient = useQueryClient();
  const {
    state: {
      settings: { currency },
    },
  } = useContext(AppContext);
  const { responce: portfolioStocksResponce, isLoading: isLoadingStocks } = useFetch({
    queryFn: () => fetchPortfolioStocks({ search: lwin18, page: PAGE, pageSize: PAGESIZE }),
    queryKey: [`${QUERY_KEYS.FETCH_PORTFOLIO_STOCK}${lwin18}`],
    isEnabled: !!lwin18,
    shouldCache: false,
  });

  const { responce: portfolioOffersResponce, isLoading: isLoadingOffers } = useFetch({
    queryFn: () => fetchPortfolioOffers({ search: lwin18, page: PAGE, pageSize: PAGESIZE }),
    queryKey: [`${QUERY_KEYS.FETCH_PORTFOLIO_OFFERS}${lwin18}`],
    isEnabled: !!lwin18,
  });

  const initLoading = useMemo(() => {
    return isLoadingStocks || isLoadingOffers;
  }, [isLoadingStocks, isLoadingOffers]);

  const offers = useMemo(() => {
    if (!portfolioOffersResponce?.count) return null;
    const data = portfolioOffersResponce.data;
    return data;
  }, [portfolioOffersResponce?.data, portfolioOffersResponce?.count]);

  const stocks = useMemo(() => {
    if (!portfolioStocksResponce?.count) return null;
    const data = portfolioStocksResponce.data;
    return data[0];
  }, [portfolioStocksResponce?.data, portfolioStocksResponce?.count]);

  const { mutateAsync: mutateAcceptHighestBid } = useMutate({ mutationFn: acceptHighestBid });
  const { mutateAsync: mutateDeleteOffer } = useMutate({ mutationFn: deleteOffer });
  const [isProccessing, setIsProccessing] = useState(false);
  const [status, setStatus] = useState<ACCEPT_BID_STATUS | null>(null);

  const deleteExistingOffers = async (offersData: PortfolioOffersType[] | null, quantity: number) => {
    if (!offersData?.length) return null;
    offersData.sort((a, b) => a.quantityOutstanding - b.quantityOutstanding);

    let targetedQty = quantity;
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < offersData.length; i++) {
      const { quantityOutstanding, id } = offersData[i];

      if (quantityOutstanding && targetedQty > 0) {
        targetedQty -= quantityOutstanding;
        const deleteOfferParams: IDeleteOffersParams = {
          headers: {
            emailAddress: 'portal',
            operationType: 'DeleteSellOrder',
          },
          orderId: id,
        };

        await mutateDeleteOffer(deleteOfferParams);
      }
    }
    return new Promise((resolve) => {
      // delay
      setTimeout(() => {
        resolve('done');
      }, 1500);
    });
  };

  const acceptBid = async (selectedQty: number) => {
    setIsProccessing(true);

    let requestBody: IAcceptHighestBidBody;

    if (!stocks || selectedQty > stocks?.quantity) {
      await deleteExistingOffers(offers, selectedQty);
      requestBody = {
        accountId: offers?.[0]?.assetAccountId || '',
        items: [
          {
            lwin18: offers?.[0]?.lwin18 || '',
            stopLimitPerUnit: offers?.[0]?.highestBid?.price as number,
            stopLimitType: STOP_LIMIT_TYPE.customPrice,
            priceType: 'AcceptHighestBid',
            quantity: selectedQty,
          },
        ],
      };
    } else {
      requestBody = {
        accountId: stocks?.assetAccountId || '',
        items: [
          {
            lwin18: stocks?.lwin18 || '',
            stopLimitPerUnit: stocks?.highestBid as number,
            stopLimitType: STOP_LIMIT_TYPE.customPrice,
            priceType: 'AcceptHighestBid',
            quantity: selectedQty,
          },
        ],
      };
    }

    try {
      await mutateAcceptHighestBid(requestBody);
      setStatus(ACCEPT_BID_STATUS.success);
      setTimeout(() => {
        queryClient.resetQueries({ queryKey: ['fetchCurrentHoldingVT2', { currency }] });
      }, 1200);
    } catch (error) {
      setStatus(ACCEPT_BID_STATUS.error);
      throw error;
    } finally {
      setIsProccessing(false);
    }
  };

  return { initLoading, isProccessing, status, acceptBid };
};

export default useAcceptBid;
