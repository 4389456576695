import { httpPost } from 'utils';

interface Params {
  emailAddress: string;
  password: string;
  passwordConfirmation: string;
  registeredByApp: string;
}

export interface RegistrationResult {
  userId: string;
  firstName: string;
  surname: string;
  emailAddress: string;
  cultWineId: string;
  displayName?: string;
  fullName?: string;
}

export const authRegisterApi = (config?: Params) =>
  httpPost<RegistrationResult, Params>('portal/AuthRegister', config, false);
