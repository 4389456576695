import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

export interface ApiMutationResponse<R, T> {
  executor: UseMutateAsyncFunction<AxiosResponse<R, any>, unknown, T | undefined, unknown>;
  data: AxiosResponse<R, any> | undefined;
  loading: boolean;
  error: unknown;
  status?: 'error' | 'idle' | 'loading' | 'success';
}

const useApiMutation = <T, R>(fn: (config?: T) => Promise<AxiosResponse<R, any>>) => {
  const { data, mutateAsync, error, isLoading, status } = useMutation({
    mutationFn: fn,
  });
  return { data, executor: mutateAsync, error, loading: isLoading, status };
};

export default useApiMutation;
