export const STOP_LIMIT_TYPE: Record<number | string, string> = {
  0.9: 'currentMarketPriceMinus10Percent',
  0.85: 'currentMarketPriceMinus15Percent',
  0.8: 'currentMarketPriceMinus20Percent',
  0.6: 'currentMarketPriceMinus40Percent',
  customPrice: 'customPrice',
};

export const QUERY_REFECH_TIMEOUT = 3000;
// export const ASSET_ACCOUNT_ID = 'cb98ba83-b011-41ac-9dde-5fab78faed7c'; // '057efcde-adea-49b9-b900-f35c45d978d4';
