import { httpPost } from 'utils';

interface SellApiRequest {
  lwin18: string;
  qty: number;
  marketPrice: number;
  stopLimitPrice: number;
  reasonForSale: string;
}

interface SellRequestResponse {
  isSuccess: boolean;
}

export const sellRequestApi = (config?: SellApiRequest) =>
  httpPost<SellRequestResponse, SellApiRequest>('portal/SellRequest', config);
