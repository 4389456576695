import { useReactiveVar } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import UnderMaintenance from 'components/UnderMaintenance';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import 'moment/min/locales';
import React, { Dispatch, ReactNode, createContext, useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { fetchProfile } from 'services';
import { useFetchAccountData } from 'views/hooks/useFetchAccountData';
import { useOutstandingFeeAmounts } from 'views/shared/MgmtFeePolicyEnforcer/hooks/useOutstandingFeeAmounts';
import { GET_MISCELLANEOUS } from '../App/graphql/getMiscellaneous';
import { logError } from '../components/LogError';
import { isLoggedInVar } from '../graphql/cache';
import { myCellarReducer } from '../reducers';
import { appReducer } from '../reducers/appReducer';
import { miscellaneousReducer } from '../reducers/miscellaneousReducer';
import { settingsReducer } from '../reducers/settingsReducer';
import {
  AppAction,
  AppEventTypes,
  AppState,
  LegacyFeePolicy,
  ManagementFeePolicy,
  MiscellaneousAction,
  MiscellaneousType,
  SettingsAction,
  SettingsEventTypes,
  UserSettings,
} from '../types/AppType';
import { CurrencyFormater, GbpToTargetCurrency } from '../types/commonTypes';
import { ProductActions } from '../types/productType';
import { currencyFormatter } from '../utils';
import { GET_AUTH_USER } from '../views/Accounts/graphql/getAuthUser';
import { GET_USER_PREFERENCES } from '../views/Accounts/graphql/getUserPreferences';
import { TopupSlideoutViewType } from '../views/Accounts/types';
import { useExecuteQuery } from '../views/hooks/useExecuteQuery';
import { useLazyExecuteQuery } from '../views/hooks/useLazyExecuteQuery';

declare global {
  interface Heap {
    identify(identity: string): VoidFunction;
  }

  interface Hubspot {
    push(args: unknown[]): VoidFunction;
  }

  interface Window {
    heap: Heap;
    _hsq: Hubspot;
  }
}

type ProductType = {
  id: number;
  name: string;
  price: number;
};

type InitialStateType = {
  app: AppState;
  settings: UserSettings;
  miscellaneous: MiscellaneousType;
  myCellar: ProductType[];
};

const exemptFromFeeslist = ['liquidating', 'ex-client'];
const initialState = {
  auth: { isLogin: false },
  app: {
    id: 'Portal',
    campaignPurchaseRef: null,
    isConfirmPayment: false,
    paymentType: TopupSlideoutViewType.STRIPE_RECURRING_PAYMENT,
    hasNotifications: false,
    refresh: [],
    showAddCardModal: false,
    addCardRef: {
      token: '',
      timestamp: '',
      type: '',
      dbKey: '',
    },
    hasCard: false,
    remindToPayBankTransfer: false,
    refetchPreferences: false,
  },
  settings: {
    // accountName: '',
    // userId: '',
    // assetAccountId: '',
    // relationshipManagerId: '',
    accountHolderId: null,
    // portfolios: [],
    accountInfo: null,

    language: 'en-GB',
    currency: 'GBP',
    email: '',
    useLoginEmail: false,
    fullname: '',
    firstLoginDate: '',
    isExemptFromFees: false,
    mgmtFeesPolicy: {
      type: '',
      startDate: '2023-10-1',
      endDate: '2030-12-29',
      loadOnInit: true,
      timestamp: '',
      howToPay: '',
    } as ManagementFeePolicy,

    legacyFeesPolicy: {
      startDate: '2023-10-1',
      endDate: '2030-12-29',
      howToPay: '',
      timestamp: '',
      isDismissed: false,
    } as LegacyFeePolicy,
  },
  miscellaneous: {
    frequencyIntervalMap: new Map<string, string>([
      ['day', 'daily'],
      ['week', 'weekly'],
      ['month', 'monthly'],
      ['year', 'yearly'],
    ]),
  },
  myCellar: [],
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<ProductActions | SettingsAction | MiscellaneousAction | AppAction>;
  onLanguageChange?: (lang: string) => void;
  formatter: CurrencyFormater;
  gbpToTargetCurrency: GbpToTargetCurrency;
}>({
  state: initialState,
  dispatch: () => null,
  formatter: {
    format: () => null || '',
  },
  gbpToTargetCurrency: {
    convert: () => null || 0,
    convertAndFormat: () => null || '',
  },
});

const mainReducer = (
  { myCellar, settings, miscellaneous, app }: InitialStateType,
  action: ProductActions | SettingsAction | MiscellaneousAction | AppAction,
) => ({
  myCellar: myCellarReducer(myCellar, action as ProductActions),
  app: appReducer(app, action as AppAction),
  settings: settingsReducer(settings, action as SettingsAction),
  miscellaneous: miscellaneousReducer(miscellaneous, action as MiscellaneousAction),
});

interface AppProviderProps {
  children: ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [searchParams] = useSearchParams();

  const [state, dispatch] = useReducer(mainReducer, initialState);

  const accountInfo = useFetchAccountData();

  const ldClient = useLDClient();
  const { error: miscError } = useExecuteQuery('miscellaneous', GET_MISCELLANEOUS);
  const { executor: refetchUserPreferences, data: userPreferences } = useLazyExecuteQuery(GET_USER_PREFERENCES);
  const { executor: getUserInfo, data: userInfo } = useLazyExecuteQuery(GET_AUTH_USER);

  const { i18n } = useTranslation();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const { exemptFromAllFees, portalUnderMaintenance } = useFlags();

  const userInfoEmail = (userInfo as { authUser: { emailAddress: string } })?.authUser?.emailAddress;
  const { data: userProfile } = useReactQuery({
    queryKey: ['HubSpotUserProfile', userInfoEmail],
    queryFn: () => fetchProfile({ email: userInfoEmail }),
    enabled: isLoggedIn && !!userInfoEmail,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  // const {
  //   executor: totalMgmtFeeResponseExecutor,
  //   data: totalMgmtFeeResponse,
  //   loading: isLoadingTotalMgmtFeeResponse,
  // } = useLazyExecuteQuery(GET_ESTIMATED_FEE_AMOUNT);

  const { feeAmounts: totalMgmtFeeResponse, loading: isLoadingTotalMgmtFeeResponse } = useOutstandingFeeAmounts({
    isEnabled: isLoggedIn,
  });

  const legacyFee = useMemo(() => {
    if (isLoadingTotalMgmtFeeResponse) return { isLoading: true, amount: 0 };
    return {
      isLoading: false,
      amount: (totalMgmtFeeResponse as { totalOutstandingFeesAmount: number })?.totalOutstandingFeesAmount || 0,
    };
  }, [totalMgmtFeeResponse, isLoadingTotalMgmtFeeResponse]);

  const onLanguageChange = (lang: string, updateApp = true) => {
    i18n.changeLanguage(lang);
    moment.locale(lang);
    if (updateApp)
      dispatch({
        type: SettingsEventTypes.UPDATE_SETTINGS,
        payload: { language: lang },
      });
  };

  const setLoggingUser = (name: string, email: string) => {
    Sentry.setUser({ email });
  };

  const currentLanguage = useMemo(() => i18n.language || window.localStorage.i18nextLng || navigator.language, [i18n]);
  const currencyConverter = useMemo(() => {
    return {
      format: (value: number, isNew = false) => {
        const { language, currency } = state.settings;
        const converter = currencyFormatter(language, isNew ? currency : 'GBP');
        return converter.format(value);
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.settings.currency, state.settings.language]);

  const gbpToTargetCurrency = useMemo(() => {
    const fxRate = accountInfo?.data?.account?.fxRate || 1;
    return {
      convertAndFormat: (value: number) => {
        return currencyConverter.format(value * fxRate, true);
      },
      convert: (value: number) => {
        return value * fxRate;
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyConverter, accountInfo?.data?.account?.fxRate]);

  useEffect(() => {
    if (accountInfo.isLoading || accountInfo?.error) return;
    if (accountInfo.data) {
      dispatch({
        type: SettingsEventTypes.UPDATE_SETTINGS,
        payload: {
          accountInfo: accountInfo.data,
        },
      });
    }
  }, [accountInfo.isLoading, accountInfo.error, accountInfo.data]);

  useEffect(() => {
    if (!userProfile || !userInfoEmail) return;

    const { heap, _hsq } = window;
    const { firstName, lastName } = userProfile;

    heap.identify(userInfoEmail);
    const hubspotTracker = _hsq || [];
    hubspotTracker.push(['identify', { email: userInfoEmail }]);
    hubspotTracker.push(['trackPageView']);
    setLoggingUser(`${firstName} ${lastName}`, userInfoEmail);
    dispatch({
      type: SettingsEventTypes.UPDATE_SETTINGS,
      payload: {
        email: userInfoEmail,
        fullname: `${firstName} ${lastName}`,
        isExemptFromFees: exemptFromFeeslist.includes((userProfile.cwLeadStatus ?? '').toLowerCase()),
      },
    });
  }, [userProfile, userInfoEmail]);

  useEffect(() => {
    if (userInfo && ldClient) {
      const email = (userInfo as { authUser: { emailAddress: string } }).authUser.emailAddress;

      const _ldUser = {
        key: email,
        email,
      };
      ldClient?.identify(_ldUser, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (userPreferences) {
      const usrSetttings = (userPreferences as { portalClientPreferences: UserSettings }).portalClientPreferences;
      const lang = usrSetttings.language;
      const remindToPayBankTransfer = usrSetttings.bankTransferChaseEmailCount === 2;
      if (lang !== currentLanguage) onLanguageChange(lang, false);
      dispatch({
        type: SettingsEventTypes.UPDATE_SETTINGS,
        payload: { ...usrSetttings, remindToPayBankTransfer },
      });

      if (searchParams.get('testmode')) {
        const payload = {
          testMode: searchParams.get('mock') === 'true',
          totalMgmtFees: searchParams.get('totalfees') || '0',
          remindToPayBankTransfer: searchParams.get('playreminder') === 'true',
          hasCard: searchParams.get('hascard') === 'true',
        };

        dispatch({
          type: AppEventTypes.UPDATE_STATE,
          payload,
        });

        if (searchParams.get('playmgmtfees') === 'true') {
          dispatch({
            type: SettingsEventTypes.UPDATE_MGMT_FEE_SETTINGS,
            payload: {
              timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
              howToPay: searchParams.get('howtopaymgmtfees') || '',
            },
          });
        }

        if (
          searchParams.get('playreminder') === 'true' &&
          (!searchParams.get('playmgmtfees') || searchParams.get('playmgmtfees') !== 'true')
        ) {
          dispatch({
            type: SettingsEventTypes.UPDATE_MGMT_FEE_SETTINGS,
            payload: {
              timestamp: '',
              endDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
              howToPay: 'bankTransfer',
              type: 'annual',
            },
          });
        }

        if (searchParams.get('playlegacyfees') === 'true') {
          if (
            !searchParams.get('playmgmtfees') ||
            (searchParams.get('playmgmtfees') !== 'true' &&
              (!searchParams.get('playreminder') || searchParams.get('playreminder') !== 'true'))
          ) {
            dispatch({
              type: SettingsEventTypes.UPDATE_MGMT_FEE_SETTINGS,
              payload: {
                timestamp: '',
                endDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                howToPay: searchParams.get('howtopaymgmtfees') ?? 'bankTransfer',
                type: 'annual',
              },
            });
          }

          dispatch({
            type: SettingsEventTypes.UPDATE_LEGACY_FEE_SETTINGS,
            payload: {
              timestamp: searchParams.get('playlegacyfees') === 'true' ? moment().format('YYYY-MM-DD HH:mm:ss') : '',
              endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
              howToPay: searchParams.get('howtopaylegacyfees') || '',
            },
          });
        }
      } else {
        if (legacyFee.isLoading) return;
        const isExempted = exemptFromAllFees || state.settings.isExemptFromFees;
        if (isExempted !== state.settings.isExemptFromFees)
          dispatch({
            type: SettingsEventTypes.UPDATE_SETTINGS,
            payload: {
              remindToPayBankTransfer,
              isExemptFromFees: isExempted,
            },
          });

        const disableMgmtFeeEnforcer: boolean =
          isExempted ||
          (!!usrSetttings.annualManagementFeePayType &&
            (usrSetttings.annualManagementFeePayType as string).length > 0 &&
            !!usrSetttings.managementFeePayment &&
            (usrSetttings.managementFeePayment as string).length > 0);

        const shouldCombinedfees =
          !usrSetttings.annualManagementFeePayType &&
          !usrSetttings.managementFeePayment &&
          !usrSetttings.historicManagementFeePayType &&
          legacyFee.amount > 0;

        dispatch({
          type: SettingsEventTypes.UPDATE_MGMT_FEE_SETTINGS,
          payload: {
            timestamp: disableMgmtFeeEnforcer ? '' : shouldCombinedfees ? '' : moment().format('YYYY-MM-DD HH:mm:ss'),
            endDate: disableMgmtFeeEnforcer ? '' : state.settings.mgmtFeesPolicy.endDate,
            howToPay: !usrSetttings.annualManagementFeePayType
              ? ''
              : (usrSetttings.annualManagementFeePayType as string).toLowerCase(),
            type: !usrSetttings.managementFeePayment ? '' : (usrSetttings.managementFeePayment as string).toLowerCase(),
          },
        });

        dispatch({
          type: SettingsEventTypes.UPDATE_LEGACY_FEE_SETTINGS,
          payload: {
            timestamp: isExempted
              ? ''
              : (!!usrSetttings.annualManagementFeePayType && !!usrSetttings.managementFeePayment) ||
                usrSetttings.historicManagementFeePayType
              ? moment().format('YYYY-MM-DD HH:mm:ss')
              : '',
            endDate:
              !usrSetttings.historicManagementFeePayType && !isExempted
                ? state.settings.legacyFeesPolicy.endDate
                : moment().subtract(1, 'day').format('YYYY-MM-DD'),
            howToPay: !usrSetttings.historicManagementFeePayType
              ? ''
              : (usrSetttings.historicManagementFeePayType as string).toLowerCase(),
          },
        });
      }
    }
    logError(miscError);
    logError(JSON.stringify({ email: state.settings.email, notes: 'On Login', userPreferences }), {
      tags: { userFlow: 'pay_fee_preferences' },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences, exemptFromAllFees, legacyFee.isLoading, legacyFee.amount]);

  useEffect(() => {
    if (isLoggedIn) {
      refetchUserPreferences(undefined, { fetchPolicy: 'network-only' });
      getUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (state.app.refetchPreferences) {
      refetchUserPreferences(undefined, { fetchPolicy: 'network-only' });
      dispatch({
        type: AppEventTypes.UPDATE_STATE,
        payload: {
          refetchPreferences: false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.app.refetchPreferences]);

  if (miscError) {
    logError(miscError);
  }
  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
        onLanguageChange,
        formatter: currencyConverter,
        gbpToTargetCurrency,
      }}
    >
      {portalUnderMaintenance ? <UnderMaintenance /> : children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
