import { Button } from 'components';
import { useMemo } from 'react';
import { DisplayTextKeys, OffersType, QUERY_KEYS, SetSlideoutConfigType, SlideoutConfigType } from 'views/Trade/types';
import { useQueryClient } from '@tanstack/react-query';
import useMutate from 'views/Trade/hooks/useMutate';
import { IDeleteOffersParams, deleteOffer } from 'views/Trade/services/deleteOffer';
import { QUERY_REFECH_TIMEOUT } from 'views/Trade/constants';
import { ViewStateType } from 'components/ProductTemplates/types';
import { FeedbackTemplate } from 'views/shared/FeedbackTemplate';
import { useTranslation } from 'react-i18next';
import { buildDisplayText } from 'utils';
import WineInfoTemplate from './WineInfoTemplate';

interface IAcceptBidOfMyOfferViewtProps {
  slideOutConfig: SlideoutConfigType;
  setSlideOutConfig: SetSlideoutConfigType;
}

const CancelOfferView = ({ slideOutConfig, setSlideOutConfig }: IAcceptBidOfMyOfferViewtProps) => {
  const { t } = useTranslation();
  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'trade:common', t), [t]);

  const queryClient = useQueryClient();
  const { mutateAsync: mutateDeleteOffer } = useMutate({ mutationFn: deleteOffer });

  const cancelOffer = async (offer?: OffersType) => {
    const deleteOfferParams: IDeleteOffersParams = {
      headers: {
        emailAddress: 'email' || 'portal',
        operationType: 'DeleteSellOrder',
      },
      orderId: offer?.id as string,
    };

    try {
      setSlideOutConfig((prev) => ({
        ...prev,
        view: ViewStateType.LOADING,
      }));
      await mutateDeleteOffer(deleteOfferParams);
      setSlideOutConfig((prev) => ({
        ...prev,
        view: ViewStateType.THANK_YOU,
        templateConfig: { translationKey: 'common:offerCanceled' },
        isOpen: true,
      }));
    } catch (error) {
      setSlideOutConfig((prev) => ({
        ...prev,
        view: ViewStateType.ERROR_RESULT,
        onCTA: () =>
          setSlideOutConfig(() => ({
            ...prev,
            view: ViewStateType.DEFAULT,
          })),
      }));
    }

    setTimeout(() => {
      // only works if they are separately called
      queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_OFFERS] });
      queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_OFFERS] });
      queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_STOCK] });
    }, QUERY_REFECH_TIMEOUT);
  };

  return (
    <FeedbackTemplate
      viewState={slideOutConfig?.view || ViewStateType.DEFAULT}
      templateConfig={slideOutConfig.templateConfig}
      onCTA={slideOutConfig.onCTA}
      onClose={() => {
        setSlideOutConfig({ isOpen: false });
      }}
    >
      <WineInfoTemplate config={slideOutConfig.wineInfoTemplateConfig}>
        <div className="flex flex-col py-10 px-5 drop-shadow-sm ">
          <div className="flex justify-between divide-x divide-gray-300">
            <div className="flex-1 flex flex-col">
              <span className="text-center text-sm">{`${displayText[DisplayTextKeys.CANCEL_OFFER_MESSAGE]}`}</span>
            </div>
          </div>

          <Button
            onClick={() => cancelOffer(slideOutConfig.offer)}
            type="button"
            className="text-14 font-normal rounded-full mt-8  btn  bg-orange text-black disabled:bg-[#cccccc] disabled:text-[#b3b3b3]"
          >
            {displayText[DisplayTextKeys.CANCEL]}
          </Button>
        </div>
      </WineInfoTemplate>
    </FeedbackTemplate>
  );
};

export default CancelOfferView;
