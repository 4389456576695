import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export const Success = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const isTopUp = searchParams.get('topup');

  return (
    <view className="flex h-screen bg-stone justify-center items-center p-5 text-center">
      {isTopUp ? t('common:paymentSuccess.subTitle') : t('account:payments.addCard.3DSecureSuccess')}
    </view>
  );
};
