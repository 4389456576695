import { httpPost } from 'utils';

export interface ArrangeDeliveryRequest {
  assetUnitId?: string;
  lwin18: string;
  qty: number;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  isDefault?: boolean;
}

interface ArrangeDeliveryResponse {
  isSuccess: boolean;
}

export const arrangeDeliveryApi = (config?: ArrangeDeliveryRequest) =>
  httpPost<ArrangeDeliveryResponse, ArrangeDeliveryRequest>('portal/DeliveryRequest', config);
