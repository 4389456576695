import { httpPost } from 'utils';

export interface ICreateOffersParams {
  accountId: string;
  items: {
    lwin18: string;
    stopLimitPerUnit: number;
    stopLimitType: string | undefined | number;
    quantity: number;
  }[];
}

interface Order {
  offerId: null | string;
  clientOrderId: string;
  lwin18: string;
  price: number;
  quantity: number;
}

interface Result {
  orderGroupId: string;
  buyOrSell: 'S' | 'B';
  accountId: string;
  orders: Order[];
}

interface IFetchOfferResponce {
  result: Result;
  status: 'Succeed' | 'Failed';
  errorMessage: null | string;
}

export const createOffers = (body?: ICreateOffersParams) => {
  const path = `portal/Vintrade/Orders/Sell`;
  return httpPost<IFetchOfferResponce, ICreateOffersParams>(path, body);
};
