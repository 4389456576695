import { Product } from 'types/productType';
import { httpGet } from 'utils';

interface Params {
  offset?: number;
  limit?: number;
}

interface MyCellarResponse {
  data: Product[];
  size?: number;
}

interface GetDetailsParams {
  assetUnitId: string;
}

export const fetchMyCellar = (config?: Params) => httpGet<MyCellarResponse, Params>('portal/Cellar', config);

export const fetchMyCellarDetails = (params: GetDetailsParams) =>
  httpGet<Product, GetDetailsParams>('portal/CellarDetail', params);
