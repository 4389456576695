import { useTranslation } from 'react-i18next';
import { fetchOfferDocById } from 'services';
import { useQuery } from '@tanstack/react-query';
import { logError } from '../../../components/LogError';

export const useInvestDetails = ({ id, enabled }: { id: string; enabled: boolean }) => {
  const { data, isLoading } = useQuery({
    queryFn: () => fetchOfferDocById(id),
    queryKey: ['fetchOfferDocById', id],
    enabled: enabled,
  });

  return {
    sections: useSectionsWithDisclaimer(data),
    loading: isLoading,
  };
};

const useSectionsWithDisclaimer = (data: Awaited<ReturnType<typeof fetchOfferDocById>> | undefined) => {
  const { t } = useTranslation();

  if (!data) {
    return [];
  }

  if (!('sections' in data)) {
    logError('Wrong type of document. "section" prop not found in document.');
    return [];
  }

  const { sections, disclaimer } = data;
  return [...(sections || []), ...(disclaimer ? [{ title: t('invest:section_disclaimer'), content: disclaimer }] : [])];
};
