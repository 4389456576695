import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import reportWebVitals from './reportWebVitals';
import apolloClient from './graphql/client';
import i18n from './i18n';
import FallbackError from './components/FallbackError';
import './index.css';
import App from './App';

// Only for production
if (process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring, Transactions
    tracesSampleRate: 1,
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
    },
  },
});

const AppWithWrappers = () => {
  return (
    <React.StrictMode>
      <Sentry.ErrorBoundary fallback={<FallbackError />} showDialog={false}>
        <QueryClientProvider client={queryClient}>
          <ApolloProvider client={apolloClient}>
            <Router>
              <I18nextProvider i18n={i18n}>
                <SnackbarProvider
                  autoHideDuration={3000}
                  preventDuplicate
                  maxSnack={1}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Suspense fallback="">
                    <App />
                  </Suspense>
                </SnackbarProvider>
              </I18nextProvider>
            </Router>
          </ApolloProvider>
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppWithWrappers />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
