import { gql, useMutation } from '@apollo/client';
import { CheckoutDotComCardPaymentMutation, CheckoutDotComCardPaymentMutationVariables } from '__generated__/graphql';

const CHECKOUT_COM_CARD_PAYMENT = gql`
  mutation CheckoutDotComCardPayment($args: CardPaymentInput!) {
    createCardPayment(cardPayment: $args) {
      id
      status
      errorMessage
      redirectUrl
    }
  }
`;

export function useCreateCheckoutDotComPayment() {
  const [createCardPayment, { loading }] = useMutation<
    CheckoutDotComCardPaymentMutation,
    CheckoutDotComCardPaymentMutationVariables
  >(CHECKOUT_COM_CARD_PAYMENT);

  return { createCardPayment, loading };
}
