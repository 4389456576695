import { useQuery, QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';

import { fetchCashBalance } from '../services/fetchCashBalance';
import { CashBalanceResponse } from '../types';

interface UseFetchCashBalanceResponce {
  data: CashBalanceResponse | undefined;
  isLoading: boolean;
  refetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<CashBalanceResponse, unknown>>;
  error?: unknown;
}

export const useFetchCashBalance = (): UseFetchCashBalanceResponce => {
  const { data, isLoading, refetch, error } = useQuery({
    queryFn: fetchCashBalance,
    queryKey: ['fetchCashBalance'],
  });

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};
