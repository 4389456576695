import { FC } from 'react';
import { Document, Page, View, Text } from '@react-pdf/renderer';
import { styles } from './styles/index';
import Heading from './components/header';
import Footer from './components/footer';
import { RowData } from './types';
import PdfTable from './components/table';
import { colums } from './constants';

interface IReportData {
  ReportData: RowData[];
  accountHolderId: number | null;
  accountName: string | null;
}

const CashflowReport: FC<IReportData> = ({ ReportData, accountHolderId, accountName }) => {
  return (
    <Document title="Cash flow Report">
      <Page size="B4" style={styles.page}>
        <View style={styles.baseContainer} fixed>
          <Heading accountHolderId={accountHolderId} accountName={accountName} />
        </View>
        <View style={styles.baseContainer}>
          <View style={styles.tableHead} fixed>
            {colums.map((data) => (
              <View style={styles.cell} key={data.id}>
                <Text style={styles.headingCell}>{data.title}</Text>
              </View>
            ))}
          </View>
          <PdfTable tableCol={colums} tableRow={ReportData} />
        </View>
        <View fixed>
          <Footer />
        </View>
      </Page>
    </Document>
  );
};

export default CashflowReport;
