import { ReactNode } from 'react';
import { NavigationPath } from '../../types/DomainTypes';

export interface SideBarItemType {
  title: string;
  icon?: ReactNode;
  value: NavigationPath;
  onClick?: () => void;
}

export interface SideBarProps {
  value: NavigationPath;
  onClose?: () => void;
  onClick: (item: SideBarItemType) => void;
  isSmallScreen: boolean;
}

export interface BalanceSummary {
  portfolioName: number;
  portfolioId: string;
  balance: number;
  exchangeRate: number;
}
export interface CashBalanceResponse {
  todayInvestment: number;
  balances: BalanceSummary[];
}

export interface EstimatedManagementFeeResponse {
  monthlyFeeAmount: number;
  annualFeeAmount: number;
  discountedAnnualFeeAmount: number;
  discountPct: number;
}

export enum MgmtViewsEnum {
  COMBINE_FEE_TYPE = 'combineFeeType',
  COMBINE_FEE_PAYMENT_TYPE = 'combineFeePaymentType',
  MGMT_FEE_TYPE = 'mgmtFeeType',
  LEGACY_FEE_TYPE = 'legacyFeeType',
  LEGACY_FEE_PAYMENT_TYPE = 'legacyFeePaymentType',
  MGMT_FEE_PAYMENT_TYPE = 'mgmtFeePaymentType',
  BANK_TRANSFER = 'bankTransfer',
  CONFIRMATION = 'confirmation',
  ADD_CARD_FAILED = 'addCardFailed',
  PAYMENT_REMINDER = 'paymentReminder',
  CHARGE_AND_SAVE_CARD = 'chargeAndSaveCard',
  CHARGE_AND_SAVE_CARD_FAILED = 'chargeAndSaveCardFailed',
}

export enum MgmtFeeTypeEnum {
  MONTHLY = 'monthly',
  YEARLY = 'annual',
}
