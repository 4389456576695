import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($loginRequestInput: AuthLoginRequestInput!) {
    portalAuthLogin(loginRequestInput: $loginRequestInput) {
      accessToken
      refreshToken
      userToken
      mfaToken
    }
  }
`;

export const GET_MFA_AUTHENTICATORS = gql`
  mutation GetMFAAuthenticators($getMfaAuthenticatorsRequestInput: AuthGetMfaAuthenticatorsRequestInput!) {
    portalAuthMfaAuthenticators(getMfaAuthenticatorsRequestInput: $getMfaAuthenticatorsRequestInput) {
      mfaAuthenticators {
        id
        active
        authenticatorType
        name
        oobChannel
      }
    }
  }
`;

export const ISSUE_MFA_CHALLENGE = gql`
  mutation IssueMFAChallenge($mfaChallengeRequestInput: AuthMfaChallengeRequestInput!) {
    portalAuthMfaChallenge(mfaChallengeRequestInput: $mfaChallengeRequestInput) {
      challengeType
      oobCode
    }
  }
`;
