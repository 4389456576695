import { useQuery } from '@tanstack/react-query';
import { logError } from 'components/LogError';
import { OutstandingFeeAmountReponse, outstandingFeeAmounts } from '../services/outstandingFeeAmounts';

interface UseReturnHookResponse {
  feeAmounts: OutstandingFeeAmountReponse;
  loading: boolean;
  error: unknown;
}
interface UseOutstandingFeeAmountsProps {
  isEnabled?: boolean;
}

export const useOutstandingFeeAmounts = ({
  isEnabled = true,
}: UseOutstandingFeeAmountsProps): UseReturnHookResponse => {
  const {
    data: feeAmounts,
    isLoading: loading,
    error,
  } = useQuery({
    queryFn: outstandingFeeAmounts,
    queryKey: ['outstandingFeeAmounts'],
    enabled: isEnabled,
  });

  if (error) {
    logError(JSON.stringify(error));
  }
  return {
    feeAmounts: feeAmounts ?? {
      totalOutstandingFeesAmount: 0,
      partialOutstandingFeesAmount: 0,
    },
    loading,
    error,
  };
};
