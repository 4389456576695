import { httpGet } from 'utils';

interface wine {
  id: string;
  userId: string;
  assetAccountId: string;
  lwin18: string;
  assetId: number;
  initialQuantity: number;
  quantityOutstanding: number;
  quantityFailed: number;
  price: number;
  totalPrice: number;
  buyOrSell: string;
  orderType: string;
  status: 'Completed' | 'Failed' | 'PartiallyFailed';
  createdDateTime: string;
  updatedDateTime: string;
  wineName: string;
  vintage: number;
  unitSize: string;
  region: string;
}

interface IBookGroupsOrdersResponce {
  size: number;
  data: wine[];
}

export const fetchBookGroupsOrders = (bookGroupId: string | number) => {
  const path = `portal/BookGroups/${bookGroupId}/Orders`;
  return httpGet<IBookGroupsOrdersResponce>(path);
};

// export const fetchOffer = (): Promise<BidsResponse> =>
//   new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(mockBids);
//     }, 2000);
//   });
