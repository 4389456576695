import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardDetailsForm, { FlowType } from './CardDetailsForm';
import { PortfolioBalanceIcon } from '../../../../assets/icons';
import { formatterGbp } from '../../../../utils';

interface CheckoutOtpProps {
  amount: number;
  portfolioId: number;
  btnText?: string;
  flowType?: FlowType;
  onSuccessUrl?: string;
  onFailureUrl?: string;
  isUseExistingCard?: boolean;
  transactionType?: string;
  showTopUpIcon?: boolean;
}

export const CheckoutOtp: FC<CheckoutOtpProps> = ({
  amount,
  portfolioId,
  btnText,
  flowType = FlowType.TOP_UP,
  onSuccessUrl,
  onFailureUrl,
  isUseExistingCard,
  transactionType = '',
  showTopUpIcon = true,
}) => {
  const { t } = useTranslation();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(true);
  }, []);
  return (
    <div>
      {flowType === FlowType.TOP_UP && showTopUpIcon && (
        <div className="flex items-center flex-col p-5 gap-2">
          <div className="w-[32px] h-[26.18px]">
            <PortfolioBalanceIcon className="mr-2 bg-gray-200 " />
          </div>
          <span className="text-14">{t('account:slideout.topup.top_up_amount')}</span>
          <span className="text-center text-lg border-none">{`${formatterGbp.format(amount)}`}</span>
        </div>
      )}
      {isReady && (
        <CardDetailsForm
          isUseExistingCard={isUseExistingCard}
          btnText={btnText}
          ContainerClassName="flex"
          amount={amount}
          flow={flowType}
          transactionType={transactionType}
          portfolioId={portfolioId}
          onSuccessUrl={onSuccessUrl}
          onFailureUrl={onFailureUrl}
        />
      )}
    </div>
  );
};
