import React, { FC, Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { classNames } from 'utils';
import { CloseBlackIcon } from 'assets/icons';
import MgmtPaymentTypeSelector, { RadioBtnOption } from '../MgmtFeePolicyEnforcer/mgmtPaymentTypeSelector';

interface MgmtHowToPayProps {
  show: boolean;
  savedOption?: RadioBtnOption;
  onClose?: () => void;
  onSubmit?: (data: unknown) => void;
  forceAction?: boolean;
  options: RadioBtnOption[];
  config: {
    title: string | JSX.Element;
    subTitle: string | JSX.Element;
  };
}

const HowToPay: FC<MgmtHowToPayProps> = ({
  show = false,
  config,
  options,
  onClose,
  onSubmit,
  forceAction = false,
  savedOption,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<RadioBtnOption | null>();

  function closeModal(override = false) {
    if (override || !forceAction) {
      onClose?.();
      setIsOpen(false);
    }
  }

  const onOptionSelected = (selected: RadioBtnOption) => {
    setSelectedOption(selected);
  };

  const onSubmitPreference = () => {
    setIsOpen(false);
    onSubmit?.(selectedOption);
  };

  useEffect(() => {
    if (show !== isOpen) setIsOpen(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (savedOption) setSelectedOption(savedOption);
  }, [savedOption]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-20 font-medium leading-6 text-gray-900  flex justify-between">
                  {config.title}
                  <CloseBlackIcon
                    className="right-5 cursor-pointer text-14 z-10  top-4"
                    onClick={() => closeModal(true)}
                  />
                </Dialog.Title>
                <div className="mt-2">
                  {typeof config.subTitle === 'string' ? (
                    <p className="text-14 text-gray-700">{config.subTitle}</p>
                  ) : (
                    config.subTitle
                  )}
                </div>

                <MgmtPaymentTypeSelector onSelected={onOptionSelected} options={options} selectedId={savedOption?.id} />
                <div className="mt-4 flex w-full justify-end">
                  <button
                    type="button"
                    className={classNames(
                      'inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium',
                      selectedOption ? 'bg-orange' : 'btn-disabled',
                    )}
                    onClick={() => onSubmitPreference()}
                  >
                    Select now
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default HowToPay;
