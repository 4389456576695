import { httpGet } from 'utils';
import { CampaignsOffersType } from '../types';

interface FetchCampaignOffersResponseType {
  data: CampaignsOffersType[];
  count?: number;
}

export interface campaignsListType {
  id: string;
  name: string;
  numberOfUnitsAllowed?: number;
}
export interface fetchCampaignOffersParams {
  // queryKey: string;
  listOfCampaigns: campaignsListType[];
  pageSize: number;
  page: number;
}

/* eslint-disable no-await-in-loop */
export const fetchCampaignOffers = async (params: fetchCampaignOffersParams, signal: AbortSignal | undefined) => {
  const { listOfCampaigns, page, pageSize } = params;
  if (!listOfCampaigns && !params?.listOfCampaigns?.length) return [];

  const campaignData: CampaignsOffersType[] = [];
  for (let i = 0; i < listOfCampaigns.length; i++) {
    await new Promise((resolve) => {
      setTimeout(async () => {
        // console.log('fetchResponse count ', listOfCampaigns[i]);
        const { id: campaignId, name: campaignName, numberOfUnitsAllowed } = listOfCampaigns[i];
        const url = `portal/Campaigns/${campaignId}/Offers?pageSize=${pageSize}&page=${page}`;
        const fetchResponse = await httpGet<FetchCampaignOffersResponseType>(url, undefined, true, signal);
        let resp = fetchResponse?.data ?? [];
        if (resp.length > 0)
          resp = resp.map((item) => ({
            ...item,
            numberOfUnitsAllowed: numberOfUnitsAllowed ?? -1,
            stringRef: `${campaignId};${campaignName}`,
          }));
        campaignData.push(...resp);
        resolve(null);
      }, 1000);
    });
  }

  return Promise.resolve(campaignData);
};
