import { httpDelete } from 'utils/httpDelete';

export interface IDeleteOffersParams {
  orderId: string | number;
  headers: {
    emailAddress: string;
    operationType: 'DeleteSellOrder';
  };
}

interface Result {
  offerId: string;
  clientOrderId: string;
  stopLimitPerUnit: string | null;
  quantity: string | null;
}

interface IDeleteOfferResponce {
  result: Result;
  status: 'Succeed' | 'Failed';
  errorMessage: null | string;
}

export const deleteOffer = (params?: IDeleteOffersParams) => {
  const path = `portal/Vintrade/Orders/${params?.orderId}/Sell`;
  return httpDelete<IDeleteOfferResponce>(path, true, params?.headers);
};
