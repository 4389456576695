import { ViewStateType } from 'components/ProductTemplates/types';
import { MessageType } from 'views/shared/FeedbackTemplate';
import { ActionMap } from './commonTypes';

export enum Types {
  Delete = 'DELETE_PRODUCT',
  Add = 'ADD_PRODUCT',
}

export type ProductType = {
  id: number;
  name: string;
  price: number;
};

type ProductPayload = {
  [Types.Add]: {
    id: number;
    name: string;
    price: number;
  };
  [Types.Delete]: {
    id: number;
  };
};

export type ProductActions = ActionMap<ProductPayload>[keyof ActionMap<ProductPayload>];

export interface Region {
  id: string;
  text: string;
  color: string;
  value: string;
  textColor?: string;
}

interface HoldingStockItem {
  rotationNumber: string;
  dealDate: string;
  location: string;
}

export interface HistoricMarketPriceItem {
  date: string;
  marketPrice: number;
}

export interface BaseProduct {
  id: string;
  holdingId?: string;
  assetId: number;
  assetUnitId?: string;
  lwin18: string;
  wineName: string;
  vintage: string;
  region: string;
  dealDate: string;
  dealRef: string;
  dealCCY: string;
  unit: string;
  unitCount: number;
  qty: number;
  location?: string;
  status?: string;
  portfolioId?: number;
  rotationNumber?: string;
  sanitized_wine_name?: string;
}

interface CurrentHolding extends BaseProduct {
  avgProfitAndLoss: number;
  qtyForSale: number;
  cultWinesAllocationRegion?: string;
  qtySold?: number;
  soldDate?: string;
  soldPricePerUnit?: number;
  cashOffer?: number;
  costPerUnit: number;
  avgCost?: number;
  totalCost: number;
  valuePerUnit: number;
  totalValue: number;
  changedPct: number;
  totalMgmtFee: number;
  totalCostWithMgmtFee: number;
  netPosition: number;
  profitAndLoss: number;
  profitAndLossPerUnit?: number;
  netPositionPerUnit?: number;
  costWithMgmtFeePerUnit?: number;
  mgmtFeePerUnit?: number;
  profitAndLossHistory: {
    date: string;
    profitAndLoss: number;
  }[];
  holdingStocks?: HoldingStockItem[];
  historicMarketPrices?: HistoricMarketPriceItem[];
  priceForSale: number;
  highestBid?: ActiveMarketOrder | null;
  lowestOffer?: ActiveMarketOrder | null;
}

interface ActiveMarketOrder {
  price: number | null;
  priceGbp: number | null;
  priceInTargetCurrency: number | null;
  orderType: OrderType | null;
  orderCreatedDatetime: string | null;
  quantity: number | null;
  quantityOutstanding: number | null;
}
enum OrderType {
  Regular = 'Regular',
  Campaign = 'Campaign',
  CX = 'CX',
  Cwi = 'Cwi',
  CwBid = 'CwBid',
  CwBuy = 'CwBuy',
}

export interface AcceptBidEventType {
  viewStateType: ViewStateType;
  messageType?: MessageType;
}
export interface Product extends CurrentHolding {
  imageFileName?: string;
  imageUrl?: string;
  color?: string;
  description?: string;
  stockId?: string;
}

export enum DATA_REFS {
  PORTFOLIO_ID = 'portfolioId',
  ASSETID = 'lwin18',
  NAME = 'wineName',
  DEAL_DATE = 'dealDate',
  UNIT = 'unit',
  QUANTITY = 'qty',
  AVG_COST = 'avgCost',
  COST_PER_UNIT = 'costPerUnit',
  AVG_COST_PER_UNIT = 'avgCostPerUnit',
  AVG_VALUE_PER_UNIT = 'avgValuePerUnit',
  COST_PER_UNIT_INCLUDING_MANAGEMENT_FEE = 'costWithMgmtFeePerUnit',
  TOTAL_COST = 'totalCost',
  TOTAL_COST_INCLUDING_MANAGEMENT_FEE = 'totalCostWithMgmtFee',
  VALUE_PER_UNIT = 'valuePerUnit',
  TOTAL_VALUE = 'totalValue',
  PERCENT_CHANGE = 'changedPct',
  PROFIT_LOSS = 'profitAndLoss',
  REGION = 'region',
  VINTAGE = 'vintage',
  SOLD_DATE = 'soldDate',
  QTY_SOLD = 'qtySold',
  SOLD_PER_UNIT = 'soldPricePerUnit',
  STATUS = 'status',
  LOCATION = 'location',
  CASH_OFFER = 'cashOffer',
  ROTATION_NUMBER = 'rotationNumber',
  SANITIZED_WINE_NAME = 'sanitized_wine_name',
  NET_POSITION = 'netPosition',
  CULT_WINES_ALLOCATION_REGION = 'cultWinesAllocationRegion',
  PRICE_FOR_SALE = 'priceForSale',
  HIGHEST_LIVE_BID = 'highestBid',
  LOWEST_OFFER = 'lowestOffer',
}
