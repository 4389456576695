import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PortfolioBalance } from 'types/DomainTypes';

interface PortfolioMetaDataTableProps {
  portfolios: PortfolioBalance[];
}

export default function PortfolioMetaDataTable({ portfolios: portfolioBalances }: PortfolioMetaDataTableProps) {
  const { t } = useTranslation();
  return (
    <table className="table-auto w-full text-black">
      <thead className="bg-gray-50">
        <tr className="border-b border-gray-200 text-left text-[12px]">
          <th className="py-3 px-6 whitespace-nowrap font-normal">
            {t('account:overviewReport.managementFees.table.headers.clientName')}
          </th>
          <th className="py-3 px-6 whitespace-nowrap font-normal">
            {t('account:overviewReport.managementFees.table.headers.firstDealDate')}
          </th>
          <th className="py-3 px-6 whitespace-nowrap font-normal">
            {t('account:overviewReport.managementFees.table.headers.anniversary')}
          </th>
        </tr>
      </thead>
      <tbody>
        {portfolioBalances.map((portfolio) => (
          <tr className="bg-white divide-x text-[14px] text-left border-b" key={portfolio.portfolioId}>
            <td className="py-4 px-6 whitespace-nowrap">{portfolio.portfolioName}</td>
            <td className="py-4 px-6 whitespace-nowrap">{moment(portfolio.firstDealDate).format('YYYY-MM-DD')}</td>
            <td className="py-4 px-6 whitespace-nowrap">
              {moment(portfolio.firstDealDate).add(5, 'years').format('YYYY-MM-DD')}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
