import { httpGet } from 'utils';
import { StocksType } from '../types';

interface IFetchPortfolioStocksResponce {
  data: StocksType[];
  count?: number;
}

interface IFetchPortfolioStocksParam {
  page?: number;
  pageSize?: number;
  search?: string;
}

export const fetchPortfolioStocks = (params?: IFetchPortfolioStocksParam, signal?: AbortSignal | undefined) => {
  const path = `portal/VintradePortfolioStocks?pageSize=${params?.pageSize || 200}&page=${params?.page || 0}${
    params?.search ? `&queryStr=${params.search}` : ''
  }`;
  return httpGet<IFetchPortfolioStocksResponce>(path, undefined, true, signal);
};
