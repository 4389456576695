import Loading from 'components/Loading/loading';
import {
  DisplayTextKeys,
  QUERY_KEYS,
  SeletedWineType,
  SetSlideoutConfigType,
  SlideoutConfigType,
} from 'views/Trade/types';
import { Button, Dropdown } from 'components';
import CustomInput from 'components/CustomInput';
import { DisplayFieldType } from 'components/DisplayForms';
import { ChangeEvent, useContext, useMemo, useState } from 'react';
import useMutate from 'views/Trade/hooks/useMutate';
import useDebounce from 'components/ExpandingSearch/useDebounce';
import { fetchPortfolioStocks } from 'views/Trade/services/fetchPortfolioStocks';
import { QUERY_REFECH_TIMEOUT, STOP_LIMIT_TYPE } from 'views/Trade/constants';
import { useFetch } from 'views/Trade/hooks/useFetch';
import { ICreateOffersParams, createOffers } from 'views/Trade/services/createOffer';
import { ViewStateType } from 'components/ProductTemplates/types';
import { generateQuantityDropdown, stopLimitsItems } from 'views/Trade/helpers';
import { FeedbackTemplate } from 'views/shared/FeedbackTemplate';
import { AppContext } from 'context/ContextProvider';
import { useQueryClient } from '@tanstack/react-query';
import { buildDisplayText } from 'utils';
import { useTranslation } from 'react-i18next';
import WineInfoTemplate from './WineInfoTemplate';

interface ICreateOfferSlideOutProps {
  slideOutConfig: SlideoutConfigType;
  setSlideOutConfig: SetSlideoutConfigType;
}
type ScreenStateType = 'select-wine-screen' | 'submit-offer-screen';
const DEBOUNCE_TIMEOUT = 1000;

const CreateOfferView = ({ setSlideOutConfig, slideOutConfig }: ICreateOfferSlideOutProps) => {
  const { formatter, gbpToTargetCurrency } = useContext(AppContext);
  const { t } = useTranslation();
  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'trade:common', t), [t]);

  const queryClient = useQueryClient();
  const [screenState, setScreenState] = useState<ScreenStateType>(
    slideOutConfig.extraConfig?.initSelectedWine ? 'submit-offer-screen' : 'select-wine-screen',
  );
  const [selected, setSelected] = useState<string>();
  const [quantitySelected, setQuantitySelected] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [stopLimits, setStopLimits] = useState<{ text: string | undefined; value: number }>({ text: '', value: 0 });

  const { isLoading, mutateAsync } = useMutate({ mutationFn: createOffers });
  const { isLoading: isLoadingPortfolioStocks, responce: portfolioStocksResponce } = useFetch({
    queryFn: () => fetchPortfolioStocks({ search }),
    queryKey: [`${QUERY_KEYS.FETCH_PORTFOLIO_STOCK}${search}`],
  });
  const debouncedFunction = useDebounce((event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    setSearch(event.target.value);
  }, DEBOUNCE_TIMEOUT);

  const seletedWine: SeletedWineType | null = useMemo(() => {
    const initSelectedWine = slideOutConfig.extraConfig?.initSelectedWine;
    if (initSelectedWine) {
      const wineSelected = {
        ...initSelectedWine,
        marketValueGbp: initSelectedWine.marketValue,
        marketValueInTargetCurrency: gbpToTargetCurrency.convert(initSelectedWine.marketValue),
      };

      const { lwin18, marketValueInTargetCurrency, quantity, unitSize, vintage, wineName } = wineSelected;
      setSlideOutConfig((prev) => ({
        ...prev,
        showBackButton: true,
        wineInfoTemplateConfig: {
          showTemplate: true,
          lwin18,
          marketValue: marketValueInTargetCurrency,
          quantity,
          unitSize,
          vintage,
          wineName,
          region: wineSelected.region,
          cultWinesAllocationRegion: wineSelected.cultWinesAllocationRegion ?? '',
        },
      }));
      return wineSelected;
    }

    const select = portfolioStocksResponce?.data.filter((x) => x.id === selected)[0];
    if (!select) return null;
    return {
      ...select,
      marketValueGbp: select.marketValue,
      marketValueInTargetCurrency: gbpToTargetCurrency.convert(select.marketValue),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, portfolioStocksResponce, gbpToTargetCurrency, slideOutConfig.extraConfig?.initSelectedWine]);

  const handleSubmit = async () => {
    // console.log('seletedWine ', seletedWine);
    // return;
    const requestBody: ICreateOffersParams = {
      accountId: seletedWine?.assetAccountId || '',
      items: [
        {
          lwin18: seletedWine?.lwin18 || '',
          stopLimitPerUnit: (seletedWine?.marketValueGbp || 0) * stopLimits.value,
          stopLimitType: STOP_LIMIT_TYPE[stopLimits.value],
          quantity: quantitySelected,
        },
      ],
    };

    try {
      setSlideOutConfig((prev) => ({ ...prev, view: ViewStateType.LOADING, showBackButton: false }));
      await mutateAsync(requestBody);
      setSlideOutConfig((prev) => ({
        ...prev,
        view: ViewStateType.THANK_YOU,
        templateConfig: {
          translationKey: 'common:offerCreated',
          onClick: () => {
            setSlideOutConfig({ isOpen: false });
            slideOutConfig.extraConfig?.success?.cta?.();
          },
        },
        isOpen: true,
      }));
      slideOutConfig.extraConfig?.success?.onSuccess?.();
    } catch (error) {
      setSlideOutConfig((prev) => ({
        ...prev,
        view: ViewStateType.ERROR_RESULT,
        showBackButton: false,
        onCTA: () => {
          setSlideOutConfig({ isOpen: false });
          slideOutConfig.extraConfig?.error?.cta?.();
        },
      }));
    }

    setTimeout(() => {
      queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_OFFERS] });
      queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_STOCK] });
    }, QUERY_REFECH_TIMEOUT);
  };

  const onNext = (wineSelected: SeletedWineType | null) => {
    if (!wineSelected) return;
    const { lwin18, marketValueInTargetCurrency, quantity, unitSize, vintage, wineName } = wineSelected;
    setScreenState('submit-offer-screen');
    setSlideOutConfig((prev) => ({
      ...prev,
      onBack: () => {
        setSlideOutConfig((previous) => ({
          ...previous,
          showBackButton: false,
          wineInfoTemplateConfig: { showTemplate: false },
        }));
        setScreenState('select-wine-screen');
        setSelected('');
        setQuantitySelected(0);
        setStopLimits({ text: '', value: 0 });
      },
      showBackButton: true,
      wineInfoTemplateConfig: {
        showTemplate: true,
        lwin18,
        marketValue: marketValueInTargetCurrency,
        quantity,
        unitSize,
        vintage,
        wineName,
        region: wineSelected.region,
        cultWinesAllocationRegion: wineSelected.cultWinesAllocationRegion ?? '',
      },
    }));
  };

  if (screenState === 'select-wine-screen') {
    return (
      <div className="flex bg-white overflow-y-auto flex-col flex-1">
        <CustomInput
          type={DisplayFieldType.TEXT}
          value={search}
          onChange={debouncedFunction}
          placeholder={displayText[DisplayTextKeys.SEARCH_STOCK_PLACEHOLDER]}
          name={displayText[DisplayTextKeys.SEARCH]}
          className="flex w-full px-2 pt-2 border-gray-200 items-center"
        />
        <div className="w-full flex-1 rounded-t-md  overflow-y-auto divide-gray-300 bg-white flex flex-col">
          <div className="  flex flex-1 flex-col gap-2">
            {isLoadingPortfolioStocks ? (
              <Loading />
            ) : !portfolioStocksResponce?.data.length && search ? (
              <p className="text-sm text-center min-h-[30px] p-2">{displayText[DisplayTextKeys.NOT_FOUND]}</p>
            ) : !portfolioStocksResponce?.data.length && !search ? (
              <p className="text-sm text-center min-h-[30px] p-2">{displayText[DisplayTextKeys.NO_STOCK_FOUND]}</p>
            ) : (
              <div className="flex flex-col  overflow-y-auto">
                {(portfolioStocksResponce?.data || []).map((wine) => (
                  <button
                    key={wine.id}
                    id={wine.id}
                    className={`text-sm text-left min-h-[30px] border-b p-2 ${
                      wine.id === selected ? 'bg-gray-300' : ''
                    }`}
                    onClick={() => {
                      setSelected(wine.id);
                    }}
                  >
                    <p>{`${wine.vintage} ${wine.wineName}`}</p>
                    <div className="flex mt-1">
                      <p className="rounded-lg px-2 py-1 border  text-gray-700">{`${wine.quantity} x (${wine.unitSize})`}</p>
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="bg-white flex flex-col p-2 rounded-b-md">
          <Button
            isDisable={!selected}
            onClick={() => onNext(seletedWine)}
            className="bg-orange text-sm  disabled:bg-gray-700"
          >
            {displayText[DisplayTextKeys.NEXT]}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <FeedbackTemplate
      viewState={slideOutConfig?.view || ViewStateType.DEFAULT}
      templateConfig={slideOutConfig.templateConfig}
      onCTA={slideOutConfig.onCTA}
      onClose={() => {
        setSlideOutConfig({ isOpen: false });
      }}
    >
      <WineInfoTemplate config={slideOutConfig.wineInfoTemplateConfig}>
        <div className="flex bg-white flex-col ">
          <div className="px-5 py-3 flex flex-col gap-1 mt-5">
            <div className="flex flex-col gap-2">
              <Dropdown
                placeholder={displayText[DisplayTextKeys.PLEASE_SELECT_QUANTITY]}
                value={quantitySelected > 0 ? quantitySelected?.toString() : ''}
                containerClassName="w-full rounded "
                itemsContainerClassName="max-h-[250px] min-h-10 overflow-y-auto"
                className="flex-1 text-14 text-black border  h-12 p-4 rounded justify-between items-center"
                itemsWrapperClassName="w-full"
                items={generateQuantityDropdown(seletedWine?.quantity)}
                onItemSelect={(item) => setQuantitySelected(Number(item.value))}
              />
              <Dropdown
                placeholder={displayText[DisplayTextKeys.DEFAULT_STOP_LIMIT]}
                value={stopLimits.text}
                containerClassName="w-full rounded "
                itemsContainerClassName="max-h-[250px] overflow-y-auto"
                className="flex-1 text-14 text-black border p-4 h-12 rounded justify-between items-center"
                itemsWrapperClassName="w-full"
                items={stopLimitsItems}
                onItemSelect={(item) => setStopLimits({ text: item.text, value: Number(item.value) })}
              />
              <span className="text-xs text-gray-500">{displayText[DisplayTextKeys.MAKE_OFFER_EXPLAINER]}</span>
            </div>
          </div>

          <div className="flex flex-col py-10 px-5 drop-shadow-sm ">
            <div className="flex justify-between divide-x divide-gray-300">
              <div className="flex-1 flex flex-col">
                <span className="text-center text-sm">{displayText[DisplayTextKeys.TOTAL_MARKET_VALUE]}</span>
                <span className="text-center text-20">
                  {formatter.format(
                    Number((seletedWine?.quantity || 0) * (seletedWine?.marketValueInTargetCurrency || 0)),
                    true,
                  )}
                </span>
              </div>
            </div>

            <Button
              isDisable={!(quantitySelected > 0 && stopLimits.value > 0) || isLoading}
              onClick={() => {
                handleSubmit();
              }}
              type="button"
              className="text-14 font-normal rounded-full mt-8  btn  bg-orange text-black disabled:bg-[#cccccc] disabled:text-[#b3b3b3]"
            >
              {displayText[DisplayTextKeys.SUBMIT_OFFER]}
            </Button>
          </div>
        </div>
      </WineInfoTemplate>
    </FeedbackTemplate>
  );
};

export default CreateOfferView;
