import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components';
import { logError } from '../../../components/LogError';
import { appIsWorkingVar, isLoggedInVar } from '../../../graphql/cache';
import { updateAccessToken, updateRefreshToken, updateUserToken } from '../../../services/auth';
import { NavigationPath } from '../../../types/DomainTypes';
import AuthLayout from '../components/AuthLayout';
import OtpInput from '../components/OtpInput/OtpInput';
import ShowTextIn from '../components/ShowTextIn';
import { submitMfaChallenge } from '../helpers';
import { useAuth } from '../hooks/useAuthentication';
import useCountdown from '../hooks/useCountdown';
import useMfaChallengeMutation from '../hooks/useMfaChallengeMutation';
import { MfaRequestProps } from '../types';

export interface OTPFormProps {
  mfaToken: string | null;
  mfaOOBCode: string | null;
  emailAddress: string | null;
  timesStamp?: number;
  password?: string;
  passcode?: string;
  mfaCodeLength?: number;
  viewExpiryTimeInSeconds?: number;
  onBack?: () => void;
  onSucess?: () => void;
}

interface MFAViewState {
  isLoading: boolean;
  showWaitToSubmit: boolean;
  showCodeGenTimer: boolean;
  isViewExpired: boolean;
}

const FIVE_MINUTE = 10 * 60 * 1000;
const NOW_IN_MS = new Date().getTime();
const dateTimeAfter5Minutes = NOW_IN_MS + FIVE_MINUTE;

const defaultViewState = {
  isLoading: false,
  showWaitToSubmit: false,
  showCodeGenTimer: false,
  isViewExpired: false,
};
export const MFAChallengeForm = ({
  mfaToken,
  mfaOOBCode,
  emailAddress,
  password,
  passcode,
  timesStamp = NOW_IN_MS,
  mfaCodeLength = 6,
  viewExpiryTimeInSeconds = dateTimeAfter5Minutes,
  onBack,
  onSucess,
}: OTPFormProps): JSX.Element => {
  const [oneTimePasscode, setOneTimePasscode] = useState(passcode || '');
  const [error, setError] = useState<Error | null>(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [viewState, setViewState] = useState<MFAViewState>({ ...defaultViewState });

  const [OTPInfo, setOTPInfo] = useState({ code: mfaOOBCode, token: mfaToken, email: emailAddress, password });
  const { submitOTP, loading } = useMfaChallengeMutation();
  const [isTimerEnabled, setIsTimerEnabled] = useState(false);
  const { data: countDownData, onTargetDateChange } = useCountdown(viewExpiryTimeInSeconds);
  const [, , minutes, seconds] = countDownData;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const errorHandler = (message: string) => {
    setIsLoggingIn(false);

    throw new Error(message);
  };

  const onMFARequested = ({ mfaOOBCode: _mfaOOBCode, mfaToken: _mfaToken }: MfaRequestProps): void => {
    setOTPInfo({ ...OTPInfo, code: _mfaOOBCode, token: _mfaToken });
  };

  const { onLogin } = useAuth({
    errorHandler,
    onError: (e: Error) => {
      setIsLoggingIn(false);
      setError(e);
    },
    onMFARequested,
  });

  const onClearTimer = () => {
    setViewState({ ...viewState, showCodeGenTimer: false });
  };

  if (isTimerEnabled && minutes + seconds <= 0 && !viewState.isViewExpired) {
    setIsLoggingIn(false);
    setViewState({ ...viewState, isViewExpired: true });
    setError(new Error(t('auth:otpForm.viewExpired')));
  }

  if (isTimerEnabled && minutes + seconds > 0 && viewState.isViewExpired) {
    setViewState({ ...viewState, isViewExpired: false });
    setError(null);
  }

  const reloadView = (initialTimeStamp = new Date().getTime(), update?: Partial<MFAViewState>) => {
    onTargetDateChange(initialTimeStamp + FIVE_MINUTE);
    setIsTimerEnabled(true);
    setViewState({ ...defaultViewState, ...(update || {}) });
    setError(null);
  };

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    const { token: _token, code: _code, email: _email } = OTPInfo;
    if (!_token || !_code || !_email) {
      logError('Invalid data submitted for MFA');
      setError(t('auth:otpForm.invalidData'));
      return;
    }
    setIsLoggingIn(true);
    setViewState({ ...viewState, showWaitToSubmit: true });
    submitMfaChallenge(
      { mfaOOBCode: _code, oneTimePasscode, mfaToken: _token },
      {
        appIsWorkingVar,
        submitOTP,
        t,
        onError: (message) => {
          setIsLoggingIn(false);
          logError(`FAILED: ${message}`);
          setError(new Error(message));
        },
        onSuccess: ({ portalAuthMfaVerify: { accessToken, refreshToken, userToken } }) => {
          setIsLoggingIn(false);
          updateAccessToken(accessToken);
          updateRefreshToken(refreshToken);
          updateUserToken(userToken);
          onSucess?.();
          navigate(NavigationPath.PORTFOLIO);
        },
        isLoggedInVar,
      },
    );
  }

  const onResendCode = () => {
    if (emailAddress && password) {
      // setViewState({ ...viewState, showCodeGenTimer: true });
      reloadView(new Date().getTime(), { showCodeGenTimer: true });
      onLogin({ email: emailAddress, password });
    }
  };

  useEffect(() => {
    reloadView(timesStamp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesStamp]);

  const isSubmitDisabled =
    viewState.isViewExpired ||
    loading ||
    viewState.showWaitToSubmit ||
    viewState.showCodeGenTimer ||
    oneTimePasscode.length !== mfaCodeLength;

  return (
    <AuthLayout
      title={t('auth:otpForm.title')}
      error={{ body: error?.message || '', onClose: () => setError(null) }}
      subTitle={t('auth:otpForm.subTitle')}
      classNames={{
        title: 'w-[70%]',
        subTitle: 'w-[70%]',
      }}
    >
      <form
        className="w-[70%] my-10 flex flex-col items-center  max-w-[420px] "
        aria-label="one-time-passcode"
        noValidate
        onSubmit={handleSubmit}
      >
        <OtpInput
          value={oneTimePasscode}
          valueLength={mfaCodeLength}
          onChange={(value) => {
            setOneTimePasscode(value.trim());
          }}
        />

        <Button
          type="submit"
          isDisable={isSubmitDisabled}
          className={`w-full mt-10 mb-3 ${isSubmitDisabled ? 'btn-disabled' : 'btn-primary'}`}
          isProcessing={isLoggingIn}
          props={{
            name: 'mfa',
          }}
        >
          {t('common:submit')}
        </Button>

        {!viewState.isViewExpired && viewState.showCodeGenTimer && (
          <div className="flex cursor-pointer justify-center">
            <ShowTextIn translationKey="auth:otpForm.resendCode" initValue={10} onEnd={onClearTimer} />
          </div>
        )}
        {viewState.isViewExpired && (
          <Button
            isLink={true}
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            {t('auth:otpForm.backToLogin')}
          </Button>
        )}

        {!viewState.isViewExpired && viewState.showWaitToSubmit && (
          <div className="flex cursor-pointer justify-center">
            <ShowTextIn
              translationKey={t('auth:otpForm.waitToResubmit')}
              initValue={10}
              onEnd={() => setViewState({ ...viewState, showWaitToSubmit: false })}
            />
          </div>
        )}

        {!viewState.isViewExpired && !viewState.showCodeGenTimer && !viewState.showWaitToSubmit && (
          <Button isLink={true} onClick={onResendCode}>
            {t('auth:otpForm.regenerateCode')}
          </Button>
        )}
      </form>
    </AuthLayout>
  );
};
