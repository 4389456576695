import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { logError } from '../../../../../components/LogError';
import { ManagementFeeType } from '../types';
import { fetchManagementFees } from '../services/fetchManagementFees';

interface UseManagemntFeesReturnHookResponse {
  managmentFees: ManagementFeeType[];
  loading: boolean;
  error: unknown;
}

export const useManagementFees = (): UseManagemntFeesReturnHookResponse => {
  const {
    data,
    isLoading: loading,
    error,
  } = useQuery({
    queryFn: fetchManagementFees,
    queryKey: ['fetchManagementFees'],
  });

  const managmentFees = useMemo(
    () =>
      (data?.data || []).map((x: ManagementFeeType) => ({
        ...x,
        portfolioId: x.portfolioId || '',
      })),
    [data],
  );

  if (error) {
    logError(JSON.stringify(error));
  }
  return {
    managmentFees,
    loading,
    error,
  };
};
