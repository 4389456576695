import { FC, Fragment, ReactNode, useContext, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { classNames } from 'utils';
import { AppContext } from 'context/ContextProvider';
import { useTranslation } from 'react-i18next';
import MgmtPaymentTypeSelector, { RadioBtnOption } from './mgmtPaymentTypeSelector';
import { MgmtFeeTypeEnum, MgmtViewsEnum } from '../types';

interface FeePolicyEnforcerTemplateProps {
  show: boolean;
  onClose?: () => void;
  onSubmit?: (data: unknown, amount?: number) => void;
  forceAction?: boolean;
  amount?: number;
  hasOptions?: boolean;
  accruedFee?: number;
  options?: RadioBtnOption[];
  eventType: MgmtViewsEnum;
}

interface TextConfigType {
  title: ReactNode;
  subTitle: ReactNode;
  totalDueToday: number;
}

const FeePolicyEnforcerTemplate: FC<FeePolicyEnforcerTemplateProps> = ({
  show = false,
  onClose,
  onSubmit,
  forceAction = false,
  hasOptions = true,
  options,
  eventType,
  accruedFee = 0,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<RadioBtnOption | null>();
  const { formatter: currencyFormatter } = useContext(AppContext);
  function closeModal() {
    if (!forceAction) {
      onClose?.();
      setIsOpen(false);
    }
  }

  const onOptionSelected = (selected: RadioBtnOption) => {
    setSelectedOption(selected);
  };

  const textConfig = (): TextConfigType => {
    switch (eventType) {
      case MgmtViewsEnum.COMBINE_FEE_TYPE:
        let total = 0;
        if (selectedOption?.value === MgmtFeeTypeEnum.MONTHLY) total = Number(accruedFee);
        if (selectedOption?.value === MgmtFeeTypeEnum.YEARLY) total = Number(selectedOption?.data) + accruedFee;
        return {
          totalDueToday: total,
          title: t('account:feePolicyEnforcerTemplate.common.title'),
          subTitle: (
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <h2 className="text-base text-gray-800">
                  {t('account:feePolicyEnforcerTemplate.mgmtFee&AccruedFee.subTitle1')}
                </h2>
                <p>
                  {t('account:feePolicyEnforcerTemplate.mgmtFee&AccruedFee.explainer1')}{' '}
                  {currencyFormatter.format(accruedFee || 0, true)}
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <h2 className="text-base text-gray-800">
                  {t('account:feePolicyEnforcerTemplate.mgmtFee&AccruedFee.subTitle2')}
                </h2>
                <p className="mb-2">{t('account:feePolicyEnforcerTemplate.mgmtFee&AccruedFee.explainer2')}</p>
                <p>{t('account:feePolicyEnforcerTemplate.mgmtFee&AccruedFee.note')}</p>
              </div>
            </div>
          ),
        };

      case MgmtViewsEnum.LEGACY_FEE_TYPE:
        return {
          totalDueToday: accruedFee,
          title: t('account:feePolicyEnforcerTemplate.common.title'),
          subTitle: (
            <div className="flex flex-col gap-4 mb-2">
              <div className="flex flex-col gap-2">
                <h2 className="text-base text-gray-800">
                  {t('account:feePolicyEnforcerTemplate.mgmtFee&AccruedFee.subTitle1')}
                </h2>
                <p>
                  {t('account:feePolicyEnforcerTemplate.mgmtFee&AccruedFee.explainer1')}{' '}
                  {currencyFormatter.format(accruedFee || 0, true)}
                </p>
              </div>
            </div>
          ),
        };
      default:
        return { subTitle: '', title: '', totalDueToday: 0 };
    }
  };

  const onSubmitPreference = () => {
    setIsOpen(false);
    onSubmit?.(selectedOption?.value, textConfig().totalDueToday);
  };

  useEffect(() => {
    if (show !== isOpen) setIsOpen(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-20 font-medium leading-6 text-gray-900">
                  {textConfig().title}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{textConfig().subTitle}</p>
                </div>
                {options && <MgmtPaymentTypeSelector onSelected={onOptionSelected} options={options} />}

                <span className="text-sm text-gray-800">
                  {`${t('account:feePolicyEnforcerTemplate.common.totalDueToday')} ${currencyFormatter.format(
                    textConfig().totalDueToday,
                    true,
                  )}`}
                </span>
                {selectedOption?.value === MgmtFeeTypeEnum.MONTHLY && (
                  <p className="text-sm text-gray-500 mt-2 ">
                    {t('account:feePolicyEnforcerTemplate.mgmtFee&AccruedFee.note2')}
                  </p>
                )}
                <div className="mt-2  flex w-full justify-end">
                  <button
                    type="button"
                    className={classNames(
                      'inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium',
                      selectedOption || !hasOptions ? 'bg-orange' : 'btn-disabled',
                    )}
                    onClick={() => onSubmitPreference()}
                  >
                    {!hasOptions
                      ? t('account:mgmtFeePreference.optionModal.nextBtnText')
                      : t('account:mgmtFeePreference.optionModal.btnText')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default FeePolicyEnforcerTemplate;
