import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AddCardModalWrapper from 'views/Accounts/components/Checkout.Com/AddCardModal/Wrapper';
import { OpenSlideoutFnType, SlideOutPanelViews, TopupSlideoutViewState } from 'views/Accounts/types';
import SlideOutPanel from 'components/SlideOutPanel';
import { TopupSlideout, TopupSlideoutRefType } from 'views/shared/TopupSlideout';
import { useTranslation } from 'react-i18next';
import { NavigationPath } from 'types/DomainTypes';
import { AppEventTypes } from 'types/AppType';
import { AppContext } from 'context/ContextProvider';
import { ProtectedRouteContextType } from './useProtectedPageContext';

export const ProtectedPageContainer: FC = () => {
  const { t } = useTranslation();
  const topUpSlideoutRef = useRef();
  const [slideOutPanelConfig, setSlideOutPanelConfig] = useState({ ...defaultSlideouState });
  const { cardManagement14583 } = useFlags();

  const { pathname } = useLocation();
  const { dispatch } = useContext(AppContext);

  const openSlideout: OpenSlideoutFnType = useCallback(
    (view, config) => {
      setSlideOutPanelConfig({
        showBackButton: false,
        open: true,
        title: t('account:slideout.topup.title'),
        view,
        timestamp: Date.now(),
        initialModel: undefined,
        initialView: undefined,
        customTemplate: () => null,
        ...(config || {}),
      });
    },
    [t],
  );

  useEffect(() => {
    if (pathname.endsWith(NavigationPath.PAYMENT_CONFIRMATION)) {
      dispatch({
        type: AppEventTypes.UPDATE_STATE,
        payload: {
          isConfirmPayment: true,
        },
      });
      openSlideout(SlideOutPanelViews.TOP_UP);
    }
  }, [dispatch, openSlideout, pathname]);

  const onBack = () => {
    (topUpSlideoutRef?.current as unknown as TopupSlideoutRefType)?.onBack();
  };

  const onClose = () => {
    setSlideOutPanelConfig((prev) => ({ ...prev, open: false }));
  };

  const onTopupSlideoutStateChange = (state: TopupSlideoutViewState) => {
    setSlideOutPanelConfig((prev) => ({ ...prev, showBackButton: state.showBackButton }));
  };

  const context: ProtectedRouteContextType = { openSlideout, backSlideout: onBack, closeSlidout: onClose };

  return (
    <>
      {/* Code that needs to be rendered on all protected routes should be placed here. */}
      <SlideOutPanel
        showBackButton={slideOutPanelConfig.showBackButton}
        headClassName="bg-gray-100 text-black"
        title={slideOutPanelConfig.title}
        isOpen={slideOutPanelConfig.open}
        onBack={onBack}
        onClose={() => setSlideOutPanelConfig({ ...slideOutPanelConfig, open: false })}
      >
        {slideOutPanelConfig.open && slideOutPanelConfig.view === SlideOutPanelViews.TOP_UP && (
          <TopupSlideout
            initialModel={slideOutPanelConfig.initialModel}
            initialView={slideOutPanelConfig.initialView}
            ref={topUpSlideoutRef}
            onClose={onClose}
            onStateChange={onTopupSlideoutStateChange}
          />
        )}
        {slideOutPanelConfig.open &&
          slideOutPanelConfig.view === SlideOutPanelViews.CUSTOM &&
          slideOutPanelConfig.customTemplate &&
          slideOutPanelConfig.customTemplate()}
      </SlideOutPanel>
      {cardManagement14583 && <AddCardModalWrapper />}
      <Outlet context={context} />
    </>
  );
};

const defaultSlideouState = {
  showBackButton: false,
  open: false,
  view: SlideOutPanelViews.TOP_UP,
  title: '',
  timestamp: Date.now(),
  initialModel: undefined,
  initialView: undefined,
  customTemplate: () => null,
};
